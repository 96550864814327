import jwt from 'jsonwebtoken';
import { refreshToken } from 'api/api';

function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export function getCurrentUser() {
    let authtoken = getCookie('access_token');
    if (authtoken) {
        let decode1 = jwt.decode(authtoken);

        return decode1;
    }
    return null;
}

export function isAdmin() {
    let authtoken = getCookie('access_token');
    if (authtoken) {
        let decode1 = jwt.decode(authtoken);
        if (decode1) {
            let roles = decode1.roles;
            if (Array.isArray(roles)) {
                let role = roles.find((item) => item.role_type_id === 3);

                if (role) {
                    return true;
                }
            }

            return false;
        } else {
            return false;
        }
    }
    return false;
}

export function isAuthed() {
    console.log('$$$$$$$$$$$$$$$$$$$$$');
    let authtoken = localStorage.getItem('admin_token');
    if (authtoken) {
        let decode1 = jwt.decode(authtoken);

        try {
            let decode2 = jwt.verify(
                authtoken,
                '3e32edae4ccef34049f79aef15b0d718a4fcc7e483e65e6ebc6dc0a3740adf70d134edc56e830a0473c78dc5df2dfa2778c789bfb63d2f9e2720589d4ccdf5b6'
            );
            console.log(decode2, 'this is the decode2 with verify');
            return true;
        } catch (err) {
            console.error('This is the decode error: ', err);
            return false;
        }

        if (decode1) {
            console.log(decode1, ': the token is valid');
            return true;
        }
    }
    console.log('the token is not valid');
    return false;
}

export function logout() {
    localStorage.setItem('admin_token', '');
}

export async function refresh() {
    let _refresh = await refreshToken();

    console.log(_refresh, 'this is the response from the api call');

    if (_refresh.success) {
        localStorage.setItem('admin_token', _refresh.data.accessToken);
        localStorage.setItem('admin_refresh_token', _refresh.data.refreshToken);

        setCookie('admin_token', _refresh.data.accessToken, 1); // 12.19.2022

        return { success: true };
    } else {
        return { success: false };
    }
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let _domain = 'domain=admin01.leadrpro.com';

    if (process.env.REACT_APP_NODE_ENV === 'Dev') {
        _domain = 'domain=leadrpro-client-staging.vercel.app';
    }

    document.cookie =
        cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
}
