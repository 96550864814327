import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { getStripePaymentItems, getChargesCompanyInfo } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import ProfileBanner from 'components/pages/user/ProfileBanner';
import Avatar from 'components/common/Avatar';

function CompletedStripePaymentsDetails() {
    const { paymentId, companyId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            try {
                console.log(companyId);
                const res = await getStripePaymentItems(paymentId);
                const res2 = await getChargesCompanyInfo(companyId);
                console.warn(res2, 'This is the company info');
                setData(res.data);
                setCompanyDetails(res2.data);
            } catch (err) {
                console.warn(err.message, 'this is the error message');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        onExit: {
            opacity: 0,
            transition: { duration: 1 }
        }
    };

    const columns = [
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'demo_id',
            Header: 'Demo Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },
        {
            accessor: 'amount',
            Header: 'Amount',
            Cell: (rowData) => {
                const { amount } = rowData.row.original;
                return <span>${amount}</span>;
            }
        }
    ];
    //! amount: '0';
    // date_created: '2022-12-22T23:27:08.843Z';
    // demo_id: 8568;
    //! description: null;
    //! is_active: true;
    //! stripe_payment_id: 12;
    //! stripe_payment_item_id: 56;

    return (
        <motion.div
            key="settings"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>
            <ProfileBanner className="mb-3">
                <ProfileBanner.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && companyDetails && (
                        <>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center justify-content-center flex-column me-4">
                                        <Avatar
                                            src={
                                                'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                                'tr:di-@@companylogos@@default-buyer.png/' +
                                                companyDetails.logo
                                            }
                                            rounded="circle"
                                            size="4xl"
                                        />
                                    </div>
                                    <h2 className="m-0">
                                        #{companyId} - {companyDetails.name}
                                    </h2>
                                </div>
                            </div>

                            <Row>
                                <Form.Group
                                    as={Col}
                                    lg={4}
                                    controlId="payment_plan"
                                >
                                    <Form.Label
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Payment Plan:
                                    </Form.Label>
                                    <div>
                                        {companyDetails.payment_plan
                                            .short_name === 'PAYG' && (
                                            <span className="badge badge-soft-danger">
                                                {
                                                    companyDetails.payment_plan
                                                        .short_name
                                                }
                                            </span>
                                        )}
                                        {companyDetails.payment_plan
                                            .short_name === 'PREM' && (
                                            <span className="badge badge-soft-warning">
                                                {
                                                    companyDetails.payment_plan
                                                        .short_name
                                                }
                                            </span>
                                        )}
                                        {companyDetails.payment_plan
                                            .short_name === 'GROW' && (
                                            <span className="badge badge-soft-info">
                                                {
                                                    companyDetails.payment_plan
                                                        .short_name
                                                }
                                            </span>
                                        )}
                                        {companyDetails.payment_plan
                                            .short_name === 'CUST' && (
                                            <span className="badge badge-soft-success">
                                                {
                                                    companyDetails.payment_plan
                                                        .short_name
                                                }
                                            </span>
                                        )}
                                    </div>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    lg={4}
                                    controlId="cost_per_demo"
                                >
                                    <Form.Label
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Cost Per Demo:
                                    </Form.Label>
                                    <div>
                                        {companyDetails.payment_plan
                                            .short_name === 'CUST' && (
                                            <span>
                                                $
                                                {parseInt(
                                                    companyDetails.payment_plan
                                                        .custom_cost_per_credit
                                                ) * 5}
                                            </span>
                                        )}
                                        {companyDetails.payment_plan
                                            .short_name !== 'CUST' && (
                                            <span>
                                                $
                                                {parseInt(
                                                    companyDetails.payment_plan
                                                        .cost_per_credit
                                                ) * 5}
                                            </span>
                                        )}
                                    </div>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    lg={4}
                                    controlId="cost_per_credit"
                                >
                                    <Form.Label
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Cost Per Credit:
                                    </Form.Label>
                                    <div>
                                        {companyDetails.payment_plan
                                            .short_name === 'CUST' && (
                                            <span>
                                                $
                                                {parseInt(
                                                    companyDetails.payment_plan
                                                        .custom_cost_per_credit
                                                )}
                                            </span>
                                        )}
                                        {companyDetails.payment_plan
                                            .short_name !== 'CUST' && (
                                            <span>
                                                $
                                                {parseInt(
                                                    companyDetails.payment_plan
                                                        .cost_per_credit
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </Form.Group>
                            </Row>
                        </>
                    )}
                </ProfileBanner.Body>
            </ProfileBanner>

            <Card className="mb-3">
                <Card.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && data && (
                        <Form.Group as={Col} lg={12} controlId="notes">
                            <Form.Label style={{ textDecoration: 'underline' }}>
                                Notes:
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Notes"
                                value={data[0].payment_description}
                                name="Notes"
                                readOnly
                                disabled
                            />
                        </Form.Group>
                    )}
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>
                    <h4>Charges</h4>
                </Card.Header>
                <Card.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </AdvanceTableWrapper>
                    )}
                </Card.Body>
            </Card>
        </motion.div>
    );
}

export default CompletedStripePaymentsDetails;
