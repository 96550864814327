import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

import HomeDasboard from 'components/leadrpro/home/HomeDashboard';
import DemosDashboard from 'components/leadrpro/demos/DemosDashboard';
import UsersDashboard from 'components/leadrpro/users/UsersDashboard';
import CompaniesDashboard from 'components/leadrpro/companies/CompaniesDashboard';
import CompanySettings from 'components/leadrpro/companies/CompanySettings';
import WithdrawsDashboard from 'components/leadrpro/withdraws/WithdrawsDashboard';
import AdditionalEarningsDashboard from 'components/leadrpro/withdraws/AdditionalEarningsDashboard';
import CategoriesDashboard from 'components/leadrpro/categories/CategoriesDashboard';
import Category from 'components/leadrpro/categories/CategoryDetails';
import ImportPage from 'components/leadrpro/import/ImportPage';
import Settings from 'components/leadrpro/users/Settings';
import DemoProfileContainer from 'components/leadrpro/demos/DemoProfileContainer';
import UpcomingDemosDashboard from 'components/leadrpro/demos/UpcomingDemosDashboard';
import SnoozedDemosDashboard from 'components/leadrpro/demos/SnoozedDemosDashboard';
import CompletedDemosDashboard from 'components/leadrpro/demos/completedDemos/CompletedDemosDashboard';
import DeclinedDemosDashboard from 'components/leadrpro/demos/DeclinedDemosDashboard';
import BuyersDashboard from 'components/leadrpro/buyers/BuyersDashboard';
import UnverifiedBuyersDashboard from 'components/leadrpro/buyers/UnverifiedBuyersDashboard';
import SellersDashboard from 'components/leadrpro/sellers/SellersDashboard';
import TodaysDemosDashboard from 'components/leadrpro/demos/TodaysDemosDashboard';
import BuyersDemosDashboard from 'components/leadrpro/demos/BuyersDemosDashboard';
import PendingDemosDashboard from 'components/leadrpro/demos/PendingDemosDashboard';
import Messages from 'components/leadrpro/messages/Messages';
import RescheduledDemosDashboard from 'components/leadrpro/demos/RescheduledDemosDashboard';
import MonthlyCompanyDemosTotal from 'components/leadrpro/demos/MonthlyCompanyDemosTotal';
import Activity from 'components/leadrpro/activity/Activity';
import SellerCompanies from 'components/leadrpro/companies/SellerCompanies';
import WaitlistCompanies from 'components/leadrpro/companies/WaitlistCompanies';
import InactiveCompanies from 'components/leadrpro/companies/InactiveCompanies';
import BuyerCompanies from 'components/leadrpro/companies/BuyerCompanies';
import UserInvites from 'components/leadrpro/userInvites/UserInvites';
import TextMagicTestComponent from 'components/leadrpro/test/TextMagicTest';
import UserPayoutRequests from 'components/leadrpro/withdraws/UserPayoutRequests';
import PaymentPlans from 'components/leadrpro/payments/PaymentPlans';
import CompanyPlans from 'components/leadrpro/companies/CompanyPlans';
import CompanyCharges from 'components/leadrpro/payments/CompanyCharges';
import CompanyChargesDetails from 'components/leadrpro/payments/CompanyChargesDetails';
import BuyerRequests from 'components/leadrpro/buyers/BuyerRequests';
import SourceDistributionDetails from 'components/leadrpro/sourceDistribution/SourceDistributionDetails';
import CompletedStripePayments from 'components/leadrpro/payments/CompletedStripePayments';
import CompletedStripePaymentsDetails from 'components/leadrpro/payments/CompletedStripePaymentDetails';
import CharityCampaigns from 'components/leadrpro/charity/CharityCampaigns';
import CharityCampaignDetails from 'components/leadrpro/charity/CharityCampaignDetails';
import AddUser from 'components/leadrpro/users/AddUser';
import AddBuyer from 'components/leadrpro/users/AddBuyersForDemo';
import SellerFeedback from 'components/leadrpro/feedback/SellerFeedback';
import BuyerFeedback from 'components/leadrpro/feedback/BuyerFeedback';
import Coupons from 'components/leadrpro/coupons/Coupons';
import CategoryDetails from 'components/leadrpro/categories/CategoryDetails';
import AddCompany from 'components/leadrpro/companies/AddCompany';
import Packages from 'components/leadrpro/packages/Packages';
import PackagePurchases from 'components/leadrpro/packages/PackagePurchases';
import Events from 'components/leadrpro/events/Events';
import Affiliates from 'components/leadrpro/affiliates/Affiliates';
import AffiliateDetails from 'components/leadrpro/affiliates/AffiliateDetails';

function LeadrProRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence exitBeforeEnter>
            <Routes location={location} key={location.pathname}>
                {/* Users Routes */}
                <Route path="/" element={<HomeDasboard />} />
                <Route path="users/all" element={<UsersDashboard />} />
                <Route path="users/buyers" element={<BuyersDashboard />} />
                <Route
                    path="users/unverified-buyers"
                    element={<UnverifiedBuyersDashboard />}
                />
                <Route path="users/sellers" element={<SellersDashboard />} />
                <Route path="user/add" element={<AddUser />} />
                <Route path="buyer/add" element={<AddBuyer />} />

                <Route path="user/:userId" element={<Settings />} />

                {/* Requests Routes */}
                <Route path="buyer/requests" element={<BuyerRequests />} />

                {/* Invited Users Routes */}
                <Route path="invited-users" element={<UserInvites />} />

                {/* Charges Routes */}
                <Route
                    path="charges/company/:companyId"
                    element={<CompanyChargesDetails />}
                />
                <Route path="charges/companies" element={<CompanyCharges />} />
                <Route
                    path="charges/completed"
                    element={<CompletedStripePayments />}
                />
                <Route
                    path="charges-details/:paymentId/company/:companyId"
                    element={<CompletedStripePaymentsDetails />}
                />

                {/* Company Routes */}
                <Route path="company/add" element={<AddCompany />} />
                <Route
                    path="company/:companyId"
                    element={<CompanySettings />}
                />
                <Route path="companies/buyers" element={<BuyerCompanies />} />
                <Route path="companies/sellers" element={<SellerCompanies />} />
                <Route path="companies/waitlist" element={<WaitlistCompanies />} />
                <Route path="companies/inactive" element={<InactiveCompanies />} />
                <Route path="companies/plans" element={<CompanyPlans />} />
                <Route path="companies/all" element={<CompaniesDashboard />} />

                {/* Charity Routes */}
                <Route
                    path="charity-campaigns"
                    element={<CharityCampaigns />}
                />
                <Route
                    path="charity-campaign/details/:charityCampaignId"
                    element={<CharityCampaignDetails />}
                />

                <Route path="coupons" element={<Coupons /> } />

                {/* Demos Routes */}
                <Route path="demo/:demoId" element={<DemoProfileContainer />} />
                <Route path="demos/buyer" element={<BuyersDemosDashboard />} />
                <Route
                    path="demos/completed"
                    element={<CompletedDemosDashboard />}
                />
                <Route
                    path="demos/company-monthly-totals"
                    element={<MonthlyCompanyDemosTotal />}
                />
                <Route
                    path="demos/declined"
                    element={<DeclinedDemosDashboard />}
                />
                <Route
                    path="demos/rescheduled"
                    element={<RescheduledDemosDashboard />}
                />
                <Route path="demos/today" element={<TodaysDemosDashboard />} />
                <Route
                    path="demos/upcoming"
                    element={<UpcomingDemosDashboard />}
                />
                <Route
                    path="demos/pending"
                    element={<PendingDemosDashboard />}
                />
                <Route
                    path="demos/snoozed"
                    element={<SnoozedDemosDashboard />}
                />
                <Route path="demos/all" element={<DemosDashboard />} />

                {/* Activity Routes */}
                <Route path="event-feed" element={<Activity />} />

                {/* Feedback Routes */}
                <Route path="feedback/buyer" element={<BuyerFeedback />} />
                <Route path="feedback/seller" element={<SellerFeedback />} />

                {/* Messages Routes */}
                <Route path="messages" element={<Messages />} />

                {/* Textmagic Routes */}
                <Route
                    path="textmagic/test"
                    element={<TextMagicTestComponent />}
                />

                {/* Source Distribution Routes */}
                <Route
                    path="source-distribution/details"
                    element={<SourceDistributionDetails />}
                />

                {/* Withdraws Routes */}
                <Route
                    path="withdraws/user-payout-requests"
                    element={<UserPayoutRequests />}
                />
                <Route path="withdraws" element={<WithdrawsDashboard />} />
                <Route
                    path="additional-earnings"
                    element={<AdditionalEarningsDashboard />}
                />

                {/* Payment Plans Routes */}
                <Route path="payment-plans" element={<PaymentPlans />} />

                {/* Categories Routes */}
                <Route path="categories" element={<CategoriesDashboard />} />
                <Route path="category/:categoryId" element={<CategoryDetails />} />

                {/* Import Routes */}
                <Route path="import" element={<ImportPage />} />

                {/* <Navigate to="/errors/404" /> */}
                <Route
                    path="*"
                    element={<Navigate to="/errors/404" replace />}
                />

                {/* Purchases Routes */}
                <Route
                    path="packages"
                    element={<Packages />}
                />

                <Route path="packages/purchases" element={<PackagePurchases />} />
                <Route path="events" element={<Events />} />
                <Route path='affiliates' element={<Affiliates />} />
                <Route path='affiliates/:id' element={<AffiliateDetails />} />
            </Routes>
        </AnimatePresence>
    );
}

export default LeadrProRoutes;
