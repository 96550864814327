import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { motion } from "framer-motion/dist/framer-motion";
import { getBuyerRequestedDemos } from 'api/api';
import moment from 'moment';
import CardDropdown from 'components/common/CardDropdown';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import DemosModal from './DemosModal';

function BuyersDemosDashboard() {
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState(null);
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);
    const [index, setIndex] = useState(null);
    const [action, setAction] = useState('');

    useEffect(() => {
        const loadUpcomingDemos = async () => {
            try {
                setLoaded(false);
                getBuyerRequestedDemos().then( res => setDemos(res.data));
            } catch (err) {
                console.log(JSON.stringify(err))
            } finally {
                setLoaded(true);
            }
        }

        loadUpcomingDemos();
    }, []);

    const handleShow = (index, demo, action) => {
        setIndex(index);
        setSelected(demo);
        setAction(action);
        setShow(true);
    }

    const columns = [
        {
            accessor: 'demo_id',
            Header: 'Id',
            Cell: rowData => {
                const { demo_id } = rowData.row.original
                return (
                    <Link to={'/demo/' + demo_id}>
                        {demo_id}
                    </Link>
                )
            }
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: rowData => {
                const { date_created } = rowData.row.original;
                if(!date_created){
                    return (
                        <span>No date.</span>
                    )
                }
                const date = moment(date_created).local().format("MMM Do YY, h:mm a");
                return (
                    <span>{date}</span>
                )
            }
        },
        {
            accessor: 'scheduled_date_time',
            Header: 'Scheduled Date & Time',
            Cell: rowData => {
                const { scheduled_date_time,  reschedule_date_time } = rowData.row.original;
                if(!scheduled_date_time){
                    return (
                        <span>Not scheduled.</span>
                    )
                }
                if(reschedule_date_time){
                    const date = moment(reschedule_date_time).local().format("MMM Do YY, h:mm a");
                    return (
                        <div className='d-flex flex-column'>
                            <span>
                                {date}
                            </span>
                        </div>
                    );
                } else {
                    const date = moment(scheduled_date_time).local().format("MMM Do YY, h:mm a");
                    return (
                            <div className='d-flex flex-column'>
                                <span>
                                    {date}
                                </span>
                            </div>
                        );
                }
            }
        },
        {
            accessor: 'demo_status',
            Header: 'Demo Status',
            Cell : rowData => {
                const { demo_status } = rowData.row.original;

                if(demo_status === "Completed"){
                    return (
                        <div className='d-flex flex-column container align-items-center'>
                            <span className="badge badge-soft-success">Completed</span>
                        </div>
                    )
                } else if(demo_status === "Pending"){
                    return (
                        <div className='d-flex flex-column container align-items-center'>
                            <span className="badge badge-soft-warning">Pending</span>
                        </div>
                    )
                } else if(demo_status === "Declined"){
                    return (
                        <div className='d-flex flex-column container align-items-center'>
                            <span className="badge badge-soft-danger">Declined</span>
                        </div>
                    )
                } else {
                    return (
                        <div className='d-flex flex-column container align-items-center'>
                            <span className="badge bg-light">{demo_status}</span>
                        </div>
                    )
                }
            }
        },
        {
            accessor: 'seller_email',
            Header: 'Seller Email'
        },
        {
            accessor: 'seller_company_name',
            Header: 'Seller Company',
            Cell: rowData => {
                const { seller_company_id, seller_company_name } = rowData.row.original;
                return (
                    <span>
                        {seller_company_name + " "}
                        <Link to={'/company/' + seller_company_id}>({seller_company_id})</Link>
                    </span>
                )
            }
        },
        {
            accessor: 'seller_timezone',
            Header: 'Seller Timezone'
        },
        {
            accessor: 'buyer_first_name',
            Header: 'Buyer Name',
            Cell: rowData => {
                const { buyer_first_name, buyer_last_name, buyer_id} = rowData.row.original;
                return (
                    <span>
                        { buyer_first_name + " " + buyer_last_name}
                        <Link to={'/user/' + buyer_id}> ({buyer_id})</Link>
                    </span>
                )
            }
        },
        {
            accessor: 'buyer_company_name',
            Header: 'Buyer Company',
            Cell: rowData => {
                const { buyer_company_id, buyer_company_name } = rowData.row.original;
                return (
                    <span>
                        {buyer_company_name + " "}
                        <Link to={'/company/' + buyer_company_id}>({buyer_company_id})</Link>
                    </span>
                )
            }
        },
        {
            accessor: 'buyer_email',
            Header: 'Buyer Email'
        },
        {
            accessor: 'buyer_timezone',
            Header: 'Buyer Timezone'
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: rowData => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                    return (
                            <CardDropdown iconClassName="fs--1" drop="start">
                                <div>
                                    <Dropdown.Item eventkey='1' onClick={() => handleShow(index, demo, 'Update')} className='text-success'>Completed</Dropdown.Item>
                                    <Dropdown.Item eventkey='1' onClick={() => handleShow(index, demo, 'Seller Reschedule')} className='text-primary'>Seller Reschedule</Dropdown.Item>
                                    <Dropdown.Divider as="div" />
                                    <Dropdown.Item eventkey='2' onClick={() => handleShow(index, demo, 'Delete')} className='text-danger'>Delete</Dropdown.Item>
                                </div>
                            </CardDropdown>
                    )
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: .5,
                delay: .5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: "easeInOut" }
        }
    }

    return ( 
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header title='Demos Requested From Buyers' titleTag='h2' noPreview/>
                <FalconComponentCard.Body
                    language='jsx'
                    noInline
                    noLight
                    className='bg-light'
                >
                    {(loaded && demos) &&
                        <AdvanceTableWrapper
                            columns={columns}
                            data={demos}
                            sortable
                            pagination
                            perPage={20}
                        >
                            <Row className='flex-end-center mb-3'>
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className='mt-3'>
                                <AdvanceTableFooter
                                    rowCount={demos.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    }
                    <DemosModal show={show} setShow={setShow} selected={selected} setData={setDemos} index={index} action={action} />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default BuyersDemosDashboard;