import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getSellersForCompany } from "api/api";

function NewSellerDropdown({company_id, seller, setShow, setSelected, setAction}) {
    const [sellers, setSellers] = useState(null);
    let organizerOptions;

    useEffect(() => {
        const loadSellers = async () => {
            try {
                const res = await getSellersForCompany(company_id);
                setSellers(res.data);
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        };

        loadSellers();
    }, []);

    if (sellers){
        organizerOptions = sellers.map((level) => {
            const data = {value : level.registered_user_id, label: level.email}
            return data;
        });
        organizerOptions.sort((a, b) => (a.label > b.label) ? 1 : -1);
    }

    const handleChange = target => {
        setShow(true)
        setAction('Change Seller');
        setSelected(target)
    }
    // console.log(organizerOptions, "these are the levels inside the Seniority Dropdown");

    return (
        <Select
            closeMenuOnSelect={true}
            options={organizerOptions}
            placeholder={seller ? seller : "Select A New Seller"}
            classNamePrefix="react-select"
            onChange={value => handleChange(value)}
        />
    );
}

export default NewSellerDropdown;