import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button, Toast } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import {
    updateUser,
    updateUserPhone,
    updateUserProfile,
    createDemo,
    deleteDemo
} from 'api/api';

function UserSettingsModal({show, setShow, loading, setLoading, user, setUser, selected, action}) {
    const [toast, setToast] = useState(false);
    const [failed, setFailed] = useState(false);
    const { userId } = useParams();
    console.log(user);
    const handleClose = () => setShow(false);

    const handleDelete = async () => {
        console.log("clicked");
        const d = {is_active: false}
        const response = await updateUser(userId, d);
        console.log(response);
        if(response.success){
            setShow(false);
            setToast(true);
        } else if (!response.success){
            setShow(false);
            setFailed(true);
        };
    };

    const handleDeleteDemo = async () => {
        console.log(selected);
        console.log('demo deleted')
        // const d = {registered_user_id: parseInt(userId), company_id: selected.value}
        const response = await deleteDemo(selected.demo_id);
        if(response.success){
            setShow(false);
            setToast(true);
            setUser({
                ...user,
                ...user.demos.filter((demo) => demo.demo_id !== selected.demo_id)
            });
        } else if (!response.success){
            setShow(false);
            setFailed(true);
        };

    };

    const handleCreateDemo = async (send_email = true) => {
        const d = {registered_user_id: parseInt(userId), company_id: selected.value, send_email: send_email};
        const response = await createDemo(d);
        if(response.success){
            setShow(false);
            setToast(true);
            window.location = '/demo/' + response.data;
        } else if (!response.success){
            setShow(false);
            setFailed(true);
        };
    };

    const handleUpdate = async () => {
        if(selected === 'handleUserSubmit'){
            const data = {
                first_name: user["first_name"],
                last_name: user["last_name"],
                email: user["email"],
                timezone: user["timezone"]
            };

            let response = await updateUser(userId, data);
            console.log(response);

            if(response.success){
                setShow(false)
                setToast(true);
            } else if (!response.success){
                setShow(false);
                setFailed(true);
            }
        } else if (selected === 'handlePhoneSubmit') {
            const data = user["phone_number"];
            let response = await updateUserPhone(userId, data);

            if(response.success){
                setShow(false)
                setToast(true);
            } else if (!response.success){
                setShow(false);
                setFailed(true);
            }
        } else if (selected === 'handleUserProfileSubmit') {
            let data;
            if(user['seniority_level_id']){
                data = {
                    job_title: user["job_title"],
                    department_id: user["department_id"],
                    seniority_level_id: user["seniority_level_id"],
                    linkedin_url: user["linkedin_url"]
                };
            } else {
                data ={
                    job_title: user["job_title"],
                    department_id: user["department_id"],
                    linkedin_url: user["linkedin_url"]
                }
            }

            let response = await updateUserProfile(userId, data);
            console.log(response, "this is the profile settings update call")

            if(response.success){
                setShow(false)
                setToast(true);
            } else if (!response.success){
                setShow(false);
                setFailed(true);
            }
        }
        
    };

    return (
        <>
            {
                action === 'Delete' &&
                <>
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Delete User</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this user?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        Close
                        </Button>
                        <Button variant="danger" onClick={() => handleDelete()}>Yes</Button>
                    </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast onClose={() => setToast(false)} show={toast} delay={4000} autohide>
                            <Toast.Header className='bg-success text-white'>
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>The user was successfully deleted.</Toast.Body>
                        </Toast>
                    </div>
                </>
            }
            {
                action === 'Create Demo' &&
                <>
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Create Demo for {user.first_name}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to create a demo with {selected.label}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={() => handleCreateDemo(true)}>Yes</Button>
                        <Button variant="primary" onClick={() => handleCreateDemo(false)}>
                            <span>Yes (w/o email)</span>
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast onClose={() => setToast(false)} show={toast} delay={4000} autohide>
                            <Toast.Header className='bg-success text-white'>
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>Demo was successfully created.</Toast.Body>
                        </Toast>
                    </div>
                </>
            }
            {
                action === 'Delete Demo' &&
                <>
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Delete demo</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete demo with {selected.label}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={() => handleDeleteDemo()}>Yes</Button>
                    </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast onClose={() => setToast(false)} show={toast} delay={4000} autohide>
                            <Toast.Header className='bg-success text-white'>
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>Demo was successfully deleted.</Toast.Body>
                        </Toast>
                    </div>
                </>
            }
            {
                action === "Update" &&
                <>
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Update User</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to update the users settings?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={() => handleUpdate()}>Yes</Button>
                    </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast onClose={() => setToast(false)} show={toast} delay={3500} autohide >
                            <Toast.Header className='bg-success text-white'>
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>The user was successfully updated.</Toast.Body>
                        </Toast>
                    </div>
                </>
                }

            <div className="position-fixed bottom-0 end-0 p-3">
                <Toast onClose={() => setFailed(false)} show={failed} delay={3500} autohide >
                    <Toast.Header className='bg-danger text-white'>
                        <strong className="me-auto">Failed</strong>
                    </Toast.Header>
                    <Toast.Body>There was an error. Contact DEV if this issue persists.</Toast.Body>
                </Toast>
            </div>
        </>
    );
}

export default UserSettingsModal;