import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { motion } from 'framer-motion/dist/framer-motion';
import { getAdditionalEarnings, bonusPaid } from 'api/api';
import CardDropdown from 'components/common/CardDropdown';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

function AdditionalEarningsDashboard() {
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const loadEarnings = async () => {
            try {
                setLoaded(false);
                getAdditionalEarnings().then((res) => setData(res.data));
            } catch (err) {
                console.log(err);
            } finally {
                setLoaded(true);
            }
        };
        loadEarnings();
    }, []);

    // setData(items => {
    //     return items.map((item, j) => {
    //         if(j === index){
    //             item.demo_status = 'Completed';
    //             return item;
    //         } else {
    //             return item;
    //         }
    //     })
    // })

    const handleUpdate = async (id, index) => {
        const res = await bonusPaid(id);
        if (res.success) {
            setData((items) => {
                return items.map((item, j) => {
                    if (j === index) {
                        item.status = 'Paid';
                        return item;
                    } else {
                        return item;
                    }
                });
            });
        } else {
            alert('Error: ' + JSON.stringify(res));
            console.log(res);
        }
    };

    const columns = [
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'first_name',
            Header: 'Name',
            Cell: (rowData) => {
                const { first_name, last_name, registered_user_id } =
                    rowData.row.original;
                return (
                    <span>
                        {first_name + ' ' + last_name}
                        <Link to={'/user/' + registered_user_id}>
                            {' '}
                            ({registered_user_id})
                        </Link>
                    </span>
                );
            }
        },
        {
            accessor: 'name',
            Header: 'Bonus Name'
        },
        {
            accessor: 'amount',
            Header: 'Amount'
        },
        {
            accessor: 'payout_method',
            Header: 'Payout Method'
        },
        {
            accessor: 'payout_source',
            Header: 'Payout Source'
        },
        // {
        //     accessor: 'status',
        //     Header: 'Status',
        //     Cell: (rowData) => {
        //         const { status, additional_earning_id } = rowData.row.original;
        //         const index = rowData.row.index;

        //         if (status === 'Requested') {
        //             return (
        //                 <div>
        //                     <span>{status}</span>
        //                     <motion.button
        //                         onClick={() =>
        //                             handleUpdate(additional_earning_id, index)
        //                         }
        //                         className="btn btn-primary rounded-pill btn-sm mx-3 shadow"
        //                         whileTap={{ scale: 0.9 }}
        //                         whileHover={{
        //                             boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
        //                             scale: 1.1
        //                         }}
        //                     >
        //                         Paid
        //                     </motion.button>
        //                 </div>
        //             );
        //         } else {
        //             return <span>{status}</span>;
        //         }
        //     }
        // },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                const { status, additional_earning_id } = rowData.row.original;
                return (
                    <CardDropdown iconClassName="fs--1" drop="start">
                        <div>
                            <Dropdown.Item
                                eventkey="1"
                                onClick={() =>
                                    handleUpdate(additional_earning_id, index)
                                }
                                className={
                                    status === 'Completed'
                                        ? 'text-secondary disabled'
                                        : 'text-success'
                                }
                            >
                                Set Paid
                            </Dropdown.Item>
                            <Dropdown.Divider as="div" />
                            <Dropdown.Item
                                eventkey="2"
                                onClick={() => console.log('clicked')}
                                className="text-danger disabled"
                            >
                                Delete
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    // amount: 10
    // date_created: "2022-07-11T23:49:26.786Z"
    // first_name: null
    // last_name: null
    // name: "Signup Bonus"
    // payout_method: null
    // payout_source: null
    // status: "Pending"

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {loaded && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                            pagination
                            perPage={20}
                        >
                            <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default AdditionalEarningsDashboard;
