import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { getSourceDetails } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

function SourceDistributionDetails() {
    const [searchParams, setSearchParams] = useSearchParams();
    const source = searchParams.get('source');
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getSourceDetails(source);
                console.log(
                    res,
                    'this is the response from the source details'
                );
                setData(res.data);
            } catch (err) {
                console.log(err.message, 'this is the error message');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const columns = [
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <div>No date.</div>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <div>{date}</div>;
            }
        },
        {
            accessor: 'company_id',
            Header: 'Company',
            Cell: (rowData) => {
                const { company_id, company_name, logo } = rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {company_name + ' '}
                        <Link className="ms-1" to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'first_name',
            Header: 'Name',
            Cell: (rowData) => {
                const { first_name, last_name } = rowData.row.original;
                return <div>{first_name + ' ' + last_name}</div>;
            }
        },
        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'timezone',
            Header: 'Timezone'
        }
    ];

    // company_id: 9897;
    // company_name: 'OHSU';
    // date_created: '2022-12-15T18:55:13.165Z';
    // email: 'rajkumar@ohsu.edu';
    // first_name: 'Janani';
    // last_name: 'Rajkumar';
    // logo: 'logo_gy-hYtI7d.jpg';
    // timezone: 'America/Los_Angeles';

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title={`Source '${source}' Details`}
                    titleTag="h2"
                    noPreview
                />
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {!loading && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </AdvanceTableWrapper>
                    )}
                    {loading && <div>Loading...</div>}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default SourceDistributionDetails;
