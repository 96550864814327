import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { toast } from 'react-toastify';
import { isAdmin, isAuthed, refresh } from 'services/auth.service';

// const App = () => {
//     // const [admin, setAdmin] = useState(false);
//     // useEffect(() => {
//     //     const admin = isAdmin();
//     //     if (!isAuthed()) {
//     //         return (window.location = '/authentication/simple/login');
//     //     } else if (!admin) {
//     //         return (window.location = 'https://leadrpro.com');
//     //     } else {
//     //         setAdmin(true);
//     //     }
//     // }, []);

//     // return (
//     //     <Router basename={process.env.PUBLIC_URL}>
//     //         <Layout />
//     //     </Router>
//     // );

//     const [admin, setAdmin] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (!isAuthed()) {
//             navigate('/authentication/simple/login');
//         } else if (!isAdmin()) {
//             window.location.href = 'https://leadrpro.com';
//         } else {
//             setAdmin(true);
//         }
//     }, [navigate]);

//     if (!admin) {
//         // You can display a message, a spinner, a redirection button or anything else here.
//         return <div>Please wait...</div>;
//     }

//     return (
//         <Router basename={process.env.PUBLIC_URL}>
//             <Layout />
//         </Router>
//     );
// };

// export default App;

const AppContent = () => {
    const navigate = useNavigate();

    useEffect(async () => {
        if (!isAuthed()) {
            console.log('token is not valid');
            let res = await refresh();
            console.log(res, 'this is the response to refresh the token');
            if (res.success) {
                return toast.success(`Token refreshed.`, {
                    theme: 'colored'
                });
            } else {
                navigate('/authentication/simple/login');
                return toast.error(
                    `Token could not be refreshed, please log in.`,
                    {
                        theme: 'colored',
                        pauseOnHover: true,
                        pauseOnFocusLoss: true
                    }
                );
            }
        }
    }, [navigate]);

    return <Layout />;
};

const App = () => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <AppContent />
        </Router>
    );
};

export default App;
