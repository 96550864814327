import React, { useState, useEffect } from 'react';
import { getDepartments } from 'api/api';
import Select from 'react-select'

function DepartmentsDropdown({ user, setUser }) {
    const [departments, setDepartments] = useState(null);
    let organizerOptions;

    useEffect(() => {
        const loadDepartments = async () => {
            try {
                const res = await getDepartments()
                console.log(res);
                setDepartments(res.data);
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        }
        loadDepartments();
    }, []);

    const handleChange = target => {
        setUser({
            ...user,
            ['department_id']: target.value,
            ['department_name']: target.label
        })
    }

    if (departments){
        organizerOptions = departments.map((object) => {
            const data = {value : object.department_id, label: object.name}
            return data;
        });
        organizerOptions.sort((a, b) => (a.value > b.value) ? 1 : -1);
    }

    // console.log(departments, "these are the deparments returned");

    return (
        <Select 
            closeMenuOnSelect={true}
            options={organizerOptions}
            placeholder={user.department_name ? user.department_name : user.department}
            classNamePrefix="react-select"
            value={user.department_name}
            onChange={value => handleChange(value)}
        />
    );
}

export default DepartmentsDropdown;