import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    FloatingLabel,
    Spinner
} from 'react-bootstrap';
import { createNewCompany, createNewSeller } from 'api/api';

function AddCompany({ step, setStep }) {
    const [formData, setFormData] = useState(null);
    return (
        <Card.Body className="p-4 p-sm-5">
            <Row className="align-items-center mb-2">
                <Col className="mb-2">
                    <h5 id="modalLabel">Add Company</h5>
                </Col>

                <SellerForm
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    setStep={setStep}
                    hasLabel={true}
                />
            </Row>
        </Card.Body>
    );
}

function SellerForm({ formData, setFormData, step, setStep }) {
    //* State
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [website, setWebsite] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    //* Helper Functions
    const nextStep = () => {
        setStep((value) => value + 1);
    };

    const prevStep = () => {
        setStep((value) => {
            if (value > 0) {
                return value - 1;
            } else {
                return;
            }
        });
    };

    const handleSubmit = async (e) => {
       // alert('asdf');
        e.preventDefault();
        setLoading(true);
        setIsSubmitting(true);

        try {
            var createdCompany = await createNewCompany(companyName, website);

            if (createdCompany.success){
                navigate('/company/' + createdCompany.data);
               // alert(createdCompany.data);
            }
        } catch (err) {
            toast.error(
                `There was an error, please contact DEV. ERROR: ${err.message}`,
                {
                    render: `Error: ${err.message}`,
                    type: 'error',
                    isLoading: false
                }
            );
        }

    };

    const handleFieldChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={6}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Company Name"
                        className="mb-3"
                    >
                        <Form.Control
                            placeholder={'Company Name'}
                            name="company_name"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            type="text"
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} sm={6}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Website"
                        className="mb-3"
                    >
                        <Form.Control
                            placeholder={'Website'}
                            name="website"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            type="text"
                        />
                    </FloatingLabel>
                </Form.Group>
            </Row>

            <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={6}>
                    {isSubmitting === false && (
                    <Button className='btn' onClick={(e) => handleSubmit(e)}>Save Company</Button>
                    )}

                    {isSubmitting && (
                        <Spinner />
                    )}
                </Form.Group>
            </Row>
        </Form>
    );
}

SellerForm.propTypes = {
    hasLabel: PropTypes.bool
};
export default AddCompany;