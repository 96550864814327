import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getSeniorityLevels } from 'api/api';

function SeniorityFilter({ data, setData }) {
    const [levels, setLevels] = useState(null);
    let organizerOptions;
    useEffect(() => {
        const controller = new AbortController();
        const loadData = async () => {
            try {
                const res = await getSeniorityLevels();
                setLevels(res.data);
            } catch (err) {
                console.warn(err.message);
            }
        };
        loadData();

        return () => {
            controller.abort();
        };
    }, []);

    if (levels) {
        organizerOptions = levels.map((level) => {
            const data = { value: level.seniority_level_id, label: level.name };
            return data;
        });
        organizerOptions.sort((a, b) => (a.value > b.value ? 1 : -1));
        organizerOptions.unshift({ value: 0, label: 'Seniority' });
    }
    const handleChange = (target) => {
        setData(target.label);
    };
    // console.log(organizerOptions, "these are the levels inside the Seniority Dropdown");
    return (
        <Select
            closeMenuOnSelect={true}
            options={organizerOptions}
            placeholder={data ? data : 'Seniority'}
            classNamePrefix="react-select"
            value={data ? data : 'Seniority'}
            onChange={(value) => handleChange(value)}
        />
    );
}

export default SeniorityFilter;
