import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getSellerFeedback } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import StarRating from 'components/common/StarRating';

function SellerFeedback() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const res = await getSellerFeedback();
                console.log(res);
                setData(res.data);
            } catch (err) {
                console.warn('This is the error: ', err.message);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    const columns = [
        {
            accessor: 'demo_id',
            Header: 'Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },
        {
            accessor: 'demo_date',
            Header: 'Demo Date',
            Cell: (rowData) => {
                const { demo_date } = rowData.row.original;
                if (!demo_date) {
                    return <span>No date.</span>;
                }
                const date = moment(demo_date)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'seller_company_name',
            Header: 'Seller Company',
            Cell: (rowData) => {
                const { seller_id, seller_company_name, seller_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    seller_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {seller_company_name + ' '}
                        <Link className="ms-1" to={'/company/' + seller_id}>
                            ({seller_id})
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'first_name',
            Header: 'Buyer Name',
            Cell: (rowData) => {
                const { first_name, last_name, buyer_id } =
                    rowData.row.original;
                return (
                    <div>
                        {first_name + ' ' + last_name}
                        <Link to={'/user/' + buyer_id}> ({buyer_id})</Link>
                    </div>
                );
            }
        },
        {
            accessor: 'buyer_company_name',
            Header: 'Buyer Company',
            Cell: (rowData) => {
                const { buyer_id, buyer_company_name, buyer_company_id, buyer_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    buyer_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {buyer_company_name + ' '}
                        <Link className="ms-1" to={'/company/' + buyer_company_id}>
                            ({buyer_company_id})
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'email',
            Header: 'Buyer Email'
        },
        {
            accessor: 'estimated_value',
            Header: 'ACV',
            Cell: (rowData) => {
                const { estimated_value } = rowData.row.original;

                return <span>${estimated_value}</span>;
            }
        },
        {
            accessor: 'moving_to_proposal',
            Header: 'Moving to Proposal?',
            Cell: (rowData) => {
                const { moving_to_proposal } = rowData.row.original;

                if (moving_to_proposal) {
                    return (
                        <div className="d-flex justify-content-center align-items-center text-success">
                            <FontAwesomeIcon icon={faCheckSquare} />
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'accuracy',
            Header: 'Accuracy',
            Cell: (rowData) => {
                const { accuracy } = rowData.row.original;
                return (
                    <div>
                        <StarRating readonly rating={accuracy} />
                    </div>
                );
            }
        },
        {
            accessor: 'intent',
            Header: 'Intent',
            Cell: (rowData) => {
                const { intent } = rowData.row.original;
                return (
                    <div>
                        <StarRating readonly rating={intent} />
                    </div>
                );
            }
        },
        {
            accessor: 'communication',
            Header: 'Communication',
            Cell: (rowData) => {
                const { communication } = rowData.row.original;
                return (
                    <div>
                        <StarRating readonly rating={communication} />
                    </div>
                );
            }
        },
        {
            accessor: 'professionalism',
            Header: 'Professionalism',
            Cell: (rowData) => {
                const { professionalism } = rowData.row.original;
                return (
                    <div>
                        <StarRating readonly rating={professionalism} />
                    </div>
                );
            }
        },
        {
            accessor: 'timeliness',
            Header: 'Timeliness',
            Cell: (rowData) => {
                const { timeliness } = rowData.row.original;
                return (
                    <div>
                        <StarRating readonly rating={timeliness} />
                    </div>
                );
            }
        }
    ];

    // accuracy: 5;
    // intent: 5;
    // communication: 5;
    // professionalism: 5;
    // timeliness: 5;
    // buyer_company_name: 'Marshall Retail Group';
    // buyer_id: 7667;
    // demo_date: '2023-01-13T21:00:00.574Z';
    // demo_id: 12877;
    // email: 'Rrandazzo@marshallretailgroup.com';
    //! estimated_value: 0;
    //! feedback_text: 'Good. They have specific problems they’re looking to solve, and we have a free beta product that could help.';
    // first_name: 'Ray';
    // last_name: 'Randazzo';
    // moving_to_proposal: true;
    // seller_company_name: 'Vendorful';
    // seller_id: 8432;

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Seller Feedback"
                    titleTag="h2"
                    noPreview
                />
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {!loading && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                            pagination
                            perPage={100}
                        >
                            <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {loading && <h5>Loading...</h5>}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default SellerFeedback;
