const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || 'http://localhost:3003';
/**
 * Defines the default headers for these functions to work with `json-server`
 */
const headers = new Headers();
headers.append('Content-Type', 'application/json');

console.log(API_BASE_URL, 'THIS IS INSIDE THE API FILE');

function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export async function loginUser(email, password) {
    // let channel_cookie = getCookie('channel_token');

    const url = new URL(`${API_BASE_URL}/user/login`);
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ email, password }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function refreshToken() {
    let admin_token = getCookie('admin_token');
    let refresh_token = localStorage.getItem('admin_refresh_token');

    const url = new URL(`${API_BASE_URL}/refresh`);

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ refreshToken: refresh_token }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUserProfile() {
    let admin_token = getCookie('admin_token');
    const url = new URL(`${API_BASE_URL}/user/profile`);

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.status === 403) return { success: false, status: 403 };
        return response.json();
    });

    return response;
}

export async function getPersonaTypes(user_type) {
    const url = new URL(`${API_BASE_URL}/user/personatypes/${user_type}`);
    const response = await fetch(url, { headers }).then((response) =>
        response.json()
    );
    return response;
}

export async function testAdminConnection() {
    const url = new URL(`${API_BASE_URL}/company/industries`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUsers() {
    const url = new URL(`${API_BASE_URL}/admin/users`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getBuyers() {
    const url = new URL(`${API_BASE_URL}/admin/buyers`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUnverifiedBuyers() {
    const url = new URL(`${API_BASE_URL}/admin/unverified-buyers`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSellers() {
    const url = new URL(`${API_BASE_URL}/admin/sellers`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUser(userId) {
    const url = new URL(`${API_BASE_URL}/admin/user/${userId}`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateUser(id, data) {
    const url = new URL(`${API_BASE_URL}/admin/update-user`);
    let admin_token = getCookie('admin_token');
    let d = { id, data };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateUserPhone(id, phone) {
    const url = new URL(`${API_BASE_URL}/admin/update-user-phone`);
    let admin_token = getCookie('admin_token');
    let d = { id, phone_number: phone };
    console.log(d, 'inside the api file');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateUserProfile(id, data) {
    const url = new URL(`${API_BASE_URL}/admin/update-user-profile`);
    let admin_token = getCookie('admin_token');
    let d = { id, data };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSeniorityLevels() {
    const url = new URL(`${API_BASE_URL}/company/seniority`);

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function searchIndustries(query) {
    if (!query) query = '';
    try {
        const url = new URL(
            `${API_BASE_URL}/company/industries/search/${query}`
        );
        const response = await fetch(url).then((response) => response.json());
        return response;
    } catch (error) {
        return 'Error has occurred';
    }
}

export async function getCompanies(page) {
    const url = new URL(`${API_BASE_URL}/admin/companies/${page}`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompaniesQuery(query) {
    const url = new URL(`${API_BASE_URL}/admin/company/search/${query}`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getBuyerCompanies() {
    const url = new URL(`${API_BASE_URL}/admin/buyer-companies`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSellerCompanies() {
    const url = new URL(`${API_BASE_URL}/admin/seller-companies`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getWaitlistCompanies() {
    const url = new URL(`${API_BASE_URL}/admin/waitlist-companies`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getInactiveCompanies() {
    const url = new URL(`${API_BASE_URL}/admin/inactive-companies`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompany(companyId) {
    const url = new URL(`${API_BASE_URL}/admin/company/${companyId}`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getDemos(page) {
    const url = new URL(`${API_BASE_URL}/admin/demos/${page}`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getRescheduledDemos() {
    const url = new URL(`${API_BASE_URL}/admin/demos/rescheduled`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getWithdraws() {
    const url = new URL(`${API_BASE_URL}/admin/withdraws`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getAdditionalEarnings() {
    const url = new URL(`${API_BASE_URL}/admin/additional-earnings`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function demoPaid(id) {
    const url = new URL(`${API_BASE_URL}/admin/demo-paid`);
    let admin_token = getCookie('admin_token');
    let d = { id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function bonusPaid(id) {
    const url = new URL(`${API_BASE_URL}/admin/bonus-paid`);
    let admin_token = getCookie('admin_token');
    let d = { id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCategories() {
    const url = new URL(`${API_BASE_URL}/admin/categories`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCategory(category_id) {
    const url = new URL(`${API_BASE_URL}/admin/category/${category_id}`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateCategory(
    category_id,
    short_description,
    description
) {
    const url = new URL(`${API_BASE_URL}/admin/category`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ category_id, short_description, description }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function deleteDemo(id) {
    const url = new URL(`${API_BASE_URL}/admin/delete-demo`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id: id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function completeDemo(id) {
    const url = new URL(`${API_BASE_URL}/admin/complete-demo`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id: id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getDepartments() {
    const url = new URL(`${API_BASE_URL}/admin/departments`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function createBuyer(data) {
    const url = new URL(`${API_BASE_URL}/admin/import/buyer`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(data),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getDemo(demoId) {
    const url = new URL(`${API_BASE_URL}/admin/demo/${demoId}`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateCompany(id, data, message = '') {
    const url = new URL(`${API_BASE_URL}/admin/update-company`);
    let admin_token = getCookie('admin_token');
    let d = { id, data, message };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateCategoryNew(id, data, message = '') {
    const url = new URL(`${API_BASE_URL}/admin/update-category`);
    let admin_token = getCookie('admin_token');
    let d = { id, data, message };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getIndustries() {
    const url = new URL(`${API_BASE_URL}/company/all-industries`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanySizes() {
    const url = new URL(`${API_BASE_URL}/company/companysizes`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUpcomingDemos() {
    const url = new URL(`${API_BASE_URL}/admin/demos/upcoming`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompletedDemos() {
    const url = new URL(`${API_BASE_URL}/admin/demos/completed`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getDeclinedDemos() {
    const url = new URL(`${API_BASE_URL}/admin/demos/declined`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSnoozedDemos() {
    const url = new URL(`${API_BASE_URL}/admin/demos/snoozed`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getDemosToday(date) {
    let newDate;
    let d;
    if (date) {
        newDate = new Date(date).toISOString();
        d = { date: newDate };
    }
    const url = new URL(`${API_BASE_URL}/admin/demos/today`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getPendingDemos() {
    const url = new URL(`${API_BASE_URL}/admin/demos/pending`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getBuyerRequestedDemos() {
    const url = new URL(`${API_BASE_URL}/admin/demos/buyers`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateDemo(id, data) {
    const url = new URL(`${API_BASE_URL}/admin/update-demo`);
    let admin_token = getCookie('admin_token');
    let d = { id, data };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSellersForDropdown() {
    const url = new URL(`${API_BASE_URL}/admin/seller-dropdown`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getBuyersForDropdown() {
    const url = new URL(`${API_BASE_URL}/admin/company-dropdown`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function createDemo(data) {
    const url = new URL(`${API_BASE_URL}/admin/create-demo`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(data),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function logImport(data) {
    const url = new URL(`${API_BASE_URL}/admin/import-log`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(data),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getHomeData() {
    const url = new URL(`${API_BASE_URL}/admin/dashboard-data`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function notifyDemo(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/demo-reminders`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getICPDepartment(id) {
    const url = new URL(`${API_BASE_URL}/admin/get-icp-department`);
    let admin_token = getCookie('admin_token');
    let d = { company_id: id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getICPIndustry(id) {
    const url = new URL(`${API_BASE_URL}/admin/get-icp-industry`);
    let admin_token = getCookie('admin_token');
    let d = { company_id: id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getICPSeniority(id) {
    const url = new URL(`${API_BASE_URL}/admin/get-icp-seniority`);
    let admin_token = getCookie('admin_token');
    let d = { company_id: id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getICPCompanySize(id) {
    const url = new URL(`${API_BASE_URL}/admin/get-icp-size`);
    let admin_token = getCookie('admin_token');
    let d = { company_id: id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function saveICPDepartment(department_id, company_id) {
    const url = new URL(`${API_BASE_URL}/admin/save-icp-department`);
    let admin_token = getCookie('admin_token');
    let d = { department_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function saveICPIndustry(industry_id, company_id) {
    const url = new URL(`${API_BASE_URL}/admin/save-icp-industry`);
    let admin_token = getCookie('admin_token');
    let d = { industry_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function removeICPIndustry(industry_id, company_id) {
    const url = new URL(`${API_BASE_URL}/admin/remove-icp-industry`);
    let admin_token = getCookie('admin_token');
    let d = { industry_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function removeICPDepartment(department_id, company_id) {
    const url = new URL(`${API_BASE_URL}/admin/remove-icp-department`);
    let admin_token = getCookie('admin_token');
    let d = { department_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function saveICPSeniority(seniority_level_id, company_id) {
    const url = new URL(`${API_BASE_URL}/admin/save-icp-seniority`);
    let admin_token = getCookie('admin_token');
    let d = { seniority_level_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function removeICPSeniority(seniority_level_id, company_id) {
    const url = new URL(`${API_BASE_URL}/admin/remove-icp-seniority`);
    let admin_token = getCookie('admin_token');
    let d = { seniority_level_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function saveICPCompanySize(company_size_id, company_id) {
    const url = new URL(`${API_BASE_URL}/admin/save-icp-size`);
    let admin_token = getCookie('admin_token');
    let d = { company_size_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function removeICPCompanySize(company_size_id, company_id) {
    const url = new URL(`${API_BASE_URL}/admin/remove-icp-size`);
    let admin_token = getCookie('admin_token');
    let d = { company_size_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function demoSellerReschedule(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/demo-reschedule`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getMessages() {
    const url = new URL(`${API_BASE_URL}/admin/demo-messages`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSellersForCompany(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/company-sellers/${company_id}`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUserCountsPerMonth() {
    const url = new URL(`${API_BASE_URL}/admin/user-counts`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addTrackingEvent(demo_id, event_name) {
    const url = new URL(`${API_BASE_URL}/demo/event-track`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id, event_name };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function createSellerDemos(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/create-seller-demos`);
    let admin_token = getCookie('admin_token');
    let d = { company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getDemoCounts() {
    const url = new URL(`${API_BASE_URL}/admin/demo-counts`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getMonthlyCompanyDemos() {
    const url = new URL(`${API_BASE_URL}/admin/company-monthly-demos`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getMonthlyCompanyDemosTotal(controller) {
    const url = new URL(`${API_BASE_URL}/admin/company-monthly-demos-total`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addSellerRole(registered_user_id) {
    const url = new URL(`${API_BASE_URL}/admin/add-seller-role`);
    let admin_token = getCookie('admin_token');

    let d = { registered_user_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addBuyerRole(registered_user_id) {
    const url = new URL(`${API_BASE_URL}/admin/add-buyer-role`);
    let admin_token = getCookie('admin_token');

    let d = { registered_user_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getEventFeed(controller) {
    const url = new URL(`${API_BASE_URL}/admin/event-feed`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function sendReminderSms(
    demos,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/reminder-sms`);
    let admin_token = getCookie('admin_token');

    let d = { demos };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUserInvites() {
    const url = new URL(`${API_BASE_URL}/admin/user-invites`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUserPayoutsRequests() {
    const url = new URL(`${API_BASE_URL}/admin/user-payout-requests`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getPaymentPlans() {
    const url = new URL(`${API_BASE_URL}/admin/payment-plans`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyPlans() {
    const url = new URL(`${API_BASE_URL}/admin/company-plans`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCompanyPlan(
    company_id,
    payment_plan_id,
    cost_per_credit = 0,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/add-company-plan`);
    let admin_token = getCookie('admin_token');

    let d = { company_id, payment_plan_id, cost_per_credit };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function dotsWithdraw(data, controller = new AbortController()) {
    const url = new URL(`${API_BASE_URL}/admin/dots-withdraw`);
    let admin_token = getCookie('admin_token');

    let d = { data };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyCharges() {
    const url = new URL(`${API_BASE_URL}/admin/charges`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyChargesDetails(
    company_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/company-charges`);
    let admin_token = getCookie('admin_token');

    let d = { company_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getChargesCompanyInfo(
    company_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/company-charges-details`);
    let admin_token = getCookie('admin_token');

    let d = { company_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getBuyerRequests() {
    const url = new URL(`${API_BASE_URL}/admin/buyer-requests`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function createStripePayment(
    registered_user_id,
    amount,
    company_id,
    demos,
    description,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/charge-stripe`);
    let admin_token = getCookie('admin_token');

    let d = { registered_user_id, amount, company_id, demos, description };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSourceDistribution() {
    const url = new URL(`${API_BASE_URL}/admin/source-distribution`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSourceDetails(
    source,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/source-details`);
    let admin_token = getCookie('admin_token');

    let d = { source };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getChannelDistribution() {
    const url = new URL(`${API_BASE_URL}/admin/channel-distribution`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getStripePayments() {
    const url = new URL(`${API_BASE_URL}/admin/stripe-payments`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getStripePaymentItems(
    stripe_payment_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/stripe-payment-items`);
    let admin_token = getCookie('admin_token');

    let d = { stripe_payment_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCharityCampaigns() {
    const url = new URL(`${API_BASE_URL}/admin/charity-campaigns`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCharityCampaignPayouts(
    charity_campaign_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/charity-payouts`);
    let admin_token = getCookie('admin_token');

    let d = { charity_campaign_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}
// /charity-payouts POST
// {charity_campaign_id}

export async function getCharityCampaignAdditionalEarnings(
    charity_campaign_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/charity-additional-earnings`);
    let admin_token = getCookie('admin_token');

    let d = { charity_campaign_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCharityCampaignDetails(
    charity_campaign_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/charity-details`);
    let admin_token = getCookie('admin_token');

    let d = { charity_campaign_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function createNewCompany(
    name,
    website,
    company_size_id,
    industry_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/new-company`);
    let admin_token = getCookie('admin_token');

    let d = { name, website, company_size_id, industry_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function createNewSeller(
    email,
    first_name,
    last_name,
    company_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/new-seller`);
    let admin_token = getCookie('admin_token');

    let d = { email, first_name, last_name, company_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function createNewBuyer(
    email,
    first_name,
    last_name,
    company_id,
    controller = new AbortController()
) {
    const url = new URL(`${API_BASE_URL}/admin/new-buyer`);
    let admin_token = getCookie('admin_token');

    let d = { email, first_name, last_name, company_id };

    const response = await fetch(url, {
        signal: controller.signal,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSellerFeedback() {
    const url = new URL(`${API_BASE_URL}/admin/seller-feedback`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getBuyerFeedback() {
    const url = new URL(`${API_BASE_URL}/admin/buyer-feedback`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCoupons() {
    const url = new URL(`${API_BASE_URL}/admin/coupons`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCoupon(
    code,
    company_id,
    expires_on,
    discount_amount,
    discount_type,
    single_use
) {
    const url = new URL(`${API_BASE_URL}/admin/add-coupon`);
    let admin_token = getCookie('admin_token');

    let d = {
        code,
        company_id,
        expires_on,
        discount_amount,
        discount_type,
        single_use
    };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addQualifierQuestion(company_id, question) {
    const url = new URL(`${API_BASE_URL}/admin/add-question`);
    let admin_token = getCookie('admin_token');

    let d = { company_id, question };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateQualifierQuestion(qualifier_question_id, question) {
    const url = new URL(`${API_BASE_URL}/admin/update-question`);
    let admin_token = getCookie('admin_token');

    let d = { qualifier_question_id, question };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyNotes(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/company-notes`);
    let admin_token = getCookie('admin_token');

    let d = { company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getDemoNotes(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/demo-notes`);
    let admin_token = getCookie('admin_token');

    let d = { demo_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUserNotes(user_id) {
    const url = new URL(`${API_BASE_URL}/admin/user-notes`);
    let admin_token = getCookie('admin_token');

    let d = { user_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCompanyNote(company_id, note_text, is_pinned = false) {
    const url = new URL(`${API_BASE_URL}/admin/add-company-note`);
    let admin_token = getCookie('admin_token');

    let d = { company_id, note_text, is_pinned };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addDemoNote(demo_id, note_text, is_pinned = false) {
    const url = new URL(`${API_BASE_URL}/admin/add-demo-note`);
    let admin_token = getCookie('admin_token');

    let d = { demo_id, note_text, is_pinned };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addUserNote(user_id, note_text, is_pinned = false) {
    const url = new URL(`${API_BASE_URL}/admin/add-user-note`);
    let admin_token = getCookie('admin_token');

    let d = { user_id, note_text, is_pinned };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function deleteCompanyNote(company_note_id) {
    const url = new URL(`${API_BASE_URL}/admin/delete-company-note`);
    let admin_token = getCookie('admin_token');

    let d = { company_note_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function deleteDemoNote(demo_note_id) {
    const url = new URL(`${API_BASE_URL}/admin/delete-demo-note`);
    let admin_token = getCookie('admin_token');
    let d = { demo_note_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function deleteUserNote(user_note_id) {
    const url = new URL(`${API_BASE_URL}/admin/delete-user-note`);
    let admin_token = getCookie('admin_token');
    let d = { user_note_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCompanyCategory(company_id, category) {
    const url = new URL(`${API_BASE_URL}/admin/company-category`);
    let admin_token = getCookie('admin_token');
    let d = { company_id, category };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function removeCompanyCategory(company_id, category_id) {
    const url = new URL(`${API_BASE_URL}/admin/remove-company-category`);
    let admin_token = getCookie('admin_token');
    let d = { company_id, category_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function qualifierQuestionMoveUp(
    qualifier_question_id,
    previous_qualifier_question_id,
    index
) {
    const url = new URL(`${API_BASE_URL}/admin/qualifier-question-up`);
    let admin_token = localStorage.getItem('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({
            qualifier_question_id,
            previous_qualifier_question_id,
            index
        }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.status === 403) return { success: false, status: 403 };
        return response.json();
    });

    return response;
}

export async function qualifierQuestionMoveDown(
    qualifier_question_id,
    next_qualifier_question_id,
    index
) {
    const url = new URL(`${API_BASE_URL}/admin/qualifier-question-down`);
    let admin_token = localStorage.getItem('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({
            qualifier_question_id,
            next_qualifier_question_id,
            index
        }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.status === 403) return { success: false, status: 403 };
        return response.json();
    });

    return response;
}

export async function getCompanyQualifierQuestions(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/qualifier-questions`);
    let admin_token = localStorage.getItem('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ company_id }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.status === 403) return { success: false, status: 403 };
        return response.json();
    });

    return response;
}

export async function getCompanyAvailableBuyers_broken(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/company-available-buyers`);
    let admin_token = localStorage.getItem('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ id: company_id }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyAvailableBuyers(id) {
    const url = new URL(`${API_BASE_URL}/admin/company-available-buyers`);
    let admin_token = getCookie('admin_token');

    let d = { id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addDemoMeetingLink(demo_id, meeting_link) {
    const url = new URL(`${API_BASE_URL}/admin/demo-meeting-link`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id, meeting_link };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function expireDemo(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/expire-demo`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function generateDemoInvite(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/demo-invite`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateDemoInvite(demo_invite_id, question) {
    const url = new URL(`${API_BASE_URL}/admin/update-demo-invite`);
    let admin_token = getCookie('admin_token');
    let d = { demo_invite_id, question };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyFeedItems(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/company-feed-items`);
    let admin_token = getCookie('admin_token');
    let d = { company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCompanyFeedItem(
    company_id,
    content_body,
    department_id
) {
    const url = new URL(`${API_BASE_URL}/admin/add-feed-item`);
    let admin_token = getCookie('admin_token');
    let d = { company_id, content_body, department_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getFeedDepartments() {
    const url = new URL(`${API_BASE_URL}/admin/feed-departments`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function sendDemoReminders(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/send-demo-reminders`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ demo_id }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getAdminsForDropdown() {
    const url = new URL(`${API_BASE_URL}/admin/admin-dropdown`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function removeUserRole(registered_user_id, role_type_id) {
    const url = new URL(`${API_BASE_URL}/admin/remove-user-role`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ registered_user_id, role_type_id }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCompanyPaymentTest(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/payment-test`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ company_id }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyPaymentTests(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/get-payment-tests`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ company_id }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCompanyInvoiceNote(
    company_id,
    note_text,
    is_pinned = false
) {
    const url = new URL(`${API_BASE_URL}/admin/add-company-invoice-note`);
    let admin_token = getCookie('admin_token');

    let d = { company_id, note_text, is_pinned };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addDemoStrike(demo_id, reason) {
    const url = new URL(`${API_BASE_URL}/admin/demo-strike`);
    let admin_token = getCookie('admin_token');

    let d = { demo_id, reason };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateWorkEmail(registered_user_id, company_id, email) {
    const url = new URL(`${API_BASE_URL}/admin/update-work-email`);
    let admin_token = getCookie('admin_token');
    let d = { registered_user_id, company_id, email };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyStages() {
    const url = new URL(`${API_BASE_URL}/admin/company-stages`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getPaymentMethodsForCompany(company_id) {
    const url = new URL(`${API_BASE_URL}/stripe/payment-methods-company`);
    let admin_token = getCookie('admin_token');
    let d = { company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getUserPersonaTypes(registered_user_id) {
    const url = new URL(`${API_BASE_URL}/admin/user-persona-types`);
    let admin_token = getCookie('admin_token');
    let d = { registered_user_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function enableUserPersonaType(
    registered_user_id,
    persona_type_id
) {
    const url = new URL(`${API_BASE_URL}/admin/enable-persona-type`);
    let admin_token = getCookie('admin_token');
    let d = { registered_user_id, persona_type_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function disableUserPersonaType(
    registered_user_id,
    persona_type_id
) {
    const url = new URL(`${API_BASE_URL}/admin/disable-persona-type`);
    let admin_token = getCookie('admin_token');
    let d = { registered_user_id, persona_type_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function reassignDemos(old_user_id, new_user_id) {
    const url = new URL(`${API_BASE_URL}/admin/reassign-demos`);
    let admin_token = getCookie('admin_token');
    let d = { old_user_id, new_user_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addDemoTag(demo_id, tag) {
    const url = new URL(`${API_BASE_URL}/admin/add-demo-tag`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id, tag };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function removeDemoTag(demo_id, tag) {
    const url = new URL(`${API_BASE_URL}/admin/remove-demo-tag`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id, tag };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getDemoTags(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/get-demo-tags`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyPackages() {
    const url = new URL(`${API_BASE_URL}/admin/company-packages`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyPackagePurchases() {
    const url = new URL(`${API_BASE_URL}/admin/company-package-purchases`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function createPackagePurchase(
    company_package_id,
    company_id,
    registered_user_id,
    modified_price
) {
    const url = new URL(`${API_BASE_URL}/admin/create-package-purchase`);
    let admin_token = getCookie('admin_token');
    let d = {
        company_package_id,
        company_id,
        registered_user_id,
        modified_price
    };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function demoInReview(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/demo-in-review`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function demoInReviewComplete(demo_id) {
    const url = new URL(`${API_BASE_URL}/admin/demo-in-review-complete`);
    let admin_token = getCookie('admin_token');
    let d = { demo_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addProfileUrl(registered_user_id, profile_url) {
    const url = new URL(`${API_BASE_URL}/admin/add-profile-url`);
    let admin_token = getCookie('admin_token');
    let d = { registered_user_id, profile_url };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCompanyFeature(company_id, feature_text) {
    const url = new URL(`${API_BASE_URL}/admin/add-company-feature`);
    let admin_token = getCookie('admin_token');
    let d = { company_id, feature_text };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyFeatures(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/get-company-features`);
    let admin_token = getCookie('admin_token');
    let d = { company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getEventsList() {
    const url = new URL(`${API_BASE_URL}/admin/get-events-list`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addNewEvent(
    name,
    description,
    address1,
    address2,
    city,
    state,
    zipcode,
    website,
    event_date,
    timezone,
    start_date,
    end_date,
    is_free,
    image_url,
    venue_instructions,
    is_partner
) {
    const url = new URL(`${API_BASE_URL}/admin/add-new-event`);
    let admin_token = getCookie('admin_token');
    let d = {
        name,
        description,
        address1,
        address2,
        city,
        state,
        zipcode,
        website,
        event_date,
        timezone,
        start_date,
        end_date,
        is_free,
        image_url,
        venue_instructions,
        is_partner
    };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateEvent(
    event_id,
    name,
    description,
    address1,
    address2,
    city,
    state,
    zipcode,
    website,
    event_date,
    timezone,
    start_date,
    end_date,
    is_free,
    image_url,
    venue_instructions
) {
    const url = new URL(`${API_BASE_URL}/admin/update-event`);
    let admin_token = getCookie('admin_token');
    let d = {
        event_id,
        name,
        description,
        address1,
        address2,
        city,
        state,
        zipcode,
        website,
        event_date,
        timezone,
        start_date,
        end_date,
        is_free,
        image_url,
        venue_instructions
    };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function deleteEvent(event_id) {
    const url = new URL(`${API_BASE_URL}/admin/delete-event`);
    let admin_token = getCookie('admin_token');
    let d = { event_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getAffiliates() {
    const url = new URL(`${API_BASE_URL}/admin/affiliates`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getAffiliateById(affiliate_id) {
    const url = new URL(`${API_BASE_URL}/admin/affiliate`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({ affiliate_id }),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateAffiliate(id, data, message = '') {
    const url = new URL(`${API_BASE_URL}/admin/update-affiliate`);
    let admin_token = getCookie('admin_token');
    let d = { id, data, message };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addAffiliate(
    name,
    description,
    cta_text,
    affiliate_url,
    logo
) {
    const url = new URL(`${API_BASE_URL}/admin/add-affiliate`);
    let admin_token = getCookie('admin_token');
    let d = { name, description, cta_text, affiliate_url, logo };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getSellerCategories() {
    const url = new URL(`${API_BASE_URL}/admin/seller-categories`);
    let admin_token = getCookie('admin_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getAffiliateCategories(affiliate_id) {
    const url = new URL(`${API_BASE_URL}/admin/affiliate-categories`);
    let admin_token = getCookie('admin_token');
    let d = { affiliate_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addAffiliateCategory(affiliate_id, category_id) {
    const url = new URL(`${API_BASE_URL}/admin/add-affiliate-category`);
    let admin_token = getCookie('admin_token');
    let d = { affiliate_id, category_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function removeAffiliateCategory(affiliate_id, category_id) {
    const url = new URL(`${API_BASE_URL}/admin/remove-affiliate-category`);
    let admin_token = getCookie('admin_token');
    let d = { affiliate_id, category_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyPackagePurchaseItems(
    company_package_purchase_id
) {
    const url = new URL(`${API_BASE_URL}/admin/company-package-purchase-items`);
    let admin_token = getCookie('admin_token');
    let d = { company_package_purchase_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function addCompanyPricing(
    company_id,
    name,
    description,
    price,
    unit
) {
    const url = new URL(`${API_BASE_URL}/admin/add-company-pricing`);
    let admin_token = getCookie('admin_token');
    let d = { company_id, name, description, price, unit };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function updateCompanyPricing(
    company_pricing_id,
    name,
    description,
    price,
    unit
) {
    const url = new URL(`${API_BASE_URL}/admin/update-company-pricing`);
    let admin_token = getCookie('admin_token');
    let d = { company_pricing_id, name, description, price, unit };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function getCompanyPricing(company_id) {
    const url = new URL(`${API_BASE_URL}/admin/get-company-pricing`);
    let admin_token = getCookie('admin_token');
    let d = { company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}

export async function unassignPackagePurchaseItemDemo(
    reference_id,
    company_id
) {
    const url = new URL(`${API_BASE_URL}/admin/unassign-purchase-item-demo`);
    let admin_token = getCookie('admin_token');
    let d = { reference_id, company_id };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(d),
        headers: {
            Authorization: 'Bearer ' + admin_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}
