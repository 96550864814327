import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { getWithdraws, demoPaid } from 'api/api';
import moment from 'moment';
import Lottie from 'lottie-react';
import leadrpro_loading from 'assets/img/animated-icons/leadrpro_loading.json';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

function WithdrawsDashboard() {
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(true);
    const [updated, setUpdated] = useState(true);
    const [err, setErr] = useState(false);
    const [selected, setSelected] = useState(null);
    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: leadrpro_loading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                setLoaded(false);
                getWithdraws().then(res => setData(res.data));
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        }
        loadData();
    }, []);

    const handleClick = async (id, index) => {
        let response;
        setSelected(id);
        setUpdated(false);
        
        setTimeout( async () => {
            response = await demoPaid(id);
            console.log(response, "this is the response");
            if(response.success){
                setUpdated(true)
                setData(items => {
                    return items.map((item, j) => {
                        if(j === index){
                            item['status'] = "Paid";
                            return item;
                        } else {
                            return item;
                        }
                    })
                })
            } else {
                setErr(true);
                console.log(JSON.stringify(response));
                setTimeout(() => setErr(false), 1500);
            }
            // setData(items => {
            //     return items.map((item, j) => {
            //         if(j === index){
            //             item['status'] = "Paid";
            //             return item;
            //         } else {
            //             return item;
            //         }
            //     })
            // })
        }, 500);
    };

    const columns = [
        {
            accessor: 'demo_id',
            Header: 'Demo Id',
            Cell: rowData => {
                const { demo_id } = rowData.row.original
                return (
                    <Link to={'/demo/' + demo_id}>
                        {demo_id}
                    </Link>
                )
            }
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: rowData => {
                const { date_created } = rowData.row.original;
                const date = moment(date_created).local().format("MMM Do YY, h:mm a");
                return (<span>{date}</span>);
            }
        },
        {
            accessor: 'first_name',
            Header: 'Name',
            Cell: rowData => {
                const { first_name, last_name, registered_user_id} = rowData.row.original;
                return (
                    <span>
                        { first_name + " " + last_name }
                        <Link to={'/user/' + registered_user_id}> ({registered_user_id})</Link>
                    </span>
                )
            }
        },
        {
            accessor: 'email',
            Header: 'Email',

        },
        {
            accessor: 'amount',
            Header: 'Amount'
        },
        {
            accessor: 'display_name',
            Header: 'Method'
        },
        {
            accessor: 'source',
            Header: 'Source'
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: rowData => {
                const index = rowData.row.index;
                // console.log(rowData.row.index, "this is the row data")
                const { status, demo_id } = rowData.row.original;
                if (status === "Paid"){
                    return (
                        <span className='badge badge-soft-success rounded-pill'>{status}</span>
                    )
                } else {
                    return (
                        <div className='d-flex'>
                            <div className='d-flex align-items-center'>
                            <span className='badge badge-soft-danger rounded-pill'>{status}</span>
                            </div>
                            {(!updated && selected === demo_id) &&
                                <div className='ms-4'>
                                    <Lottie options={loadingOptions} width="30px" height="30px" />
                                </div>
                            }
                            {(updated && selected === demo_id) &&
                                <span className='ms-4'>Updated.</span>
                            }
                            {(selected && err) &&
                                <span className='ms-4'>Update failed.</span>
                            }
                            {(selected !== demo_id) &&
                                <Button
                                    size='sm'
                                    className='badge rounded-pill ms-2'
                                    onClick={() => handleClick(demo_id, index)}
                                    variant='primary'
                                >Update Status</Button>
                            }
                        </div>
                    )
                }
            }
        }
    ]

    // demo_id: 1241
    // date_created: "2022-06-20T02:53:32.344Z"
    // amount: 120
    // email: "danny@dannyfranklin.com"
    // display_name: "Visa"
    // first_name: "Danny"
    // last_name: "Franklin"
    // reschedule_date_time: null
    // scheduled_date_time: "2022-06-14T21:30:00.000Z"
    // source: "dantheman252@gmail.com"
    // status: "Paid"
    // console.log(data, "these are the withdraws")
    return (
        <FalconComponentCard>
            <FalconComponentCard.Header title="Withdraws" titleTag="h2" noPreview/>
            <FalconComponentCard.Body
                language='jsx'
                noInline
                noLight
                className='bg-light'
            >
                {(loaded && data) &&
                    <AdvanceTableWrapper
                        columns={columns}
                        data={data}
                        sortable
                        pagination
                        perPage={20}
                    >
                        <Row className='flex-end-center mb-3'>
                            <Col xs="auto" sm={6} lg={4}>
                                <AdvanceTableSearchBox table/>
                            </Col>
                        </Row>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className='mt-3'>
                            <AdvanceTableFooter 
                                rowCount={data.length}
                                table
                                rowInfo
                                navButtons
                            />
                        </div>
                    </AdvanceTableWrapper>
                }
            </FalconComponentCard.Body>
        </FalconComponentCard>
    );
}

export default WithdrawsDashboard;