import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { getUserInvites } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

function UserInvites() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getUserInvites();
                setData(() => res.data);
            } catch (err) {
                alert(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    // date_created: '2022-11-08T13:00:00.876Z';
    // email: 'chris@insurednomads.com';
    // has_account: '0';
    // is_active: true;
    // registered_user_id: 7993;
    // status: 'Pending';
    // user_invite_id: 115;

    const columns = [
        {
            accessor: 'registered_user_id',
            Header: 'User Id',
            Cell: (rowData) => {
                const { registered_user_id } = rowData.row.original;
                return (
                    <Link to={'/user/' + registered_user_id}>
                        {registered_user_id}
                    </Link>
                );
            }
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <div>No date.</div>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <div>{date}</div>;
            }
        },
        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'has_account',
            Header: 'Has Account?',
            Cell: (rowData) => {
                const { has_account } = rowData.row.original;
                if (has_account === '0') {
                    return <span className="badge badge-soft-warning">No</span>;
                } else {
                    return (
                        <span className="badge badge-soft-success">Yes</span>
                    );
                }
            }
        },
        {
            accessor: 'is_active',
            Header: 'Is Active?',
            Cell: (rowData) => {
                const { is_active } = rowData.row.original;
                if (is_active === false) {
                    return <span className="badge badge-soft-warning">No</span>;
                } else {
                    return (
                        <span className="badge badge-soft-success">Yes</span>
                    );
                }
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: (rowData) => {
                const { status } = rowData.row.original;

                if (status === 'Success') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <div className="badge badge-soft-success">
                                Success
                            </div>
                        </div>
                    );
                } else if (status === 'Pending') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <div className="badge badge-soft-warning">
                                Pending
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <div className="badge bg-light">{status}</div>
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'user_invite_id',
            Header: 'User Invite Id'
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="User Invites"
                    titleTag="h2"
                    noPreview
                />
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {!loading && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                            pagination
                            perPage={20}
                        >
                            <Row
                                className="flex-between-center mb-3"
                                style={{ paddingLeft: '1.25rem' }}
                            >
                                {/* <Col
                                    xs="auto"
                                    lg={6}
                                    style={{
                                        border: '.5px rgb(99 99 99 / 20%) solid',
                                        borderRadius: '10px',
                                        boxShadow:
                                            'rgb(60 64 67 / 20%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px',
                                        padding: '1rem'
                                    }}
                                >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="me-2">
                                                <DatePicker
                                                    closeOnScroll={true}
                                                    selected={startDate}
                                                    onChange={dateChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    formatWeekDay={(day) =>
                                                        day.slice(0, 3)
                                                    }
                                                    className="form-control"
                                                    placeholderText="Select Date Range"
                                                    dateFormat="MM/dd/yyyy"
                                                    fixedHeight
                                                />
                                            </div>

                                            <div>
                                                <SellerDropdown
                                                    setSelected={
                                                        setSelectedSeller
                                                    }
                                                    sellerRef={sellerRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <motion.button
                                                className="btn btn-sm btn-primary rounded-pill d-flex align-items-center justify-content-center"
                                                onClick={() => handleFilter()}
                                                style={{
                                                    boxShadow:
                                                        '0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)'
                                                }}
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFilter}
                                                    className="me-1"
                                                />
                                                Filter
                                            </motion.button>
                                            <button
                                                className="btn btn-falcon-secondary rounded-pill btn-sm"
                                                onClick={() =>
                                                    handleFilterReset()
                                                }
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </Col> */}
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {loading && <h4>Loading...</h4>}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default UserInvites;
