import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCharityCampaigns } from 'api/api';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

function CharityCampaigns() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getCharityCampaigns();
                setData(res.data);
                console.warn(
                    res,
                    'this is the response from getCharityCampaigns'
                );
            } catch (err) {
                console.warn(err.message, 'this the error');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const columns = [
        {
            accessor: 'date_created',
            Header: 'Created On',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'charity_campaign_id',
            Header: 'Charity Campaign Id',
            Cell: (rowData) => {
                const { charity_campaign_id } = rowData.row.original;
                return (
                    <Link
                        to={'/charity-campaign/details/' + charity_campaign_id}
                    >
                        {charity_campaign_id}
                    </Link>
                );
            }
        },
        {
            accessor: 'name',
            Header: 'Name'
        },
        {
            accessor: 'seoname',
            Header: 'SEO Name'
        },
        {
            accessor: 'description',
            Header: 'Description'
        },
        {
            accessor: 'start_month',
            Header: 'Start Month'
        },
        {
            accessor: 'start_year',
            Header: 'Start Year'
        },
        {
            accessor: 'website',
            Header: 'Website'
        },
        {
            accessor: 'is_active',
            Header: 'Active?'
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Buyers"
                    titleTag="h2"
                    noPreview
                />
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {!loading && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                            pagination
                            perPage={15}
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {loading && <div>Loading...</div>}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default CharityCampaigns;
