import React, { useState, useEffect } from 'react';
import { getPaymentPlans } from 'api/api';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';

function PaymentPlans() {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);

    // cost_per_credit: 50;
    // credits_received: 0;
    // date_created: '2022-06-20T18:27:41.977Z';
    // is_active: true;
    // is_recurring: true;
    // monthly_subscription: 0;
    // name: 'Pay as you go';
    //! order_id: 1;
    // payment_plan_id: 3;

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getPaymentPlans();
                console.log(res, ': payment plans');
                setData(() => res.data);
            } catch (err) {
                console.log(err, 'this is the error in the useEffect');
            } finally {
                setLoaded(() => true);
            }
        };

        loadData();
    }, []);

    const columns = [
        {
            accessor: 'name',
            Header: 'Name'
        },
        {
            accessor: 'credits_received',
            Header: 'Credits Received'
        },
        {
            accessor: 'is_active',
            Header: 'Is Active?',
            Cell: (rowData) => {
                const { is_active } = rowData.row.original;

                if (is_active) {
                    return (
                        <span className="badge badge-soft-success">True</span>
                    );
                }
                return <span className="badge bagde-soft-danger">False</span>;
            }
        },
        {
            accessor: 'cost_per_credit',
            Header: 'Cost per Credit',
            Cell: (rowData) => {
                const { cost_per_credit } = rowData.row.original;

                return <span>${cost_per_credit}</span>;
            }
        },
        {
            accessor: 'monthly_subscription',
            Header: 'Monthly Subscription',
            Cell: (rowData) => {
                const { monthly_subscription } = rowData.row.original;

                return <span>${monthly_subscription}</span>;
            }
        },
        {
            accessor: 'short_name',
            Header: 'Short Name',
            Cell: (rowData) => {
                const { short_name } = rowData.row.original;

                if (short_name === 'PAYG') {
                    return (
                        <span className="badge badge-soft-danger">
                            {short_name}
                        </span>
                    );
                }
                if (short_name === 'PREM') {
                    return (
                        <span className="badge badge-soft-warning">
                            {short_name}
                        </span>
                    );
                }
                if (short_name === 'GROW') {
                    return (
                        <span className="badge badge-soft-info">
                            {short_name}
                        </span>
                    );
                }
                if (short_name === 'CUST') {
                    return (
                        <span className="badge badge-soft-success">
                            {short_name}
                        </span>
                    );
                }
                return (
                    <span className="badge badge-soft-info">{short_name}</span>
                );
            }
        },
        {
            accessor: 'number_of_companies',
            Header: '# of Companies'
        }
    ];

    return (
        <div>
            <Card>
                <Card.Header>
                    <h5>Payment Plans</h5>
                </Card.Header>
                <Card.Body className="bg-light">
                    {loaded && data && (
                        <AdvanceTableWrapper columns={columns} data={data}>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </AdvanceTableWrapper>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}

export default PaymentPlans;
