import React from "react";
import Select from 'react-select';

function TimezoneDropdown ({user, setUser}) {
    //todo: Organize all the timezones into an array with the corresponding value
    //todo: then make a handle function that targets the timezone inside the state
    // console.log(user, "this is the current user state inside the dropdown");
    const handleChange = target => {
        // console.log(target.value, "inside the handleChange")
        setUser({
            ...user, 
            ['timezone']: target.value
        })
    }
    const organizerOptions = [
        { value: 'America/Adak', label: '(GMT-10:00) Adak' },
        { value: 'America/Anchorage', label: '(GMT-09:00) Anchorage' },
        { value: 'America/Juneau', label: '(GMT-09:00 Juneau' },
        { value: 'America/Metlakatla', label: '(GMT-09:00) Metlakatla' },
        { value: 'America/Nome', label: '(GMT-09:00) Nome'},
        { value: 'America/Sitka', label: '(GMT-09:00) Sitka'},
        { value: 'America/Yakutat', label: '(GMT-09:00) Yakutat'},
        { value: 'America/Los_Angeles', label: '(GMT-08:00) Los_Angeles'},
        { value: 'America/Tijuana', label: '(GMT-08:00) Tijuana'},
        { value: 'America/Vancouver', label: '(GMT-08:00) Vancouver'},
        { value: 'America/Boise', label: '(GMT-07:00) Boise'},
        { value: 'America/Cambridge_Bay', label: '(GMT-07:00) Cambridge_Bay'},
        { value: 'America/Chihuahua', label: '(GMT-07:00) Chihuahua'},
        { value: 'America/Creston', label: '(GMT-07:00) Creston'},
        { value: 'America/Dawson', label: '(GMT-07:00) Dawson'},
        { value: 'America/Dawson_Creek', label: '(GMT-07:00) Dawson Creek'},
        { value: 'America/Denver', label: '(GMT-07:00) Denver'},
        { value: 'America/Edmonton', label: '(GMT-07:00) Edmonton'},
        { value: 'America/Fort_Nelson', label: '(GMT-07:00) Fort Nelson'},
        { value: 'America/Hermosillo', label: '(GMT-07:00) Hermosillo'},
        { value: 'America/Inuvik', label: '(GMT-07:00) Inuvik'},
        { value: 'America/Mazatlan', label: '(GMT-07:00) Mazatlan'},
        { value: 'America/Ojinaga', label: '(GMT-07:00) Ojinaga'},
        { value: 'America/Phoenix', label: '(GMT-07:00) Phoenix'},
        { value: 'America/Whitehorse', label: '(GMT-07:00) Whitehorse'},
        { value: 'America/Yellowknife', label: '(GMT-07:00) Yellowknife'},
        { value: 'America/Bahia_Banderas', label: '(GMT-06:00) Bahia Banderas'},
        { value: 'America/Belize', label: '(GMT-06:00) Belize'},
        { value: 'America/Chicago', label: '(GMT-06:00) Chicago'},
        { value: 'America/Costa_Rica', label: '(GMT-06:00) Costa Rica'},
        { value: 'America/El_Salvador', label: '(GMT-06:00) El Salvador'},
        { value: 'America/Guatemala', label: '(GMT-06:00) Guatemala'},
        { value: 'America/Indiana/Knox', label: '(GMT-06:00) Indiana / Knox'},
        { value: 'America/Indiana/Tell_City', label: '(GMT-06:00) Indiana / Tell City '},
        { value: 'America/Managua', label: '(GMT-06:00) Managua'},
        { value: 'America/Matamoros', label: '(GMT-06:00) Matamoros'},
        { value: 'America/Menominee', label: '(GMT-06:00) Menominee'},
        { value: 'America/Merida', label: '(GMT-06:00) Merida'},
        { value: 'America/Mexico_City', label: '(GMT-06:00) Mexico City'},
        { value: 'America/Monterrey', label: '(GMT-06:00) Monterrey'},
        { value: 'America/North_Dakota/Beulah', label: '(GMT-06:00) North Dakota / Beulah'},
        { value: 'America/North_Dakota/Center"', label: '(GMT-06:00) North Dakota / Center'},
        { value: 'America/North_Dakota/New_Salem', label: '(GMT-06:00) North Dakota / New Salem'},
        { value: 'America/Rainy_River"', label: '(GMT-06:00) Rainy River'},
        { value: 'America/Rankin_Inlet', label: '(GMT-06:00) Rankin Inlet'},
        { value: 'America/Regina', label: '(GMT-06:00) Regina'},
        { value: 'America/Resolute', label: '(GMT-06:00) Resolute'},
        { value: 'America/Swift_Current', label: '(GMT-06:00) Swift Current'},
        { value: 'America/Tegucigalpa', label: '(GMT-06:00) Tegucigalpa'},
        { value: 'America/Winnipeg', label: '(GMT-06:00) Winnipeg'},
        { value: 'America/Atikokan', label: '(GMT-05:00) Atikokan'},
        { value: 'America/Bogota', label: '(GMT-05:00) Bogota'},
        { value: 'America/Cancun', label: '(GMT-05:00) Cancun'},
        { value: 'America/Cayman', label: '(GMT-05:00) Cayman'},
        { value: 'America/Detroit', label: '(GMT-05:00) Detroit'},
        { value: 'America/Eirunepe', label: '(GMT-05:00) Eirunepe'},
        { value: 'America/Grand_Turk', label: '(GMT-05:00) Grand Turk'},
        { value: 'America/Guayaquil', label: '(GMT-05:00) Guayaquil'},
        { value: 'America/Havana', label: '(GMT-05:00) Havana'},
        { value: 'America/Indiana/Indianapolis', label: '(GMT-05:00) Indiana / Indianapolis'},
        { value: 'America/Indiana/Marengo', label: '(GMT-05:00) Indiana / Marengo'},
        { value: 'America/Indiana/Petersburg', label: '(GMT-05:00) Indiana / Petersburg'},
        { value: 'America/Indiana/Vevay', label: '(GMT-05:00) Indiana / Vevay'},
        { value: 'America/Indiana/Vincennes', label: '(GMT-05:00) Indiana / Vincennes'},
        { value: 'America/Indiana/Winamac', label: '(GMT-05:00) Indiana / Winamac'},
        { value: 'America/Iqaluit', label: '(GMT-05:00) Iqaluit'},
        { value: 'America/Jamaica"', label: '(GMT-05:00) Jamaica'},
        { value: 'America/Kentucky/Louisville', label: '(GMT-05:00) Kentucky / Louisville'},
        { value: 'America/Kentucky/Monticello', label: '(GMT-05:00) Kentucky / Monticello'},
        { value: 'America/Lima', label: '(GMT-05:00) Lima'},
        { value: 'America/Nassau', label: '(GMT-05:00) Nassau'},
        { value: 'America/New_York', label: '(GMT-05:00) New York'},
        { value: 'America/Nipigon', label: '(GMT-05:00) Nipigon'},
        { value: 'America/Panama', label: '(GMT-05:00) Panama'},
        { value: 'America/Pangnirtung"', label: '(GMT-05:00) Pangnirtung'},
        { value: 'America/Port-au-Prince"', label: '(GMT-05:00) Port-au-Prince'},
        { value: 'America/Rio_Branco', label: '(GMT-05:00) Rio Branco'},
        { value: 'America/Thunder_Bay', label: '(GMT-05:00) Thunder Bay'},
        { value: 'America/Toronto', label: '(GMT-05:00) Toronto'},
        { value: 'America/Anguilla', label: '(GMT-04:00) Anguilla'},
        { value: 'America/Antigua', label: '(GMT-04:00) Antigua'},
        { value: 'America/Aruba', label: '(GMT-04:00) Aruba'},
        { value: 'America/Asuncion', label: '(GMT-04:00) Asuncion'},
        { value: 'America/Barbados', label: '(GMT-04:00) Barbados'},
        { value: 'America/Blanc-Sablon', label: '(GMT-04:00) Blanc-Sablon'},
        { value: 'America/Boa_Vista', label: '(GMT-04:00) Boa Vista'},
        { value: 'America/Campo_Grande', label: '(GMT-04:00) Campo Grande'},
        { value: 'America/Caracas', label: '(GMT-04:00) Caracas'},
        { value: 'America/Cuiaba', label: '(GMT-04:00) Cuiaba'},
        { value: 'America/Curacao', label: '(GMT-04:00) Curacao'},
        { value: 'America/Dominica', label: '(GMT-04:00) Dominica'},
        { value: 'America/Glace_Bay', label: '(GMT-04:00) Glace Bay'},
        { value: 'America/Goose_Bay', label: '(GMT-04:00) Goose Bay'},
        { value: 'America/Grenada', label: '(GMT-04:00) Grenada'},
        { value: 'America/Guadeloupe', label: '(GMT-04:00) Guadeloupe'},
        { value: 'America/Guyana', label: '(GMT-04:00) Guyana'},
        { value: 'America/Halifax', label: '(GMT-04:00) Halifax'},
        { value: 'America/Kralendijk', label: '(GMT-04:00) Kralendijk'},
        { value: 'America/La_Paz', label: '(GMT-04:00) La Paz'},
        { value: 'America/Lower_Princes', label: '(GMT-04:00) Lower Princes'},
        { value: 'America/Manaus', label: '(GMT-04:00) Manaus'},
        { value: 'America/Marigot', label: '(GMT-04:00) Marigot'},
        { value: 'America/Martinique', label: '(GMT-04:00) Martinique'},
        { value: 'America/Moncton', label: '(GMT-04:00) Moncton'},
        { value: 'America/Montserrat', label: '(GMT-04:00) Montserrat'},
        { value: 'America/Port_of_Spain', label: '(GMT-04:00) Port of Spain'},
        { value: 'America/Porto_Velho', label: '(GMT-04:00) Porto Velho'},
        { value: 'America/Puerto_Rico', label: '(GMT-04:00) Puerto Rico'},
        { value: 'America/Santiago', label: '(GMT-04:00) Santiago'},
        { value: 'America/Santo_Domingo', label: '(GMT-04:00) Santo Domingo'},
        { value: 'America/St_Barthelemy', label: '(GMT-04:00) St Barthelemy'},
        { value: 'America/St_Kitts', label: '(GMT-04:00) St Kitts'},
        { value: 'America/St_Lucia', label: '(GMT-04:00) St Lucia'},
        { value: 'America/St_Thomas', label: '(GMT-04:00) St Thomas'},
        { value: 'America/St_Vincent', label: '(GMT-04:00) St Vincent'},
        { value: 'America/Thule', label: '(GMT-04:00) Thule'},
        { value: 'America/Tortola', label: '(GMT-04:00) Tortola'},
        { value: 'America/St_Johns', label: '(GMT-03:30) St Johns'},
        { value: 'America/Araguaina', label: '(GMT-03:00) Araguaina'},
        { value: 'America/Argentina/Buenos_Aires', label: '(GMT-03:00) Argentina / Buenos Aires'},
        { value: 'America/Argentina/Catamarca', label: '(GMT-03:00) Argentina / Catamarca'},
        { value: 'America/Argentina/Cordoba', label: '(GMT-03:00) Argentina / Cordoba'},
        { value: 'America/Argentina/Jujuy', label: '(GMT-03:00) Argentina / Jujuy'},
        { value: 'America/Argentina/La_Rioja', label: '(GMT-03:00) Argentina / La Rioja'},
        { value: 'America/Argentina/Mendoza', label: '(GMT-03:00) Argentina / Mendoza'},
        { value: 'America/Argentina/Rio_Gallegos', label: '(GMT-03:00) Argentina / Rio Gallegos'},
        { value: 'America/Argentina/Salta', label: '(GMT-03:00) Argentina / Salta'},
        { value: 'America/Argentina/San_Juan', label: '(GMT-03:00) Argentina / San Juan'},
        { value: 'America/Argentina/San_Luis', label: '(GMT-03:00) Argentina / San Luis'},
        { value: 'America/Argentina/Tucuman', label: '(GMT-03:00) Argentina / Tucuman'},
        { value: 'America/Argentina/Ushuaia', label: '(GMT-03:00) Argentina / Ushuaia'},
        { value: 'America/Bahia', label: '(GMT-03:00) Bahia'},
        { value: 'America/Belem', label: '(GMT-03:00) Belem'},
        { value: 'America/Cayenne', label: '(GMT-03:00) Cayenne'},
        { value: 'America/Fortaleza', label: '(GMT-03:00) Fortaleza'},
        { value: 'America/Maceio', label: '(GMT-03:00) Maceio'},
        { value: 'America/Miquelon', label: '(GMT-03:00) Miquelon'},
    ];
    return (
        <Select
        closeMenuOnSelect={true}
        options={organizerOptions}
        placeholder={user.timezone}
        classNamePrefix="react-select"
        value={user.timezone}
        onChange={value => handleChange(value)}
        />
    );
}

export default TimezoneDropdown;