import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Dropdown, Button } from 'react-bootstrap';
import { motion } from 'framer-motion/dist/framer-motion';
import { getCompletedDemos } from 'api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import es from 'date-fns/locale/es';
import SoftBadge from 'components/common/SoftBadge';
import CardDropdown from 'components/common/CardDropdown';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import DemosModal from '../DemosModal';
import SellerDropdown from 'components/leadrpro/demos/completedDemos/SellerDropdown';

registerLocale('es', es);

function CompletedDemosDashboard() {
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState(null);
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);
    const [index, setIndex] = useState(null);
    const [action, setAction] = useState('');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedSeller, setSelectedSeller] = useState(null);
    const [filteredDemos, setFilteredDemos] = useState(null);
    const sellerRef = useRef();

    useEffect(() => {
        const loadUpcomingDemos = async () => {
            try {
                setLoaded(false);
                getCompletedDemos().then((res) => setDemos(res.data));
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        };

        loadUpcomingDemos();
    }, []);

    const handleShow = (index, demo, action) => {
        setIndex(index);
        setSelected(demo);
        setAction(action);
        setShow(true);
    };

    const dateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(() => start);
        setEndDate(() => end);
    };

    const handleFilter = () => {
        if (!startDate && !endDate && !selectedSeller) return;
        let newDemos = demos;

        if (startDate && endDate) {
            let start = new Date(startDate).setUTCHours(0, 0, 0, 0);
            start = new Date(start).toISOString();
            let end = new Date(endDate).setUTCHours(23, 59, 0, 0);
            end = new Date(end).toISOString();

            newDemos = newDemos.filter((object) => {
                if (object.reschedule_date_time) {
                    if (
                        start <= object.reschedule_date_time &&
                        object.reschedule_date_time <= end
                    ) {
                    }
                    return (
                        start <= object.reschedule_date_time &&
                        object.reschedule_date_time <= end
                    );
                } else {
                    return (
                        start <= object.scheduled_date_time &&
                        object.scheduled_date_time <= end
                    );
                }
            });
        }

        if (selectedSeller) {
            console.log(selectedSeller, 'seller selected');
            newDemos = newDemos.filter((object) => {
                return object.seller_company_id === selectedSeller.value;
            });
        }
        setFilteredDemos(() => newDemos);
        console.log(newDemos, 'these are the filtered demos');
    };

    const handleFilterReset = () => {
        console.log(sellerRef);
        sellerRef.current.clearValue();
        setFilteredDemos(() => null);
        setStartDate(null);
        setEndDate(null);
        setSelectedSeller(null);
    };

    const columns = [
        {
            accessor: 'demo_id',
            Header: 'Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },

        {
            accessor: 'seller_company_name',
            Header: 'Seller Company',
            Cell: (rowData) => {
                const { seller_company_id, seller_company_name, seller_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    seller_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {seller_company_name + ' '}
                        <Link
                            className="ms-1"
                            to={'/company/' + seller_company_id}
                        >
                            ({seller_company_id})
                        </Link>
                    </div>
                );
            }
        },

        {
            accessor: 'buyer_company_name',
            Header: 'Buyer Company',
            Cell: (rowData) => {
                const { buyer_company_id, buyer_company_name, buyer_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    buyer_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>

                        {buyer_company_name + ' '}
                        <Link to={'/company/' + buyer_company_id}>
                            ({buyer_company_id})
                        </Link>
                    </div>
                );
            }
        },

        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <div>No date.</div>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <div>{date}</div>;
            }
        },
        {
            accessor: 'scheduled_date_time',
            Header: 'Scheduled Date & Time',
            Cell: (rowData) => {
                const { scheduled_date_time, reschedule_date_time } =
                    rowData.row.original;
                if (!scheduled_date_time && !reschedule_date_time) {
                    return <span>Not scheduled.</span>;
                }
                if (reschedule_date_time) {
                    const date = moment(reschedule_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                } else {
                    const date = moment(scheduled_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                }
            }
        },
        // {
        //     accessor: 'demo_status',
        //     Header: 'Demo Status',
        //     Cell: (rowData) => {
        //         const { demo_status } = rowData.row.original;

        //         if (demo_status === 'Completed') {
        //             return (
        //                 <div className="d-flex flex-column container align-items-center">
        //                     <div className="badge badge-soft-success">
        //                         Completed
        //                     </div>
        //                 </div>
        //             );
        //         } else if (demo_status === 'Pending') {
        //             return (
        //                 <div className="d-flex flex-column container align-items-center">
        //                     <div className="badge badge-soft-warning">
        //                         Pending
        //                     </div>
        //                 </div>
        //             );
        //         } else if (demo_status === 'Declined') {
        //             return (
        //                 <div className="d-flex flex-column container align-items-center">
        //                     <div className="badge badge-soft-danger">
        //                         Declined
        //                     </div>
        //                 </div>
        //             );
        //         } else {
        //             return (
        //                 <div className="d-flex flex-column container align-items-center">
        //                     <div className="badge bg-light">{demo_status}</div>
        //                 </div>
        //             );
        //         }
        //     }
        // },
        {
            accessor: 'is_paid',
            Header: 'Is Paid',
            Cell: (rowData) => {
                const { is_paid } = rowData.row.original;
                if (is_paid === '0') {
                    return (
                        <SoftBadge pill bg={'warning'} className="d-block">
                            Not paid
                            <FontAwesomeIcon
                                icon={'stream'}
                                transform="shrink-2"
                                className="ms-1"
                            />
                        </SoftBadge>
                    );
                } else {
                    return (
                        <SoftBadge pill bg={'success'} className="d-block">
                            Paid
                            <FontAwesomeIcon
                                icon={'check'}
                                transform="shrink-2"
                                className="ms-1"
                            />
                        </SoftBadge>
                    );
                }
            }
        },
        {
            accessor: 'seller_email',
            Header: 'Seller Email'
        },
        {
            accessor: 'seller_timezone',
            Header: 'Seller Timezone'
        },
        {
            accessor: 'buyer_first_name',
            Header: 'Buyer Name',
            Cell: (rowData) => {
                const { buyer_first_name, buyer_last_name, buyer_id } =
                    rowData.row.original;
                return (
                    <div>
                        {buyer_first_name + ' ' + buyer_last_name}
                        <Link to={'/user/' + buyer_id}> ({buyer_id})</Link>
                    </div>
                );
            }
        },
       
        {
            accessor: 'buyer_email',
            Header: 'Buyer Email'
        },
        {
            accessor: 'buyer_timezone',
            Header: 'Buyer Timezone'
        },
        {
            accessor: 'feedback',
            Header: 'Feedback',
            disableSortBy: true,
            cellProps: {
                className: 'd-flex justify-content-center'
            },
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                return (
                    <div>
                        <Button
                            variant="light"
                            size="sm"
                            className="border-300 me-1 text-600"
                            as={'a'}
                            onClick={() => handleShow(index, demo, 'Feedback')}
                        >
                            <FontAwesomeIcon icon="comment-dots" />
                        </Button>
                    </div>
                );
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                const { demo_status } = rowData.row.original;
                return (
                    <CardDropdown iconClassName="fs--1" drop="start">
                        <div>
                            <Dropdown.Item
                                eventkey="1"
                                onClick={() =>
                                    handleShow(index, demo, 'Update')
                                }
                                className={
                                    demo_status === 'Completed'
                                        ? 'text-secondary disabled'
                                        : 'text-success'
                                }
                            >
                                Completed
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                onClick={() =>
                                    handleShow(index, demo, 'Pending')
                                }
                                className={
                                    demo_status === 'Pending'
                                        ? 'text-warning disabled'
                                        : 'text-warning'
                                }
                            >
                                Pending
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventkey="1"
                                onClick={() =>
                                    handleShow(index, demo, 'Seller Reschedule')
                                }
                                className="text-primary"
                            >
                                Seller Reschedule
                            </Dropdown.Item>
                            <Dropdown.Divider as="div" />
                            <Dropdown.Item
                                eventkey="2"
                                onClick={() =>
                                    handleShow(index, demo, 'Delete')
                                }
                                className="text-danger"
                            >
                                Delete
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Completed Demos"
                    titleTag="h2"
                    noPreview
                />
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {loaded && demos && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={
                                !filteredDemos || filteredDemos.length < 0
                                    ? demos
                                    : filteredDemos
                            }
                            sortable
                            pagination
                            perPage={20}
                        >
                            <Row
                                className="flex-between-center mb-3"
                                style={{ paddingLeft: '1.25rem' }}
                            >
                                <Col
                                    xs="auto"
                                    lg={6}
                                    style={{
                                        border: '.5px rgb(99 99 99 / 20%) solid',
                                        borderRadius: '10px',
                                        boxShadow:
                                            'rgb(60 64 67 / 20%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px',
                                        padding: '1rem'
                                    }}
                                >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="me-2">
                                                <DatePicker
                                                    closeOnScroll={true}
                                                    selected={startDate}
                                                    onChange={dateChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    formatWeekDay={(day) =>
                                                        day.slice(0, 3)
                                                    }
                                                    className="form-control"
                                                    placeholderText="Select Date Range"
                                                    dateFormat="MM/dd/yyyy"
                                                    fixedHeight
                                                />
                                            </div>

                                            <div>
                                                <SellerDropdown
                                                    setSelected={
                                                        setSelectedSeller
                                                    }
                                                    sellerRef={sellerRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <motion.button
                                                className="btn btn-sm btn-primary rounded-pill d-flex align-items-center justify-content-center"
                                                onClick={() => handleFilter()}
                                                style={{
                                                    boxShadow:
                                                        '0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)'
                                                }}
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFilter}
                                                    className="me-1"
                                                />
                                                Filter
                                            </motion.button>
                                            <button
                                                className="btn btn-falcon-secondary rounded-pill btn-sm"
                                                onClick={() =>
                                                    handleFilterReset()
                                                }
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={demos.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    <DemosModal
                        show={show}
                        setShow={setShow}
                        selected={selected}
                        setData={setDemos}
                        index={index}
                        action={action}
                    />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default CompletedDemosDashboard;
