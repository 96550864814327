import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Form, Card } from 'react-bootstrap';
import Select from 'react-select';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getSellerFeedback, getCoupons, addCoupon, getSellersForDropdown} from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import StarRating from 'components/common/StarRating';
import SellersDropdown from '../users/addSeller/SellersDropdown';// from '../demos/completedDemos/SellerDropdown';
import DatePicker, { registerLocale } from 'react-datepicker';
//import SellersDropdown from './SellersDropdown';

function Coupons() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [sellers, setSellers] = useState(null);
    const [code, setCode] = useState('');
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('');
    const [date, setDate] = useState(null);
    const [isSingleUse, setIsSingleUse] = useState(false);
    const initialForm = {
        company: '',
        company_id: '',
        website: '',
        company_size_id: '',
        industry_id: '',
        email: '',
        first_name: '',
        last_name: '',
        company_id: ''
    };
    const [formData, setFormData] = useState({ ...initialForm });

    useEffect(() => {
        console.log('form data', formData);
    }, [formData]);

    const loadData = async () => {
        setLoading(true);
        try {
            const res = await getCoupons();
            console.log(res);
            setData(res.data);
        } catch (err) {
            console.warn('This is the error: ', err.message);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const res = await getCoupons();
                console.log(res);
                setData(res.data);
            } catch (err) {
                console.warn('This is the error: ', err.message);
            } finally {
                setLoading(false);
            }
        };

        const loadSellers = async () => {
            try {
                const res = await getSellersForDropdown();
                setSellers(res);
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        };

        loadSellers();
        loadData();
    }, []);

    async function handleSubmit(e){
        e.preventDefault();
        //console.log(code, date, isSingleUse, amount, type,formData.company_id);
        let res = await addCoupon(code, formData.company_id, date, amount, type, isSingleUse);

        if (res.success){
            let res2 = await loadData();

            if (res2.success){
                setData(res2.data);
                setCode('');
            }
        }
    }

    async function generateCode(){

    }

    async function handleDiscountTypeChange(e){
        console.log('value', e);
        setType(e.label);
       // console.log(e.target);
    }

    async function toggleIsSingleUse(){
        setIsSingleUse(!isSingleUse);
    }

    async function handleGenerateCodeClick(){
        let code = generateCode();
        setCode(code);
    }

    function generateCode() {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 20; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength)).toUpperCase();
        }
        return result;
      }

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    const columns = [
        {
            accessor: 'coupon_id',
            Header: 'Id'
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'demo_id',
            Header: 'Demo Applied To',
            Cell: (rowData) => {
                const { demo_id } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <Link to={'/demo/' + demo_id}>{demo_id}</Link>
                    </div>
                );
            }
        },
        {
            accessor: 'code',
            Header: 'Code',
            Cell: (rowData) => {
                const { code } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        {code}
                    </div>
                );
            }
        },
        {
            accessor: 'expires_on',
            Header: 'Expires On',
            Cell: (rowData) => {
                const { expires_on } = rowData.row.original;
                if (!expires_on) {
                    return <span>No date.</span>;
                }
                const date = moment(expires_on)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'discount_amount',
            Header: 'Discount',
            Cell: (rowData) => {
                const { discount_amount, discount_type } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        {discount_amount} {discount_type} off
                    </div>
                );
            }
        },
        {
            accessor: 'company_name',
            Header: 'Company',
            Cell: (rowData) => {
                const { company_name } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        {company_name}
                    </div>
                );
            }
        },
        {
            accessor: 'single_use',
            Header: 'Single use?',
            Cell: (rowData) => {
                const { single_use } = rowData.row.original;

                if (single_use) {
                    return (
                        <div className="d-flex justify-content-center align-items-center text-success">
                            <FontAwesomeIcon icon={faCheckSquare} />
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    );
                }
            }
        }
    ];

    // accuracy: 5;
    // intent: 5;
    // communication: 5;
    // professionalism: 5;
    // timeliness: 5;
    // buyer_company_name: 'Marshall Retail Group';
    // buyer_id: 7667;
    // demo_date: '2023-01-13T21:00:00.574Z';
    // demo_id: 12877;
    // email: 'Rrandazzo@marshallretailgroup.com';
    //! estimated_value: 0;
    //! feedback_text: 'Good. They have specific problems they’re looking to solve, and we have a free beta product that could help.';
    // first_name: 'Ray';
    // last_name: 'Randazzo';
    // moving_to_proposal: true;
    // seller_company_name: 'Vendorful';
    // seller_id: 8432;

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            {/* Company Info Card */}
            <Card className="mb-3">
                <FalconCardHeader title={'Create Coupon'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleSubmit}>
                        {/* Name & Website */}
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="code">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Code:
                                </Form.Label>
                                <span style={{textDecoration: "underline", cursor: "pointer", color: "dodgerblue", marginLeft: "20px"}} onClick={() => handleGenerateCodeClick()}>Generate Code</span>
                                <Form.Control
                                    type="text"
                                    placeholder="Code"
                                    name="code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Company Id:
                                </Form.Label>
                                <SellersDropdown data={formData} setData={setFormData} />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="amount">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Discount amount:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Discount Amount"
                                    name="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="type">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Discount Type:
                                </Form.Label>
                                <Select
                                    closeMenuOnSelect={true}
                                    options={[{label: "Dollar", value: "1"}, {label: "Percent", value: "2"}]}
                                    placeholder={'Select a discount type'}
                                    classNamePrefix="react-select"
                                    onChange={(value) => handleDiscountTypeChange(value)}
                                    className="form-control p-0"
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="type">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Expires On (optional):
                                </Form.Label>
                                <DatePicker
                                selected={date}
                                onChange={(date) => setDate(date)}
                                formatWeekDay={(day) => day.slice(0, 3)}
                                className="form-control"
                                placeholderText="Select Date & Time"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                fixedHeight
                            />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="type">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Single Use:
                                </Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    id="singleUseCheckbox"
                                    label="One-time use coupon code"
                                    checked={isSingleUse}
                                    onChange={() => toggleIsSingleUse()}
                                />
                            </Form.Group>
                        </Row>
                      
                        {/* Submit Button */}
                        <div className="text-end">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Save
                            </motion.button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Coupon Codes"
                    titleTag="h2"
                    noPreview
                />

                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {!loading && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                            pagination
                            perPage={100}
                        >
                            <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {loading && <h5>Loading...</h5>}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default Coupons;
