import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Image, ProgressBar, Row, Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { getSize } from 'helpers/utils';
import { createBuyer, logImport } from 'api/api';
import screenshot from '../../../assets/img/leadrpro/import_guidlines2.png'
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import FalconComponentCard from 'components/common/FalconComponentCard';

function ImportPage() {
    const [files, setFiles] = useState([]); 
    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [source, setSource] = useState({ source: '' });

    var date = new Date().toISOString();
    console.log(date);

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.csv',
        onDrop: acceptedFiles => {
            setFiles(
                acceptedFiles.map(file => 
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            )
        }
    });

    function csvToArray(str, delimiter = ","){
        let data_split = str.split(/\r?\n/);
        data_split.shift()

        const arr = data_split.map(function (row) {
            const values = row.split(delimiter);
            const el = {
                first_name: values[0],
                last_name: values[1],
                email: values[2],
                company_name: values[3],
                industry: values[4],
                company_size: values[5],
                job_title: values[6],
                seniority: values[7],
                department: values[8],
                phone_number: values[9],
            };

            return el;
        });

        return arr;
    }

    const handleChange = ({ target }) => {
        setSource({[target.name]: target.value});
    }

    const handleRemove = path => {
        setFiles(files.filter(file => file.path !== path));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let parsed;
        const input = files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            parsed = csvToArray(text);

            try {
                setLoading(true);
                await logImport({name: source.source + "-" + date});
                
                for(let i = 0; i < parsed.length; i++){
                    setPercentage(Math.round((i + 1) / parsed.length));
                    const d = {...parsed[i], 'source' : source.source + "-" + date};
                    const response = await createBuyer(d);

                    if(response.success){
                        console.log('Import Successful');
                    } else {
                        console.log('Import failed.');
                    }
                }
                return alert('Import successful');
            } catch (err){
                alert(err.message);
                console.log(JSON.stringify(err.message));
            } finally {
                setLoading(false);
            }
        }
        reader.readAsText(input);
    }

    return (
        <>
            <FalconComponentCard className="mb-3">
                <FalconComponentCard.Header title='Import' titleTag='h2' noPreview/>
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3 g-3">
                            <Form.Label>Import Name (source)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                value={source.source}
                                name="source"
                                onChange={handleChange}
                                required
                            />
                        </Row>
                        <div {...getRootProps({ className: 'dropzone-area py-6' })}>
                            <input {...getInputProps()} />
                            <Flex justifyContent="center">
                            <img src={cloudUpload} alt="" width={25} className="me-2" />
                            <p className="fs-0 mb-0 text-700">Drop your files here. (csv)</p>
                            </Flex>
                        </div>
                        <div className="mt-3">
                            {files.map(file => (
                                <Flex
                                alignItems="center"
                                className="py-3 border-bottom btn-reveal-trigger"
                                key={file.path}
                                >
                                <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                                <div>
                                    <h6>{file.path}</h6>
                                    <Flex className="position-relative" alignItems="center">
                                    <p className="mb-0 fs--1 text-400 line-height-1">
                                        <strong>
                                        {getSize(file.size)}
                                        </strong>
                                    </p>
                                    </Flex>
                                </div>
                                </Flex>
                                <CardDropdown>
                                <div className="py-2">
                                    <Dropdown.Item className="text-danger" onClick={() => handleRemove(file.path)}>
                                    Remove
                                    </Dropdown.Item>
                                </div>
                                </CardDropdown>
                            </Flex>
                            ))}
                        </div>
                        <div className='mt-3 d-flex justify-content-end'>
                            {!loading &&
                                <Button 
                                type='submit'
                                variant='primary'
                                className={files.length > 0 ? '' : 'disabled'}
                                >Import</Button>
                            }
                            {loading &&
                                <ProgressBar animated="true" now={percentage} label={`${percentage}%`}/>
                            }
                        </div>
                    </Form>
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {/* Screenshot */}
            <FalconComponentCard className="mb-3">
                <FalconComponentCard.Header title='Import Guidelines' noPreview/>
                    <FalconComponentCard.Body>
                        <div>
                            <p>The name of the import will have a timestamp attached to it ex. you type `fize` it will be submitted as `fize-{date}`</p>
                            <p>THE NAME OF THE COLUMNS NEED TO BE INCLUDED AND NEED TO BE IN THE FOLLOWING ORDER: </p>
                            <pre>First Name|Last Name|Email|Company Name|Industry|Company Size|Job Title|Seniority|Department|Phone Number</pre>
                        </div>
                        <div className='mt-3'>
                            <img src={screenshot} alt='guidelines-screenshot' style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                        </div>
                    </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
}

export default ImportPage;