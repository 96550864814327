import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { motion } from 'framer-motion/dist/framer-motion';
import { getUserPayoutsRequests } from 'api/api';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import DotsImage from 'assets/img/leadrpro/dotsdark.png';
import UserPayoutsModal from './UserPayoutsModal';

import './UserPayoutRequests.css';

function UserPayoutRequests() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [index, setIndex] = useState(null);
    const [show, setShow] = useState(false);
    const [action, setAction] = useState('');
    const [modalData, setModalData] = useState(null);
    const [demoPayouts, setDemoPayouts] = useState(null);
    const [additionalEarnings, setAdditionalEarnings] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getUserPayoutsRequests();
                console.log(res, 'this is the data returned');
                setData(() => res.data);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const handleShow = (index, data, action) => {
        console.log(index, 'this is inside the handleShow');
        console.log(data);
        console.log(action);
        setIndex(index);
        setAction(action);
        setModalData(data);
        setShow(!show);
    };

    const handleShowDemos = (index, action, demos, additional) => {
        setIndex(index);
        setAction(action);
        setDemoPayouts(demos);
        setAdditionalEarnings(additional);
        setShow(true);
    };

    const columns = [
        {
            accessor: 'user_payout_request_id',
            Header: 'Payout Id'
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <div>No date.</div>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <div>{date}</div>;
            }
        },
        {
            accessor: 'first_name',
            Header: 'Name',
            Cell: (rowData) => {
                const { first_name, last_name, registered_user_id } =
                    rowData.row.original;

                return (
                    <div>
                        {first_name + ' ' + last_name}
                        <Link to={'/user/' + registered_user_id}>
                            {' '}
                            ({registered_user_id})
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'is_active',
            Header: 'Is Active?',
            Cell: (rowData) => {
                const { is_active } = rowData.row.original;

                if (is_active) {
                    return <span>True</span>;
                } else {
                    return <span>False</span>;
                }
            }
        },
        {
            accessor: 'date_paid',
            Header: 'Date Paid',
            Cell: (rowData) => {
                const { date_paid } = rowData.row.original;
                if (!date_paid) {
                    return <div>No date.</div>;
                }
                const date = moment(date_paid)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <div>{date}</div>;
            }
        },
        {
            accessor: 'payout_url',
            Header: 'Payout URL',
            Cell: (rowData) => {
                const { payout_url } = rowData.row.original;
                if (!payout_url) {
                    return <span>No URL found.</span>;
                } else {
                    return (
                        <a href={payout_url} target="_blank">
                            Dots Link
                        </a>
                    );
                }
            }
        },
        {
            accessor: 'amount',
            Header: 'Amount'
        },
        {
            accessor: 'charity_amount',
            Header: 'Charity amount'
        },
        {
            accessor: 'reference_id',
            Header: 'Reference Id',
            Cell: (rowData) => {
                const { reference_id, demo_payouts, additional_earnings } =
                    rowData.row.original;

                if (
                    demo_payouts?.length > 0 &&
                    additional_earnings?.length > 0
                ) {
                    return (
                        <button
                            className="button-link"
                            onClick={() =>
                                handleShowDemos(
                                    0,
                                    'Reference Id',
                                    demo_payouts,
                                    additional_earnings
                                )
                            }
                        >
                            {reference_id}
                        </button>
                    );
                } else if (demo_payouts?.length > 0) {
                    return (
                        <button
                            className="button-link"
                            onClick={() =>
                                handleShowDemos(
                                    0,
                                    'Reference Id',
                                    demo_payouts,
                                    null
                                )
                            }
                        >
                            {reference_id}
                        </button>
                    );
                } else if (additional_earnings?.length > 0) {
                    return (
                        <button
                            className="button-link"
                            onClick={() =>
                                handleShowDemos(
                                    0,
                                    'Reference Id',
                                    null,
                                    additional_earnings
                                )
                            }
                        >
                            {reference_id}
                        </button>
                    );
                } else {
                    return <span>{reference_id}</span>;
                }
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            headerProps: { className: 'text-center' },
            Cell: (rowData) => {
                const { status } = rowData.row.original;

                if (status === 'Paid') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <div className="badge badge-soft-success">Paid</div>
                        </div>
                    );
                } else if (status === 'Pending') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <div className="badge badge-soft-warning">
                                Pending
                            </div>
                        </div>
                    );
                } else if (status === 'Declined') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <div className="badge badge-soft-danger">
                                Declined
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <div className="badge bg-light">{status}</div>
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'none',
            Header: '',
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original;
                const data = rowData.row.original;
                const index = rowData.row.index;

                if (status === 'Paid') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span>Link sent.</span>
                        </div>
                    );
                }
                return (
                    <div className="m-auto" style={{ width: '85px' }}>
                        <motion.button
                            className="btn btn-sm rounded-pill"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() =>
                                handleShow(index, data, 'Dots Withdraw')
                            }
                            style={{ width: '100%' }}
                        >
                            <img
                                src={DotsImage}
                                alt="dots_image"
                                style={{ width: '100%' }}
                            />
                        </motion.button>
                    </div>
                );
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <Card>
                <Card.Header>
                    <h4>User Payouts</h4>
                </Card.Header>
                <Card.Body>
                    {loading && <h3>Loading...</h3>}
                    {!loading && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            pagination
                            perPage={20}
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                </Card.Body>
            </Card>
            <UserPayoutsModal
                show={show}
                action={action}
                demoPayouts={demoPayouts}
                additionalEarnings={additionalEarnings}
                index={index}
                setShow={setShow}
                setData={setData}
                modalData={modalData}
            />
        </motion.div>
    );
}

export default UserPayoutRequests;
