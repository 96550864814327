import React, { useState, useEffect } from 'react';
import { textmagicTest } from 'api/TestApi';
import { Card } from 'react-bootstrap';

function TextMagicTestComponent() {
    const [response, setResponse] = useState(null);
    const handleClick = async () => {
        try {
            const res = await textmagicTest();
            alert(JSON.stringify(res));
        } catch (err) {
            console.log(err);
            alert(err.message);
        }
    };

    return (
        <Card>
            <Card.Header className="bg-light">
                <h4>Textmagic Test Page</h4>
            </Card.Header>
            <Card.Body>
                <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleClick()}
                >
                    Send test sms
                </button>
                {response && <p className="mt-4">{JSON.stringify(response)}</p>}
            </Card.Body>
        </Card>
    );
}

export default TextMagicTestComponent;
