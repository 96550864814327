/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { getCompaniesQuery, getCompanies } from 'api/api';

const CompaniesSearchBox = ({
    companies,
    setCompanies,
    query,
    setQuery,
    placeholder = 'Search Companies...'
}) => {
    const [value, setValue] = useState("");

    const handleChange = ({ target } ) => {
        console.log(target.value);
        setQuery(target.value)
    };

    return (
        <InputGroup className="position-relative">
        <FormControl
            value={query || ''}
            onChange={handleChange}
            size="sm"
            id="search"
            placeholder={placeholder}
            type="search"
            className="shadow-none"
        />
        <InputGroup.Text className="bg-transparent">
            <FontAwesomeIcon icon="search" className="fs--1 text-600" />
        </InputGroup.Text>
        </InputGroup>
    );
};

export default CompaniesSearchBox;
