import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import moment from 'moment';
import { getEventFeed } from 'api/api';
import Notification from 'components/notification/Notification';

function Activity({}) {
    const controller = new AbortController;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getEventFeed(controller);
                console.log(res, 'this is inside the activity component');
                const newData = res.data.map((item) => {
                    const demoDate = moment(item.demo_date).local().format("MMM Do YY, h:mm a")
                    const newItem = {
                        demo_id: item.demo_id,
                        time: moment(item.date_created).local().format("MMM Do YY, h:mm a"),
                        children: `<span class='fs-2'><strong>${item.event_name}</strong>.</span>
                            <span>Demo Status: <strong>${item.demo_status}</strong>.</span>
                            <span>Buyer Company: <strong>${item.buyer_company_name}</strong>.</span>
                            <span>Seller Company: <strong>${item.seller_company_name}</strong>.</span>
                            <span>Demo Date: <strong>${demoDate}</strong>.</span>`
                    }
                    return newItem;
                })
                setData((prev) => newData);
            } catch (err) {
                alert(JSON.stringify(err.message));
            } finally {
                setLoading(false);
            }
        };

        loadData();
        return () => {    
            controller.abort();        
        } 
    }, []);

    // buyer_company_name: "LeadrPro"
    // date_created: "2022-08-26T21:04:14.264Z"
    // demo_date: "2022-08-28T23:00:00.273Z"
    // demo_id: 2364
    // demo_status: "Declined"
    // event_name: "Applied"
    // seller_company_name : "LeadrPro"

    return (
        <>
        {loading &&
            <h4>loading...</h4>
        }

        {/* Activity Feed */}
        {(!loading && data && data.length > 0) &&
            <Card className='mb-3'>
                <Card.Header className="bg-light">
                    <h5 className="mb-1 mb-md-0">Activity</h5>
                </Card.Header>
                <Card.Body className="p-0">
                        {data.map((activity, index) => {
                            return (
                                <Notification
                                    {...activity}
                                    key={activity.demo_id}
                                    className={classNames(
                                        'border-x-0 border-bottom-0 border-300',
                                        index + 1 === data.length ? 'rounded-top-0' : 'rounded-0'
                                    )}
                                />
                            )
                        })}
                </Card.Body>
            </Card>
        }
        </>
    );
}

export default Activity;