import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Row, Dropdown } from 'react-bootstrap';
import { motion } from 'framer-motion/dist/framer-motion';
import { getDemo, updateDemo, generateDemoInvite, addDemoStrike, demoInReview, demoInReviewComplete } from 'api/api';
import CardDropdown from 'components/common/CardDropdown';
import ProfileBanner from '../../pages/user/ProfileBanner';
import DemoProfilePage from './DemoProfilePage';
import { Modal, Button, Toast, Col, Form } from 'react-bootstrap';

//? admin/demo/:id to get the demo details
const DemoProfileContainer = () => {
    const [demo, setDemo] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [show, setShow] = useState(false);
    const [action, setAction] = useState('');
    const [showStrike, setShowStrike] = useState(false);
    const [strikeReason, setStrikeReason] = useState('');

    const { demoId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const loadDemo = async () => {
            try {
                setLoaded(false);
                getDemo(demoId).then((res) => setDemo(res.data));
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        };

        loadDemo();
    }, [demoId]);

    const handleTimeShow = () => {
        setShow(true);
        setAction('UpdateTime');
    };

    const handleCompleted = () => {
        setShow(true);
        setAction('Completed');
    };

    const handlePending = () => {
        setShow(true);
        setAction('Pending');
    };

    async function handleDecline(){
        //setShow(true);
        //setAction('Decline');
        let res = await updateDemo(demoId, {demo_status_id: 3});

        if (res.success){
            alert("Demo Declined");
            window.location = window.location;
        }
    };

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    async function handleInviteLink(){
        let res = await generateDemoInvite(demoId);

        if (res.success){
            //alert(res.data);
            window.location = window.location;
        }

        else{
            //alert('nope');
        }
    }

    async function handleBuyerStrike(){
        setShowStrike(true);
    }

    async function handleInReview(){
        let res = await demoInReview(demoId);

        if (res.success){
            setDemo((prev) => ({
                ...prev, in_review: true
            }))
            alert(`Demo set to 'In Review'`);
        }
    }

    async function handleInReviewComplete(){
        let res = await demoInReviewComplete(demoId);

        if (res.success){
            setDemo((prev) => ({
                ...prev, in_review: false
            }))
            alert(`Demo Out of Review`);
        }
    }

    async function handleBuyerStrikeSubmit(){
        let res = await addDemoStrike(demoId, strikeReason);

        if (res.success){
            setShowStrike(false);
            alert("Strike has been added!");
        }

        else{
            alert("Something went wrong. Could not add strike");
        }
    }

    async function handleClose(){
        setShowStrike(false);
    }

    return (
        <motion.div
            key="settings"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>
            <ProfileBanner>
                <ProfileBanner.Body>
                    <div style={{position: "absolute", top: "10px", right: "10px", fontSize: "small", color: "#ddd"}}>C:23</div>
                    <div className="d-flex justify-content-between">
                        {loaded && demo && (
                            <h3>
                                {demo.seller_feedback_average && (
                                <span className='badge badge-soft-success' style={{fontSize: "12px", padding: "10px", verticalAlign: "middle", marginRight: "10px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{height: "14px", fill: "var(--falcon-badge-soft-success-color)"}}><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
                                {' '}
                        <span style={{verticalAlign: "middle"}}>{demo.seller_feedback_average}</span></span>
                                )}

                                Demo #({demoId}) {demo.seller_company_name}{' '}
                                <Link
                                    className="ms-1"
                                    to={`/company/${demo.seller_company_id}`}
                                >
                                    ({demo.seller_company_id})
                                </Link>{' '}
                                {'<'}
                                {'>'} {demo.buyer_company_name}{' '}
                                <Link
                                    className="ms-1"
                                    to={`/company/${demo.buyer_company_id}`}
                                >
                                    ({demo.buyer_company_id})
                                </Link>
                            </h3>
                        )}
                        {loaded && demo && (
                            <div className="align-self-center py-2">
                                <CardDropdown icon="bars">
                                    <div style={{padding: '15px 10px'}}>
                                        <Dropdown.Item
                                            eventkey="1"
                                            onClick={() => handleCompleted()}
                                        >
                                            ✅ Mark as Completed
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            eventkey="1"
                                            onClick={() => handleTimeShow()}
                                        >
                                            📅 Change Demo Date
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            eventkey="1"
                                            onClick={() => handlePending()}
                                        >
                                            🕛 Set to Pending
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            eventkey="1"
                                            onClick={() => handleDecline()}
                                        >
                                            ❌ Seller Decline
                                        </Dropdown.Item>

                                        {demo.demo_invite_token == null && (
                                        <Dropdown.Item
                                            eventkey="1"
                                            onClick={() => handleInviteLink()}
                                        >
                                            🔗 Generate Invite Link
                                        </Dropdown.Item>
                                        )}

                                        <Dropdown.Item
                                            eventkey="1"
                                            onClick={() => handleBuyerStrike()}
                                        >
                                            ⚾ Add Buyer Strike
                                        </Dropdown.Item>

                                        {demo.in_review === true && (
                                        <Dropdown.Item
                                            eventkey="1"
                                            onClick={() => handleInReviewComplete()}
                                        >
                                            ✔️ Passes Review
                                        </Dropdown.Item>
                                        )}

                                        {demo.in_review === false && (
                                            <Dropdown.Item
                                            eventkey="1"
                                            onClick={() => handleInReview()}
                                        >
                                            🔍 Set to In Review
                                        </Dropdown.Item>
                                        )}

                                        {/* <Dropdown.Divider as="div" /> */}

                                        {/* <Dropdown.Item
                                        eventkey='2'
                                        className='text-danger disabled'
                                    >
                                        Delete (Coming soon)
                                    </Dropdown.Item> */}
                                    </div>
                                </CardDropdown>
                            </div>
                        )}
                    </div>
                </ProfileBanner.Body>
            </ProfileBanner>
            <>
                {loaded && demo && (
                    <DemoProfilePage
                        demo={demo}
                        setDemo={setDemo}
                        show={show}
                        setShow={setShow}
                        action={action}
                        setAction={setAction}
                    />
                )}
                {!loaded && <div>Loading...</div>}
            </>

            {showStrike && (
                <Modal
                show={showStrike}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Demo Strike ⚾</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to add a strike to this demo?</p>

                    <div>
                        <textarea className='form-control' placeholder='Reason...' value={strikeReason} onChange={(e) => setStrikeReason(e.target.value)}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={()=> handleBuyerStrikeSubmit()}>
                        Submit
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            )}
        </motion.div>
    );
};

export default DemoProfileContainer;
