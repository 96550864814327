import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import Avatar from 'components/common/Avatar';
import {
    getCharityCampaignDetails,
    getCharityCampaignPayouts,
    getCharityCampaignAdditionalEarnings,
    getCategory,
    updateCategory,
    updateCategoryNew,
    getAffiliateById,
    updateAffiliate,
    getSellerCategories,
    getAffiliateCategories,
    addAffiliateCategory,
    removeAffiliateCategory
} from 'api/api';
import ProfileBanner from 'components/pages/user/ProfileBanner';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Col, Form, Row, Toast, ListGroup } from 'react-bootstrap';

function AffiliateDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [charityPayouts, setCharityPayouts] = useState(null);
    const [additionalEarnings, setAdditionalEarnings] = useState(null);
    const [category, setCategory] = useState([]);
    const [shortDescription, setShortDescription] = useState('');
    const [logo, setLogo] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [affiliateUrl, setAffiliateUrl] = useState('');
    const [ctaText, setCtaText] = useState('');
    const [slug, setSlug] = useState('');
    const [categories, setCategories] = useState([]);
    const [affiliateCategories, setAffiliateCategories] = useState([]);
    const [affiliateCategoriesLoaded, setAffiliateCategoriesLoaded] = useState(false);
    const [loading, setLoading] = useState(null);

    const loadData = async () => {
        try {
           let res = await getAffiliateById(id);

           if (res.success){
            let _data = res.data;
            console.log('data', res.data);
            setData(res.data);
            setName(_data.name);
            setDescription(_data.description);
            setAffiliateUrl(_data.affiliate_url);
            setLogo(_data.logo);
            setCtaText(_data.cta_text);
           }

           else{
            alert('nope');
           }
        } catch (err) {
            alert(err.message);
            console.warn(err.message, 'this is the error');
        } finally {
            setLoading(false);
        }
    };

    async function loadCategories(){
        let res = await getSellerCategories();

        if (res.success){
            setCategories(res.data);
        }
    }

    async function loadAffiliateCategories(){
        let res = await getAffiliateCategories(id);

        if (res.success){
            setAffiliateCategories(res.data);
            setAffiliateCategoriesLoaded(true);
        }

        else{
            alert('nope');
        }
    }

    useEffect(() => {
        loadData();
        loadCategories();
        loadAffiliateCategories();
    }, []);

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        onExit: {
            opacity: 0,
            transition: { duration: 1 }
        }
    };

    async function handleSave(){
        let res = await updateAffiliate(id, {name, description, cta_text: ctaText, affiliate_url: affiliateUrl});

       if (res.success){
        loadData();
        alert("Success");
       }
    }

    async function handleDelete(){
        let res = await updateAffiliate(id, {is_active: false});

        if (res.success){
            navigate(-1);
        }
    }

    async function addCategory(category_id){
       // alert('adding ' + category_id);
        let res = await addAffiliateCategory(id, category_id);

        if (res.success){
            loadAffiliateCategories(id);
        }
    }

    async function removeCategory(category_id){
       // alert('removing ' + category_id);

       let res = await removeAffiliateCategory(id, category_id);

       if (res.success){
        loadAffiliateCategories(id);
       }
    }

    async function handleSaveCategory(){
        
    }

    return (
        <motion.div
            key="settings"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>
            <ProfileBanner className="mb-3">
                <ProfileBanner.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && data && (
                        <>
                            <div className="d-grid gap-4">
                                <div className="d-grid">
                                    <h2 className="m-0">
                                        {data.name}
                                    </h2>
                                    <span>
                                        <u>Created:</u>{' '}
                                        {moment(data.date_created)
                                            .local()
                                            .format('MMM Do YY, h:mm a')}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </ProfileBanner.Body>
            </ProfileBanner>

            <Card className="mb-3">
                <FalconCardHeader title={'Affiliate'} />
                <Card.Body className="bg-light">

                <Form.Group as={Col} controlId='logo'>
                    <Avatar src={
                            'https://ik.imagekit.io/ivgrhmd4k/affiliates/' +
                            'tr:di-@@affiliates@@default-buyer.png/' +
                            logo
                        }
                        rounded="none"
                        size="4xl"
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="name">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Name
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder="Name"
                        value={name}
                        name="slug"
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="description">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Description
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="Description"
                        value={description}
                        name="note"
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="cta_text">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        CTA Text
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder="CTA Text"
                        value={ctaText}
                        name="cta_text"
                        onChange={(e) => setCtaText(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="name">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Affiliate URL
                    </Form.Label>
                    <Form.Control
                        type='text'
                        rows={6}
                        placeholder="Description"
                        value={affiliateUrl}
                        name="slug"
                        onChange={(e) => setAffiliateUrl(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="name">
                <div className="text-start" style={{marginTop: '20px'}}>
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => handleSave()}
                            >
                                Save
                            </motion.button>
                            {' '}
                            <button className='btn btn-danger' onClick={() => handleDelete()}>Delete</button>
                        </div>
                </Form.Group>

                <div style={{marginTop: '30px'}}>
                    <h2>Categories</h2>

                    <div>
                        <input type="text" className='form-control' placeholder='Enter category' value={category} onChange={(e) => setCategory(e.target.value)} />
                        <br/>
                        <button className='btn btn-primary' onClick={() => handleSaveCategory()}>Save</button>
                    </div>

                    <div>
                        {affiliateCategoriesLoaded === false && (
                            <div>Loading...</div>
                        )}
                    </div>
                    {affiliateCategoriesLoaded === true && categories.map(item => (
                        <>
                        {affiliateCategories.filter(x => x.category_id === item.category_id).length > 0 && (
                            <>
                                <span onClick={() => removeCategory(item.category_id)} style={{background: 'dodgerblue', color: 'white', padding: '3px 10px', margin: '10px', border: 'solid 1px #333', cursor: 'pointer', display: 'inline-block'}}>
                                        {item.name}
                                    </span>
                            </>
                        )}

                        {affiliateCategories.filter(x => x.category_id === item.category_id).length == 0 && (
                            <>
                            <span onClick={() => addCategory(item.category_id)} style={{background: 'transparent', color: 'white', padding: '3px 10px', margin: '10px', border: 'solid 1px #333', cursor: 'pointer', display: 'inline-block'}}>
                                    {item.name}
                                </span>
                            </>
                        )}
                        </>
                    ))}
                </div>
            </Card.Body>
            </Card>
        </motion.div>
    );
}

export default AffiliateDetails;