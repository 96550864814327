import React, { useState, useEffect } from 'react';
import { getCompanySizes } from "api/api";
import Select from 'react-select';

function CompanySizeDropdown({ company, setCompany }) {
    const [sizes, setSizes] = useState(null);
    let organizerOptions;

    useEffect(() => {
        async function loadCompanySizes(){
            try {
                getCompanySizes().then(res => setSizes(res.data));
            } catch (err){
                console.log(JSON.stringify(err));
            } 
        }

        loadCompanySizes();
    }, []);

    const handleChange = target => {
        setCompany({
            ...company,
            ['company_size']: target.label,
            ['company_size_id']: target.value,
        })
    }

    if(sizes){
        organizerOptions = sizes.map((object) => {
            const data = {value : object.company_size_id, label: object.value}
            return data;
        })
        organizerOptions.sort((a, b) => (a.value > b.value) ? 1 : -1);
    }


    return (
        <Select
            options={organizerOptions}
            onChange={value => handleChange(value)}
            placeholder={company.company_size ? company.company_size : "No company size found."}
            value={company.company_size}
            classNamePrefix="react-select"
        />
    );
}

export default CompanySizeDropdown;