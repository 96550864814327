import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDoubleLeft,
    faPauseCircle,
    faPlayCircle,
    faCloudUploadAlt
} from '@fortawesome/free-solid-svg-icons';
import { Row, ToastContainer, Toast, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';
import { getCompany, createSellerDemos, updateCompany, getCompanyAvailableBuyers } from 'api/api';
import Avatar from 'components/common/Avatar';
import ProfileBanner from 'components/pages/user/ProfileBanner';
import CompanyProfile from './CompanyProfile';
import CompanySettingsModal from './CompanySettingsModal';

function CompanySettings() {
    const { companyId } = useParams();
    const [company, setCompany] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [action, setAction] = useState('');
    const [show, setShow] = useState(false);
    const [availableBuyers, setAvailableBuyers] = useState(null);
    const [progressLoading, setProgressLoading] = useState(false);
    const [demosPerMonth, setDemosPerMonth] = useState(0);
    const [requestsPerDay, setRequestsPerDay] = useState(0);
    const inputRefTest = useRef(null);
    const inputRefThumb = useRef(null);
    const ikUploadRefTest = useRef(null);
    const ikUploadRefThumb = useRef(null);
    const navigate = useNavigate();

    const refreshCompany = async () => {
        try {
            const res = await getCompany(companyId);
            setCompany(res.data);
            setRequestsPerDay(res.data.requests_per_day);
        } catch (err) {
            console.log(JSON.stringify(err));
        } finally {
            setLoaded(true);
        }
    };

    useEffect(() => {
        const loadUser = async () => {
            try {
                setLoaded(false);
                const res = await getCompany(companyId);
                setCompany(res.data);
                setAvailableBuyers(res.data.available_buyers);
                setRequestsPerDay(res.data.requests_per_day);
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        };

        loadUser();
    }, [companyId]);

    const onUploadProgress = (progress) => {
        console.log('Progress', progress);
    };
    const onUploadStart = (evt) => {
        console.log('Start', evt);
    };

    const onError = async (e) => {
        console.log(JSON.stringify(e), 'this is the imagekit error!!!!!!!');
        alert(
            'Error uploading to imagekit. Contact DEV if the issue persists.'
        );
    };

    const onSuccess = async (e) => {
        const res = await updateCompany(companyId, { logo: e.name });
        if (res.success) {
            alert('Image successfully uploaded!');
            navigate(0);
        } else {
            console.log(
                res,
                'this was the error when updating the company logo!!!!!!'
            );
            alert(
                'Error uploading to imagekit. Contact DEV if the issue persists.'
            );
        }
    };

    const onSuccessThumbnail = async (e) => {
        const res = await updateCompany(companyId, { thumbnail_image: e.name });
        if (res.success) {
            alert('Image successfully uploaded!');
            navigate(0);
        } else {
            console.log(
                res,
                'this was the error when updating the company logo!!!!!!'
            );
            alert(
                'Error uploading to imagekit. Contact DEV if the issue persists.'
            );
        }
    };

    const handleShow = async (action) => {
        async function loadBuyers(){
            //alert('loading buyers');
            let res = await getCompanyAvailableBuyers(companyId);

            if (res.success){
                setAvailableBuyers(res.data);
            }
        }

        loadBuyers();
        setAction(action);
        setShow(true);
    };

    const handleCreateSellerDemos = async () => {
        try {
            const res = await createSellerDemos(companyId);
            if (res.success) {
                alert('Demos Created');
            } else {
                alert('Creating Failed');
            }
        } catch (err) {
            alert(`${err.message}`);
        }
    };

    const handleCompanyPause = async () => {
        console.log('paused');
        try {
            const res = await updateCompany(companyId, { is_paused: true });
            if (res.success) {
                alert('Company Paused');
                setCompany({
                    ...company,
                    ['is_paused']: true
                });
            } else {
                alert('Paused failed');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    };

    const handleCompanyUnpause = async () => {
        console.log('paused');
        try {
            const res = await updateCompany(companyId, { is_paused: false });
            if (res.success) {
                alert('Company Unpaused');
                setCompany({
                    ...company,
                    ['is_paused']: false
                });
            } else {
                alert('Unpause failed');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    };

    async function handleWaitlist(e){
        try {
            const res = await updateCompany(companyId, { on_waitlist: e.target.checked });
            if (res.success) {
                //alert('Company On Waitlist');
                setCompany({
                    ...company,
                    ['on_waitlist']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });
            } else {
                //alert('Paused failed');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleInactive(e){
        try {
            let message = `Updated company to ${e.target.checked ? 'inactive': 'active'}`;
            const res = await updateCompany(companyId, { is_inactive: e.target.checked }, message);
            if (res.success) {
                setCompany({
                    ...company,
                    ['is_inactive']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
                //alert('Paused failed');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleIsSeller(e){
        try {
            const res = await updateCompany(companyId, { is_seller: e.target.checked });
            if (res.success) {
                setCompany({
                    ...company,
                    ['is_seller']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleIsPaused(e){
        try {
            const res = await updateCompany(companyId, { is_paused: e.target.checked });
            if (res.success) {
                setCompany({
                    ...company,
                    ['is_paused']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleHasPaymentMethod(e){
        try {
            let message = `${e.target.checked ? 'Skip payment check': 'Undo skip payment check'}`;
            const res = await updateCompany(companyId, { has_payment_method: e.target.checked }, message);
            if (res.success) {
                setCompany({
                    ...company,
                    ['has_payment_method']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleEnableBuyerSearch(e){
        try {
            let message = `${e.target.checked ? 'Enable buyer search': 'Disable buyer search'}`;
            const res = await updateCompany(companyId, { enable_search: e.target.checked }, message);
            if (res.success) {
                setCompany({
                    ...company,
                    ['enable_search']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleIsFeatured(e){
        try {
            let message = `${e.target.checked ? 'Enable is featured': 'Disable is featured'}`;
            const res = await updateCompany(companyId, { is_featured: e.target.checked }, message);
            if (res.success) {
                setCompany({
                    ...company,
                    ['is_featured']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handlePayLater(e){
        try {
            let message = `${e.target.checked ? 'Enable pay later': 'Disable pay later'}`;
            const res = await updateCompany(companyId, { payment_type: e.target.checked ? 'pay_later': 'pay_now' }, message);
            if (res.success) {
                setCompany({
                    ...company,
                    ['payment_type']: !e.target.checked ? 'pay_later': 'pay_now'
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleSelfSignup(e){
        try {
            let message = `${e.target.checked ? 'Set to self-signup': 'Unset self-signup'}`;
            const res = await updateCompany(companyId, { self_signup: e.target.checked}, message);
            if (res.success) {
                setCompany({
                    ...company,
                    ['self_signup']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleUsOnly(e){
        try {
            let message = `${e.target.checked ? 'Enable US only': 'Disable US only'}`;
            const res = await updateCompany(companyId, { us_only: e.target.checked}, message);
            if (res.success) {
                setCompany({
                    ...company,
                    ['us_only']: !e.target.checked
                });

                toast.success(`Company profile successfully updated`, {
                    theme: 'colored'
                  });

            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        onExit: {
            opacity: 0,
            transition: { duration: 1 }
        }
    };

    async function handleSaveDemosPerMonth(){
       // alert(demosPerMonth);
       try {
        const res = await updateCompany(companyId, { demos_per_month: demosPerMonth });
        if (res.success) {
            //alert('Company On Waitlist');
            setCompany({
                ...company,
                ['demos_per_month']: demosPerMonth
            });

            toast.success(`Company profile successfully updated`, {
                theme: 'colored'
              });
        } else {
            //alert('Paused failed');
        }
    } catch (err) {
        console.log(JSON.stringify(err.message));
    }
    }

    async function handleSaveRequestsPerDay(){
        // alert(demosPerMonth);
        try {
         const res = await updateCompany(companyId, { requests_per_day: requestsPerDay });
         if (res.success) {
             //alert('Company On Waitlist');
             setCompany({
                 ...company,
                 ['demos_per_month']: requestsPerDay
             });
 
             toast.success(`Company profile successfully updated`, {
                 theme: 'colored'
               });
         } else {
             //alert('Paused failed');
         }
     } catch (err) {
         console.log(JSON.stringify(err.message));
     }
     }

    return (
        <motion.div
            key="settings"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>
            <ProfileBanner>
                <ProfileBanner.Body>
                {loaded && company && (
                <h2 className="m-0">
                    #{companyId} - {company.name} <span style={{marginLeft: "10px"}}>
                        <br/>
                        <a href={`https://www.leadrpro.com/company/${companyId}`} target='_blank' style={{fontSize: 'small'}}>Public Profile</a></span>
                </h2>
                )}
                    {loaded && company && (
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center flex-column me-4">
                                <div className='form-label'>Logo</div>
                                    <Avatar
                                        src={
                                            'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                            'tr:di-@@companylogos@@default-buyer.png/' +
                                            company.logo
                                        }
                                        rounded="none"
                                        size="4xl"
                                    />
                                    <div className="d-flex flex-column">
                                        <IKContext
                                            publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs="
                                            urlEndpoint="https://ik.imagekit.io/ivgrhmd4k"
                                            authenticationEndpoint={
                                                process.env
                                                    .REACT_APP_API_BASE_URL +
                                                '/imagekit/auth'
                                            }
                                        >
                                            <IKUpload
                                                fileName="logo.jpg"
                                                tags={[
                                                    'sample-tag1',
                                                    'sample-tag2'
                                                ]}
                                                customCoordinates={
                                                    '10,10,10,10'
                                                }
                                                isPrivateFile={false}
                                                useUniqueFileName={true}
                                                responseFields={['tags']}
                                                folder={'/companylogos'}
                                                onError={onError}
                                                onSuccess={onSuccess}
                                                onUploadProgress={
                                                    onUploadProgress
                                                }
                                                onUploadStart={onUploadStart}
                                                style={{ display: 'none' }} // hide the default input and use the custom upload button
                                                inputRef={inputRefTest}
                                                ref={ikUploadRefTest}
                                            />
                                            {inputRefTest && (
                                                <button
                                                    onClick={() =>
                                                        inputRefTest.current.click()
                                                    }
                                                    className="btn btn-success rounded-pill btn-sm mt-3"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCloudUploadAlt}
                                                        className="me-1"
                                                    />
                                                    Upload
                                                </button>
                                            )}
                                            {/* {ikUploadRefTest && (
                                                <button
                                                    onClick={() =>
                                                        ikUploadRefTest.current.abort()
                                                    }
                                                    className="btn btn-falcon-secondary rounded-pill btn-sm"
                                                >
                                                    Cancel
                                                </button>
                                            )} */}
                                        </IKContext>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center justify-content-center flex-column me-4">
                                    <div className='form-label'>Thumbnail</div>
                                    <Avatar
                                        src={
                                            'https://ik.imagekit.io/ivgrhmd4k/companythumbnails/' +
                                            'tr:di-@@companylogos@@default-buyer.png/' +
                                            company.thumbnail_image
                                        }
                                        rounded="none"
                                        size="4xl"
                                    />
                                    <div className="d-flex flex-column">
                                        <IKContext
                                            publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs="
                                            urlEndpoint="https://ik.imagekit.io/ivgrhmd4k"
                                            authenticationEndpoint={
                                                process.env
                                                    .REACT_APP_API_BASE_URL +
                                                '/imagekit/auth'
                                            }
                                        >
                                            <IKUpload
                                                fileName="logo.jpg"
                                                tags={[
                                                    'sample-tag1',
                                                    'sample-tag2'
                                                ]}
                                                customCoordinates={
                                                    '10,10,10,10'
                                                }
                                                isPrivateFile={false}
                                                useUniqueFileName={true}
                                                responseFields={['tags']}
                                                folder={'/companythumbnails'}
                                                onError={onError}
                                                onSuccess={onSuccessThumbnail}
                                                onUploadProgress={
                                                    onUploadProgress
                                                }
                                                onUploadStart={onUploadStart}
                                                style={{ display: 'none' }} // hide the default input and use the custom upload button
                                                inputRef={inputRefThumb}
                                                ref={ikUploadRefThumb}
                                            />
                                            {inputRefThumb && (
                                                <button
                                                    onClick={() =>
                                                        inputRefThumb.current.click()
                                                    }
                                                    className="btn btn-success rounded-pill btn-sm mt-3"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCloudUploadAlt}
                                                        className="me-1"
                                                    />
                                                    Upload
                                                </button>
                                            )}
                                            {/* {ikUploadRefTest && (
                                                <button
                                                    onClick={() =>
                                                        ikUploadRefThumb.current.abort()
                                                    }
                                                    className="btn btn-falcon-secondary rounded-pill btn-sm"
                                                >
                                                    Cancel
                                                </button>
                                            )} */}
                                        </IKContext>
                                    </div>
                                </div>

                                <div className="d-grid">
                                    
                                    <span className="me-2" style={{position: "absolute", top: "10px", right: "10px", fontSize: "small"}}>
                                        Date Created:{' '}
                                        {moment(company.date_created)
                                            .local()
                                            .format('MMM Do YY, h:mm a')}
                                    </span>

                                    <div style={{display: "flex", flexWrap: "wrap", width: "300px", marginTop: "20px"}}>
                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbWaitlist'
                                            title='Demos can not be booked with this company'
                                            label='On Waitlist'
                                            checked={company.on_waitlist}
                                            onChange={(e) => handleWaitlist(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbInactive'
                                            label='Inactive'
                                            title='Removed from reporting'
                                            checked={company.is_inactive}
                                            onChange={(e) => handleInactive(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbIsSeller'
                                            label='Live On Search'
                                            checked={company.is_seller}
                                            onChange={(e) => handleIsSeller(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbIsPaused'
                                            label='Turn Off Auto-Requests'
                                            checked={company.is_paused}
                                            onChange={(e) => handleIsPaused(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbHasPaymentMethod'
                                            label='Skip Payment Check'
                                            checked={company.has_payment_method}
                                            onChange={(e) => handleHasPaymentMethod(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbEnableBuyerSearch'
                                            label='Enable Buyer Search'
                                            checked={company.enable_search}
                                            onChange={(e) => handleEnableBuyerSearch(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbIsFeatured'
                                            label='Featured Seller'
                                            checked={company.is_featured}
                                            onChange={(e) => handleIsFeatured(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbPayLater'
                                            label='Pay Later'
                                            checked={company.payment_type === 'pay_later'? true: false}
                                            onChange={(e) => handlePayLater(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbSelfSignup'
                                            label='Self Signup'
                                            checked={company.self_signup === true ? true: false}
                                            onChange={(e) => handleSelfSignup(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <Form.Check
                                            type="checkbox"
                                            id='cbUsOnly'
                                            label='U.S. Only'
                                            checked={company.us_only === true ? true: false}
                                            onChange={(e) => handleUsOnly(e)}
                                        />
                                    </span>

                                    <span className="me-2">
                                        <input className='form-control' placeholder='Requests per day...' style={{display:"inline-block"}} value={requestsPerDay} onChange={(e) => setRequestsPerDay(e.target.value)} />
                                        <a href='javascript:void(0)' onClick={() => handleSaveRequestsPerDay()}>Save</a>
                                        <label style={{marginLeft: "10px"}}>Requests Per Day</label>
                                    </span>
                                    </div>
                                </div>
                            </div>

                            <div className="" style={{padding: '0px 20px', marginTop: '-150px'}}>
                                <p><strong>Company Info</strong></p>

                                <div>
                                    <strong>Demos completed: </strong> {company.demos_completed}
                                </div>

                                <div>
                                    <strong>Demos pending: </strong> {company.demos_pending}
                                </div>

                                <div style={{marginTop: '20px'}}>
                                <motion.button
                                    className="btn btn-success me-2"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => handleCreateSellerDemos()}
                                >
                                    Invite ICPs
                                </motion.button>
                                <motion.button
                                    className="btn btn-primary"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() =>
                                        handleShow('Available Buyers')
                                    }
                                >
                                    Available Buyers
                                </motion.button>
                                </div>
                            </div>
                            <div style={{fontSize:"small", color:"#ccc", position: "absolute", bottom: "5px", right: "10px"}}>C:456</div>
                        </div>
                    )}
                </ProfileBanner.Body>
            </ProfileBanner>
            <Row className="g-1">
                {loaded && company && (
                    <CompanyProfile company={company} setCompany={setCompany} refresh={refreshCompany} />
                )}
                {!loaded && <div>Loading...</div>}
            </Row>
            {progressLoading && (
                <div
                    aria-live="polite"
                    aria-atomic="true"
                    className="position-relative"
                    style={{ minHeight: '240px' }}
                >
                    <ToastContainer className="p-3" position={'top-center'}>
                        <Toast>
                            <Toast.Header closeButton={false}>
                                <img className="rounded me-2" alt="" />
                                <strong className="me-auto">Uploading.</strong>
                            </Toast.Header>
                            <Toast.Body>The image is uploading.</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </div>
            )}

            {/* Modal */}
            <CompanySettingsModal
                show={show}
                setShow={setShow}
                company={company}
                setCompany={setCompany}
                action={action}
                availableBuyers={availableBuyers}
                setAvailableBuyers={setAvailableBuyers}
            />
        </motion.div>
    );
}

export default CompanySettings;
