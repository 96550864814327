import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Dropdown, Card } from 'react-bootstrap';
import { motion } from 'framer-motion/dist/framer-motion';
import { getDemosToday } from 'api/api';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSms } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/common/IconButton';
import { sendReminderSms, sendDemoReminders } from 'api/api';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import es from 'date-fns/locale/es';
import CardDropdown from 'components/common/CardDropdown';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import DemosModal from './DemosModal';

registerLocale('es', es);

function TodaysDemosDashboard() {
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState(null);
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);
    const [index, setIndex] = useState(null);
    const [action, setAction] = useState('');
    const [date, setDate] = useState(null);

    useEffect(() => {
        const loadUpcomingDemos = async () => {
            try {
                setLoaded(false);
                if (date) {
                    getDemosToday(date).then((res) => setDemos(res.data));
                } else {
                    getDemosToday().then((res) => setDemos(res.data));
                }
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        };

        loadUpcomingDemos();
    }, [date]);

    const handleShow = (index, demo, action) => {
        setIndex(index);
        setSelected(demo);
        setAction(action);
        setShow(true);
    };

    async function handleNotify(demo_id){
        let res = await sendDemoReminders(demo_id);

        if (res.success){
            alert("Reminder has been sent!");
        }

        else{
            alert("Error: Something went wrong");
        }
        //alert(demo_id);
    }

    const columns = [
        {
            accessor: 'notify',
            Header: 'Notify',
            disableSortBy: true,
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                return (
                    <div>
                        <Button
                            variant="light"
                            size="sm"
                            className="border-300 me-1 text-600"
                            as={'a'}
                            onClick={() => handleNotify(demo.demo_id)}
                        >
                            <FontAwesomeIcon icon="bell" />
                        </Button>
                    </div>
                );
            }
        },
        {
            accessor: 'demo_id',
            Header: 'Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },

        {
            accessor: 'interest_level',
            Header: 'Interest',
            disableSortBy: true,
            Cell: (rowData) => {
                const { interest_level } = rowData.row.original;

                return (
                    <>
                        {interest_level == 'Very interested' && (
                            <span style={{background: "#18b318", color:"white", fontSize: "x-small", padding: "0px 10px", borderRadius:"30px"}}>High</span>
                        )}

                        {interest_level == 'Somewhat interested' && (
                            <span style={{background: "#edd039", color:"black", fontSize: "x-small", padding: "0px 10px", borderRadius:"30px"}}>Medium</span>
                        )}

                        {interest_level == 'Just researching' && (
                            <span style={{background: "red", color:"white", fontSize: "x-small", padding: "0px 10px", borderRadius:"30px"}}>Low</span>
                        )}
                    </>
                )
            }
        },
        {
            Header: 'Meeting Link',
            Cell: (rowData) => {
                const { reference_id } = rowData.row.original;
                return (
                    <a
                        href={`https://meetings.leadrpro.com/demo?refid=${reference_id}`}
                        target="_blank"
                    >
                        Link
                    </a>
                );
            }
        },
        {
            Header: 'Confirm Link',
            Cell: (rowData) => {
                const { confirmation_token } = rowData.row.original;
                return (
                    <a
                        href={`https://www.leadrpro.com/confirmation?token=${confirmation_token}`}
                        target="_blank"
                    >
                        Link
                    </a>
                );
            }
        },
        {
            accessor: 'seller_company_name',
            Header: 'Seller Company',
            Cell: (rowData) => {
                const { seller_company_id, seller_company_name, seller_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    seller_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {seller_company_name + ' '}
                        <Link
                            className="ms-1"
                            to={'/company/' + seller_company_id}
                        >
                            ({seller_company_id})
                        </Link>
                    </div>
                );
            }
        },

        {
            accessor: 'buyer_company_name',
            Header: 'Buyer Company',
            Cell: (rowData) => {
                const { buyer_company_id, buyer_company_name, buyer_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    buyer_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                    <span>
                        {buyer_company_name + ' '}
                        <Link to={'/company/' + buyer_company_id}>
                            ({buyer_company_id})
                        </Link>
                    </span>
                    </div>
                );
            }
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'scheduled_date_time',
            Header: 'Scheduled Date & Time',
            Cell: (rowData) => {
                const { scheduled_date_time, reschedule_date_time } =
                    rowData.row.original;
                if (!scheduled_date_time && !reschedule_date_time) {
                    return <span>Not scheduled.</span>;
                }
                if (reschedule_date_time) {
                    const date = moment(reschedule_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                } else {
                    const date = moment(scheduled_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                }
            }
        },

        {
            accessor: 'demo_status',
            Header: 'Demo Status',
            Cell: (rowData) => {
                const { demo_status } = rowData.row.original;

                if (demo_status === 'Completed') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-success">
                                Completed
                            </span>
                        </div>
                    );
                } else if (demo_status === 'Pending') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-warning">
                                Pending
                            </span>
                        </div>
                    );
                } else if (demo_status === 'Declined') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-danger">
                                Declined
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge bg-light">
                                {demo_status}
                            </span>
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'is_confirmed',
            Header: 'Confirmed',
            Cell: (rowData) => {
                const { is_confirmed } = rowData.row.original;

                if (is_confirmed) {
                    return (
                        <span className="badge badge-soft-success">True</span>
                    );
                } else {
                    return (
                        <span className="badge badge-soft-danger">False</span>
                    );
                }
            }
        },
        {
            accessor: 'seller_has_joined',
            Header: 'Seller Joined?',
            Cell: (rowData) => {
                const { seller_has_joined } = rowData.row.original;

                if (seller_has_joined) {
                    return (
                        <span className="badge badge-soft-success">True</span>
                    );
                } else {
                    return (
                        <span className="badge badge-soft-danger">False</span>
                    );
                }
            }
        },
        {
            accessor: 'buyer_has_joined',
            Header: 'Buyer Joined?',
            Cell: (rowData) => {
                const { buyer_has_joined } = rowData.row.original;

                if (buyer_has_joined) {
                    return (
                        <span className="badge badge-soft-success">True</span>
                    );
                } else {
                    return (
                        <span className="badge badge-soft-danger">False</span>
                    );
                }
            }
        },
        {
            Header: 'Seller Status',
            Cell: (rowData) => {
                const { seller_status } = rowData.row.original;
                let status = [];
                if (seller_status === 'Approved') {
                    status.push(
                        <span className="badge badge-soft-success">
                            Approved
                        </span>
                    );
                } else if (seller_status === 'Pending') {
                    status.push(
                        <span className="badge badge-soft-warning">
                            Pending
                        </span>
                    );
                } else if (seller_status === 'Declined') {
                    status.push(
                        <span className="badge badge-soft-danger">
                            Declined
                        </span>
                    );
                } else if (seller_status === 'Rescheduled') {
                    status.push(
                        <span className="badge badge-soft-primary">
                            Rescheduled
                        </span>
                    );
                }
                return <span>{status}</span>;
            }
        },
        {
            Header: 'Buyer Status',
            Cell: (rowData) => {
                const { buyer_status } = rowData.row.original;
                let status = [];
                if (buyer_status === 'Approved') {
                    status.push(
                        <span className="badge badge-soft-success">
                            Approved
                        </span>
                    );
                } else if (buyer_status === 'Pending') {
                    status.push(
                        <span className="badge badge-soft-warning">
                            Pending
                        </span>
                    );
                } else if (buyer_status === 'Declined') {
                    status.push(
                        <span className="badge badge-soft-danger">
                            Declined
                        </span>
                    );
                } else if (buyer_status === 'Rescheduled') {
                    status.push(
                        <span className="badge badge-soft-primary">
                            Rescheduled
                        </span>
                    );
                }
                return <span>{status}</span>;
            }
        },
        {
            accessor: 'seller_email',
            Header: 'Seller Email'
        },
        
        {
            accessor: 'seller_timezone',
            Header: 'Seller Timezone'
        },
        {
            accessor: 'buyer_first_name',
            Header: 'Buyer Name',
            Cell: (rowData) => {
                const { buyer_first_name, buyer_last_name, buyer_id } =
                    rowData.row.original;
                return (
                    <span>
                        {buyer_first_name + ' ' + buyer_last_name}
                        <Link to={'/user/' + buyer_id}> ({buyer_id})</Link>
                    </span>
                );
            }
        },
        
        {
            accessor: 'buyer_email',
            Header: 'Buyer Email'
        },
        {
            accessor: 'buyer_timezone',
            Header: 'Buyer Timezone'
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                return (
                    <CardDropdown iconClassName="fs--1" drop="start">
                        <div>
                            <Dropdown.Item
                                eventkey="1"
                                onClick={() =>
                                    handleShow(index, demo, 'Update')
                                }
                                className="text-success"
                            >
                                Completed
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventkey="1"
                                onClick={() =>
                                    handleShow(index, demo, 'Seller Reschedule')
                                }
                                className="text-primary"
                            >
                                Seller Reschedule
                            </Dropdown.Item>
                            <Dropdown.Divider as="div" />
                            <Dropdown.Item
                                eventkey="2"
                                onClick={() =>
                                    handleShow(index, demo, 'Delete')
                                }
                                className="text-danger"
                            >
                                Delete
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    const sendSMSReminders = async () => {
        function extractValue(arr, key) {
            return arr.reduce(function (accum, item) {
                if (!item[key]) {
                    return accum; // <--- we must still return the accumulator
                }

                accum.push(item[key]);
                return accum; // <---- and return accumulator here also
            }, []);
        }

        let newData = extractValue(demos, 'demo_id');
        console.log(newData, 'this is the extreacted data!!!!!!!!');

        try {
            const res = await sendReminderSms(newData);
            console.log(
                res,
                'this is the response from the api call to send the sms reminder!!!!!!'
            );
        } catch (err) {
            alert(err.message);
            console.log(JSON.stringify(err));
        }
    };

    const formatIntoCSV = async () => {
        let csvContent =
            'data:text/csv;charset=utf-8,' +
            demos.map((e) => e.join(',')).join('\n');
        console.log(csvContent, 'this is the formatted array');
        // var encodedUri = encodeURI(csvContent);
        // window.open(encodedUri);
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5>Daily Demos</h5>
                    {/* <motion.button
                        className='btn btn-danger'
                        whileHover={{ scale: 1.1}}
                        whileTap={{ scale: .9}}
                        onClick={() => sendSMSReminders()}
                    >
                        <FontAwesomeIcon icon={ faSms } size='lg'  className='me-2'/>
                        Send Reminder (In Testing!)
                    </motion.button> */}
                </Card.Header>
                {/* <FalconComponentCard.Header title='Daily Demos' titleTag='h2' noPreview/> */}
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {loaded && demos && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={demos}
                            sortable
                            pagination
                            perPage={20}
                        >
                            <Row className="flex-between-center mb-3">
                                <Col xs="auto" lg={3} sm={4}>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => setDate(() => date)}
                                        formatWeekDay={(day) => day.slice(0, 3)}
                                        className="form-control"
                                        placeholderText="Select Date & Time"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        fixedHeight
                                    />
                                </Col>
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                    {/* <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="external-link-alt"
                                        transform="shrink-3"
                                        onClick={() => formatIntoCSV()}
                                    >
                                        <span className="d-none d-sm-inline-block ms-1">
                                            Export
                                        </span>
                                    </IconButton> */}
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={demos.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    <DemosModal
                        show={show}
                        setShow={setShow}
                        selected={selected}
                        setData={setDemos}
                        index={index}
                        action={action}
                    />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default TodaysDemosDashboard;
