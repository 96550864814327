import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { getBuyerRequests } from 'api/api';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

function BuyerRequests() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getBuyerRequests();
                console.log(res.data);
                setData(res.data);
            } catch (err) {
                console.warn(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const columns = [
        {
            accessor: 'buyer_request_id',
            Header: 'Request Id'
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span className="text-nowrap">{date}</span>;
            }
        },
        {
            accessor: 'is_active',
            Header: 'Is Active?',
            Cell: (rowData) => {
                const { is_active } = rowData.row.original;

                if (is_active) {
                    return (
                        <span className="badge badge-soft-success">Yes</span>
                    );
                } else {
                    return <span className="badge badge-soft-warning">No</span>;
                }
            }
        },
        {
            accessor: 'registered_user_id',
            Header: 'User Id',
            Cell: (rowData) => {
                const { registered_user_id } = rowData.row.original;
                return (
                    <Link to={'/user/' + registered_user_id}>
                        {registered_user_id}
                    </Link>
                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: (rowData) => {
                const { status } = rowData.row.original;

                if (status === 'Completed') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-success">
                                Completed
                            </span>
                        </div>
                    );
                } else if (status === 'Pending') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-warning">
                                Pending
                            </span>
                        </div>
                    );
                } else if (status === 'Declined') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-danger">
                                Declined
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge bg-light">{status}</span>
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'request_text',
            Header: 'Message',
            Cell: (rowData) => {
                const { request_text } = rowData.row.original;
                return <span className="text-start">{request_text}</span>;
            }
        }
    ];

    return (
        <Card>
            <Card.Header>
                <h5>Buyer Requests</h5>
            </Card.Header>
            <Card.Body>
                {!loading && data && (
                    <AdvanceTableWrapper columns={columns} data={data} sortable>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 align-middle text-center"
                            rowClassName="align-middle text-center"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                )}
            </Card.Body>
        </Card>
    );
}

export default BuyerRequests;

// buyer_request_id: 3;
// date_created: '2022-12-08T20:36:05.248Z';
// is_active: true;
// registered_user_id: 7779;
// request_text: 'I need to reschedule my demo with Fatihstreet. It was cleared from my seller dashboard';
// status: 'Pending';
