import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';
import { getCompanyCharges } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

function CompanyCharges() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            accessor: 'name',
            Header: 'Company Name',
            Cell: (rowData) => {
                const { company_id, name, logo } = rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {name + ' '}
                        <Link className="ms-1" to={'/company/' + company_id}>
                            {company_id}
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'demo_count',
            Header: 'Uncharged Demos'
        },
        {
            accessor: 'charge_amount',
            Header: 'Charge Amount',
            Cell: (rowData) => {
                const { charge_amount } = rowData.row.original;
                return <span>${charge_amount}</span>;
            }
        },
        {
            accessor: 'payment_plan',
            Header: 'Payment Plan'
        },
        {
            accessor: 'none',
            Header: 'Charges',
            Cell: (rowData) => {
                const { company_id } = rowData.row.original;
                return (
                    <>
                        <Link to={`/charges/company/${company_id}`}>View</Link>
                    </>
                );
            }
        },
        {
            accessor: 'special_pricing',
            Header: 'Special Pricing',
            Cell: (rowData) => {
                const { special_pricing } = rowData.row.original;
                return (
                    <>
                        {special_pricing}
                    </>
                );
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getCompanyCharges();
                setData(res.data);
            } catch (err) {
                console.warn(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Company Charges"
                    titleTag="h3"
                    noPreview
                />
                <FalconComponentCard.Body language="jsx" noInline noLight>
                    {data && !loading && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                            pagination
                            perPage={20}
                        >
                            <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {loading && <div>Loading...</div>}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default CompanyCharges;
