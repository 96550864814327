import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getSellersForDropdown } from "api/api";

function SellerDropdown({ setSelected , sellerRef}) {
    const [sellers, setSellers] = useState(null);
    let organizerOptions;

    useEffect(() => {
        const loadSellers = async () => {
            try {
                const res = await getSellersForDropdown();
                setSellers(res);
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        };

        loadSellers();
    }, []);

    if (sellers){
        organizerOptions = sellers.map((level) => {
            const data = {value : level.company_id, label: level.name}
            return data;
        });
        organizerOptions.sort((a, b) => (a.label > b.label) ? 1 : -1);
    }
    const handleChange = target => {
        setSelected(target)
    }

    return ( 
        <Select
            ref={sellerRef}
            closeMenuOnScroll={true}
            closeMenuOnSelect={true}
            options={organizerOptions}
            placeholder={"Select Seller"}
            classNamePrefix="react-select"
            onChange={value => handleChange(value)}
        />
    );
}

export default SellerDropdown;