import Divider from 'components/common/Divider';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUser } from 'api/api';
import SocialAuthButtons from './SocialAuthButtons';

const LoginForm = ({ hasLabel, layout }) => {
    // State
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        remember: false
    });
    const navigate = useNavigate();

    // Handler
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     toast.success(`Logged in as ${formData.email}`, {
    //         theme: 'colored'
    //         // onClose: () => navigate('/')
    //     });
    // };

    const handleFieldChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    async function handleSubmit(e) {
        setLoading(true);
        e.preventDefault();
        let el1 = formData.email;
        let el2 = formData.password;

        if (el1.length > 0 && el2.length > 0) {
            let response = await loginUser(el1, el2);

            if (response.success) {
                let tokens = response.data;

                localStorage.setItem('admin_token', tokens.accessToken);
                localStorage.setItem(
                    'admin_refresh_token',
                    tokens.refreshToken
                );

                // 5/2/2022 - used in meeting.leadrpro
                setCookie('admin_token', tokens.accessToken, 2);

                toast.success(`Logged in as ${formData.email}`, {
                    theme: 'colored'
                });

                navigate('/');

                // if (isSeller()) window.location = '/dashboard/seller';
                // else window.location = '/dashboard/buyer';
            } else {
                toast.error(`Incorrect email or password. ${response?.data}`, {
                    theme: 'colored',
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                    onOpen: () => setLoading(false)
                });
                // setHasError(true);
                // setErrorMessage('Oops! Incorrect email or password');
            }
        }
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + d.toUTCString();
        let _domain = 'domain=admin01.leadrpro.com';

        if (process.env.REACT_APP_NODE_ENV === 'Dev') {
            _domain = 'domain=leadrpro-client-staging.vercel.app';
        }

        document.cookie =
            cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Email address</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Email address' : ''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Password' : ''}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>

            <Row className="justify-content-between align-items-center">
                {/* <Col xs="auto">
                    <Form.Check
                        type="checkbox"
                        id="rememberMe"
                        className="mb-0"
                    >
                        <Form.Check.Input
                            type="checkbox"
                            name="remember"
                            checked={formData.remember}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    remember: e.target.checked
                                })
                            }
                        />
                        <Form.Check.Label className="mb-0 text-700">
                            Remember me
                        </Form.Check.Label>
                    </Form.Check>
                </Col> */}

                <Col xs="auto">
                    <Link
                        className="fs--1 mb-0"
                        to={`/authentication/${layout}/forgot-password`}
                    >
                        Forgot Password?
                    </Link>
                </Col>
            </Row>

            <Form.Group>
                <Button
                    type="submit"
                    color={loading ? 'secondary' : 'primary'}
                    className="mt-3 w-100"
                    disabled={!formData.email || !formData.password || loading}
                >
                    Log in
                </Button>
            </Form.Group>

            {/* <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons /> */}
        </Form>
    );
};

LoginForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default LoginForm;
