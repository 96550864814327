import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Col, Form, Row, Toast, ListGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion/dist/framer-motion';
import {
    getICPDepartment,
    getICPSeniority,
    getICPCompanySize,
    getICPIndustry,
    getDepartments,
    getIndustries,
    getSeniorityLevels,
    getCompanySizes,
    saveICPDepartment,
    saveICPIndustry,
    saveICPSeniority,
    saveICPCompanySize,
    removeICPDepartment,
    removeICPIndustry,
    removeICPSeniority,
    removeICPCompanySize,
    addQualifierQuestion,
    updateQualifierQuestion,
    getCompany,
    getCompanyNotes,
    addCompanyNote,
    deleteCompanyNote,
    addCompanyCategory,
    removeCompanyCategory,
    qualifierQuestionMoveUp,
    qualifierQuestionMoveDown,
    getCompanyQualifierQuestions,
    updateCompany,
    getCompanyFeedItems,
    addCompanyFeedItem,
    getFeedDepartments,
    getAdminsForDropdown,
    addCompanyPaymentTest,
    getCompanyPaymentTests,
    getCompanyStages,
    getPaymentMethodsForCompany,
    getCompanyFeatures,
    addCompanyFeature,
    getCompanyPricing,
    addCompanyPricing,
    updateCompanyPricing,
    getCompanyPackagePurchaseItems,
    unassignPackagePurchaseItemDemo
} from 'api/api';
import moment from 'moment';
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IndustriesDropdown from './IndustriesDropdown';
import CompanySizeDropdown from './CompanySizeDropdown';
import DemoStatusDropdown from './DemoStatusDropdown';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CompanySettingsModal from './CompanySettingsModal';
import PrimarySellerDropdown from './PrimarySellerDropdown';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

function CompanyProfile({ company, setCompany, refresh }) {
    const { companyId } = useParams();
    const [show, setShow] = useState(false);
    const [action, setAction] = useState('');
    const [selected, setSelected] = useState(null);
    const [departmentSuccess, setDepartmentSuccess] = useState(false);
    const [departmentFailure, setDepartmentFailure] = useState(false);
    const [industrySuccess, setIndustrySuccess] = useState(false);
    const [industryFailure, setIndustryFailure] = useState(false);
    const [senioritySuccess, setSenioritySuccess] = useState(false);
    const [seniorityFailure, setSeniorityFailure] = useState(false);
    const [sizesSuccess, setSizesSuccess] = useState(false);
    const [sizesFailure, setSizesFailure] = useState(false);
    const [question, setQuestion] = useState('');
    const [note, setNote] = useState('');
    const [notes, setNotes] = useState([]);
    const [features, setFeatures] = useState([]);
    const [featureText, setFeatureText] = useState('');
    const [feedItem, setFeedItem] = useState('');
    const [showAllNotes, setShowAllNotes] = useState(false);
    const [specialPricing, setSpecialPricing] = useState(company.special_pricing);
    const [pricingSuccess, setPricingSuccess] = useState(false);
    const [feedItems, setFeedItems] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [companyStages, setCompanyStages] = useState([]);
    const [salesRepId, setSalesRepId] = useState(company.sales_rep_id);
    const [companyStageId, setCompanyStageId] = useState(company.company_stage_id);
    const [showPricing, setShowPricing] = useState(false);
    const [companyPricing, setCompanyPricing] = useState([]);
    const [pricingName, setPricingName] = useState('');
    const [pricingDescription, setPricingDescription] = useState('');
    const [pricingAmount, setPricingAmount] = useState('');
    const [pricingUnit, setPricingUnit] = useState('');
    const [selectedPricingId, setSelectedPricingId] = useState(null);

    const [demos, setDemos] = useState(
        Array.isArray(company.demos) ? [...company.demos] : null
    );
    const [filteredDemos, setFilteredDemos] = useState(null);
    const [demoStatus, setDemoStatus] = useState('');
    const statusRef = useRef();

    const [departments, setDepartments] = useState(null);
    const [selectedDepartments, setSelectedDepartments] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [selectedIndustries, setSelectedIndustries] = useState(null);
    const [seniority, setSeniority] = useState(null);
    const [selectedSeniority, setSelectedSeniority] = useState(null);
    const [companySizes, setCompanySizes] = useState(null);
    const [selectedSizes, setSelectedSizes] = useState(null);
    const [showQuestionEdit, setShowQuestionEdit] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [newCategory, setNewCategory] = useState('');
    const [feedDepartments, setFeedDepartments] = useState([]);
    const [selectedFeedDepartmentId, setSelectedFeedDepartmentId] = useState(null);
    const [tests, setTests] = useState([]);
    const [submittingTest, setSubmittingTest] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
    const [paymentCheckLoaded, setPaymentCheckLoaded] = useState(true);
    const [priceName, setPriceName] = useState('');
    const [priceDescription, setPriceDescription] = useState('');
    const [priceAmount, setPriceAmount] = useState('');
    const [priceUnit, setPriceUnit] = useState('');
    const [purchaseItems, setPurchaseItems] = useState([]);
    const [showPurchaseItems, setShowPurchaseItems] = useState(false);

    async function loadNotes(){
        try {
            //setLoaded(false);
            const res = await getCompanyNotes(companyId);
            setNotes(res.data);
          //  setAvailableBuyers(res.data.available_buyers);
        } catch (err) {
            console.log(JSON.stringify(err));
        } finally {
           // setLoaded(true);
        }
    };

    async function showItems(id){
        let res = await getCompanyPackagePurchaseItems(id);

        if (res.success){
            setPurchaseItems(res.data);
            setShowPurchaseItems(true);
        }
    }

    async function loadFeatures(){
        try {
            //setLoaded(false);
            const res = await getCompanyFeatures(companyId);
            setFeatures(res.data);
          //  setAvailableBuyers(res.data.available_buyers);
        } catch (err) {
            console.log(JSON.stringify(err));
        } finally {
           // setLoaded(true);
        }
    };

    async function loadTests(){
        let res = await getCompanyPaymentTests(companyId);

        if (res.success){
            setTests(res.data);
        }
    }

    async function loadAdmins(){
        let res = await getAdminsForDropdown();

        if (res.success){
            setAdmins(res.data);
        }
    }

    async function loadCompanyStages(){
        let res = await getCompanyStages();

        if (res.success){
            setCompanyStages(res.data);
        }
    }

    async function loadFeedDepartments(){
        try {
            const res = await getFeedDepartments();
            setFeedDepartments(res.data);
          //  setAvailableBuyers(res.data.available_buyers);
        } catch (err) {
            console.log(JSON.stringify(err));
        } finally {
           // setLoaded(true);
        }
    };

    async function loadFeedItems(){
        let res = await getCompanyFeedItems(companyId);

        if (res.success){
            setFeedItems(res.data);
        }
    }

    async function loadCompanyPricing(){
        let res = await getCompanyPricing(companyId);

        if (res.success){
            console.log('pricing', res.data);
            setCompanyPricing(res.data);
        }
    }

    async function checkPaymentMethods(){

        let res = await getPaymentMethodsForCompany(companyId);

        if (res.success){
            if (res.data.length > 0){
                setPaymentCheckLoaded(false);
                setHasPaymentMethod(true);
            }

            else{
                setHasPaymentMethod(false);
                setPaymentCheckLoaded(true);
            }
        }

        setPaymentCheckLoaded(true);
    }

    useEffect(() => {
        const loadICP = async () => {
            try {
                await getICPDepartment(companyId).then((res) =>
                    setSelectedDepartments(res.data)
                );
                await getICPSeniority(companyId).then((res) =>
                    setSelectedSeniority(res.data)
                );
                await getICPCompanySize(companyId).then((res) =>
                    setSelectedSizes(res.data)
                );
                await getICPIndustry(companyId).then((res) => setSelectedIndustries(res.data));
                await getCompanySizes().then((res) =>
                    setCompanySizes(res.data)
                );
                await getDepartments().then((res) => setDepartments(res.data));
                await getIndustries().then((res) => setIndustries(res.data));
                
                await getSeniorityLevels().then((res) =>
                    setSeniority(
                        res.data.sort((a, b) =>
                            a.seniority_level_id > b.seniority_level_id ? 1 : -1
                        )
                    )
                );
            } catch (err) {
                console.log(err.message);
            }
        };

        loadICP();
        loadNotes();
        loadFeedItems();
        loadFeedDepartments();
        loadAdmins();
        loadTests();
        loadCompanyStages();
        loadFeatures();
        checkPaymentMethods();
        loadCompanyPricing();
    }, []);

    const handleChange = (e) => {
        setCompany({
            ...company,
            [e.target.name]: e.target.value
        });
    };

    const handleFilter = () => {
        let newDemos = demos;
        newDemos = newDemos.filter((object) => {
            return object.demo_status === selected;
        });
        console.log(newDemos, 'these are the filtered demos by status');
    };

    const handleFilterReset = () => {
        statusRef.current.clearValue();
        setFilteredDemos(() => null);
        setDemoStatus('');
    };

    const handleCompanyDelete = () => {
        setAction('Delete');
        setShow(true);
    };

    const handleDepartmentChange = async (e, department_id, item) => {
        setDepartmentSuccess(false);
        setDepartmentFailure(false);
        setSenioritySuccess(false);
        setSeniorityFailure(false);
        setSizesSuccess(false);
        setSizesFailure(false);

        if (e.target.checked) {
            const res = await saveICPDepartment(department_id, companyId);

            if (res.success) {
                setDepartmentSuccess(true);
                setSelectedDepartments([...selectedDepartments, item]);
            } else {
                setDepartmentFailure(true);
            }
        } else {
            const res = await removeICPDepartment(department_id, companyId);

            if (res.success) {
                setDepartmentSuccess(true);
                let _filtered = selectedDepartments.filter(
                    (x) => x.department_id !== department_id
                );
                setSelectedDepartments(_filtered);
            } else {
                setDepartmentFailure(true);
            }
        }
    };

     const handleIndustryChange = async (e, industry_id, item) => {
        setDepartmentSuccess(false);
        setDepartmentFailure(false);
        setSenioritySuccess(false);
        setSeniorityFailure(false);
        setSizesSuccess(false);
        setSizesFailure(false);
        setIndustrySuccess(false);
        setIndustryFailure(false);

        if (e.target.checked) {
            const res = await saveICPIndustry(industry_id, companyId);

            if (res.success) {
                setDepartmentSuccess(true);
                setSelectedIndustries([...selectedIndustries, item]);
            } else {
                setIndustryFailure(true);
            }
        } else {
            const res = await removeICPIndustry(industry_id, companyId);

            if (res.success) {
                setIndustrySuccess(true);
                let _filtered = selectedIndustries.filter(
                    (x) => x.industry_id !== industry_id
                );
                setSelectedIndustries(_filtered);
            } else {
                setIndustryFailure(true);
            }
        }
    };

    const handleSeniorityChange = async (e, seniority_level_id, item) => {
        setDepartmentSuccess(false);
        setDepartmentFailure(false);
        setSenioritySuccess(false);
        setSeniorityFailure(false);
        setSizesSuccess(false);
        setSizesFailure(false);

        if (e.target.checked) {
            const res = await saveICPSeniority(seniority_level_id, companyId);

            if (res.success) {
                setSenioritySuccess(true);
                setSelectedSeniority([...selectedSeniority, item]);
            } else {
                setSeniorityFailure(true);
            }
        } else {
            const res = await removeICPSeniority(seniority_level_id, companyId);

            if (res.success) {
                setSenioritySuccess(true);
                let _filtered = selectedSeniority.filter(
                    (x) => x.seniority_level_id !== seniority_level_id
                );
                setSelectedDepartments(_filtered);
            } else {
                setSeniorityFailure(true);
            }
        }
    };

    const handleCompanySizeChange = async (e, company_size_id, object) => {
        setDepartmentSuccess(false);
        setDepartmentFailure(false);
        setSenioritySuccess(false);
        setSeniorityFailure(false);
        setSizesSuccess(false);
        setSizesFailure(false);

        if (e.target.checked) {
            const res = await saveICPCompanySize(company_size_id, companyId);

            if (res.success) {
                setSizesSuccess(true);
                setSelectedSizes([...selectedSizes, object]);
            } else {
                setSizesFailure(true);
            }
        } else {
            const res = await removeICPCompanySize(company_size_id, companyId);

            if (res.success) {
                setSizesSuccess(true);
                let _filtered = selectedSizes.filter(
                    (x) => x.company_size_id !== company_size_id
                );
                setSelectedSizes(_filtered);
            } else {
                setSizesFailure(true);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAction('Update');
        setShow(true);
    };

    async function handleEditQuestion(q, id){
       setSelectedQuestion(q);
       setSelectedQuestionId(id);
       setShowQuestionEdit(true);
    }

    async function handleEditQuestionClose(){
        setShowQuestionEdit(false);
     }

     async function handleQuestionUpdate(){
        let res = await updateQualifierQuestion(selectedQuestionId, selectedQuestion);
        setShowQuestionEdit(false);

        let questions = company.qualifier_questions;
        questions.find(x => x.qualifier_question_id === selectedQuestionId).question = selectedQuestion;
        //questions.push({question: question});

        setCompany({
            ...company,qualifier_questions: questions
        });
     }

     async function handleCategoryDelete(id){
        let res = await removeCompanyCategory(companyId, id);

        if (res.success){
            let categories = company.categories.filter(x => x.category_id !== id);

            setCompany({
                ...company,categories: categories
            });
        }
     }

    async function handleNoteSubmit(e){
        e.preventDefault();
        if (note.length > 0){
            let res = await addCompanyNote(companyId, note, false);

            if (res.success){
                await loadNotes();
                setNote('');
            }
        }

        else{
            alert("Must enter a valid note");
        }
    }

    async function handlePricingSubmit(e){
        e.preventDefault();
        if (specialPricing.length > 0){
            let res = await updateCompany(companyId, {special_pricing: specialPricing});
          //  let res = await addCompanyNote(companyId, note, false);

            if (res.success){
                setPricingSuccess(true);
                //setSpecialPricing('');
            }
        }

        else{
            alert("Must enter a valid note");
        }
    }

    async function handleFeatureSubmit(e){
        e.preventDefault();
        if (featureText.length > 0){
            let res = await addCompanyFeature(companyId, featureText);

            if (res.success){
                setPricingSuccess(true);
                await loadFeatures();
            }
        }

        else{
            alert("Must enter a valid feature");
        }
    }

    async function handleAddQualifierQuestion(){
        let res = await addQualifierQuestion(companyId, question);

        if (res.success){
            let questions = company.qualifier_questions;
            questions.push({question: question});

            setCompany({
                ...company,qualifier_questions: res.data
            });

            setQuestion('');
        }
    }

    async function handleDeleteNote(company_note_id){
        try {
            let res = await deleteCompanyNote(company_note_id);

            if (res.success){
                await loadNotes();
            }
        } catch (error) {
            
        }
    }

    async function handleNewCategory(){
        let res = await addCompanyCategory(companyId, newCategory);

        if (res.success){
            setNewCategory('');

            let categories = company.categories;
            categories.push({name: newCategory, category_id: res.data});

            setCompany({
                ...company,categories: categories
            });
        }
    }

    async function moveUp(qualifier_question_id, index){
        let prev = company.qualifier_questions[index-1];
        let res = await qualifierQuestionMoveUp(qualifier_question_id, prev.qualifier_question_id, index);
    
        if (res.success){
         //wait getCompany();
         //await refresh();
         let res2 = await getCompanyQualifierQuestions(companyId);
         if (res2.success){
            setCompany({
                ...company,qualifier_questions: res2.data
            });
         }
        }
    
        else{
        }
      }
    
      async function moveDown(qualifier_question_id, index){
        let next = company.qualifier_questions[index+1];
        let res = await qualifierQuestionMoveDown(qualifier_question_id, next.qualifier_question_id, index);
    
        if (res.success){
           // await refresh();
           let res2 = await getCompanyQualifierQuestions(companyId);
            if (res2.success){
                setCompany({
                    ...company,qualifier_questions: res2.data
                });
            }
        }
    
        else{
        }
      }

      async function handleFeedItemSubmit(){
        if (feedItem.length > 0){
            let res = await addCompanyFeedItem(companyId, feedItem, selectedFeedDepartmentId);

            if (res.success){
                alert('success');
                await loadFeedItems();
            }

            else{
                alert('nope');
            }
        }

        else{

        }

       setFeedItem('');
      }

      async function handleFeedDepartmentChange(e){
        setSelectedFeedDepartmentId(e.target.value);
    }

    async function handleSalesRepChange(e){
        setCompany({...company, sales_rep_id: e.target.value});
        setSalesRepId(e.target.value);
    }

    async function handleCompanyStageChange(e){
        setCompany({...company, company_stage_id: e.target.value});
        setCompanyStageId(e.target.value);
    }

    async function handlePaymentTest(){
        setSubmittingTest(true);
        let res = await addCompanyPaymentTest(companyId);

        if (res.success){
            alert('Charge Successful');
        }

        else{
            alert('Charge failed');
        }

        await loadTests();
        setSubmittingTest(false);
    }

    async function handlePricingClick(){
        setSelectedPricingId(null);
        setShowPricing(true);
    }

    async function handleUnassign(reference_id, id){
        let res = await unassignPackagePurchaseItemDemo(reference_id, companyId);

        if (res.success){
            showItems(id);
        }
    }

    async function handleAddPricing(){
        if (selectedPricingId == null){
            let res = await addCompanyPricing(companyId, pricingName, pricingDescription, pricingAmount, pricingUnit);

            setPricingName('');
            setPricingDescription('');
            setPricingAmount('');
            setPricingUnit('');

            setShowPricing(false);
            await loadCompanyPricing();
        }

        else{
            let res = await updateCompanyPricing(selectedPricingId, pricingName, pricingDescription, pricingAmount, pricingUnit);

            setPricingName('');
            setPricingDescription('');
            setPricingAmount('');
            setPricingUnit('');

            setShowPricing(false);
            await loadCompanyPricing();
        }
    }

    async function handlePricingEdit(item){
        //alert(item.company_pricing_id);
        setSelectedPricingId(item.company_pricing_id);
        setPricingName(item.name);
        setPricingDescription(item.description);
        setPricingAmount(item.price);
        setPricingUnit(item.unit);

        setShowPricing(true);
    }

    const columns = [
        {
            Cell: (rowData) => {
                const { date_created, first_name, last_name, registered_user_id } =
                    rowData.row.original;

                if (company.default_seller_id === registered_user_id) {
                    return (
                        <span>
                            {first_name + ' ' + last_name}
                            <Link to={'/user/' + registered_user_id}>
                                {' '}
                                ({registered_user_id}) -{' '}
                            </Link>
                            <span className="badge badge-soft-success">
                                {'Primary Seller'}
                            </span>
                        </span>
                    );
                }
                return (
                    <span>
                        {first_name + ' ' + last_name}
                        <Link to={'/user/' + registered_user_id}>
                            {' '}
                            ({registered_user_id})
                        </Link>
                    </span>
                );
            },
            Header: 'Name'
        },
        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'date_created',
            Header: 'Date Joined',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'seller_role',
            Header: 'Role'
        },
    ];

    const demosColumns = [
        {
            accessor: 'demo_id',
            Header: 'Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },
        {
            Header: 'Meeting Link',
            Cell: (rowData) => {
                const { reference_id } = rowData.row.original;
                return (
                    <a
                        href={`https://meetings.leadrpro.com/demo?refid=${reference_id}`}
                        target="_blank"
                    >
                        Link
                    </a>
                );
            }
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'scheduled_date_time',
            Header: 'Scheduled Date & Time',
            Cell: (rowData) => {
                const { scheduled_date_time, reschedule_date_time } =
                    rowData.row.original;
                if (!scheduled_date_time) {
                    return <span>Not scheduled.</span>;
                }
                if (reschedule_date_time) {
                    const date = moment(reschedule_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                } else {
                    const date = moment(scheduled_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'demo_status',
            Header: 'Status',
            Cell: (rowData) => {
                const { demo_status } = rowData.row.original;

                if (demo_status === 'Completed') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-success">
                                Completed
                            </span>
                        </div>
                    );
                } else if (demo_status === 'Pending') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-warning">
                                Pending
                            </span>
                        </div>
                    );
                } else if (demo_status === 'Declined') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-danger">
                                Declined
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge bg-light">
                                {demo_status}
                            </span>
                        </div>
                    );
                }
            }
        },
        {
            Header: 'Seller Status',
            Cell: (rowData) => {
                const { seller_status } = rowData.row.original;
                let status = [];
                if (seller_status === 'Approved') {
                    status.push(
                        <span className="badge badge-soft-success">
                            Approved
                        </span>
                    );
                } else if (seller_status === 'Pending') {
                    status.push(
                        <span className="badge badge-soft-warning">
                            Pending
                        </span>
                    );
                } else if (seller_status === 'Declined') {
                    status.push(
                        <span className="badge badge-soft-danger">
                            Declined
                        </span>
                    );
                } else if (seller_status === 'Rescheduled') {
                    status.push(
                        <span className="badge badge-soft-primary">
                            Rescheduled
                        </span>
                    );
                }
                return <span>{status}</span>;
            }
        },
        {
            Header: 'Buyer Status',
            Cell: (rowData) => {
                const { buyer_status } = rowData.row.original;
                let status = [];
                if (buyer_status === 'Approved') {
                    status.push(
                        <span className="badge badge-soft-success">
                            Approved
                        </span>
                    );
                } else if (buyer_status === 'Pending') {
                    status.push(
                        <span className="badge badge-soft-warning">
                            Pending
                        </span>
                    );
                } else if (buyer_status === 'Declined') {
                    status.push(
                        <span className="badge badge-soft-danger">
                            Declined
                        </span>
                    );
                } else if (buyer_status === 'Rescheduled') {
                    status.push(
                        <span className="badge badge-soft-primary">
                            Rescheduled
                        </span>
                    );
                }
                return <span>{status}</span>;
            }
        },
        {
            accessor: 'seller_email',
            Header: 'Seller Email'
        },
        // {
        //     accessor: 'seller_company_name',
        //     Header: 'Seller Company',
        //     Cell: rowData => {
        //         const { seller_company_id, seller_company_name } = rowData.row.original;
        //         return (
        //             <span>
        //                 {seller_company_name + " "}
        //                 <Link to={'/company/' + seller_company_id}>({seller_company_id})</Link>
        //             </span>
        //         )
        //     }
        // },

        {
            accessor: 'seller_timezone',
            Header: 'Seller Timezone'
        },
        {
            accessor: 'buyer_first_name',
            Header: 'Buyer Name',
            Cell: (rowData) => {
                const { buyer_first_name, buyer_last_name, buyer_id } =
                    rowData.row.original;
                return (
                    <span>
                        {buyer_first_name + ' ' + buyer_last_name}
                        <Link to={'/user/' + buyer_id}> ({buyer_id})</Link>
                    </span>
                );
            }
        },
        {
            accessor: 'buyer_company_name',
            Header: 'Buyer Company',
            Cell: (rowData) => {
                const { buyer_company_id, buyer_company_name } =
                    rowData.row.original;
                return (
                    <span>
                        {buyer_company_name + ' '}
                        <Link to={'/company/' + buyer_company_id}>
                            ({buyer_company_id})
                        </Link>
                    </span>
                );
            }
        },
        {
            accessor: 'buyer_email',
            Header: 'Buyer Email'
        },
        {
            accessor: 'buyer_timezone',
            Header: 'Buyer Timezone'
        }
        // {
        //     accessor: 'none',
        //     Header: '',
        //     disableSortBy: true,
        //     cellProps: {
        //         className: 'text-end py-2'
        //     },
        //     Cell: rowData => {
        //         const index = rowData.row.index;
        //         const demo = rowData.row.original;
        //             return (
        //                     <CardDropdown iconClassName="fs--1" drop="start">
        //                         <div>
        //                             <Dropdown.Item eventkey='1' onClick={() => handleShow(index, demo, 'Update')} className='text-success'>Completed</Dropdown.Item>
        //                             <Dropdown.Item eventkey='1' onClick={() => handleShow(index, demo, 'Seller Reschedule')} className='text-primary'>Seller Reschedule</Dropdown.Item>
        //                             <Dropdown.Divider as="div" />
        //                             <Dropdown.Item eventkey='3' onClick={() => handleShow(index, demo, 'Delete')} className='text-danger'>Delete</Dropdown.Item>
        //                         </div>
        //                     </CardDropdown>
        //             )
        //         }
        // }
    ];

    return (
        <>
            {/* Company Payment Plan Info */}
            {company.company_plan && (
                <Card className="mb-3">
                    <FalconCardHeader title={'Company Plan'} />
                    <Card.Body className="bg-light">
                        <Row>
                            <Form.Group
                                as={Col}
                                lg={4}
                                controlId="payment_plan"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Payment Plan:
                                </Form.Label>
                                <div>
                                    {company?.company_plan.short_name && company.company_plan.short_name !==
                                        '' && (
                                        <span className="badge badge-soft-danger">
                                            {company?.company_plan.short_name}
                                        </span>
                                    )}
                                </div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={4}
                                controlId="cost_per_demo"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Cost Per Demo:
                                </Form.Label>
                                <div>
                                    {company?.company_plan.short_name ===
                                        'CUST' && (
                                        <span>
                                            $
                                            {parseInt(
                                                company?.company_plan
                                                    .custom_cost_per_credit
                                            ) * 5}
                                        </span>
                                    )}
                                    {company?.company_plan.short_name !==
                                        'CUST' && (
                                        <span>
                                            $
                                            {parseInt(
                                                company?.company_plan
                                                    .cost_per_credit
                                            ) * 5}
                                        </span>
                                    )}
                                </div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={4}
                                controlId="cost_per_credit"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Cost Per Credit:
                                </Form.Label>
                                <div>
                                    {company?.company_plan.short_name ===
                                        'CUST' && (
                                        <span>
                                            $
                                            {parseInt(
                                                company?.company_plan
                                                    .custom_cost_per_credit
                                            )}
                                        </span>
                                    )}
                                    {company?.company_plan.short_name !==
                                        'CUST' && (
                                        <span>
                                            $
                                            {parseInt(
                                                company?.company_plan
                                                    .cost_per_credit
                                            )}
                                        </span>
                                    )}
                                </div>
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>
            )}

            {/* Company Info Card */}
            <Card className="mb-3">
                <FalconCardHeader title={`Addons`} />
                <Card.Body className="bg-light">
                    <div>
                        <strong>Custom meeting links: {company.addons[0].custom_meeting }</strong>
                    </div>

                    <div>
                        <strong>Additional team seats: {company.addons[0].team_seats}</strong>
                    </div>
                </Card.Body>
            </Card>

            {/* Company Info Card */}
            <Card className="mb-3">
                <FalconCardHeader title={`💳 Payment Test`} />
                <Card.Body className="bg-light">
                    <div style={{marginBottom: '10px'}}>
                        {hasPaymentMethod && paymentCheckLoaded ? '✅ Card Found': '❌ No Card Found'}
                    </div>

                    <div>
                        {submittingTest === false && (
                            <button className='btn btn-secondary' onClick={() => handlePaymentTest()}>Create $1 Test Charge</button>
                        )}

                        {submittingTest && (
                            <span><i>Submitting...</i></span>
                        )}
                    </div>
                    
                    <div style={{marginTop:"20px"}}>
                        <table className='fs--1 mb-0 overflow-hidden table table-striped table-bordered'>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        {tests.map(item => (
                            <tr>
                                <td>{new Date(item.date_created).toLocaleDateString()}</td>
                                <td>${item.amount}</td>
                                <td>{item.passed === true?'✅':'❌'}</td>
                            </tr>
                        ))}
                        </table>
                    </div>

                    {tests && tests.length === 0 && (
                        <div style={{marginTop: "15px"}}>
                            <i>No tests found</i>
                        </div>
                    )}
                </Card.Body>
            </Card>

            {company && company.purchases.length > 0 && (
            <Card className="mb-3">
                <FalconCardHeader title={`Package Purchases`} />
                <Card.Body className="bg-light">
                    <div>
                        Custom Pricing: 
                    </div>

                    <div style={{marginTop:"20px"}}>
                        <table className='fs--1 mb-0 overflow-hidden table table-striped table-bordered'>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Demos</th>
                                <th>CPD</th>
                                <th>Total</th>
                                <th>View</th>
                            </tr>
                        {company.purchases.map(item => (
                            <tr>
                                <td>{new Date(item.date_paid).toLocaleDateString()}</td>
                                <td>{item.name}</td>
                                <td>{item.status}</td>
                                <td>{item.number_of_demos}</td>

                                <td>
                                    {item.custom_cost_per_demo != null && (
                                        <span>${item.custom_cost_per_demo}</span>
                                    )}

                                    {item.custom_cost_per_demo == null && (
                                        <span>${item.cost_per_demo}</span>
                                    )}
                                </td>

                                <td>
                                    {item.custom_cost_per_demo != null && (
                                        <span>${item.number_of_demos * item.custom_cost_per_demo}</span>
                                    )}

                                    {item.custom_cost_per_demo == null && (
                                        <span>${item.number_of_demos * item.cost_per_demo}</span>
                                    )}
                                </td>

                                <td><a href='javascript:void(0)' onClick={() => showItems(item.company_package_purchase_id)}>See demos</a></td>
                            </tr>
                        ))}
                        </table>
                    </div>

                    {tests && tests.length === 0 && (
                        <div style={{marginTop: "15px"}}>
                            <i>No tests found</i>
                        </div>
                    )}
                </Card.Body>
            </Card>
            )}

             {/* Company Info Card */}
             <Card className="mb-3">
                <FalconCardHeader title={'📝 Company Notes'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleNoteSubmit}>
                       
                       <Row className='mb-3 g-3'>
                        <table className="" style={{fontSize: '12px'}}>
                       {notes.map((item, index) => {
                            return (
                                <tr style={{fontSize: '14px'}}>
                                    <td>{moment(item.date_created)
                    .local()
                    .format('MMM Do YY, h:mm a')}</td>

                                <td>
                                    {item.first_name}
                                </td>
                                    
                                <td>{item.note_text}</td>

                                <td className='text-end'>
                                    <span className='btn' onClick={() => handleDeleteNote(item.company_note_id)}>Delete Note</span>
                                </td>
                            </tr>
                            );
                            
                        })}
                        </table>

                        {/* {showAllNotes === false && notes.length > 1 && (
                            <div>
                                <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setShowAllNotes(true)}>Show All</span>
                            </div>
                        )} */}
                       </Row>

                       <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="name">
                                <Form.Control
                                    type="text"
                                    placeholder="Add note"
                                    autoComplete='off'
                                    value={note}
                                    name="note"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        {/* Submit Button */}
                        <div className="text-start">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Save note
                            </motion.button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <FalconCardHeader title={'💵 Special Pricing'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handlePricingSubmit}>
                        {/* Name & Website */}

                       <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="name">
                                <Form.Control
                                    type="text"
                                    placeholder="Add note"
                                    value={specialPricing}
                                    name="note"
                                    onChange={(e) => setSpecialPricing(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        {/* Submit Button */}
                        <div className="text-start">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Save note
                            </motion.button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <FalconCardHeader title={'Company Features'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleFeatureSubmit}>
                        <div style={{display: 'flex'}}>
                            {features.map(x => (
                                <div style={{fontSize: '12px', background: '#111', padding: '10px', borderRadius: '5px', margin: '5px 5px'}}>{x.feature_text}</div>
                            ))}
                        </div>

                       <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="feature">
                                <Form.Control
                                    type="text"
                                    placeholder="Add feature"
                                    value={featureText}
                                    name="note"
                                    onChange={(e) => setFeatureText(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        {/* Submit Button */}
                        <div className="text-start">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Add feature
                            </motion.button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <FalconCardHeader title={'Company Pricing'} />
                    <Card.Body className="bg-light">
                        <div>
                            <table className='fs--1 mb-0 overflow-hidden table table-striped table-bordered'>
                            {company && companyPricing && companyPricing.map(x => (
                                <tr style={{padding: '10px'}}>
                                    <td>{x.name}</td>
                                    <td>{x.description}</td>
                                    <td>{x.price}</td>
                                    <td>{x.unit}</td>
                                    <td>
                                        <a href="javascript:void(0)" onClick={() => handlePricingEdit(x)}>Edit</a>
                                    </td>
                                </tr>
                            ))}
                            </table>
                        </div>

                        {/* Submit Button */}
                        <div className="text-start">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }} onClick={() => handlePricingClick()}>
                                Add Pricing
                            </motion.button>
                        </div>
                </Card.Body>
            </Card>

            {/* Company Info Card */}
            <Card className="mb-3">
                <FalconCardHeader title={'Company Details'} />
                <Card.Body className="bg-light">
                    <div style={{fontSize:"small", color:"#ccc", position:"absolute", top: "5px", right: "5px"}}>CP: 432</div>
                    <Form onSubmit={handleSubmit}>
                        {/* Name & Website */}
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="name">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Name:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    value={company.name}
                                    name="name"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="website">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Website:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Company website"
                                    name="website"
                                    value={company.website}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        {/* Industry & Company Size */}
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="industry">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Industry:
                                </Form.Label>
                                <IndustriesDropdown
                                    company={company}
                                    setCompany={setCompany}
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="company_size"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Company Size:
                                </Form.Label>
                                <CompanySizeDropdown
                                    company={company}
                                    setCompany={setCompany}
                                />
                            </Form.Group>
                        </Row>
                        {/* Pain Point & Unique Value Prop */}
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="pain_point">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Pain Point:
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Pain Point"
                                    value={company.pain_point}
                                    name="pain_point"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="unique_value_proposition"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Unique Value Proposition:
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Unique Value Proposition"
                                    value={company.unique_value_proposition}
                                    name="unique_value_proposition"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        {/* Description & ICP */}
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="description">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Description:
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="description"
                                    value={company.description}
                                    name="description"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="ideal_customer_profile"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Ideal Customer Profile:
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Name"
                                    rows={5}
                                    value={company.ideal_customer_profile}
                                    name="ideal_customer_profile"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="tagline">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Tagline:
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Tagline"
                                    value={company.tagline}
                                    name="tagline"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId=''>
                            <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Rep ID:
                                </Form.Label>

                        <select className='form-control' value={salesRepId}
                    onChange={(e) => handleSalesRepChange(e)} name='sales_rep_id'>
                            <option>Select rep</option>

                            {admins.map(dep => (
                                <option value={dep.registered_user_id}>
                                    {dep.first_name} {dep.last_name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="annual_revenue">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Annual Revenue:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Revenue"
                                    value={company.annual_revenue_text}
                                    name="annual_revenue_text"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId='company_stage_id'>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Company Stage:
                                </Form.Label>

                                <select className='form-control' value={companyStageId}
                            onChange={(e) => handleCompanyStageChange(e)} name='company_stage_id'>
                                    <option>Company Stage</option>

                                    {companyStages.map(dep => (
                                        <option value={dep.company_stage_id}>
                                            {dep.value}
                                        </option>
                                    ))}
                                </select>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="year_founded">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Year Founded:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Year Founded"
                                    value={company.year_founded}
                                    name="year_founded"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="url_slug">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    URL Slug:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="URL Slug"
                                    value={company.url_slug}
                                    name="url_slug"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        
                        {/* Submit Button */}
                        <div className="text-end">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Update
                            </motion.button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <FalconComponentCard className="mb-3">
                <Card.Header className="d-flex justify-content-between">
                    <h5>Feed Items</h5>
                </Card.Header>
                <FalconComponentCard.Body>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="feeditem">
                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Add post"
                            value={feedItem}
                            name="feeditem"
                            onChange={(e) => setFeedItem(e.target.value)}
                        />
                    </Form.Group>
                </Row>

                <Row className='mb-3 g-3'>
                    <Form.Group as={Col} lg={6} controlId=''>
                        <select className='form-control' value={selectedFeedDepartmentId}
                    onChange={(e) => handleFeedDepartmentChange(e)}>
                            <option>Select department</option>

                            {feedDepartments.map(dep => (
                                <option value={dep.department_id}>
                                    {dep.name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>
                </Row>

                        {/* Submit Button */}
                        <div className="text-start">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => handleFeedItemSubmit()}
                            >
                                Save Post
                            </motion.button>
                        </div>

                    <div>
                        {feedItems.map(item => (
                            <div style={{backgroundColor: "rgb(39, 60, 77)", padding: "10px", borderRadius: "5px", margin: "5px 0px"}}>
                                <div style={{fontSize: "12px", marginBottom: "10px"}}>
                                    {new Date(item.date_created).toLocaleDateString()}{' '}
                                    {new Date(item.date_created).toLocaleTimeString()}</div>
                                
                                <div>
                                    {item.content_body}
                                </div>

                                <div style={{marginTop: "10px", fontSize: "12px"}}>
                                    Posted in: {item.department_name}
                                </div>
                            </div>
                        ))}
                    </div>
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {/* Sellers Table */}
            <FalconComponentCard className="mb-3">
                <Card.Header className="d-flex justify-content-between">
                    <h5>Categories</h5>
                </Card.Header>
                <FalconComponentCard.Body>
                <Row className="mb-3 g-3">
                            <Form.Label style={{ textDecoration: 'underline' }}>
                                Categories:
                            </Form.Label>
                            <Row
                                xl={7}
                                lg={4}
                                sm={3}
                                className="gap-2"
                                style={{ fontSize: '16px' }}
                            >
                                {company.categories.length === 0 && (
                                    <div>
                                        <i>No categories found</i>
                                    </div>
                                )}

                                {company?.categories.map((object) => {
                                    return (
                                        <div
                                            className="badge badge-soft-info rounded-pill"
                                            style={{ whiteSpace: 'normal', backgroundColor: "dodgerblue", color: "black", padding: "8px", position: "relative" }}
                                        >
                                            <span>{object.name}</span>
                                            <span style={{position: "absolute", right: "5px", cursor:"pointer"}} onClick={() => handleCategoryDelete(object.category_id)}>X</span>
                                        </div>
                                    );
                                })}
                            </Row>

                            <Row>
                            <div style={{marginTop: "30px"}}>
                                <Form.Label>Add Category</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Add category"
                                    name="category"
                                    value={newCategory}
                                    onChange={(e) => setNewCategory(e.target.value)}
                                /> 
                            </div>

                            <div className='mt-3'>
                                <button className='btn btn-primary' onClick={() => handleNewCategory()}>Add Category</button>
                            </div>
                            </Row>
                        </Row>    
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {/* Sellers Table */}
            <FalconComponentCard className="mb-3">
                <Card.Header className="d-flex justify-content-between">
                    <h5>Sellers</h5>
                    <div style={{ width: '300px' }}>
                        <PrimarySellerDropdown
                            sellers={company.users}
                            setShow={setShow}
                            setSelected={setSelected}
                            setAction={setAction}
                        />
                    </div>
                </Card.Header>
                <FalconComponentCard.Body>
                    <AdvanceTableWrapper
                        columns={columns}
                        data={company.sellers}
                    >
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {/* Buyers Table */}
            <FalconComponentCard className="mb-3">
                <Card.Header className="d-flex justify-content-between">
                    <h5>Buyers</h5>
                </Card.Header>
                <FalconComponentCard.Body>
                    <AdvanceTableWrapper
                        columns={columns}
                        data={company.buyers}
                    >
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {/* Company Questions */}
            {company.qualifier_questions &&
                company.qualifier_questions.length >= 0 && (
                    <Card className="mb-3">
                        <FalconCardHeader title="Company Questions" />
                        <Card.Body className="bg-light">
                            <ListGroup>
                                {company.qualifier_questions.map(
                                    (item, index) => {
                                        return (
                                            <ListGroup.Item key={index}>
                                                <p>
                                                    {item.order_id} - {item.question}
                                                </p>

                                                <div className='mt-3' style={{display:"flex", backgroundColor: "#222", fontSize: "12px", padding:"5px"}}>
                                                    <span className='btn btn-primary btn-sm' onClick={() => handleEditQuestion(item.question, item.qualifier_question_id)}>Edit</span>

                                                    {index > 0 && (
                          <span className='btn btn-primary btn-sm ml-3' style={{marginLeft: "5px"}} onClick={() => moveUp(item.qualifier_question_id, index)}>Move Up ⬆️</span>
                        )}

                        {index < company.qualifier_questions.length - 1 && (
                          <span className='btn btn-primary btn-sm ml-3' style={{marginLeft: "5px"}} onClick={() => moveDown(item.qualifier_question_id, index)}>Move Down ⬇️</span>
                        )}
                                                </div>
                                            </ListGroup.Item>
                                        );
                                    }
                                )}
                            </ListGroup>

                            <Form.Group as={Col} lg={6} controlId="website">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    New Question:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Add question"
                                    name="question"
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                />
                            </Form.Group>

                            <div className='mt-3'>
                            <button
                                className="btn btn-primary"
                                onClick={() =>
                                    handleAddQualifierQuestion()
                                }
                            >
                                Add
                            </button>
                            </div>
                        </Card.Body>
                    </Card>
                )}

            {/* Company Demos */}
            <FalconComponentCard className="mb-3">
                <FalconCardHeader title="Company Demos" />
                <FalconComponentCard.Body>
                    {company.demos.length > 0 && (
                        <AdvanceTableWrapper
                            columns={demosColumns}
                            data={company.demos}
                            sortable
                            pagination
                            perPage={10}
                        >
                            <Row
                                className="flex-between-center mb-3"
                                style={{ paddingLeft: '1.25rem' }}
                            >
                                <Col
                                    xs="auto"
                                    lg={6}
                                    style={{
                                        border: '.5px rgb(99 99 99 / 20%) solid',
                                        borderRadius: '10px',
                                        boxShadow:
                                            'rgb(60 64 67 / 20%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px',
                                        padding: '1rem'
                                    }}
                                >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <DemoStatusDropdown
                                                setSelected={setSelected}
                                                statusRef={statusRef}
                                            />
                                        </div>
                                        <div className="d-flex">
                                            <motion.button
                                                className="btn btn-sm btn-primary rounded-pill d-flex align-items-center justify-content-center"
                                                onClick={() => handleFilter()}
                                                style={{
                                                    boxShadow:
                                                        '0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)'
                                                }}
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFilter}
                                                    className="me-1"
                                                />
                                                Filter
                                            </motion.button>
                                            <button
                                                className="btn btn-falcon-secondary rounded-pill btn-sm"
                                                onClick={() =>
                                                    handleFilterReset()
                                                }
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={company.demos.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {company.demos.length === 0 && <h5>No demos found.</h5>}
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {/* ICP */}
            <FalconComponentCard className="mb-3">
                <FalconCardHeader title="ICP" />
                <FalconComponentCard.Body>
                    <Form.Label className="mb-3">
                        <u>Departments:</u>
                    </Form.Label>
                    {departments &&
                        departments.length > 0 &&
                        departments.map((department) => {
                            return (
                                <Form.Check
                                    type="checkbox"
                                    id={department.name}
                                    label={department.name}
                                    onChange={(e) =>
                                        handleDepartmentChange(
                                            e,
                                            department.department_id,
                                            department
                                        )
                                    }
                                    defaultChecked={
                                        selectedDepartments.filter(
                                            (x) =>
                                                x.department_id ===
                                                department.department_id
                                        ).length > 0
                                            ? true
                                            : false
                                    }
                                />
                            );
                        })}
                    <Form.Label className="mb-3">
                        <u>Seniority:</u>
                    </Form.Label>
                    {seniority &&
                        seniority.length > 0 &&
                        seniority.map((level) => {
                            return (
                                <Form.Check
                                    type="checkbox"
                                    id={level.name}
                                    label={level.name}
                                    onChange={(e) =>
                                        handleSeniorityChange(
                                            e,
                                            level.seniority_level_id,
                                            level
                                        )
                                    }
                                    defaultChecked={
                                        selectedSeniority.filter(
                                            (x) =>
                                                x.seniority_level_id ===
                                                level.seniority_level_id
                                        ).length > 0
                                            ? true
                                            : false
                                    }
                                />
                            );
                        })}
                    <Form.Label className="mb-3">
                        <u>Company Size:</u>
                    </Form.Label>
                    {companySizes &&
                        companySizes.length > 0 &&
                        companySizes.map((object) => {
                            return (
                                <Form.Check
                                    type="checkbox"
                                    id={object.value}
                                    label={object.value}
                                    onChange={(e) =>
                                        handleCompanySizeChange(
                                            e,
                                            object.company_size_id,
                                            object
                                        )
                                    }
                                    defaultChecked={
                                        selectedSizes.filter(
                                            (x) =>
                                                x.company_size_id ===
                                                object.company_size_id
                                        ).length > 0
                                            ? true
                                            : false
                                    }
                                />
                            );
                        })}

                    <Form.Label className="mb-3">
                        <u>Industries:</u>
                    </Form.Label>
                    {industries &&
                        industries.length > 0 &&
                        industries.map((industry) => {
                            return (
                                <Form.Check
                                    type="checkbox"
                                    id={industry.name}
                                    label={industry.name}
                                    onChange={(e) =>
                                        handleIndustryChange(
                                            e,
                                            industry.industry_id,
                                            industry
                                        )
                                    }
                                    defaultChecked={
                                        selectedIndustries.filter(
                                            (x) =>
                                                x.industry_id ===
                                                industry.industry_id
                                        ).length > 0
                                            ? true
                                            : false
                                    }
                                />
                            );
                        })}
                    <div className="my-2 text-end">
                        <motion.button
                            className="btn btn-sm btn-outline-danger"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleCompanyDelete()}
                        >
                            Delete Company
                        </motion.button>
                    </div>
                </FalconComponentCard.Body>

                {/* Company Modal */}
                <CompanySettingsModal
                    selected={selected}
                    show={show}
                    setShow={setShow}
                    company={company}
                    setCompany={setCompany}
                    action={action}
                />

<div className="position-fixed bottom-0 end-0 p-3">
                    <Toast
                        onClose={() => setPricingSuccess(false)}
                        show={pricingSuccess}
                        delay={1500}
                        autohide
                    >
                        <Toast.Header className="bg-success text-white">
                            <strong className="me-auto">Success</strong>
                        </Toast.Header>
                        <Toast.Body>
                            Special pricing update was successful.
                        </Toast.Body>
                    </Toast>
                </div>

                {/* Department toasts */}
                <div className="position-fixed bottom-0 end-0 p-3">
                    <Toast
                        onClose={() => setDepartmentSuccess(false)}
                        show={departmentSuccess}
                        delay={1500}
                        autohide
                    >
                        <Toast.Header className="bg-success text-white">
                            <strong className="me-auto">Success</strong>
                        </Toast.Header>
                        <Toast.Body>
                            Department update was successful.
                        </Toast.Body>
                    </Toast>
                </div>

                <div className="position-fixed bottom-0 end-0 p-3">
                    <Toast
                        onClose={() => setDepartmentFailure(false)}
                        show={departmentFailure}
                        delay={1500}
                        autohide
                    >
                        <Toast.Header className="bg-danger text-white">
                            <strong className="me-auto">Failed</strong>
                        </Toast.Header>
                        <Toast.Body>
                            There was an error updating the department. Contact
                            DEV if this issue persists.
                        </Toast.Body>
                    </Toast>
                </div>

                {/* Seniority toasts */}
                <div className="position-fixed bottom-0 end-0 p-3">
                    <Toast
                        onClose={() => setSenioritySuccess(false)}
                        show={senioritySuccess}
                        delay={1500}
                        autohide
                    >
                        <Toast.Header className="bg-success text-white">
                            <strong className="me-auto">Success</strong>
                        </Toast.Header>
                        <Toast.Body>
                            Seniority update was successful.
                        </Toast.Body>
                    </Toast>
                </div>

                <div className="position-fixed bottom-0 end-0 p-3">
                    <Toast
                        onClose={() => setSeniorityFailure(false)}
                        show={seniorityFailure}
                        delay={1500}
                        autohide
                    >
                        <Toast.Header className="bg-danger text-white">
                            <strong className="me-auto">Failed</strong>
                        </Toast.Header>
                        <Toast.Body>
                            There was an error updating the user. Contact DEV if
                            this issue persists.
                        </Toast.Body>
                    </Toast>
                </div>

                {/* Company Sizes toasts */}
                <div className="position-fixed bottom-0 end-0 p-3">
                    <Toast
                        onClose={() => setSizesSuccess(false)}
                        show={sizesSuccess}
                        delay={1500}
                        autohide
                    >
                        <Toast.Header className="bg-success text-white">
                            <strong className="me-auto">Success</strong>
                        </Toast.Header>
                        <Toast.Body>
                            Company Size update was successful.
                        </Toast.Body>
                    </Toast>
                </div>

                <div className="position-fixed bottom-0 end-0 p-3">
                    <Toast
                        onClose={() => setSizesFailure(false)}
                        show={sizesFailure}
                        delay={1500}
                        autohide
                    >
                        <Toast.Header className="bg-danger text-white">
                            <strong className="me-auto">Failed</strong>
                        </Toast.Header>
                        <Toast.Body>
                            There was an error updating the user. Contact DEV if
                            this issue persists.
                        </Toast.Body>
                    </Toast>
                </div>
            </FalconComponentCard>

            <Modal
                show={showQuestionEdit}
                onHide={handleEditQuestionClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-95vw"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Edit Qualifier Question
                    </Modal.Title>
                    <button className='btn' onClick={handleEditQuestionClose}>Close</button>
                </Modal.Header>
                <Modal.Body>
                    <p>
                    <Form.Control
                        as="textarea"
                        placeholder="Add note"
                        value={selectedQuestion}
                        name="note"
                        onChange={(e) => setSelectedQuestion(e.target.value)}
                    />
                    </p>

                    <p>
                        <button className='btn btn-primary' onClick={() => handleQuestionUpdate()}>Update Question</button>
                    </p>
                </Modal.Body>
            </Modal>

            <Modal show={showPricing}
            onHide={() => setShowPricing(false)}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-95vw"
            aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header>
                <Modal.Title id="example-custom-modal-styling-title">
                    Company Pricing
                </Modal.Title>
                <button className='btn' onClick={() => setShowPricing(false)}>Close</button>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Add company price plan
                </p>
            <p style={{textAlign: 'left'}}>
                <Row>
                <Form.Group as={Col} controlId="pricingname">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Name:
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Pricing plan name"
                        name="pricingname"
                        value={pricingName}
                        onChange={(e) => setPricingName(e.target.value)}
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} controlId="pricingdescription">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Description:
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Description"
                        name="pricingdescription"
                        value={pricingDescription}
                        onChange={(e) => setPricingDescription(e.target.value)}
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} controlId="pricingamount">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Price:
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Amount"
                        name="pricingamount"
                        value={pricingAmount}
                        onChange={(e) => setPricingAmount(e.target.value)}
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} controlId="pricingunit">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Unit:
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Unit"
                        name="pricingunit"
                        value={pricingUnit}
                        onChange={(e) => setPricingUnit(e.target.value)}
                    />
                </Form.Group>
            </Row>

            <Row>
            <Form.Group as={Col} controlId="pricingunit">
                <button className='btn btn-primary' onClick={() => handleAddPricing()}>Save</button>
                </Form.Group>
            </Row>
            </p>
            </Modal.Body>
            </Modal>

            <Modal
                show={showPurchaseItems}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-95vw"
                aria-labelledby="example-custom-modal-styling-title"
            >
            <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Purchase Items
                    </Modal.Title>
                    <button className='btn' onClick={() => setShowPurchaseItems(false)}>Close</button>
                </Modal.Header>
                <Modal.Body>
                   <table className='table table-striped table-bordered'>
                    <thead>
                        <tr>
                            <th>Date created</th>
                            <th>Reference ID</th>
                            <th>Status</th>
                            <th>Buyer Company</th>
                            <th>Demo</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {purchaseItems.map(x => (
                            <tr>
                                <td>{new Date(x.date_created).toLocaleString()}</td>
                                <td>{x.reference_id}</td>
                                <td>{x.status}</td>
                                <td>{x.buyer_company}</td>
                                <td><Link to={`/demo/${x.demo_id}`}>{x.demo_id}</Link></td>

                                <td>
                                    {x.demo_id && (
                                        <>
                                            <span style={{color:'dodgerblue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => handleUnassign(x.reference_id, x.company_package_purchase_id)}>Unassign Demo</span>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                   </table>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CompanyProfile;
