import React, { useState, useEffect } from 'react';
import { Modal, Button, Toast, Form, Row, Card, Col } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import {
    deleteDemo,
    completeDemo,
    updateDemo,
    notifyDemo,
    demoSellerReschedule,
    addTrackingEvent
} from 'api/api';

function DemosModal({ show, setShow, selected, setData, index, action }) {
    const [loaded, setLoaded] = useState(false);
    const [toast, setToast] = useState(false);
    const [failed, setFailed] = useState(false);

    const handleClose = () => setShow(false);

    const handleDelete = async () => {
        const response = await deleteDemo(selected.demo_id);
        console.log(response);
        if (response.success) {
            setShow(false);
            setToast(true);
            setData((items) => {
                return items.filter((item, j) => j !== index);
            });
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleNotify = async () => {
        console.log('notified');
        const response = await notifyDemo(selected.demo_id);
        console.log(response);
        if (response.success) {
            setShow(false);
            setToast(true);
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleSellerReschedule = async () => {
        const response = await demoSellerReschedule(selected.demo_id);
        console.log(response);
        if (response.success) {
            //await addTrackingEvent(selected.demo_id, 'Admin Seller Reschedule');
            setShow(false);
            setToast(true);
            setData((items) => {
                return items.map((item, j) => {
                    if (j === index) {
                        item.seller_status = 'Rescheduled';
                        return item;
                    } else {
                        return item;
                    }
                });
            });
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handlePending = async () => {
        const d = { demo_status_id: 1 };
        const response = await updateDemo(selected.demo_id, d);
        console.log(response);
        if (response.success) {
            setShow(false);
            setToast(true);
            setData((items) => {
                return items.map((item, j) => {
                    if (j === index) {
                        item.demo_status = 'Pending';
                        return item;
                    } else {
                        return item;
                    }
                });
            });
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleUpdate = async () => {
        const response = await completeDemo(selected.demo_id);
        console.log(response);
        if (response.success) {
            setShow(false);
            setToast(true);
            setData((items) => {
                return items.map((item, j) => {
                    if (j === index) {
                        item.demo_status = 'Completed';
                        return item;
                    } else {
                        return item;
                    }
                });
            });
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    // ? these are the relevant status ids for demos
    // demo_status_id
    //1 pending
    //3 declined
    //4 completed

    return (
        <>
            {action === 'Delete' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Delete Demo</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this demo?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => handleDelete()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The demo was successfully deleted.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}
            {action === 'Pending' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Update Demo</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to set this demo to pending?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handlePending()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The demo was successfully updated.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}
            {action === 'Seller Reschedule' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                Set seller status to reschedule
                            </Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to set the seller status to
                            rescheduled? This sends an email to the buyer that
                            the demo was rescheduled and to submit new times.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleSellerReschedule()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                Seller status was succesfully set to
                                'Rescheduled'.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}
            {action === 'Notify' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Send Email</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to send an email to notify
                            both {selected.seller_company_name} and{' '}
                            {selected.buyer_first_name +
                                ' ' +
                                selected.buyer_last_name}{' '}
                            of their upcoming demo?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleNotify()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>The users were notified.</Toast.Body>
                        </Toast>
                    </div>
                </>
            )}
            {action === 'Feedback' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Demo Feedback</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            <Card className="mb-3">
                                <Card.Header>Buyer Feedback</Card.Header>
                                <Card.Body className="bg-light">
                                    <Form>
                                        <Row className="mb-3 g-3">
                                            <Form.Group
                                                as={Col}
                                                lg={6}
                                                controlId="moving_to_proposal"
                                                className="d-flex flex-column justify-content-center align-items-center"
                                            >
                                                <Form.Label>
                                                    Moving to Proposal
                                                </Form.Label>
                                                {selected.buyer_feedback &&
                                                    selected.buyer_feedback
                                                        .moving_to_proposal && (
                                                        <div className="">
                                                            <span className="badge badge-soft-success mb-2 ">
                                                                True
                                                            </span>
                                                        </div>
                                                    )}
                                                {selected.buyer_feedback &&
                                                    !selected.buyer_feedback
                                                        .moving_to_proposal && (
                                                        <div className="">
                                                            <span className="badge badge-soft-danger mb-2 ">
                                                                False
                                                            </span>
                                                        </div>
                                                    )}
                                            </Form.Group>

                                            <Form.Group
                                                as={Col}
                                                lg={6}
                                                controlId="reason"
                                            >
                                                <Form.Label>Reason</Form.Label>
                                                {(!selected ||
                                                    !selected.buyer_feedback) && (
                                                    <p>No reason provided.</p>
                                                )}
                                                {selected.buyer_feedback && (
                                                    <p>
                                                        {
                                                            selected
                                                                .buyer_feedback
                                                                .reason
                                                        }
                                                    </p>
                                                )}
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3 g-3">
                                            <Form.Group
                                                as={Col}
                                                lg={12}
                                                controlId="buyer_feedback"
                                            >
                                                <Form.Label>
                                                    Buyer Feedback
                                                </Form.Label>
                                                <p>
                                                    {!selected.buyer_feedback
                                                        ? 'No feedback.'
                                                        : selected
                                                              .buyer_feedback
                                                              .feedback_text}
                                                </p>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>

                            <Card className="mb-3">
                                <Card.Header>Seller Feedback</Card.Header>
                                <Card.Body className="bg-light">
                                    <Form>
                                        <Row className="mb-3 g-3">
                                            <Form.Group
                                                as={Col}
                                                lg={6}
                                                controlId="moving_to_proposal"
                                                className="d-flex flex-column justify-content-center align-items-center"
                                            >
                                                <Form.Label>
                                                    Moving to Proposal
                                                </Form.Label>
                                                {selected.seller_feedback &&
                                                    selected.seller_feedback
                                                        .moving_to_proposal && (
                                                        <div className="">
                                                            <span className="badge badge-soft-success mb-2 ">
                                                                True
                                                            </span>
                                                        </div>
                                                    )}
                                                {selected.seller_feedback &&
                                                    !selected.seller_feedback
                                                        .moving_to_proposal && (
                                                        <div className="">
                                                            <span className="badge badge-soft-danger mb-2 ">
                                                                False
                                                            </span>
                                                        </div>
                                                    )}
                                            </Form.Group>

                                            <Form.Group
                                                as={Col}
                                                lg={6}
                                                controlId="reason"
                                            >
                                                <Form.Label>Reason</Form.Label>
                                                <p>
                                                    {!selected.seller_feedback
                                                        ? 'No reason provided.'
                                                        : selected
                                                              .seller_feedback
                                                              .reason}
                                                </p>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3 g-3">
                                            <Form.Group
                                                as={Col}
                                                lg={12}
                                                controlId="buyer_feedback"
                                            >
                                                <Form.Label>
                                                    Seller Feedback
                                                </Form.Label>
                                                <p>
                                                    {!selected.seller_feedback
                                                        ? 'No feedback.'
                                                        : selected
                                                              .seller_feedback
                                                              .feedback_text}
                                                </p>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Modal.Body>
                    </Modal>
                </>
            )}
            {action === 'Update' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Update Demo</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to set this demo to completed?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleUpdate()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The demo was successfully updated.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            <div className="position-fixed bottom-0 end-0 p-3">
                <Toast
                    onClose={() => setFailed(false)}
                    show={failed}
                    delay={4000}
                    autohide
                >
                    <Toast.Header className="bg-danger text-white">
                        <strong className="me-auto">Failed</strong>
                    </Toast.Header>
                    <Toast.Body>
                        Their was an error updating the demo. Contact DEV if
                        this issue persists.
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
}

export default DemosModal;
