import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { Link } from 'react-router-dom';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Index from './addSeller';

function AddUser() {
    return (
        <Section className="py-0">
            <Row className="flex-center min-vh-100 py-6">
                <Col sm={10} md={8} lg={8} xl={8} className="col-xxl-8">
                    <Logo />
                    <Card>
                        <AnimatePresence exitBeforeEnter>
                            <Index />
                        </AnimatePresence>
                    </Card>
                </Col>
            </Row>
        </Section>
    );
}

export default AddUser;
