import React, { useState, useEffect } from 'react';
import { getCompanyPlans, getPaymentPlans, addCompanyPlan } from 'api/api';
import { Card, OverlayTrigger, Tooltip, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
// import moment from 'moment';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

function CompanyPlans() {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const [paymentPlans, setPaymentPlans] = useState([]);
    const [showPlanSelect, setShowPlanSelect] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [customPrice, setCustomPrice] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [selectedPaymentPlanId, setSelectedPaymentPlanId] = useState(null);
    let refPrice = React.createRef();

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getCompanyPlans();
                console.log(res, ': getCompanyPlans');
                setData(() => res.data);
            } catch (err) {
                console.warn(err.message, 'this is the error in useEffect');
            } finally {
                setLoaded(() => true);
            }
        };

        async function loadPaymentPlans(){
            let res = await getPaymentPlans();

            if (res.success){
                setPaymentPlans(res.data);
                console.log('payment plans', res.data);
            }
        }

        loadData();
        loadPaymentPlans();
    }, []);

    async function handlePlanSelect(company_id){
        setSelectedCompanyId(company_id);
        setShowPlanSelect(true);
    }

    const loadData = async () => {
        try {
            const res = await getCompanyPlans();
            console.log(res, ': getCompanyPlans');
            setData(() => res.data);
        } catch (err) {
            console.warn(err.message, 'this is the error in useEffect');
        } finally {
            setLoaded(() => true);
        }
    };

    async function setPlan(){
        let data = {company_id: selectedCompanyId, payment_plan_id: selectedPaymentPlanId};
        let cost_per_credit = 0;

        if (refPrice.current.value !== ''){
            cost_per_credit = parseInt(refPrice.current.value) / 5;
        }

       let res = await addCompanyPlan(selectedCompanyId, selectedPaymentPlanId, cost_per_credit);

       if (res.success){
        console.log('company', selectedCompanyId);
        console.log('plan', selectedPaymentPlanId);
        setShowPlanSelect(false);
        setSelectedCompanyId(null);

        loadData();

        toast(
            <div className="text-700">
              <h5 className="text-success fs-0 mb-0">Payment Plan Added!</h5>
              <hr className="my-2" />
            </div>
          );
       }
    }

    async function handlePlanChange(e){
        setSelectedPaymentPlanId(e.target.value);
    }

    async function handleCustomPrice(e){
        setCustomPrice(e.target.value);
    }

    async function handleSubmit(e){
        e.preventDefault();
    }

    // todo: add the is paused flag to the company name
    const columns = [
        {
            accessor: 'name',
            Header: 'Company',
            Cell: (rowData) => {
                const { company_id, name, logo, is_paused } =
                    rowData.row.original;

                if (is_paused) {
                    return (
                        <div
                            className="d-flex align-items-center"
                            style={{ opacity: '.4' }}
                        >
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip id={'tooltip-' + 'top'}>
                                        <strong>Paused</strong>.
                                    </Tooltip>
                                }
                            >
                                <div className="me-2">
                                    <FontAwesomeIcon
                                        icon={faPauseCircle}
                                        size="lg"
                                    />
                                </div>
                            </OverlayTrigger>
                            <div
                                className="me-2"
                                style={{
                                    borderRadius: '50%',
                                    overflow: 'hidden'
                                }}
                            >
                                <img
                                    src={
                                        'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                        'tr:di-@@companylogos@@default-buyer.png/' +
                                        logo
                                    }
                                    alt=""
                                    className="dashboard__company-logo-column1"
                                    style={{ height: '30px', width: '30px' }}
                                />
                            </div>
                            {name + ' '}
                            <Link
                                className="ms-1"
                                to={'/company/' + company_id}
                            >
                                ({company_id})
                            </Link>
                        </div>
                    );
                }

                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-2"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {name + ' '}
                        <Link className="ms-1" to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </div>
                );
            }
        },
        // {
        //     accessor: 'start_date',
        //     Header: 'Start Date',
        //     Cell: (rowData) => {
        //         const { start_date } = rowData.row.original;
        //         const date = moment(start_date)
        //             .local()
        //             .format('MMM Do YY, h:mm a');
        //         return <div>{date}</div>;
        //     }
        // },
        // {
        //     accessor: 'end_date',
        //     Header: 'End Date',
        //     Cell: (rowData) => {
        //         const { end_date } = rowData.row.original;
        //         const date = moment(end_date)
        //             .local()
        //             .format('MMM Do YY, h:mm a');
        //         return <div>{date}</div>;
        //     }
        // },
        {
            accessor: 'short_name',
            Header: 'Payment Plan',
            Cell: (rowData) => {
                const { short_name, company_id } = rowData.row.original;

                if (short_name === 'PAYG') {
                    return (
                        <>
                        <span className="badge badge-soft-danger" onClick={() => handlePlanSelect(company_id)}>
                            {short_name}
                        </span>

                        {showPlanSelect && company_id === selectedCompanyId && (
                            <div>
                                <select className='w-100' value={selectedPaymentPlanId}
                    onChange={(e) => handlePlanChange(e)}>
                            <option>Select plan...</option>
                                    {paymentPlans.map(item => (
                                        <option value={item.payment_plan_id}>{item.name + ' $' + (item.cost_per_credit * 5)}</option>
                                    ))}
                                </select>

                                <div style={{marginTop: "10px"}}>
                                <Form onSubmit={handleSubmit} key='form123'>
                                <Form.Control
                                    type="text"
                                    placeholder="Price per demo (optional)"
                                    name="first_name"
                                    key='samekey'
                                    id='sameid'
                                    ref={refPrice}
                                />
                                </Form>
                                </div>

                                <div style={{marginTop: "10px"}}>
                                    <button className='btn btn-success' onClick={() => setPlan()}>Set Plan</button>
                                </div>
                            </div>
                        )}
                        </>
                    );
                }
                if (short_name === 'PREM') {
                    return (
                        <>
                        <span className="badge badge-soft-warning" onClick={() => handlePlanSelect(company_id)}>
                            {short_name}
                        </span>

                        {showPlanSelect && company_id === selectedCompanyId && (
                            <div>
                                <select className='w-100' value={selectedPaymentPlanId}
                    onChange={(e) => handlePlanChange(e)}>
                            <option>Select plan...</option>
                                    {paymentPlans.map(item => (
                                        <option value={item.payment_plan_id}>{item.name + ' $' + (item.cost_per_credit * 5)}</option>
                                    ))}
                                </select>

                                <div style={{marginTop: "10px"}}>
                                <Form onSubmit={handleSubmit} key='form123'>
                                <Form.Control
                                    type="text"
                                    placeholder="Price per demo (optional)"
                                    name="first_name"
                                    key='samekey'
                                    id='sameid'
                                    ref={refPrice}
                                />
                                </Form>
                                </div>

                                <div style={{marginTop: "10px"}}>
                                    <button className='btn btn-success' onClick={() => setPlan()}>Set Plan</button>
                                </div>
                            </div>
                        )}
                        </>
                    );
                }
                if (short_name === 'GROW') {
                    return (
                        <>
                        <span className="badge badge-soft-info" onClick={() => handlePlanSelect(company_id)}>
                            {short_name}
                        </span>

                        {showPlanSelect && company_id === selectedCompanyId && (
                            <div>
                                <select className='w-100' value={selectedPaymentPlanId}
                    onChange={(e) => handlePlanChange(e)}>
                            <option>Select plan...</option>
                                    {paymentPlans.map(item => (
                                        <option value={item.payment_plan_id}>{item.name + ' $' + (item.cost_per_credit * 5)}</option>
                                    ))}
                                </select>

                                <div style={{marginTop: "10px"}}>
                                <Form onSubmit={handleSubmit} key='form123'>
                                <Form.Control
                                    type="text"
                                    placeholder="Price per demo (optional)"
                                    name="first_name"
                                    key='samekey'
                                    id='sameid'
                                    ref={refPrice}
                                />
                                </Form>
                                </div>

                                <div style={{marginTop: "10px"}}>
                                    <button className='btn btn-success' onClick={() => setPlan()}>Set Plan</button>
                                </div>
                            </div>
                        )}
                        </>
                    );
                }
                if (short_name === 'CUST') {
                    return (
                        <>
                        <span className="badge badge-soft-success" onClick={() => handlePlanSelect(company_id)}>
                            {short_name}
                        </span>

                        {showPlanSelect && company_id === selectedCompanyId && (
                            <div>
                                <select className='w-100' value={selectedPaymentPlanId}
                    onChange={(e) => handlePlanChange(e)}>
                            <option>Select plan...</option>
                                    {paymentPlans.map(item => (
                                        <option value={item.payment_plan_id}>{item.name + ' $' + (item.cost_per_credit * 5)}</option>
                                    ))}
                                </select>

                                <div style={{marginTop: "10px"}}>
                                <Form onSubmit={handleSubmit} key='form123'>
                                <Form.Control
                                    type="text"
                                    placeholder="Price per demo (optional)"
                                    name="first_name"
                                    key='samekey'
                                    id='sameid'
                                    ref={refPrice}
                                />
                                </Form>
                                </div>

                                <div style={{marginTop: "10px"}}>
                                    <button className='btn btn-success' onClick={() => setPlan()}>Set Plan</button>
                                </div>
                            </div>
                        )}
                        </>
                    );
                }

                if (short_name && short_name !== ''){
                    return (
                        <>
                        <span className="badge badge-soft-success" onClick={() => handlePlanSelect(company_id)}>
                            {short_name}
                        </span>

                        {showPlanSelect && company_id === selectedCompanyId && (
                            <div>
                                <select className='w-100' value={selectedPaymentPlanId}
                    onChange={(e) => handlePlanChange(e)}>
                            <option>Select plan...</option>
                                    {paymentPlans.map(item => (
                                        <option value={item.payment_plan_id}>{item.name + ' $' + (item.cost_per_credit * 5)}</option>
                                    ))}
                                </select>

                                <div style={{marginTop: "10px"}}>
                                <Form onSubmit={handleSubmit} key='form123'>
                                <Form.Control
                                    type="text"
                                    placeholder="Price per demo (optional)"
                                    name="first_name"
                                    key='samekey'
                                    id='sameid'
                                    ref={refPrice}
                                />
                                </Form>
                                </div>

                                <div style={{marginTop: "10px"}}>
                                    <button className='btn btn-success' onClick={() => setPlan()}>Set Plan</button>
                                </div>
                            </div>
                        )}
                        </>
                    );
                }
                return (
                    <div>
                        <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => handlePlanSelect(company_id)}>Select Plan</span>

                        {showPlanSelect && company_id === selectedCompanyId && (
                            <div>
                                <select className='w-100' value={selectedPaymentPlanId}
                    onChange={(e) => handlePlanChange(e)}>
                            <option>Select plan...</option>
                                    {paymentPlans.map(item => (
                                        <option value={item.payment_plan_id}>{item.name + ' $' + (item.cost_per_credit * 5)}</option>
                                    ))}
                                </select>

                                <div style={{marginTop: "10px"}}>
                                <Form onSubmit={handleSubmit} key='form123'>
                                <Form.Control
                                    type="text"
                                    placeholder="Price per demo (optional)"
                                    name="first_name"
                                    key='samekey'
                                    id='sameid'
                                    ref={refPrice}
                                />
                                </Form>
                                </div>

                                <div style={{marginTop: "10px"}}>
                                    <button className='btn btn-success' onClick={() => setPlan()}>Set Plan</button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        },
        {
            accessor: 'cost_per_credit',
            Header: 'Cost Per Credit',
            Cell: (rowData) => {
                const { cost_per_credit, custom_cost_per_credit } =
                    rowData.row.original;
                if (custom_cost_per_credit) {
                    return <span>${custom_cost_per_credit}</span>;
                } else {
                    return <span>${cost_per_credit}</span>;
                }
            }
        },
        {
            accessor: 'none',
            Header: 'Cost Per Demo',
            Cell: (rowData) => {
                const { cost_per_credit, custom_cost_per_credit } =
                    rowData.row.original;

                if (custom_cost_per_credit) {
                    return <span>${custom_cost_per_credit * 5}</span>;
                } else {
                    return <span>${cost_per_credit * 5}</span>;
                }
            }
        },
        {
            accessor: 'none1',
            Header: 'Charges',
            Cell: (rowData) => {
                const { company_id } = rowData.row.original;
                return (
                    <>
                        <Link to={`/charges/company/${company_id}`}>View</Link>
                    </>
                );
            }
        }
        // {
        //     accessor: 'is_active',
        //     Header: 'Is Active?'
        // }
    ];

    return (
        <div>
            <Card>
                <Card.Header>
                    <h5>Company Plans</h5>
                </Card.Header>
                <Card.Body className="bg-light">
                    {loaded && data && (
                        <AdvanceTableWrapper columns={columns} data={data}>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </AdvanceTableWrapper>
                    )}
                </Card.Body>
            </Card>

            {showPlanSelect && (
                <>
                    <div></div>


                </>
            )}
        </div>
    );
}

// company_id: 375;
//! company_plan_id: 2;
// date_created: '2022-06-20T18:29:59.936Z';
// end_date: '2022-07-01T00:00:00.000Z';
//! is_active: true;
// payment_plan_id: 3;
// start_date: '2022-06-01T00:00:00.000Z';

export default CompanyPlans;
