import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import React from 'react';
import { Button } from 'react-bootstrap';
import TypeAnimation from 'react-type-animation';

const exampleCode = `  <TypeAnimation
cursor={false}
sequence={[
  'A smarter way to tell about new features. ',
  1000,
  'A smarter way to tell about a new idea.',
  1000,
  'A smarter way to tell about an announcment.',
  1000
  ]}
repeat={Infinity}
wrapper="h3"
className="text-success"
/>`;

const TypedText = () => (
  <>
    <PageHeader
      title="Typed Text"
      description="Falcon-React uses <strong>react-type-animation</strong> for text typing animation."
      className="mb-3"
    >
      <Button
        href="https://github.com/maxeth/react-type-animation"
        target="_blank"
        variant="link"
        size="sm"
        className="ps-0"
      >
        React-type-animation Documentation
        <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
      </Button>
    </PageHeader>

    <FalconComponentCard>
      <FalconComponentCard.Header title="Example" light={false} />
      <FalconComponentCard.Body
        code={exampleCode}
        scope={{ TypeAnimation }}
        language="jsx"
      />
    </FalconComponentCard>
  </>
);

export default TypedText;
