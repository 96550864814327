import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import { getStripePayments } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

function CompletedStripePayments() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getStripePayments();
                console.log(
                    res,
                    'this is the response from the getStripePayments'
                );
                setData(res.data);
            } catch (err) {
                console.warn(err.message, 'this is the error');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    // admin_id: 6972;
    // amount: '500';
    //! amount_in_cents: 50000;
    // company_id: 9749;
    // company_name: 'DocJuris';
    //! currency: 'usd';
    // date_created: '2022-12-22T23:27:08.840Z';
    //! description: '';
    //! is_active: true;
    // receipt_url: 'https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLcVFkTEFZSlNUTXpydjUozM-TnQYyBkGrThPSuzosFkSN0YraPMxyrSfWqDh-jYETqvP-JFT-zFWD6Uljteih-l6U8S2grdxMti0';
    //! reference_id: 'pi_3MHyAJAYJSTMzrv51ml1rgmA';
    //! stripe_account_id: null;
    // stripe_payment_id: 12;

    const columns = [
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <div>No date.</div>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <div>{date}</div>;
            }
        },
        {
            accessor: 'stripe_payment_id',
            Header: 'Payment Id',
            Cell: (rowData) => {
                const { stripe_payment_id, company_id } = rowData.row.original;
                return (
                    <Link
                        to={
                            '/charges-details/' +
                            stripe_payment_id +
                            '/company/' +
                            company_id
                        }
                    >
                        {stripe_payment_id}
                    </Link>
                );
            }
        },
        {
            accessor: 'admin_id',
            Header: 'Admin Id',
            Cell: (rowData) => {
                const { admin_id } = rowData.row.original;
                return <Link to={'/user/' + admin_id}>{admin_id}</Link>;
            }
        },
        {
            accessor: 'company_name',
            Header: 'Company',
            Cell: (rowData) => {
                const { company_id, company_name, logo } = rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {company_name + ' '}
                        <Link className="ms-1" to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'amount',
            Header: 'Amount',
            Cell: (rowData) => {
                const { amount } = rowData.row.original;
                return <span>${amount}</span>;
            }
        },
        {
            accessor: 'receipt_url',
            Header: 'Receipt',
            Cell: (rowData) => {
                const { receipt_url } = rowData.row.original;
                return (
                    <a href={receipt_url} target="_blank">
                        Receipt
                    </a>
                );
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Completed Stipe Payments"
                    titleTag="h2"
                    noPreview
                />
                {!loading && data && (
                    <AdvanceTableWrapper columns={columns} data={data} sortable>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                )}
                {loading && <div>Loading...</div>}
            </FalconComponentCard>
        </motion.div>
    );
}

export default CompletedStripePayments;
