import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Form, Card, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getSellerFeedback, getCoupons, addCoupon, getSellersForDropdown, getCompanyPackagePurchases, getCompanyPackages, createPackagePurchase, getCompanyPackagePurchaseItems, unassignPackagePurchaseItemDemo} from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

function PackagePurchases() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [sellers, setSellers] = useState(null);
    const [packages, setPackages] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [modifiedPrice, setModifiedPrice] = useState(null);
    const [showPurchaseItems, setShowPurchaseItems] = useState(false);
    const [purchaseItems, setPurchaseItems] = useState([]);
    const initialForm = {
        company: '',
        company_id: '',
        website: '',
        company_size_id: '',
        industry_id: '',
        email: '',
        first_name: '',
        last_name: '',
        company_id: ''
    };
    const [formData, setFormData] = useState({ ...initialForm });

    async function loadPurchases(){
        let res = await getCompanyPackagePurchases();

        if (res.success){
            setPurchases(res.data);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const res = await getCoupons();
                console.log(res);
                setData(res.data);
            } catch (err) {
                console.warn('This is the error: ', err.message);
            } finally {
                setLoading(false);
            }
        };

        async function loadPackages(){
            let res = await getCompanyPackages();

            if (res.success){
                let mapped = res.data.map(item => {
                    return {value: item.company_package_id, label: item.name + ' (' + item.number_of_demos + ' demos)'}
                });

                setPackages(mapped);
            }
        }

        const loadSellers = async () => {
            try {
                const res = await getSellersForDropdown();
                setSellers(res);
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        };

        loadSellers();
        loadData();
        loadPurchases();
        loadPackages();
    }, []);

    async function handleSubmit(e){
        e.preventDefault();

        let res = await createPackagePurchase(selectedPackage, selectedCompanyId, selectedUserId, modifiedPrice);

        if (res.success){
            alert('Purchase created');
            await loadPurchases();
        }

        else{
            alert("Error");
        }
    }

    async function handlePackageChange(e){
       setSelectedPackage(e);
    }

    async function showItems(id){
        let res = await getCompanyPackagePurchaseItems(id);

        if (res.success){
            setPurchaseItems(res.data);
            setShowPurchaseItems(true);
        }
    }

    async function handleUnassign(reference_id, id){
        let res = await unassignPackagePurchaseItemDemo(reference_id);

        if (res.success){
            showItems(id);
        }
    }

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    const columns = [
        {
            accessor: 'company_package_purchase_id',
            Header: 'Id'
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'date_paid',
            Header: 'Date paid',
            Cell: (rowData) => {
                const { date_paid } = rowData.row.original;
                if (!date_paid) {
                    return <span>No date.</span>;
                }
                const date = moment(date_paid)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: (rowData) => {
                const { status } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        {status === 'Pending' && (
                            <span>Pending</span>
                        )}
                        
                        {status === 'Paid' && (
                            <span className='badge badge-soft-success rounded-pill'>Paid</span>
                        )}
                    </div>
                );
            }
        },

        {
            accessor: 'amount',
            Header: 'Amount',
            Cell: (rowData) => {
                const { cost_per_demo, number_of_demos } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        ${cost_per_demo * number_of_demos}
                    </div>
                );
            }
        },

        {
            accessor: 'company_name',
            Header: 'Company',
            Cell: (rowData) => {
                const { company_name, logo, company_id } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                       <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div> {company_name} <span><Link to={"/company/" + company_id} target='_blank'>{company_id}</Link></span>
                    </div>
                );
            }
        },
        {
            accessor: 'reference_id',
            Header: 'Reference ID',
            Cell: (rowData) => {
                const { reference_id } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <a href={"https://www.leadrpro.com/select-plan-checkout?cartid=" + reference_id} target='_blank'>{reference_id}</a>
                    </div>
                );
            }
        },
        {
            accessor: 'number_of_demos',
            Header: 'Number of demos',
            Cell: (rowData) => {
                const { number_of_demos, company_package_purchase_id } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <a href='javascript:void(0)' onClick={() => showItems(company_package_purchase_id)}>{number_of_demos}</a>
                    </div>
                );
            }
        },
        {
            accessor: 'package_extra_demos',
            Header: 'Extra demos',
            Cell: (rowData) => {
                const { package_extra_demos } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        {package_extra_demos}
                    </div>
                );
            }
        },
        {
            accessor: 'cost_per_demo',
            Header: 'Cost per demo',
            Cell: (rowData) => {
                const { cost_per_demo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        {cost_per_demo}
                    </div>
                );
            }
        },
        {
            accessor: 'modified_price',
            Header: 'Modified price per demo',
            Cell: (rowData) => {
                const { modified_price } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        {modified_price}
                    </div>
                );
            }
        }
    ];

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            {/* Company Info Card */}
            <Card className="mb-3">
                <FalconCardHeader title={'Create Purchase Order'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleSubmit}>
                        {/* Name & Website */}
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="type">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Package:
                                </Form.Label>
                                <Select
                                    closeMenuOnSelect={true}
                                    options={packages}
                                    placeholder={'Select a package'}
                                    classNamePrefix="react-select"
                                    onChange={(e) => handlePackageChange(e.value)}
                                    className="form-control p-0"
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Company ID:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Company ID"
                                    name="company_id"
                                    value={selectedCompanyId}
                                    onChange={(e) => setSelectedCompanyId(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    User ID:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="User ID"
                                    name="user_id"
                                    value={selectedUserId}
                                    onChange={(e) => setSelectedUserId(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Modified Price Per Demo:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Modified Price"
                                    name="modified_price"
                                    value={modifiedPrice}
                                    onChange={(e) => setModifiedPrice(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                      
                        {/* Submit Button */}
                        <div className="text-end">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Create
                            </motion.button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Package Purchases"
                    titleTag="h2"
                    noPreview
                />

                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {!loading && purchases && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={purchases}
                            sortable
                            pagination
                            perPage={100}
                        >
                            <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {loading && <h5>Loading...</h5>}
                </FalconComponentCard.Body>
            </FalconComponentCard>

            <Modal
                show={showPurchaseItems}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-95vw"
                aria-labelledby="example-custom-modal-styling-title"
            >
            <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Purchase Items
                    </Modal.Title>
                    <button className='btn' onClick={() => setShowPurchaseItems(false)}>Close</button>
                </Modal.Header>
                <Modal.Body>
                   <table className='table table-striped table-bordered'>
                    <thead>
                        <tr>
                            <th>Date created</th>
                            <th>Reference ID</th>
                            <th>Status</th>
                            <th>Demo</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {purchaseItems.map(x => (
                            <tr>
                                <td>{new Date(x.date_created).toLocaleString()}</td>
                                <td>{x.reference_id}</td>
                                <td>{x.status}</td>
                                <td><Link to={`/demo/${x.demo_id}`}>{x.demo_id}</Link></td>

                                <td>
                                    {x.demo_id && (
                                        <>
                                            <span style={{color:'dodgerblue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => handleUnassign(x.reference_id, x.company_package_purchase_id)}>Unassign Demo</span>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                   </table>
                </Modal.Body>
            </Modal>
        </motion.div>
    );
}

export default PackagePurchases;
