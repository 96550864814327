import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getSellerCategories, updateCategoryNew } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Row, Col, Dropdown, Button } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

function CategoriesDashboard() {
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const loadCategories = () => {
            try {
                setLoaded(false);
                getSellerCategories().then(res => setData(res.data));
            } catch (err){
                console.warn(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        }
        loadCategories();
    }, []);

    async function handleUpdateSlug(val, category_id){
        let res = await updateCategoryNew(category_id, {slug: val});

        if (res.success){
            alert("Updated");
        }
    }
    const columns = [
        {
            accessor: 'category_id',
            Header: 'Category Id',
            Cell: (rowData) => {
                const { category_id } = rowData.row.original;
                return (
                    <Link to={'/category/' + category_id}>
                        {category_id}
                    </Link>
                )
            }
        },
        {
            accessor: 'name',
            Header: 'Category'
        },
        {
            accessor: 'short_description',
            Header: 'Short Description'
        },

        {
            accessor: 'description',
            Header: 'Description'
        },

        {
            accessor: 'slug',
            Header: 'URL Slug'

        },

        {
            accessor: 'company_count',
            Header: 'Company Count'
        },
    ]
    // category_id: 418
    // company_count: "0"
    // date_created: "2022-03-13T13:10:45.573Z"
    // is_active: true
    // name: "3D Design Software"
    return (
        <FalconComponentCard >
            <FalconComponentCard.Header title='Categories' titleTag='h3' noPreview/>
            <FalconComponentCard.Body
                language='jsx'
                noInline
                noLight
                className='bg-light'
            >
                {(data && loaded) &&
                    <AdvanceTableWrapper
                        columns={columns}
                        data={data}
                        sortable
                        pagination
                        perPage={50}
                    >
                        <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                        <AdvanceTable
                            table
                            headerClassName='bg-200 text-900 text-nowrap align-middle'
                            tableProps={{
                                bordered: true,
                                striped: true,
                                class: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className='mt-3'>
                            <AdvanceTableFooter
                                rowCount={data.length}
                                table
                                rowInfo
                                navButtons
                            />
                        </div>
                    </AdvanceTableWrapper>
                }
            </FalconComponentCard.Body>
        </FalconComponentCard>
    );
}

export default CategoriesDashboard;