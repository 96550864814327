import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getCategories, updateCategoryNew, getAffiliates, addAffiliate } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Dropdown, Button } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { Card, Col, Form, Row, Toast, ListGroup } from 'react-bootstrap';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';

function Affiliates() {
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [affiliateUrl, setAffiliateUrl] = useState('');
    const [ctaText, setCtaText] = useState('');
    const [logo, setLogo] = useState('');
    let inputRefTest = useRef();

    async function loadAffiliates(){
        try {
            setLoaded(false);
            let res = await getAffiliates();

            if (res.success){
                setData(res.data);
            }
        } catch (err){
            console.warn(JSON.stringify(err));
        } finally {
            setLoaded(true);
        }
    }

    useEffect(() => {
        loadAffiliates();
    }, []);

    async function handleSaveAffiliate(){
        let res = await addAffiliate(name, description, ctaText, affiliateUrl, logo);

        if (res.success){
            loadAffiliates();
            setName('');
            setDescription('');
            setCtaText('');
            setAffiliateUrl('');
            setLogo('');
        }
    }

    const onUploadProgress = (progress) => {
        console.log('Progress', progress);
    };
    const onUploadStart = (evt) => {
        console.log('Start', evt);
    };

    const onError = (evt) => {
        console.log('Error', evt);
    };

    const onSuccess = async (e) => {
        setLogo(e.name);
    };

    const columns = [
        {
            accessor: 'affiliate_id',
            Header: 'Affiliate Id',
            Cell: (rowData) => {
                const { affiliate_id } = rowData.row.original;
                return (
                    <Link to={'/affiliates/' + affiliate_id}>
                        {affiliate_id}
                    </Link>
                )
            }
        },
        {
            accessor: 'logo',
            Header: 'Logo',
            Cell: (rowData) => {
                const { logo } = rowData.row.original;
                return (
                    <img style={{height: '50px'}} src={'https://ik.imagekit.io/ivgrhmd4k/affiliates/' + logo} />
                )
            }
        },
        {
            accessor: 'name',
            Header: 'Name'
        },

        {
            accessor: 'cta_text',
            Header: 'CTA Text'
        },

        {
            accessor: 'affiliate_url',
            Header: 'Affiliate URL'
        }
    ]

    return (
        <FalconComponentCard >
            <FalconComponentCard.Header title='Affiliates' titleTag='h3' noPreview/>
            <FalconComponentCard.Body
                language='jsx'
                noInline
                noLight
                className='bg-light'
            >
                <div style={{marginBottom: '30px'}}>
                <Form.Group as={Col} controlId="name">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Name
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder="Name"
                        value={name}
                        name="slug"
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="description">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Description
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder="Name"
                        value={description}
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="cta_text">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        CTA Text
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder="CTA Text"
                        value={ctaText}
                        name="cta_text"
                        onChange={(e) => setCtaText(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="affiliate_url">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Affiliate URL
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder="Affiliate URL"
                        value={affiliateUrl}
                        name="affiliate_url"
                        onChange={(e) => setAffiliateUrl(e.target.value)}
                    />
                </Form.Group>

                <div className="d-flex flex-column">
                <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Logo: {logo}
                    </Form.Label>
                    <IKContext
                        publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs="
                        urlEndpoint="https://ik.imagekit.io/ivgrhmd4k"
                        authenticationEndpoint={
                            process.env
                                .REACT_APP_API_BASE_URL +
                            '/imagekit/auth'
                        }
                    >
                        <IKUpload
                            fileName="logo.jpg"
                            tags={[
                                'sample-tag1',
                                'sample-tag2'
                            ]}
                            customCoordinates={
                                '10,10,10,10'
                            }
                            isPrivateFile={false}
                            useUniqueFileName={true}
                            responseFields={['tags']}
                            folder={'/affiliates'}
                            onError={onError}
                            onSuccess={onSuccess}
                            onUploadProgress={
                                onUploadProgress
                            }
                            onUploadStart={onUploadStart}
                            style={{ display: 'none' }} // hide the default input and use the custom upload button
                            inputRef={inputRefTest}
                        />
                        {inputRefTest && (
                            <button
                                onClick={() =>
                                    inputRefTest.current.click()
                                }
                                className="btn btn-success rounded-pill btn-sm mt-3"
                            >
                                
                                Upload
                            </button>
                        )}
                        {/* {ikUploadRefTest && (
                            <button
                                onClick={() =>
                                    ikUploadRefTest.current.abort()
                                }
                                className="btn btn-falcon-secondary rounded-pill btn-sm"
                            >
                                Cancel
                            </button>
                        )} */}
                    </IKContext>
                </div>

                    <div style={{marginTop: '20px'}}>
                        <button className='btn btn-primary' onClick={() => handleSaveAffiliate()}>Add Affiliate</button>
                    </div>
                </div>

                {(data && loaded) &&
                    <AdvanceTableWrapper
                        columns={columns}
                        data={data}
                        sortable
                        pagination
                        perPage={50}
                    >
                        <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                        <AdvanceTable
                            table
                            headerClassName='bg-200 text-900 text-nowrap align-middle'
                            tableProps={{
                                bordered: true,
                                striped: true,
                                class: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className='mt-3'>
                            <AdvanceTableFooter
                                rowCount={data.length}
                                table
                                rowInfo
                                navButtons
                            />
                        </div>
                    </AdvanceTableWrapper>
                }
            </FalconComponentCard.Body>
        </FalconComponentCard>
    );
}

export default Affiliates;