import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getMessages } from 'api/api';
import moment from 'moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

function Messages() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getMessages().then((res) =>
                    setData(res.data)
                );
                console.log(res, 'this is the response from getMessages');
            } catch (err) {
                console.log(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const columns = [
        {
            accessor: 'demo_id',
            Header: 'Demo Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span className="text-nowrap">{date}</span>;
            }
        },
        // {
        //     accessor: 'registered_user_id',
        //     Header: 'User Id',
        //     Cell: rowData => {
        //         const { registered_user_id } = rowData.row.original
        //         return (
        //             <Link to={'/user/' + registered_user_id}>
        //                 {registered_user_id}
        //             </Link>
        //         )
        //     }
        // },
        {
            accessor: 'is_read',
            Header: 'Read?',
            Cell: (rowData) => {
                const { is_read } = rowData.row.original;

                if (is_read) {
                    return (
                        <span className="badge badge-soft-success">Yes</span>
                    );
                } else {
                    return <span className="badge badge-soft-warning">No</span>;
                }
            }
        },
        {
            accessor: 'none',
            Header: 'User',
            Cell: (rowData) => {
                const { email, first_name, registered_user_id } =
                    rowData.row.original;
                return (
                    <span className="text-nowrap">
                        {first_name + ' - ' + email + ' ('}
                        <Link to={'/user/' + registered_user_id}>
                            {registered_user_id}
                        </Link>
                        )
                    </span>
                );
            }
        },
        {
            accessor: 'message_text',
            Header: 'Message',
            Cell: (rowData) => {
                const { message_text } = rowData.row.original;
                return <span className="text-start">{message_text}</span>;
            }
        }
    ];

    return (
        <Card>
            <Card.Header>
                <h5>Messages</h5>
            </Card.Header>
            <Card.Body>
                {!loading && (
                    <AdvanceTableWrapper columns={columns} data={data}>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 align-middle text-center"
                            rowClassName="align-middle text-center"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                )}
                {loading && <h6>Loading...</h6>}
            </Card.Body>
        </Card>
    );
}

export default Messages;
