import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { faCcStripe } from '@fortawesome/free-brands-svg-icons';
import { motion } from 'framer-motion/dist/framer-motion';
import { Card, Col, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import {
    getCompanyChargesDetails,
    getChargesCompanyInfo,
    createStripePayment
} from 'api/api';

import Avatar from 'components/common/Avatar';
import ProfileBanner from 'components/pages/user/ProfileBanner';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CompanyChargesModal from './CompanyChargesModal';
// import PendingDemosDashboard from '../demos/PendingDemosDashboard';

function CompanyChargesDetails() {
    const { companyId } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [demos, setDemos] = useState(null);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [show, setShow] = useState(false);
    const editRef = useRef();
    const [amount, setAmount] = useState(null);
    const [notes, setNotes] = useState('');
    const [editing, setEditing] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getCompanyChargesDetails(companyId);
                const companyRes = await getChargesCompanyInfo(companyId);
                // console.log(res, 'this is the res');
                // console.log(companyRes, 'this is the company details');
                setData(res.data);
                setCompanyDetails(companyRes.data);
                setAmount(companyRes.data.charge_amount);
            } catch (err) {
                console.log(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [companyId]);

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        onExit: {
            opacity: 0,
            transition: { duration: 1 }
        }
    };

    const columns = [
        {
            accessor: 'demo_id',
            Header: 'Demo Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },
        // {
        //     accessor: 'date_created',
        //     Header: 'Date Created',
        //     Cell: (rowData) => {
        //         const { date_created } = rowData.row.original;
        //         if (!date_created) {
        //             return <span>No date.</span>;
        //         }
        //         const date = moment(date_created)
        //             .local()
        //             .format('MMM Do YY, h:mm a');
        //         return <span>{date}</span>;
        //     }
        // },
        {
            accessor: 'scheduled_date_time',
            Header: 'Scheduled Date & Time',
            Cell: (rowData) => {
                const { scheduled_date_time, reschedule_date_time } =
                    rowData.row.original;
                if (!scheduled_date_time) {
                    return <span>Not scheduled.</span>;
                }
                if (reschedule_date_time) {
                    const date = moment(reschedule_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                } else {
                    const date = moment(scheduled_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'buyer_first_name',
            Header: 'Buyer Name',
            Cell: (rowData) => {
                const { first_name, last_name, buyer_id } =
                    rowData.row.original;
                return (
                    <span>
                        {first_name + ' ' + last_name}
                        <Link to={'/user/' + buyer_id}> ({buyer_id})</Link>
                    </span>
                );
            }
        },
        {
            accessor: 'buyer_company_name',
            Header: 'Buyer Comapany',
            Cell: (rowData) => {
                const { buyer_company_name } = rowData.row.original;
                return <span>{buyer_company_name}</span>;
            }
        },
        {
            accessor: 'demo_cost',
            Header: 'Cost',
            Cell: (rowData) => {
                const { demo_cost } = rowData.row.original;
                return <span>${demo_cost}</span>;
            }
        }
    ];

    const handleChange = (e) => {
        console.log(e.target.value, 'this is the value in the handlechange');
        setAmount(e.target.value);
    };

    const handleEditClick = () => {
        setEditing(() => !editing);
        editRef.current.focus();
    };

    const handleChargeClick = async () => {
        let demos = data.map(({ demo_id }) => demo_id);
        console.log(demos);
        setDemos(() => [...demos]);
        // setAmount(() => companyDetails.charge_amount);
        setShow(true);
    };

    // console.warn(
    //     companyDetails?.payment_plan.custom_cost_per_credit,
    //     'this is the custom cost per credit'
    // );
    // console.warn(
    //     companyDetails?.payment_plan.cost_per_credit,
    //     'this the cost per credit'
    // );

    return (
        <motion.div
            key="settings"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>

            <ProfileBanner className="mb-3">
                <ProfileBanner.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && companyDetails && (
                        <>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center justify-content-center flex-column me-4">
                                        <Avatar
                                            src={
                                                'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                                'tr:di-@@companylogos@@default-buyer.png/' +
                                                companyDetails.logo
                                            }
                                            rounded="circle"
                                            size="4xl"
                                        />
                                    </div>
                                    <h2 className="m-0">
                                        #{companyId} - {companyDetails.name}
                                    </h2>
                                </div>
                            </div>
                            <Row>
                                <Form.Group
                                    as={Col}
                                    lg={4}
                                    controlId="payment_plan"
                                >
                                    <Form.Label
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Payment Plan:
                                    </Form.Label>
                                    <div>
                                        {companyDetails.payment_plan.short_name !== '' && (
                                            <span className="badge badge-soft-danger">
                                                {
                                                    companyDetails.payment_plan
                                                        .short_name
                                                }
                                            </span>
                                        )}
                                    </div>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    lg={4}
                                    controlId="cost_per_demo"
                                >
                                    <Form.Label
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Cost Per Demo:
                                    </Form.Label>
                                    <div>
                                        {companyDetails.payment_plan
                                            .short_name === 'CUST' && (
                                            <span>
                                                $
                                                {parseInt(
                                                    companyDetails.payment_plan
                                                        .custom_cost_per_credit
                                                ) * 5}
                                            </span>
                                        )}
                                        {companyDetails.payment_plan
                                            .short_name !== 'CUST' && (
                                            <span>
                                                $
                                                {parseInt(
                                                    companyDetails.payment_plan
                                                        .cost_per_credit
                                                ) * 5}
                                            </span>
                                        )}
                                    </div>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    lg={4}
                                    controlId="cost_per_credit"
                                >
                                    <Form.Label
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Cost Per Credit:
                                    </Form.Label>
                                    <div>
                                        {companyDetails.payment_plan
                                            .short_name === 'CUST' && (
                                            <span>
                                                $
                                                {parseInt(
                                                    companyDetails.payment_plan
                                                        .custom_cost_per_credit
                                                )}
                                            </span>
                                        )}
                                        {companyDetails.payment_plan
                                            .short_name !== 'CUST' && (
                                            <span>
                                                $
                                                {parseInt(
                                                    companyDetails.payment_plan
                                                        .cost_per_credit
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </Form.Group>
                            </Row>
                        </>
                    )}
                </ProfileBanner.Body>
            </ProfileBanner>

            <Card className="mb-3">
                <Card.Header>
                    <h4>Charges</h4>
                </Card.Header>
                <Card.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && data && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </AdvanceTableWrapper>
                    )}
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && companyDetails && (
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <Form.Label
                                    className="d-flex me-2 mb-0"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    Total Charges:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="No amount found."
                                    value={amount}
                                    name="amount"
                                    onChange={handleChange}
                                    disabled={editing ? false : true}
                                    ref={editRef}
                                />
                                <button
                                    onClick={() => handleEditClick()}
                                    className="btn btn-falcon-secondary btn-sm"
                                >
                                    {editing ? 'Done' : 'Edit'}
                                </button>
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="btn btn-falcon-success d-flex align-items-end"
                                onClick={() => handleChargeClick()}
                                disabled={amount < 0 ? true : false}
                            >
                                Charge{' '}
                                <FontAwesomeIcon
                                    icon={faCcStripe}
                                    className="fa-lg ms-1"
                                />
                            </motion.button>
                        </div>
                    )}
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Body>
                    <Form.Group as={Col} lg={12} controlId="notes">
                        <Form.Label style={{ textDecoration: 'underline' }}>
                            Notes:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Notes"
                            value={notes}
                            name="Notes"
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </Form.Group>
                </Card.Body>
            </Card>

            {/* Modal */}
            <CompanyChargesModal
                show={show}
                setShow={setShow}
                data={companyDetails}
                demos={demos}
                amount={amount}
                setAmount={setAmount}
                notes={notes}
            />
        </motion.div>
    );
}

export default CompanyChargesDetails;

// company_id: 9608;
// company_plan_id: 29;
// cost_per_credit: 50;
// custom_cost_per_credit: null;
// date_created: '2022-11-29T13:34:07.987Z';
// end_date: '2022-11-29T13:34:07.987Z';
// is_active: true;
// is_annual: false;
// is_paused: false;
// is_recurring: true;
// logo: 'logo_xfyzKDlca.jpg';
// name: 'Expert Marketing Advisors';
// payment_plan: 'Pay as you go';
// payment_plan_id: 3;
// short_name: 'PAYG';
// start_date: '2022-11-29T13:34:07.987Z';
