import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Form, Row, ListGroup } from 'react-bootstrap';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { toast } from 'react-toastify';
import DemoProfileModal from './DemoProfileModal';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import NewSellerDropdown from './NewSellerDropdown';
import StarRating from 'components/common/StarRating';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { deleteDemo, completeDemo, updateDemo, getDemoNotes, addDemoNote, deleteDemoNote, addDemoMeetingLink, updateDemoInvite, addDemoTag, removeDemoTag, getDemoTags } from 'api/api';

function DemoProfilePage({ demo, setDemo, show, setShow, action, setAction }) {
    const { seller_feedback, buyer_feedback, seller_feedback_criteria, buyer_feedback_criteria } = demo;
    const [selected, setSelected] = useState(null);
    let refCost = React.createRef();
    const [notes, setNotes] = useState([]);
    const [showAllNotes, setShowAllNotes] = useState(false);
    const [note,setNote] = useState('');
    const [meetingLink, setMeetingLink] = useState(demo.demo_meeting_link);
    const [question, setQuestion] = useState(demo.qualification_question);
    const [tags, setTags] = useState(null);
    const [tagsLoaded, setTagsLoaded] = useState(false);
    const demoTags = ['buyer no show', 'seller no show', 'cancelled'];

    const eventsColumns = [
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'event_name',
            Header: 'Event'
        }
    ];

    const messageColumns = [
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'scheduled_date',
            Header: 'Scheduled Date',
            Cell: (rowData) => {
                const { scheduled_date } = rowData.row.original;
                if (!scheduled_date) {
                    return <span>No date.</span>;
                }
                const date = moment(scheduled_date)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'phone_number',
            Header: 'Phone Number'
        },
        {
            accessor: 'group_id',
            Header: 'Group Id'
        },
        {
            accessor: 'identifier',
            Header: 'Identifier'
        },
        {
            accessor: 'status',
            Header: 'Status'
        }
    ];

    const demoMessagesColumns = [
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'none',
            Header: 'User',
            Cell: (rowData) => {
                const { first_name, last_name, registered_user_id } =
                    rowData.row.original;
                return (
                    <span className="text-nowrap">
                        {first_name + '  ' + last_name}
                        <Link to={'/user/' + registered_user_id}>
                            ({registered_user_id})
                        </Link>
                    </span>
                );
            }
        },
        {
            accessor: 'is_read',
            Header: 'Read?',
            Cell: (rowData) => {
                const { is_read } = rowData.row.original;

                if (is_read) {
                    return (
                        <span className="badge badge-soft-success">Yes</span>
                    );
                } else {
                    return <span className="badge badge-soft-warning">No</span>;
                }
            }
        },
        {
            accessor: 'message_text',
            Header: 'Message',
            Cell: (rowData) => {
                const { message_text } = rowData.row.original;
                return <span className="text-start">{message_text}</span>;
            }
        }
    ];

    const handleDelete = () => {
        setAction('Delete');
        setShow(true);
    };

    async function handleQuestionSubmit(){
        if (question.length > 0){
            let res= await updateDemoInvite(demo.demo_invite_id, question);

            toast(
                <div className="text-700">
                  <h5 className="text-success fs-0 mb-0">Question added!</h5>
                  <hr className="my-2" />
                </div>
              );
            //alert('works');
        }
    }

    async function handleCostSubmit(e){
        e.preventDefault();
        let val = refCost.current.value;

        const d = { seller_cost: val };
        const response = await updateDemo(demo.demo_id, d);

        toast(
            <div className="text-700">
              <h5 className="text-success fs-0 mb-0">Cost of demo updated!</h5>
              <hr className="my-2" />
            </div>
          );
       // alert(val);
    }

    async function loadNotes(){
        try {
            //setLoaded(false);
            const res = await getDemoNotes(demo.demo_id);
            setNotes(res.data);
          //  setAvailableBuyers(res.data.available_buyers);
        } catch (err) {
            console.log(JSON.stringify(err));
        } finally {
           // setLoaded(true);
        }
    };

    async function loadDemoTags(){
        try {
            //setLoaded(false);
            const res = await getDemoTags(demo.demo_id);
            setTags(res.data);
            setTagsLoaded(true);
            console.log('tags', res.data);
          //  setAvailableBuyers(res.data.available_buyers);
        } catch (err) {
            console.log(JSON.stringify(err));
        } finally {
           // setLoaded(true);
        }
    };

    useEffect(() => {
        loadNotes();
        loadDemoTags();
    }, []);

    async function handleNoteSubmit(e){
        e.preventDefault();
        if (note.length > 0){
            let res = await addDemoNote(demo.demo_id, note, false);

            if (res.success){
                await loadNotes();
                setNote('');
            }
        }

        else{
            alert("Must enter a valid note");
        }
    }

    async function handleDeleteNote(demo_note_id){
        try {
            let res = await deleteDemoNote(demo_note_id);

            if (res.success){
                await loadNotes();
            }
        } catch (error) {
            
        }
    }

    async function handleSaveLink(){
       // alert('save');
       let res = await addDemoMeetingLink(demo.demo_id, meetingLink);

       if (res.success){
        toast(
            <div className="text-700">
              <h5 className="text-success fs-0 mb-0">Meeting link updated!</h5>
              <hr className="my-2" />
            </div>
          );
       }
    }

    async function handleAddDemoTag(tag){
        let res = await addDemoTag(demo.demo_id, tag);

        await loadDemoTags();
    }

    async function handleRemoveDemoTag(tag){
        let res = await removeDemoTag(demo.demo_id, tag);

        await loadDemoTags();
    }

    return (
        <>

            {/* Demo Info */}
            <FalconComponentCard className="mb-3">
                <Card.Header>
                    <Row className="d-flex justify-content-between">
                        <h5>Demo Status &amp; Time</h5>
                    </Row>
                </Card.Header>
                <FalconComponentCard.Body
                    className="bg-light"
                    language="jsx"
                    noInline
                    noLight
                >
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={3} controlId="date_created">
                            <Form.Label>
                                <u>Date Created:</u>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Date created."
                                name="date_created"
                                value={moment(demo.date_created)
                                    .local()
                                    .format('MMM Do YY, h:mm a')}
                                plaintext
                                readOnly
                            />
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            lg={3}
                            controlId="scheduled_date_time"
                        >
                            <Form.Label>
                                <u>Scheduled Date (In Local Time):</u>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Date scheduled."
                                name="scheduled_date_time"
                                value={
                                    demo.reschedule_date_time
                                        ? moment(demo.reschedule_date_time)
                                              .local()
                                              .format('MMM Do YY, h:mm a')
                                        : moment(demo.scheduled_date_time)
                                              .local()
                                              .format('MMM Do YY, h:mm a')
                                }
                                plaintext
                                readOnly
                            />
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            lg={3}
                            controlId="demo_status"
                            className="d-flex flex-column justify-content-center align-items-center"
                        >
                            <Form.Label>
                                <u>Demo Status:</u>
                            </Form.Label>
                            {demo.demo_status === 'Completed' && (
                                <div className="">
                                    <span className="badge badge-soft-success mb-2 ">
                                        Completed
                                    </span>
                                </div>
                            )}
                            {demo.demo_status === 'Pending' && (
                                <div className="">
                                    <span className="badge badge-soft-warning mb-2 ">
                                        Pending
                                    </span>
                                </div>
                            )}
                            {demo.demo_status === 'Declined' && (
                                <div className="">
                                    <span className="badge badge-soft-danger mb-2 ">
                                        Declined
                                    </span>
                                </div>
                            )}

                            {demo.demo_status === 'Expired' && (
                                <div className="">
                                    <span className="badge badge-soft-danger mb-2 ">
                                        Expired
                                    </span>
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            lg={3}
                            controlId="interest_level"
                            className="d-flex flex-column justify-content-center align-items-center"
                        >
                            <Form.Label>
                                <u>Interest Level:</u>
                            </Form.Label>
                            {demo.interest_level && (
                                <div>
                                    <span>{demo.interest_level}</span>
                                </div>
                            )}
                            {!demo.interest_level && (
                                <div>
                                    <span>
                                        <i>N/A</i>
                                    </span>
                                </div>
                            )}
                        </Form.Group>

                        {demo.has_strike && (
                                <Form.Group
                                as={Col}
                                lg={6}
                                controlId="payout_amount"
                            >
                                <Form.Label>
                                    <u>Has Strike</u>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Payout Amount"
                                    name="payout_amount"
                                    value={`⚾ ${demo.strike_reason}`}
                                    plaintext
                                    readOnly
                                />
                            </Form.Group>
                            )}
                    </Row>
                    {demo.demo_payout[0] && (
                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={3}
                                controlId="date_created"
                            >
                                <Form.Label>
                                    <u>Date Requested:</u>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Date requested."
                                    name="date_created"
                                    value={moment(
                                        demo.demo_payout[0].date_created
                                    )
                                        .local()
                                        .format('MMM Do YY, h:mm a')}
                                    plaintext
                                    readOnly
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={3}
                                controlId="payout_status"
                            >
                                <Form.Label>
                                    <u>Status:</u>
                                </Form.Label>
                                {demo.demo_payout[0].status === 'Paid' && (
                                    <div className="">
                                        <span className="badge badge-soft-success mb-2 ">
                                            Paid
                                        </span>
                                    </div>
                                )}
                                {demo.demo_payout[0].status === 'Pending' && (
                                    <div className="">
                                        <span className="badge badge-soft-warning mb-2 ">
                                            Pending
                                        </span>
                                    </div>
                                )}
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={3}
                                controlId="payout_amount"
                            >
                                <Form.Label>
                                    <u>Payout Amount</u>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Payout Amount"
                                    name="payout_amount"
                                    value={`$${demo.demo_payout[0].amount}`}
                                    plaintext
                                    readOnly
                                />
                            </Form.Group>
                        </Row>
                    )}
                </FalconComponentCard.Body>
            </FalconComponentCard>

            <FalconComponentCard className="mb-3">
                <Card.Header>
                    <Row className="d-flex justify-content-between">
                        <h5>Demo Tags</h5>

                        <div style={{marginTop: '20px'}}>
                            {tagsLoaded && (
                            <>
                                {demoTags.map(item => (
                                    <>
                                    {tags.filter(x => x.tag === item).length > 0 && (
                                        <span className='badge badge-soft-success mb-2' style={{margin: '5px', padding: '5px', border: 'solid 1px #333', cursor: 'pointer'}} onClick={() => handleRemoveDemoTag(item)}>
                                        {item}
                                    </span>
                                    )}
                                    
                                    {tags.filter(x => x.tag === item).length == 0 && (
                                        <span className='badge badge-soft-warning mb-2' style={{margin: '5px', padding: '5px', border: 'solid 1px #333', cursor: 'pointer', backgroundColor: 'unset'}} onClick={() => handleAddDemoTag(item)}>
                                        {item}
                                    </span>
                                    )}
                                    </>
                                ))}
                            </>
                            )}
                        </div>
                    </Row>
                </Card.Header>
                <FalconComponentCard.Body
                    className="bg-light"
                    language="jsx"
                    noInline
                    noLight
                >
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={3} controlId="date_created">
                            
                        </Form.Group>
                    </Row>
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {demo.demo_invite_token && demo.demo_invite_token.length > 0 && (
            <Card className="mb-3">
            <FalconCardHeader title={'Invitation Link / Priority / Express 📨'} />
            <Card.Body className="bg-light">
            <Row className="mb-3 g-3">
                {demo.demo_invite_token && demo.demo_invite_token.length > 0 && (
                <div>Invitation to join demo: <a href={`https://www.leadrpro.com/demo-invite?refid=${demo.demo_invite_token}`} target='_blank'>
                https://www.leadrpro.com/demo-invite?refid={demo.demo_invite_token}
                </a></div>
                )}

                <div>
                    <label htmlFor='txtQuestion'>Qualifier Question</label>
                    <div>
                    <textarea id='txtQuestion' className='form-control' placeholder='Qualifier Question' value={question} onChange={(e) => setQuestion(e.target.value)}></textarea>
                    </div>

                    <div className='mt-3'>
                        <button className='btn btn-primary' onClick={() => handleQuestionSubmit()}>Save</button>
                    </div>

                    <div className='mt-3'>
                        <strong>Answer: </strong> <span>{demo.qualification_answer}</span>
                    </div>
                </div>

                {!demo.demo_invite_token && (
                    <i>Meeting URL not found</i>
                )}
            </Row>
            </Card.Body>
            </Card>
            )}

            {demo.times && demo.times.length > 0 && (
                <Card className="mb-3">
                <FalconCardHeader title={'Meeting Times'} />
                <Card.Body className="bg-light">
                <Row className="mb-3 g-3">
                    {demo.times.map(t => (
                        <div>
                            {new Date(t.available_time).toLocaleDateString()}{' '}
                            {new Date(t.available_time).toLocaleTimeString()}
                        </div>
                    ))}
                </Row>
                </Card.Body>
                </Card>
            )}

            <Card className="mb-3">
            <FalconCardHeader title={'Meeting Link'} />
            <Card.Body className="bg-light">
            <Row className="mb-3 g-3">
                {demo.reference_id && demo.reference_id.length > 0 && (
                <div>Default Meeting URL: <a href={`https://meetings.leadrpro.com/demo?refid=${demo.reference_id}`} target='_blank'>
                https://meetings.leadrpro.com/demo?refid={demo.reference_id}
                </a></div>
                )}

                {!demo.reference_id && (
                    <i>Meeting URL not found</i>
                )}
            </Row>
            <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6} controlId="name">
                    <Form.Control
                        type="text"
                        placeholder="Add custom meeting link"
                        value={meetingLink}
                        name="note"
                        onChange={(e) => setMeetingLink(e.target.value)}
                    />
                </Form.Group>

                <div className="text-start">
                    <motion.button
                        type="submit"
                        className="btn btn-primary"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleSaveLink()}
                    >
                        Save Link
                    </motion.button>
                </div>
                </Row>
            </Card.Body>
            </Card>

            {/* Company Info Card */}
            <Card className="mb-3">
                <FalconCardHeader title={'📝 Demo Notes'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleNoteSubmit}>
                        {/* Name & Website */}
                       
                       <Row className='mb-3 g-3'>
                       {notes.map((item, index) => {
                            if (index === 0 || (index > 0 && showAllNotes === true)){
                            return (
                                <div style={{padding: "10px", borderRadius: "5px", marginBottom: "5px", fontSize: "12px", border: "solid 1px #ccc"}}>
                                    <div style={{marginBottom: "10px"}}>{moment(item.date_created)
                    .local()
                    .format('MMM Do YY, h:mm a')}</div>
                                    {item.note_text}

                                    <div style={{marginTop: "10px"}}>
                                        - {item.first_name}
                                    </div>

                                    <div className='text-end'>
                                        <span onClick={() => handleDeleteNote(item.demo_note_id)}>Delete Note</span>
                                    </div>
                                </div>
                            );
                            }
                        })}

                        {showAllNotes === false && notes.length > 1 && (
                            <div>
                                <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setShowAllNotes(true)}>Show All</span>
                            </div>
                        )}
                       </Row>

                       <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="name">
                                <Form.Control
                                    type="text"
                                    placeholder="Add note"
                                    value={note}
                                    name="note"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        {/* Submit Button */}
                        <div className="text-start">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Save note
                            </motion.button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            {/* Demo Payment Info */}
            <Card className="mb-3">
                <Card.Header>
                    <Row className="d-flex justify-content-between">
                        <Col>
                            <h5>Demo Cost</h5>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="bg-light">
                    <Form onSubmit={handleCostSubmit}>
                        {/* Name & Status */}
                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="seller_first_name"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Demo Cost:
                                </Form.Label>

                                <div>
                                <Form.Control
                                    type="text"
                                    placeholder="Demo cost to seller"
                                    defaultValue={demo.seller_cost}
                                    name="seller_cost"
                                    ref={refCost}
                                />
                                </div>

                                 {/* Submit Button */}
                                <div className="mt-3">
                                    <motion.button
                                        type="submit"
                                        className="btn btn-primary"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                    >
                                        Update
                                    </motion.button>
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>

            {/* Seller Info */}
            <Card className="mb-3">
                <Card.Header>
                    <Row className="d-flex justify-content-between">
                        <Col>
                            <h5>Seller Information</h5>
                        </Col>
                        <Col xs="auto" sm={6} lg={4}>
                            <Form.Label>Change Seller:</Form.Label>
                            <NewSellerDropdown
                                company_id={demo.seller_company_id}
                                seller={demo.seller_email}
                                setSelected={setSelected}
                                setAction={setAction}
                                setShow={setShow}
                            />
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="bg-light">
                    <Form>
                        {/* Name & Status */}
                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="seller_first_name"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Name:
                                </Form.Label>
                                <div>
                                    {demo.seller_first_name +
                                        ' ' +
                                        demo.seller_last_name}{' '}
                                    <Link to={'/user/' + demo.seller_id}>
                                        ({demo.seller_id})
                                    </Link>
                                </div>
                            </Form.Group>

                            {/* Seller Status */}
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="seller_status"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Seller Status:
                                </Form.Label>
                                {demo.seller_status === 'Approved' && (
                                    <div className="">
                                        <span className="badge badge-soft-success mb-2 ">
                                            Approved
                                        </span>
                                    </div>
                                )}
                                {demo.seller_status === 'Rescheduled' && (
                                    <div className="">
                                        <span className="badge badge-soft-info mb-2 ">
                                            Rescheduled
                                        </span>
                                    </div>
                                )}
                                {demo.seller_status === 'Pending' && (
                                    <div className="">
                                        <span className="badge badge-soft-warning mb-2 ">
                                            Pending
                                        </span>
                                    </div>
                                )}
                                {demo.seller_status === 'Declined' && (
                                    <div className="">
                                        <span className="badge badge-soft-danger mb-2 ">
                                            Declined
                                        </span>
                                    </div>
                                )}
                            </Form.Group>
                        </Row>
                        {/* Company Name & Email */}
                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="seller_company_name"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Company Name:
                                </Form.Label>
                                <div>
                                    {demo.seller_company_name}
                                    <Link
                                        className="ms-1"
                                        to={`/company/${demo.seller_company_id}`}
                                    >
                                        ({demo.seller_company_id})
                                    </Link>
                                </div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="seller_email"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Email:
                                </Form.Label>
                                <div>{demo.seller_email}</div>
                            </Form.Group>
                        </Row>
                        {/* Timezone */}
                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="seller_timezone"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Seller Timezone:
                                </Form.Label>
                                <div>{demo.seller_timezone}</div>
                            </Form.Group>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>

            {/* Buyer Info */}
            <Card className="mb-3">
                <FalconCardHeader title="Buyer Information" />
                <Card.Body className="bg-light">
                    <Form>
                        {/* Name & Status */}
                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="buyer_first_name"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Name:
                                </Form.Label>
                                <div>
                                    {demo.buyer_first_name +
                                        ' ' +
                                        demo.buyer_last_name}{' '}
                                    <Link to={'/user/' + demo.buyer_id}>
                                        ({demo.buyer_id})
                                    </Link>
                                </div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="buyer_status"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Buyer Status:
                                </Form.Label>
                                {demo.buyer_status === 'Approved' && (
                                    <div className="">
                                        <span className="badge badge-soft-success mb-2 ">
                                            Approved
                                        </span>
                                    </div>
                                )}
                                {demo.buyer_status === 'Rescheduled' && (
                                    <div className="">
                                        <span className="badge badge-soft-info mb-2 ">
                                            Rescheduled
                                        </span>
                                    </div>
                                )}
                                {demo.buyer_status === 'Pending' && (
                                    <div className="">
                                        <span className="badge badge-soft-warning mb-2 ">
                                            Pending
                                        </span>
                                    </div>
                                )}
                                {demo.buyer_status === 'Declined' && (
                                    <div className="">
                                        <span className="badge badge-soft-danger mb-2 ">
                                            Declined
                                        </span>
                                    </div>
                                )}
                            </Form.Group>
                        </Row>
                        {/* Company Name & Email */}
                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="buyer_company_name"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Company Name:
                                </Form.Label>
                                <div>
                                    {demo.buyer_company_name}
                                    <Link
                                        className="ms-1"
                                        to={`/company/${demo.buyer_company_id}`}
                                    >
                                        ({demo.buyer_company_id})
                                    </Link>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="buyer_email">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Email:
                                </Form.Label>
                                <div>{demo.buyer_email}</div>
                            </Form.Group>
                        </Row>
                        {/* Timezone & Department */}
                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="buyer_timezone"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Buyer Timezone:
                                </Form.Label>
                                <div>{demo.buyer_timezone}</div>
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="department">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Department:
                                </Form.Label>
                                <div>{demo.department}</div>
                            </Form.Group>
                        </Row>
                        {/* Job Title & Seniority Level */}
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="job_title">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Job Title:
                                </Form.Label>
                                <div>{demo.job_title}</div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="seniority_level"
                            >
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Seniority Level:
                                </Form.Label>
                                <div>{demo.seniority_level}</div>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="job_title">
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Country:
                                </Form.Label>
                                <div>{demo.buyer_country}</div>
                            </Form.Group>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>

            {/* Feedback Info */}
            <Card className="mb-3">
                <FalconCardHeader title="Demo Feedback" />
                <Card.Body className="bg-light">
                    {demo.demo_status === 'Pending' && (
                        <label className="form-label">
                            <i>Demo hasn't started.</i>
                        </label>
                    )}
                    {demo.demo_status === 'Declined' && (
                        <label className="form-label">
                            <i>Demo has been canceled.</i>
                        </label>
                    )}
                    {demo.demo_status === 'Completed' && (
                        <Row className="mb-3">
                            <h4>
                                <u>Buyer Feedback</u>
                            </h4>
                        </Row>
                    )}
                    {demo.demo_status === 'Completed' && !buyer_feedback && (
                        <label className="form-label">
                            <i>Buyer hasn't submitted feedback yet.</i>
                        </label>
                    )}
                    {buyer_feedback && (
                        <div>
                            <Row className="my-2">
                                {buyer_feedback_criteria &&
                                    buyer_feedback_criteria.map((object) => {
                                        return (
                                            <div className="my-1 d-grid">
                                                <label className="form-label">
                                                    <u>
                                                        {object.display_name}:
                                                    </u>
                                                </label>
                                                <StarRating
                                                    readonly
                                                    rating={object.value}
                                                />
                                            </div>
                                        );
                                    })}
                            </Row>

                            <Row className="my-2">
                                <label className="form-label">
                                    <u>
                                        What, if anything, did you find most
                                        appealing about the product?
                                    </u>
                                </label>
                                <div>{buyer_feedback.feedback1}</div>
                            </Row>
                            <Row className="my-2">
                                <label className="form-label">
                                    <u>
                                        What, if anything, are your
                                        hesitations/concerns about the product?
                                    </u>
                                </label>
                                <div>{buyer_feedback.feedback2}</div>
                            </Row>
                            <Row className="my-2">
                                <label className="form-label">
                                    <u>
                                        Was there anything you wish to have seen
                                        or discussed in this presentation that
                                        was not?
                                    </u>
                                </label>
                                <div>{buyer_feedback.feedback3}</div>
                            </Row>
                            <Row className="my-2">
                                <label className="form-label">
                                    <u>Interested in moving forward?</u>
                                </label>
                                <div>{buyer_feedback.feedback_option_text}</div>
                            </Row>
                            {/* <Row className="my-2">
                                <label className="form-label">
                                    <u>Moving to Proposal:</u>
                                </label>
                                {buyer_feedback &&
                                    buyer_feedback.moving_to_proposal && (
                                        <div className="">
                                            <span className="badge badge-soft-success mb-2 ">
                                                True
                                            </span>
                                        </div>
                                    )}
                                {buyer_feedback &&
                                    !buyer_feedback.moving_to_proposal && (
                                        <div className="">
                                            <span className="badge badge-soft-danger mb-2 ">
                                                False
                                            </span>
                                        </div>
                                    )}
                            </Row> */}
                        </div>
                    )}
                    {demo.demo_status === 'Completed' && (
                        <Row className="mt-4 mb-3">
                            <h4>
                                <u>Seller Feedback</u>
                            </h4>
                        </Row>
                    )}
                    {demo.demo_status === 'Completed' &&
                        (!seller_feedback || !seller_feedback_criteria) && (
                            <label className="form-label">
                                <i>Seller hasn't submitted feedback yet.</i>
                            </label>
                        )}
                    {(seller_feedback ||
                        seller_feedback_criteria.length > 0) && (
                        <div>
                            <Row className="my-2">
                                {seller_feedback_criteria &&
                                    seller_feedback_criteria.map((object) => {
                                        return (
                                            <div className="my-1 d-grid">
                                                <label className="form-label">
                                                    <u>
                                                        {object.display_name}:
                                                    </u>
                                                </label>
                                                <StarRating
                                                    readonly
                                                    rating={object.value}
                                                />
                                            </div>
                                        );
                                    })}
                            </Row>
                            <Row className="my-2">
                                <label className="form-label">
                                    <u>Feedback Text:</u>
                                </label>
                                <div>{seller_feedback.feedback_text}</div>
                            </Row>
                            <Row className="my-2">
                                <label className="form-label">
                                    <u>Moving To Proposal:</u>
                                </label>
                                {seller_feedback &&
                                    seller_feedback.moving_to_proposal && (
                                        <div className="">
                                            <span className="badge badge-soft-success mb-2 ">
                                                True
                                            </span>
                                        </div>
                                    )}
                                {seller_feedback &&
                                    !seller_feedback.moving_to_proposal && (
                                        <>
                                            <div className="">
                                                <span className="badge badge-soft-danger mb-2 ">
                                                    False
                                                </span>
                                            </div>
                                            <Row className="my-2">
                                                <label className="form-label">
                                                    <u>Estimated ACV:</u>
                                                </label>
                                                <div>
                                                    {seller_feedback.reason}
                                                </div>
                                            </Row>
                                        </>
                                    )}
                                {seller_feedback &&
                                    seller_feedback.length > 0 &&
                                    seller_feedback[0].estimated_value && (
                                        <Row className="my-2">
                                            <label className="form-label">
                                                <u>Estimated ACV:</u>
                                            </label>
                                            <div>
                                                {
                                                    seller_feedback_criteria[0]
                                                        .estimated_value
                                                }
                                            </div>
                                        </Row>
                                    )}
                            </Row>
                        </div>
                    )}
                </Card.Body>
            </Card>

            {/* Buyer Decline */}
            <Card className="mb-3">
                <FalconCardHeader title="Buyer Decline" />
                <Card.Body>
                    {demo.buyer_decline.length <= 0 && (
                        <label className="form-label">
                            <i>Not available.</i>
                        </label>
                    )}

                    {demo.buyer_decline.length > 0 && (
                        <div>
                            <div>
                                <strong>Date Created: </strong> {new Date(demo.buyer_decline[0].date_created).toLocaleDateString()}
                            </div>
                            <div>
                                <strong>Reason: {demo.buyer_decline[0].reason}</strong>
                            </div>

                            <div>
                                <strong>Feedback: </strong> {demo.buyer_decline[0].decline_text}
                            </div>
                        </div>
                    )}
                </Card.Body>
            </Card>

            {/* Seller Decline */}
            <Card className="mb-3">
                <FalconCardHeader title="Seller Decline" />
                <Card.Body>
                    {demo.seller_decline.length <= 0 && (
                        <label className="form-label">
                            <i>Not available.</i>
                        </label>
                    )}

                    {demo.seller_decline.length > 0 && (
                        <div>
                            <div>
                                <strong>Date Created: </strong> {new Date(demo.seller_decline[0].date_created).toLocaleDateString()}
                            </div>
                            <div>
                                <strong>Reason: {demo.seller_decline[0].reason}</strong>
                            </div>

                            <div>
                                <strong>Feedback: </strong> {demo.seller_decline[0].decline_text}
                            </div>
                        </div>
                    )}
                </Card.Body>
            </Card>

            {/* Demo Messages */}
            <Card className="mb-3">
                <FalconCardHeader title="Demo Messages" />
                <Card.Body>
                    {demo.messages.length <= 0 && (
                        <label className="form-label">
                            <i>No messages found.</i>
                        </label>
                    )}
                    {demo.messages.length > 0 && (
                        <AdvanceTableWrapper
                            columns={demoMessagesColumns}
                            data={demo.messages}
                            pagination
                            perPage={20}
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-center align-middle"
                                rowClassName="align-middle text-center"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={demo.messages.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                </Card.Body>
            </Card>

            {/* Questions & Answers */}
            {demo.questions && demo.questions.length > 0 && (
                <Card className="mb-3">
                    <FalconCardHeader title="Questions & Answers" />
                    <Card.Body className="bg-light">
                        <ListGroup>
                            {demo.questions.map((item, index) => {
                                return (
                                    <ListGroup.Item key={index}>
                                        <h5>
                                            <u>{item.question}</u>
                                        </h5>
                                        <p>{item.answer}</p>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </Card.Body>
                </Card>
            )}

            {/* Message Sequence Table */}
            <Card className="mb-3">
                <FalconCardHeader title="Message Sequence" />
                <Card.Body>
                    <AdvanceTableWrapper
                        columns={messageColumns}
                        data={demo.message_sequence}
                    >
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                </Card.Body>
            </Card>

            {/* Events Table */}
            <Card className="mb-3">
                <FalconCardHeader title="Events" />
                <Card.Body>
                    <AdvanceTableWrapper
                        columns={eventsColumns}
                        data={demo.events}
                    >
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                    <div className="my-2 text-end">
                        <motion.button
                            whileHove={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleDelete()}
                            className="btn btn-sm btn-outline-danger"
                        >
                            Delete Demo
                        </motion.button>
                    </div>
                </Card.Body>
            </Card>

            <DemoProfileModal
                show={show}
                selected={selected}
                setShow={setShow}
                demo={demo}
                setDemo={setDemo}
                action={action}
            />
        </>
    );
}

export default DemoProfilePage;
