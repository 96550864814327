import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';
import { getCompanies, getCompaniesQuery } from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CompaniesSearchBox from './CompaniesSearchBox';

//todo: need to make a max pagenumber to track against the current page
//todo: need to calculate the number

function CompaniesDashboard() {
    const [loaded, setLoaded] = useState(false);
    const [companies, setCompanies] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [query, setQuery] = useState('');
    const [perPage, setPerPage] = useState(20);

    useEffect(() => {
        const loadCompanies = async () => {
            try {
                setLoaded(false);
                if (!query || query.length <= 2) {
                    console.log('query is empty');
                    console.log(query);
                    return getCompanies(page).then((res) => {
                        setPerPage(20);
                        setCompanies(res.data);
                        setCount(res.data[0].full_count);
                    });
                } else {
                    if (query.length >= 3) {
                        console.log('query is over 2 char');
                        console.log(query);
                        return getCompaniesQuery(query).then((res) => {
                            setPerPage(res.data.length);
                            setCompanies(res.data);
                            setCount(res.data.length);
                        });
                    }
                }
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        };
        loadCompanies();
    }, [page, query]);

    const handlePrevious = () => {
        if (page == 1) return;
        setPage((page) => page - 1);
    };

    const handleNext = () => {
        if (page == Math.ceil(count / 20)) return;
        setPage((page) => page + 1);
    };

    const columns = [
        {
            accessor: 'company_id',
            Header: 'Company Id',
            Cell: (rowData) => {
                const { company_id } = rowData.row.original;
                return (
                    <>
                        <Link to={'/company/' + company_id}>
                            {company_id + ''}
                        </Link>
                        <a
                            href={`https://leadrpro.com/company/${company_id}`}
                            target="_blank"
                        >
                            {' '}
                            (Public URL)
                        </a>
                    </>
                );
            }
        },
        {
            accessor: 'date_created',
            Header: 'Created On',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'name',
            Header: 'Company Name',
            Cell: (rowData) => {
                const { company_id, name, logo } = rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {name + ' '}
                        <Link className="ms-1" to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'company_size',
            Header: 'Company Size'
        },
        {
            accessor: 'industry',
            Header: 'Industry'
        },
        {
            accessor: 'website',
            Header: 'Website'
        },
        {
            accessor: 'source',
            Header: 'Source'
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Companies"
                    titleTag="h3"
                    noPreview
                />
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {companies && loaded && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={companies}
                            sortable
                            pagination
                            perPage={perPage}
                        >
                            <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <CompaniesSearchBox
                                        companies={companies}
                                        setCompanies={setCompanies}
                                        query={query}
                                        setQuery={setQuery}
                                    />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div
                                className="mt-3"
                                style={
                                    perPage !== 20 ? {} : { display: 'none' }
                                }
                            >
                                <AdvanceTableFooter
                                    rowCount={companies.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {!loaded && <div>Loading...</div>}
                    {perPage === 20 && (
                        <div className="mt-3 d-flex justify-content-between align-items-center">
                            {count && (
                                <span>
                                    Page {page} of {Math.ceil(count / 20)}
                                </span>
                            )}
                            <div>
                                {page === 1 && (
                                    <Button
                                        variant="outline-secondary"
                                        className="me-2 mb-1"
                                        disabled
                                    >
                                        Previous
                                    </Button>
                                )}
                                {page !== 1 && (
                                    <Button
                                        variant="secondary"
                                        className="me-2 mb-1"
                                        onClick={handlePrevious}
                                    >
                                        Previous
                                    </Button>
                                )}
                                <Button
                                    variant="primary"
                                    className="mb-1"
                                    onClick={handleNext}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    )}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default CompaniesDashboard;
