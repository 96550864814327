import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import {
    getCharityCampaignDetails,
    getCharityCampaignPayouts,
    getCharityCampaignAdditionalEarnings,
    getCategory,
    updateCategory,
    updateCategoryNew
} from 'api/api';
import ProfileBanner from 'components/pages/user/ProfileBanner';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Col, Form, Row, Toast, ListGroup } from 'react-bootstrap';

function CategoryDetails() {
    const { categoryId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [charityPayouts, setCharityPayouts] = useState(null);
    const [additionalEarnings, setAdditionalEarnings] = useState(null);
    const [category, setCategory] = useState([]);
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [loading, setLoading] = useState(null);

    const loadData = async () => {
        try {
           let res = await getCategory(categoryId);

           if (res.success){
            setCategory(res.data);
            setShortDescription(res.data.short_description);
            setDescription(res.data.description);
            setSlug(res.data.slug);
           }
        } catch (err) {
            alert(err.message);
            console.warn(err.message, 'this is the error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        onExit: {
            opacity: 0,
            transition: { duration: 1 }
        }
    };

    async function handleSave(){
       let res = await updateCategoryNew(categoryId, {short_description: shortDescription, description, slug});

       if (res.success){
        loadData();
        alert("Success");
       }
    }

    return (
        <motion.div
            key="settings"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>
            <ProfileBanner className="mb-3">
                <ProfileBanner.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && category && (
                        <>
                            <div className="d-grid gap-4">
                                <div className="d-grid">
                                    <h2 className="m-0">
                                        {category.name}
                                    </h2>
                                    <span>
                                        <u>Created:</u>{' '}
                                        {moment(category.date_created)
                                            .local()
                                            .format('MMM Do YY, h:mm a')}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </ProfileBanner.Body>
            </ProfileBanner>

            <Card className="mb-3">
                <FalconCardHeader title={'Category'} />
                <Card.Body className="bg-light">

                <Form.Group as={Col} lg={6} controlId="name">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Short Description
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Short description"
                        value={shortDescription}
                        name="note"
                        onChange={(e) => setShortDescription(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="name">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        Description
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="Description"
                        value={description}
                        name="note"
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="name">
                    <Form.Label
                        style={{ textDecoration: 'underline' }}
                    >
                        URL Slug
                    </Form.Label>
                    <Form.Control
                        type='text'
                        rows={6}
                        placeholder="Description"
                        value={slug}
                        name="slug"
                        onChange={(e) => setSlug(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="name">
                <div className="text-start">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => handleSave()}
                            >
                                Save
                            </motion.button>
                        </div>
                </Form.Group>
            </Card.Body>
            </Card>
        </motion.div>
    );
}

export default CategoryDetails;