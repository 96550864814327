import React, { useState, useEffect } from 'react';
import { getSeniorityLevels, getUserNotes, addUserNote, deleteUserNote, updateWorkEmail, getUserPersonaTypes, enableUserPersonaType, disableUserPersonaType, getSellersForCompany, reassignDemos } from 'api/api';
import { Button, Card, Col, Form, Row, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import Select from 'react-select';

import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TimezoneDropdown from './TimezoneDropdown';
import SeniorityDropdown from './SeniorityDropdown';
import DepartmentsDropdown from './DepartmentsDropdown';
import SellersDropdown from './SellersDropdown';
import UserSettingsModal from './UserSettingsModal';
import CardDropdown from 'components/common/CardDropdown';

//todo: add a set timeout to change the button look after 3 seconds
//todo: change the button to either green or red based on the status code of the update request

//* function convertToReadableTime(start, end) {
//*     var startTime = moment(start, 'HHmm').format('h:mm a');
//*     var endTime = moment(end, 'HHmm').format('h:mm a');
//*     console.log('Start time: ' + startTime + ' End time: ' + endTime);
//* }

function convertToReadableTime(time) {
    var inputString = time.toString();
    var format;
    if (inputString.length === 1) {
        return '12:00 am';
    } else if (inputString.length === 2) {
        format = 'mm';
    } else if (inputString.length === 3) {
        inputString = '0' + inputString;
        format = 'Hmm';
    } else if (inputString.length === 4) {
        format = 'HHmm';
    } else {
        return time;
    }
    var formatedTime = moment(inputString, format).format('h:mm a');
    return formatedTime;
}

const ProfileSettings = ({ user, setUser }) => {
    const [seniority, setSeniority] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState('');
    const [action, setAction] = useState('');
    const [show, setShow] = useState(false);
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState('');
    const [workEmail, setWorkEmail] = useState(user.work_email);
    const [showAllNotes, setShowAllNotes] = useState(false);
    const [userPersonaTypes, setUserPersonaTypes] = useState([]);
    const [selectedSeller, setSelectedSeller] = useState(null);

    const url = process.env.REACT_APP_IMAGE_PATH;
    // src={"/assets/payoutmethods/" + item.icon}
    const [sellers, setSellers] = useState([]);
    const [showReassignConfirm, setShowReassignConfirm] = useState(false);
    let organizerOptions;

    useEffect(() => {
        const loadSellers = async () => {
            try {
                const res = await getSellersForCompany(user.company_id);
                setSellers(res.data);

                console.log(res.data);
                if (res.data){
                    organizerOptions = res.data.map((level) => {
                        const data = {value : level.registered_user_id, label: level.email}
                        return data;
                    });
                    organizerOptions.sort((a, b) => (a.label > b.label) ? 1 : -1);
                }
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        };

        loadSellers();
    }, []);

    useEffect(() => {
        const getLevels = async () => {
            getSeniorityLevels().then((res) => setSeniority(res.data));
        };
        getLevels();
    }, []);

    const handleDemoDelete = (index, demo, action) => {
        setAction(action);
        setSelected(demo);
        setShow(true);
    };

    async function loadNotes(){
        try {
            //setLoaded(false);
            const res = await getUserNotes(user.registered_user_id);
            setNotes(res.data);
          //  setAvailableBuyers(res.data.available_buyers);
        } catch (err) {
            console.log(JSON.stringify(err));
        } finally {
           // setLoaded(true);
        }
    };

    async function loadUserPersonaTypes(){
        let res = await getUserPersonaTypes(user.registered_user_id);

        if (res.success){
            setUserPersonaTypes(res.data);
        }
    }

    const handleSellerChange = target => {
        //setShow(true)
        //setAction('Change Seller');
       // setSelectedSeller(target)
    }
    // c

    useEffect(() => {
        loadNotes();
        loadUserPersonaTypes();
    }, []);

    async function handleNoteSubmit(e){
        e.preventDefault();
        //alert(note);
        if (note.length > 0){
           // console.log(user.registered_user_id);
           // console.log(user);
           // alert(user.registered_user_id)
            let res = await addUserNote(user.registered_user_id, note, false);

            if (res.success){
                await loadNotes();
                setNote('');
            }
        }

        else{
            alert("Must enter a valid note");
        }
    }

    async function handleDeleteNote(user_note_id){
        try {
            let res = await deleteUserNote(user_note_id);

            if (res.success){
                await loadNotes();
            }
        } catch (error) {
            
        }
    }

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    };

    const handleUserSubmit = async (e) => {
        e.preventDefault();
        //todo: show the modal and set states to the action and selected
        setShow(true);
        setSelected('handleUserSubmit');
        setAction('Update');
    };

    const handlePhoneSubmit = async (e) => {
        e.preventDefault();
        //todo: show the modal and set states to the action and selected
        setShow(true);
        setSelected('handlePhoneSubmit');
        setAction('Update');
    };

    const handleUserProfileSubmit = async (e) => {
        e.preventDefault();
        //todo: show the modal and set states to the action and selected
        setShow(true);
        setSelected('handleUserProfileSubmit');
        setAction('Update');
    };

    const handleUserDelete = () => {
        setShow(true);
        setSelected('deleteUser');
        setAction('Delete');
    };

    async function handleWorkEmailSubmit(){
        let res = await updateWorkEmail(user.registered_user_id, user.company_id, workEmail);

        if (res.success){
            alert('Success!');
        }

        else{
            alert('Whoops! Something went wrong');
        }
    }

    async function enablePersonaType(persona_type_id){
        let res = await enableUserPersonaType(user.registered_user_id, persona_type_id);

        if (res.success){
           // alert('Updated');
           await loadUserPersonaTypes();
        }

        else{
            alert('Whoops');
        }
    }

    async function disablePersonaType(persona_type_id){
        let res = await disableUserPersonaType(user.registered_user_id, persona_type_id);

        if (res.success){
            await loadUserPersonaTypes();
            //alert('Updated');
        }

        else{
            alert('Whoops');
        }
    }

    async function handleReassign(){
        //alert(selectedSeller);
        let res = await reassignDemos(user.registered_user_id, selectedSeller);

        if (res.success){
            window.location = window.location;
        }

        else{
            alert("whoops, something went wrong");
        }
    }

    const columns = [
        {
            accessor: 'date_created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            },
            Header: 'Login Date'
        }
    ];

    const demoColumns = [
        {
            accessor: 'demo_id',
            Header: 'Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },
        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <span>No date.</span>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'date_applied',
            Header: 'Date Applied',
            Cell: (rowData) => {
                const { date_applied } = rowData.row.original;
                if (!date_applied) {
                    return <span>No date.</span>;
                }
                const date = moment(date_applied)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'scheduled_date_time',
            Header: 'Scheduled Date & Time',
            Cell: (rowData) => {
                const { scheduled_date_time, reschedule_date_time } =
                    rowData.row.original;
                if (!scheduled_date_time) {
                    return <span>Not scheduled.</span>;
                }
                if (reschedule_date_time) {
                    const date = moment(reschedule_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                } else {
                    const date = moment(scheduled_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'demo_status',
            Header: 'Status',
            Cell: (rowData) => {
                const { demo_status } = rowData.row.original;

                if (demo_status === 'Completed') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-success">
                                Completed
                            </span>
                        </div>
                    );
                } else if (demo_status === 'Pending') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-warning">
                                Pending
                            </span>
                        </div>
                    );
                } else if (demo_status === 'Declined') {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge badge-soft-danger">
                                Declined
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex flex-column container align-items-center">
                            <span className="badge bg-light">
                                {demo_status}
                            </span>
                        </div>
                    );
                }
            }
        },
        {
            Header: 'Seller Status',
            Cell: (rowData) => {
                const { seller_status } = rowData.row.original;
                let status = [];
                if (seller_status === 'Approved') {
                    status.push(
                        <span className="badge badge-soft-success">
                            Approved
                        </span>
                    );
                } else if (seller_status === 'Pending') {
                    status.push(
                        <span className="badge badge-soft-warning">
                            Pending
                        </span>
                    );
                } else if (seller_status === 'Rescheduled') {
                    status.push(
                        <span className="badge badge-soft-primary">
                            Rescheduled
                        </span>
                    );
                } else if (seller_status === 'Declined') {
                    status.push(
                        <span className="badge badge-soft-danger">
                            Declined
                        </span>
                    );
                }
                return <span>{status}</span>;
            }
        },
        {
            Header: 'Buyer Status',
            Cell: (rowData) => {
                const { buyer_status } = rowData.row.original;
                let status = [];
                if (buyer_status === 'Approved') {
                    status.push(
                        <span className="badge badge-soft-success">
                            Approved
                        </span>
                    );
                } else if (buyer_status === 'Pending') {
                    status.push(
                        <span className="badge badge-soft-warning">
                            Pending
                        </span>
                    );
                } else if (buyer_status === 'Rescheduled') {
                    status.push(
                        <span className="badge badge-soft-primary">
                            Rescheduled
                        </span>
                    );
                } else if (buyer_status === 'Declined') {
                    status.push(
                        <span className="badge badge-soft-danger">
                            Declined
                        </span>
                    );
                }
                return <span>{status}</span>;
            }
        },
        {
            accessor: 'seller_email',
            Header: 'Seller Email'
        },
        {
            accessor: 'company_name',
            Header: 'Seller Company',
            Cell: (rowData) => {
                const { company_id, company_name, logo } = rowData.row.original;

                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {company_name + ' '}
                        <Link className="ms-1" to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </div>
                );

                // return (
                // 	<span>
                // 		{company_name + ' '}
                // 		<Link to={'/company/' + company_id}>
                // 			({company_id})
                // 		</Link>
                // 	</span>
                // );
            }
        },

        {
            accessor: 'seller_timezone',
            Header: 'Seller Timezone'
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                return (
                    <CardDropdown iconClassName="fs--1" drop="start">
                        <div>
                            {/* <Dropdown.Item eventket='1' className='text-success disabled'>Completed</Dropdown.Item> */}
                            {/* <Dropdown.Divider  as="div" /> */}
                            <Dropdown.Item
                                eventkey="3"
                                onClick={() =>
                                    handleDemoDelete(index, demo, 'Delete Demo')
                                }
                                className="text-danger"
                            >
                                Delete
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const daysOfTheWeeks = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday'
    };

    return (
        <>
        {/* Company Info Card */}
        <Card className="mb-3">
                <FalconCardHeader title={'📝 User Notes'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleNoteSubmit}>
                        {/* Name & Website */}
                       
                       <Row className='mb-3 g-3'>
                       {notes.map((item, index) => {
                            if (index === 0 || (index > 0 && showAllNotes === true)){
                            return (
                                <div style={{padding: "10px", borderRadius: "5px", marginBottom: "5px", fontSize: "12px", border: "solid 1px #ccc"}}>
                                    <div style={{marginBottom: "10px"}}>{moment(item.date_created)
                    .local()
                    .format('MMM Do YY, h:mm a')}</div>
                                    {item.note_text}

                                    <div style={{marginTop: "10px"}}>
                                        - {item.first_name}
                                    </div>

                                    <div className='text-end'>
                                        <span onClick={() => handleDeleteNote(item.user_note_id)}>Delete Note</span>
                                    </div>
                                </div>
                            );
                            }
                        })}

                        {showAllNotes === false && notes.length > 1 && (
                            <div>
                                <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setShowAllNotes(true)}>Show All</span>
                            </div>
                        )}
                       </Row>

                       <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="name">
                                <Form.Control
                                    type="text"
                                    placeholder="Add note"
                                    value={note}
                                    name="note"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        {/* Submit Button */}
                        <div className="text-start">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Save note
                            </motion.button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <FalconCardHeader title={'💲 Payout Requests'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleNoteSubmit}>
                        {/* Name & Website */}
                       
                       <Row className='mb-3 g-3'>
                       {user.payout_requests.map((item, index) => {
                            return (
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <div>{new Date(item.date_created).toLocaleDateString()}</div>
                                    <div>{item.amount}</div>
                                    <div>{item.status}</div>
                                </div>
                            );
                        })}

                        {user.payout_requests.length == 0 && (
                            <div>
                                No payout requests...
                            </div>
                        )}
                       </Row>
                    </Form>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <FalconCardHeader title={'🅿️ Persona Types'} />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleNoteSubmit}>
                       <div className='mb-3 g-3' style={{display:'flex', flexDirection: 'row'}}>
                        {userPersonaTypes.map((item, index) => {
                            return (
                                <>
                                {item.enabled === false && (
                                    <div style={{padding: "10px", borderRadius: "5px", marginBottom: "5px", fontSize: "12px", border: "solid 1px #ccc", cursor:'pointer', margin: '5px'}} onClick={() => enablePersonaType(item.persona_type_id)}>
                                        <div>
                                            {item.name}
                                        </div>
                                    </div>
                                )}

                                {item.enabled && (
                                    <div style={{padding: "10px", borderRadius: "5px", marginBottom: "5px", fontSize: "12px", border: "solid 1px #ccc", cursor:'pointer', margin: '5px', background: 'white', color: 'black'}} onClick={() => disablePersonaType(item.persona_type_id)}>
                                        <div>
                                            {item.name}
                                        </div>
                                    </div>
                                )}
                                </>
                            );
                        })}
                       </div>
                    </Form>
                </Card.Body>
            </Card>

            {/* User Work Hours */}
            <Card className="mb-3">
                <FalconCardHeader title={`Work Hours for ${user.timezone}`} />
                <Card.Body
                    className="bg-light d-flex"
                    style={{ flexDirection: 'row', overflow: 'auto' }}
                >
                    {Array.isArray(user.work_hours) &&
                        user.work_hours.length > 0 &&
                        user.work_hours.map((item, index) => {
                            return (
                                <Card
                                    className="m-2"
                                    key={index}
                                    style={{ minWidth: '200px' }}
                                >
                                    <Card.Header>
                                        {daysOfTheWeeks[item.day_of_week]}
                                    </Card.Header>
                                    <Card.Body
                                        className="d-flex bg-light"
                                        style={{ flexDirection: 'row' }}
                                    >
                                        <Row className="mx-1">
                                            <Form.Label>Start:</Form.Label>
                                            <span>
                                                {convertToReadableTime(
                                                    item.start_time
                                                )}
                                            </span>
                                        </Row>
                                        <Row className="mx-1">
                                            <Form.Label>End:</Form.Label>
                                            <span>
                                                {convertToReadableTime(
                                                    item.end_time
                                                )}
                                            </span>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            );
                        })}
                    {Array.isArray(user.work_hours) &&
                        user.work_hours.length === 0 && (
                            <span>No work hours set.</span>
                        )}
                </Card.Body>
            </Card>

            {/* Update User */}
            <Card className="mb-3">
                <FalconCardHeader title="Basic Info" />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleUserSubmit}>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="first_name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="No first name found."
                                    value={user.first_name}
                                    name="first_name"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="last_name">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="No last name found."
                                    value={user.last_name}
                                    name="last_name"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="No email found."
                                    value={user.email}
                                    name="email"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="timezone">
                                <Form.Label>Timezone</Form.Label>
                                <TimezoneDropdown
                                    user={user}
                                    setUser={setUser}
                                />
                            </Form.Group>
                        </Row>
                        <div className="text-end">
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            {/* Update User Profile */}
            <Card className="mb-3">
                <FalconCardHeader title="Company Info" />
                <Card.Body className="bg-light">
                    <Form onSubmit={handleUserProfileSubmit}>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="job_title">
                                <Form.Label>Job Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="No job title found."
                                    value={user.job_title}
                                    name="job_title"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="department">
                                <Form.Label>Department</Form.Label>
                                <DepartmentsDropdown
                                    user={user}
                                    setUser={setUser}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="seniority_level"
                            >
                                <Form.Label>Seniority Level</Form.Label>
                                <SeniorityDropdown
                                    user={user}
                                    setUser={setUser}
                                    levels={seniority}
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                lg={6}
                                controlId="linkedin_url"
                            >
                                <Form.Label>LinkedIn URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="No URL found."
                                    value={user.linkedin_url}
                                    name="linkedin_url"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <div className="text-end">
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <FalconCardHeader title="Work Email" />
                <Card.Body className="bg-light">
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="work_email">
                                <Form.Label> Work Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="No email found."
                                    value={workEmail}
                                    name="work_email"
                                    onChange={(e) => setWorkEmail(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        
                        <div className="text-end">
                            <Button variant="primary" type="submit" onClick={() => handleWorkEmailSubmit()}>
                                Update
                            </Button>
                        </div>
                </Card.Body>
            </Card>

            {/* Update User Phone */}
            <Card className="mb-3">
                <FalconCardHeader title="Phone Number" />
                <Card.Body className="bg-light">
                    <Form onSubmit={handlePhoneSubmit}>
                        <Form.Group
                            as={Col}
                            lg={6}
                            controlId="phone_number"
                            className="mb-3"
                        >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="No phone number found."
                                value={user.phone_number}
                                name="phone_number"
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <div className="text-end">
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            {/* Payments Methods */}
            <Card className="mb-3">
                <FalconCardHeader title="Payout Methods" />
                <Card.Body className="bg-light">
                    <Form>
                        <Form.Group
                            as={Col}
                            lg={12}
                            controlId="phone_number"
                            className="mb-3"
                        >
                            <Row className="mb-3 g-3">
                                {Array.isArray(user.payout_methods) &&
                                    user.payout_methods.map((item, index) => {
                                        return (
                                            <Col sm={6} lg={4} key={index}>
                                                <Card
                                                    bg={'light'}
                                                    key={index}
                                                    text={
                                                        'light' === 'light'
                                                            ? 'dark'
                                                            : 'white'
                                                    }
                                                >
                                                    <Card.Body>
                                                        <Card.Title
                                                            as="div"
                                                            style={{
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    height: '100%',
                                                                    width: '30px'
                                                                }}
                                                                className="me-2"
                                                            >
                                                                <img
                                                                    className="payoutinfo__method-icon"
                                                                    src={
                                                                        url +
                                                                        '/assets/payoutmethods/' +
                                                                        item.icon
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                />
                                                            </div>
                                                            <span className="display-6">
                                                                {
                                                                    item.method_name
                                                                }
                                                            </span>
                                                        </Card.Title>
                                                        <Card.Text
                                                            style={{
                                                                fontSize:
                                                                    'small'
                                                            }}
                                                        >
                                                            {item.source}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>

            {/* User Demos */}
            <FalconComponentCard className="mb-3">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <h5>User Demos</h5>
                    <Col xs="auto" sm={6} lg={4}>
                        <select className='react-select__control css-1s2u09g-control' onChange={(e) => setSelectedSeller(e.target.value)} value={selectedSeller} style={{color: 'white'}}>
                            <option>Select seller</option>

                            {sellers.map(x => (
                                <>
                                <option value={x.registered_user_id}>
                                    {x.registered_user_id}{' '}{x.email}
                                </option>
                                </>
                            ))}
                        </select>
                        {' '}
                        <button className='btn' onClick={() => handleReassign()}>Reassign Demos</button>
                    </Col>
                    <Col xs="auto" sm={6} lg={4}>
                        <SellersDropdown
                            setShow={setShow}
                            setSelected={setSelected}
                            setAction={setAction}
                        />
                    </Col>
                </Card.Header>
                <FalconComponentCard.Body>
                    <AdvanceTableWrapper
                        columns={demoColumns}
                        data={user.demos}
                    >
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {/* Logins Table & Delete Button */}
            <FalconComponentCard>
                <FalconCardHeader title="Logins" />
                <FalconComponentCard.Body>
                    <AdvanceTableWrapper columns={columns} data={user.logins}>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>

                    <div className="mt-3 text-end">
                        <button
                            className="btn btn-outline-danger"
                            onClick={() => handleUserDelete()}
                        >
                            Delete User
                        </button>
                    </div>

                    {/* User Modal */}
                    <UserSettingsModal
                        show={show}
                        setShow={setShow}
                        loading={loading}
                        setLoading={setLoading}
                        user={user}
                        setUser={setUser}
                        selected={selected}
                        action={action}
                    />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default ProfileSettings;
