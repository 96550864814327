import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { getSellersForCompany } from 'api/api';

function PrimarySellerDropdown({ setShow, setSelected, setAction}) {
    let organizerOptions;
    const [sellers, setSellers] = useState(null);
    const { companyId } = useParams();

    useEffect( () => {

        const loadData = async () => {
            try {
                const res = await getSellersForCompany(companyId);
                setSellers(res.data)
            } catch (err){
                console.log(JSON.stringify(err.message));
            }
        }

        loadData();
    }, []);

    if (sellers){
        organizerOptions = sellers.map((level) => {
            const data = {value : level.registered_user_id, label: level.email}
            return data;
        });
        organizerOptions.sort((a, b) => (a.label > b.label) ? 1 : -1);
    }
    const handleChange = target => {
        setShow(true)
        setAction('Primary Seller');
        setSelected(target)
    }

    return ( 
        <Select 
            closeMenuOnSelect={true}
            options={organizerOptions}
            placeholder={"Select Primary Seller"}
            classNamePrefix="react-select"
            onChange={value => handleChange(value)}
        />
    );
}

export default PrimarySellerDropdown;