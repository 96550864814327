import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import Rating from 'react-rating';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';

const StarRating = ({ fractions = 2, rating, handleChange, ...rest }) => {
    const {
        config: { isRTL }
    } = useContext(AppContext);

    return (
        <Rating
            fractions={fractions}
            initialRating={rating}
            fullSymbol={
                <FontAwesomeIcon icon="star" className="text-warning" />
            }
            emptySymbol={<FontAwesomeIcon icon="star" className="text-300" />}
            onChange={handleChange}
            direction={isRTL ? 'rtl' : 'ltr'}
            {...rest}
        />
    );
};

StarRating.propTypes = {
    fractions: PropTypes.number,
    rating: PropTypes.number.isRequired,
    handleChange: PropTypes.func
};

export default StarRating;

// !date_created: '2022-11-15T20:28:43.602Z';
//! demo_id: 4792;
// !display_name: 'Overall professionalism';
// !estimated_value: 30000;
// !feedback_text: 'Call went well, already in contact about next steps';
// !is_active: true;
// !moving_to_proposal: true;
// !name: 'Professionalism';
//! order_id: 1;
// !registered_user_id: 6867;
// !seller_feedback_criteria_id: 1;
// !seller_feedback_criteria_value_id: 49;
// !seller_feedback_id: 198;
// !seller_objection_reason_id: null;
// !value: 5;
