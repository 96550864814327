import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { logout } from 'services/auth.service';
import team3 from 'assets/img/team/3.jpg';
import { toast } from 'react-toastify';
import Avatar from 'components/common/Avatar';

import { getUserProfile } from 'api/api';

const IMAGE_URL = `https://ik.imagekit.io/ivgrhmd4k/`;

const ProfileDropdown = () => {
    let navigate = useNavigate();
    const [user, setUser] = useState({});

    useLayoutEffect(() => {
        const loadUser = async () => {
            try {
                let res = await getUserProfile();
                setUser(res.data);
            } catch (err) {
                toast.error(`Error fetching user profile. ${err}`, {
                    theme: 'colored',
                    pauseOnHover: true,
                    pauseOnFocusLoss: true
                    // onOpen: () => setLoading(false)
                });
            }
        };

        loadUser();
    }, []);

    // `${IMAGE_URL}profileimages/${userObject?.data?.profile_image}`

    const logUserOut = async () => {
        logout();
        navigate('/authentication/simple/login');
    };
    return (
        <Dropdown navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="pe-0 ps-2 nav-link"
            >
                {!user && <Avatar />}
                {user && (
                    <Avatar
                        src={`${IMAGE_URL}profileimages/${user?.profile_image}`}
                    />
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider /> */}
                    <Dropdown.Item as={Link} to="/user/settings">
                        Settings
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logUserOut}>Logout</Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
