import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Dropdown, Button } from 'react-bootstrap';
import { motion } from 'framer-motion/dist/framer-motion';
import { getUpcomingDemos } from 'api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import CardDropdown from 'components/common/CardDropdown';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import DemosModal from './DemosModal';

function UpcomingDemosDashboard() {
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState(null);
    const [companyDemos, setCompanyDemos] = useState([]);
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);
    const [index, setIndex] = useState(null);
    const [action, setAction] = useState('');

    useEffect(() => {
        const loadUpcomingDemos = async () => {
            try {
                setLoaded(false);
                const { data } = await getUpcomingDemos();

                let arr = [];
                if (data && data.length > 0){
                    for (let i = 0; i < data.length; i++){
                        let filtered = arr.filter(x => x.name === data[i].seller_company_name);

                        if (filtered.length > 0){
                            arr.map(item => {
                                if (item.name === data[i].seller_company_name){
                                    item.count += 1;
                                }
                            })
                        }

                        else{
                            arr.push({name: data[i].seller_company_name, logo: data[i].seller_logo, count: 1})
                        }
                    }

                    setCompanyDemos(arr);
                }

                const sorted = data.sort((a, b) => {
                    if (!a.reschedule_date_time) {
                        if (!b.reschedule_date_time) {
                            return (
                                a.scheduled_date_time - b.scheduled_date_time
                            );
                        }

                        return a.scheduled_date_time - b.reschedule_date_time;
                    } else {
                        if (!b.reschedule_date_time) {
                            // console.log("returned");
                            // console.log(a.reschedule_date_time > b.scheduled_date_time);
                            return (
                                a.reschedule_date_time - b.scheduled_date_time
                            );
                        }
                        // console.log("returned")
                        // console.log(a.reschedule_date_time > b.reschedule_date_time);
                        return a.reschedule_date_time - b.reschedule_date_time;
                    }
                });
                setDemos(sorted);
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        };

        loadUpcomingDemos();
    }, []);

    const handleShow = (index, demo, action) => {
        setIndex(index);
        setSelected(demo);
        setAction(action);
        setShow(true);
    };

    const columns = [
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                return (
                    <CardDropdown iconClassName="fs--1" drop="start">
                        <div>
                            <Dropdown.Item
                                eventkey="1"
                                onClick={() =>
                                    handleShow(index, demo, 'Update')
                                }
                                className="text-success"
                            >
                                Completed
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventkey="1"
                                onClick={() =>
                                    handleShow(index, demo, 'Seller Reschedule')
                                }
                                className="text-primary"
                            >
                                Seller Reschedule
                            </Dropdown.Item>
                            <Dropdown.Divider as="div" />
                            <Dropdown.Item
                                eventkey="2"
                                onClick={() =>
                                    handleShow(index, demo, 'Delete')
                                }
                                className="text-danger"
                            >
                                Delete
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        },
        {
            accessor: 'demo_id',
            Header: 'Id',
            disableSortBy: true,
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },

        {
            accessor: 'interest_level',
            Header: 'Interest',
            disableSortBy: true,
            Cell: (rowData) => {
                const { interest_level } = rowData.row.original;

                return (
                    <>
                        {interest_level == 'Very interested' && (
                            <span style={{background: "#18b318", color:"white", fontSize: "x-small", padding: "0px 10px", borderRadius:"30px"}}>High</span>
                        )}

                        {interest_level == 'Somewhat interested' && (
                            <span style={{background: "#edd039", color:"black", fontSize: "x-small", padding: "0px 10px", borderRadius:"30px"}}>Medium</span>
                        )}

                        {interest_level == 'Just researching' && (
                            <span style={{background: "red", color:"white", fontSize: "x-small", padding: "0px 10px", borderRadius:"30px"}}>Low</span>
                        )}
                    </>
                )
            }
        },

        {
            accessor: 'seller_cost',
            Header: 'Cost'
        },

        {
            accessor: 'seller_company_name',
            Header: 'Seller Company',
            disableSortBy: true,
            Cell: (rowData) => {
                const { seller_company_id, seller_company_name, seller_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    seller_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {seller_company_name + ' '}
                        <Link
                            className="ms-1"
                            to={'/company/' + seller_company_id}
                        >
                            ({seller_company_id})
                        </Link>
                    </div>
                );
            }
        },

        {
            accessor: 'buyer_company_name',
            Header: 'Buyer Company',
            disableSortBy: true,
            Cell: (rowData) => {
                const { buyer_company_id, buyer_company_name, buyer_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    buyer_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                    <span>
                        {buyer_company_name + ' '}
                        <Link to={'/company/' + buyer_company_id}>
                            ({buyer_company_id})
                        </Link>
                    </span>
                    </div>
                );
            }
        },
        {
            accessor: 'scheduled_date_time',
            Header: 'Scheduled Date & Time',
            Cell: (rowData) => {
                const { scheduled_date_time, reschedule_date_time } =
                    rowData.row.original;

                if (!scheduled_date_time && !reschedule_date_time) {
                    return <span>Not scheduled.</span>;
                }
                if (reschedule_date_time) {
                    const date = moment(reschedule_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                } else {
                    const date = moment(scheduled_date_time)
                        .local()
                        .format('MMM Do YY, h:mm a');
                    return (
                        <div className="d-flex flex-column">
                            <span>{date}</span>
                        </div>
                    );
                }
            }
        },
        {
            accessor: 'is_confirmed',
            Header: 'Confirmed',
            Cell: (rowData) => {
                const { is_confirmed } = rowData.row.original;

                if (is_confirmed) {
                    return (
                        <span className="badge badge-soft-success">True</span>
                    );
                } else {
                    return (
                        <span className="badge badge-soft-danger">False</span>
                    );
                }
            }
        },

        {
            accessor: 'seller_email',
            Header: 'Seller Email',
            disableSortBy: true
        },
        
        {
            accessor: 'seller_timezone',
            Header: 'Seller Timezone'
        },
        {
            accessor: 'buyer_first_name',
            Header: 'Buyer Name',
            disableSortBy: true,
            Cell: (rowData) => {
                const { buyer_first_name, buyer_last_name, buyer_id } =
                    rowData.row.original;
                return (
                    <span>
                        {buyer_first_name + ' ' + buyer_last_name}
                        <Link to={'/user/' + buyer_id}> ({buyer_id})</Link>
                    </span>
                );
            }
        },
        
        {
            accessor: 'buyer_email',
            Header: 'Buyer Email',
            disableSortBy: true
        },
        {
            accessor: 'buyer_timezone',
            Header: 'Buyer Timezone',
            disableSortBy: true
        },
        {
            accessor: 'notify',
            Header: 'Notify',
            disableSortBy: true,
            cellProps: {
                className: 'text-end py-2'
            },
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                return (
                    <div>
                        <Button
                            variant="light"
                            size="sm"
                            className="border-300 me-1 text-600"
                            as={'a'}
                            onClick={() => handleShow(index, demo, 'Notify')}
                        >
                            <FontAwesomeIcon icon="bell" />
                        </Button>
                    </div>
                );
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title={"Upcoming Demos (" + (demos === null?"..." : demos.length) + ")"}
                    titleTag="h2"
                    noPreview
                />
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    <p>
                        Upcoming scheduled demos.
                    </p>

                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {companyDemos.map(x => (
                            <>
                                <div style={{ margin: '5px 10px'}}>
                                    <span><img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    x.logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '20px', width: '20px', borderRadius: '50%' }}
                            /></span>{' '}<span style={{fontSize: 'small'}}>{x.name} {x.count}</span>
                                </div>
                            </>
                        ))}
                    </div>

                    {loaded && demos && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={demos}
                            sortable
                            pagination
                            perPage={20}
                        >
                            <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={demos.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    <DemosModal
                        show={show}
                        setShow={setShow}
                        selected={selected}
                        setData={setDemos}
                        index={index}
                        action={action}
                    />
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default UpcomingDemosDashboard;
