import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckSquare,
    faTimes,
    faFilter
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion/dist/framer-motion';
import DatePicker, { registerLocale } from 'react-datepicker';
import { getBuyers } from 'api/api';
import es from 'date-fns/locale/es';
import moment from 'moment';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import SeniorityFilter from './SeniorityFilter';
import DepartmentFilter from './DepartmentFilter';

registerLocale('es', es);

function BuyersDashboard() {
    const [loaded, setLoaded] = useState(false);
    const [users, setUsers] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState(null);
    const [seniorityFilter, setSeniorityFilter] = useState(null);
    const [departmentFilter, setDepartmentFilter] = useState(null);

    useEffect(() => {
        const loadUsers = async () => {
            try {
                setLoaded(false);
                getBuyers().then((res) => setUsers(res.data.data));
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        };
        loadUsers();
    }, []);

    const dateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(() => start);
        setEndDate(() => end);
    };

    const handleFilter = () => {
        if (!startDate && !endDate && !seniorityFilter && !departmentFilter)
            return;
        let newUsers = users;

        if (startDate && endDate) {
            console.log('date filtered');
            let start = new Date(startDate).setUTCHours(0, 0, 0, 0);
            start = new Date(start).toISOString();
            let end = new Date(endDate).setUTCHours(23, 59, 0, 0);
            end = new Date(end).toISOString();

            newUsers = newUsers.filter((object) => {
                return (
                    start <= object.date_created && object.date_created <= end
                );
            });
        }

        if (seniorityFilter) {
            console.log('seniority filtered');
            newUsers = newUsers.filter(
                (object) => object.seniority_level == seniorityFilter
            );
        }

        if (departmentFilter) {
            console.log(newUsers.length, users.length);
            console.log(departmentFilter);
            newUsers = newUsers.filter((object) => {
                if (object.department_name) {
                    return object.department_name == departmentFilter;
                }
                return object.department == departmentFilter;
            });
        }

        console.log(newUsers, 'these are the filtered demos');

        setFilteredUsers(() => newUsers);
    };

    const handleFilterReset = () => {
        setFilteredUsers(() => null);
        setStartDate(null);
        setEndDate(null);
        setSeniorityFilter(null);
        setDepartmentFilter(null);
    };

    const columns = [
        {
            accessor: 'registered_user_id',
            Header: 'User Id',
            Cell: (rowData) => {
                const { registered_user_id } = rowData.row.original;
                return (
                    <Link to={'/user/' + registered_user_id}>
                        {registered_user_id}
                    </Link>
                );
            }
        },
        {
            accessor: 'is_verified',
            Header: 'Verified?',
            Cell: (rowData) => {
                const { is_verified } = rowData.row.original;

                if (is_verified) {
                    return (
                        <div className="d-flex justify-content-center align-items-center text-success">
                            <FontAwesomeIcon icon={faCheckSquare} />
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    );
                }
            }
        },
        
        {
            accessor: 'user_source',
            Header: 'User Source'
        },
        {
            Header: 'Links ',
            Cell: (rowData) => {
                const { linkedin_url, profile_url } = rowData.row.original;
                if (linkedin_url) {
                    return (
                        <>
                        <a href={linkedin_url} target="_blank">
                            LinkedIn
                        </a>
                        <br/>

                        {profile_url && (
                        <a href={`https://www.leadrpro.com/profile/${profile_url}`} target="_blank">
                            Profile URL
                        </a>
                        )}
                        </>
                    );
                } else {
                    return <span>No URL found.</span>;
                }
            }
        },
        // {
        //     accessor: 'demo_count',
        //     Header: 'Demos',
        //     Cell: (rowData) => {
        //         const {
        //             demo_count,
        //             demo_count_approved,
        //             demo_count_declined,
        //             demo_count_completed
        //         } = rowData.row.original;

        //         return (
        //             <div className="d-flex justify-content-center align-items-center">
        //                 <OverlayTrigger
        //                     placement={'top'}
        //                     overlay={
        //                         <Tooltip id={'tooltip-' + 'top'}>
        //                             <strong>Total</strong>.
        //                         </Tooltip>
        //                     }
        //                 >
        //                     <span
        //                         className="badge badge-soft-warning"
        //                         style={{ borderRadius: '50%' }}
        //                     >
        //                         {demo_count}
        //                     </span>
        //                 </OverlayTrigger>

        //                 <OverlayTrigger
        //                     placement={'top'}
        //                     overlay={
        //                         <Tooltip id={'tooltip-' + 'top'}>
        //                             <strong>Approved</strong>.
        //                         </Tooltip>
        //                     }
        //                 >
        //                     <span
        //                         className="badge badge-soft-primary mx-1"
        //                         style={{ borderRadius: '50%' }}
        //                     >
        //                         {demo_count_approved}
        //                     </span>
        //                 </OverlayTrigger>

        //                 <OverlayTrigger
        //                     placement={'top'}
        //                     overlay={
        //                         <Tooltip id={'tooltip-' + 'top'}>
        //                             <strong>Declined</strong>.
        //                         </Tooltip>
        //                     }
        //                 >
        //                     <span
        //                         className="badge badge-soft-danger mx-1"
        //                         style={{ borderRadius: '50%' }}
        //                     >
        //                         {demo_count_declined}
        //                     </span>
        //                 </OverlayTrigger>

        //                 <OverlayTrigger
        //                     placement={'top'}
        //                     overlay={
        //                         <Tooltip id={'tooltip-' + 'top'}>
        //                             <strong>Completed</strong>.
        //                         </Tooltip>
        //                     }
        //                 >
        //                     <span
        //                         className="badge badge-soft-success"
        //                         style={{ borderRadius: '50%' }}
        //                     >
        //                         {demo_count_completed}
        //                     </span>
        //                 </OverlayTrigger>
        //             </div>
        //         );
        //     }
        // },
        {
            accessor: 'date_created',
            Header: 'Joined On',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'first_name',
            Header: 'Name',
            Cell: (rowData) => {
                const { first_name, last_name } = rowData.row.original;
                return <span>{first_name + ' ' + last_name}</span>;
            }
        },
        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'company_size',
            Header: 'Size'
        },
        {
            accessor: 'company_name',
            Header: 'Company Name (Id)',
            Cell: (rowData) => {
                const { company_name, company_id, company_logo } =
                    rowData.row.original;
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-1"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    company_logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {company_name + ' '}
                        <Link to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'seniority_level',
            Header: 'Seniority'
        },
        {
            accessor: 'job_title',
            Header: 'Job Title'
        },
        {
            accessor: 'department',
            Header: 'Department',
            Cell: (rowData) => {
                const { department, department_name } = rowData.row.original;
                if (department_name) {
                    return <span>{department_name}</span>;
                } else {
                    return <span>{department}</span>;
                }
            }
        },
        {
            accessor: 'industry',
            Header: 'Industry'
        },
        {
            accessor: 'timezone',
            Header: 'Timezone'
        },
        {
            accessor: 'country',
            Header: 'Country'
        },
        // {
        //     accessor: 'source',
        //     Header: 'Source'
        // },
        {
            accessor: 'is_buyer',
            Header: 'Roles',
            Cell: (rowData) => {
                const { is_buyer, is_seller, is_admin } = rowData.row.original;
                let elements = [];
                if (is_buyer)
                    elements.push(
                        <span className="badge badge-soft-success rounded-pill">
                            Buyer
                        </span>
                    );
                if (is_seller)
                    elements.push(
                        <span className="badge badge-soft-primary rounded-pill">
                            Seller
                        </span>
                    );
                if (is_admin)
                    elements.push(
                        <span className="badge badge-soft-warning rounded-pill">
                            Admin
                        </span>
                    );
                return <div>{elements}</div>;
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <>
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard>
                <FalconComponentCard.Header
                    title="Buyers"
                    titleTag="h2"
                    noPreview
                />
                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    {loaded === false && (
                        <div>Loading...</div>
                    )}
                    
                    {loaded && users && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={
                                !filteredUsers || filteredUsers.length < 0
                                    ? users
                                    : filteredUsers
                            }
                            sortable
                            pagination
                            perPage={20}
                        >
                            <Row
                                className="d-flex justify-content-end mb-3"
                                style={{ padding: '0px 1.25rem' }}
                            >
                                <Col
                                    xs="auto"
                                    lg={12}
                                    style={{
                                        border: '.5px rgb(99 99 99 / 20%) solid',
                                        borderRadius: '10px',
                                        boxShadow:
                                            'rgb(60 64 67 / 20%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px',
                                        padding: '1rem'
                                    }}
                                    className="mb-3"
                                >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Row className="me-2">
                                            <Col xs="auto" lg={4}>
                                                <DatePicker
                                                    closeOnScroll={true}
                                                    selected={startDate}
                                                    onChange={dateChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    formatWeekDay={(day) =>
                                                        day.slice(0, 3)
                                                    }
                                                    className="form-control"
                                                    placeholderText="Date Joined"
                                                    dateFormat="MM/dd/yyyy"
                                                    fixedHeight
                                                />
                                            </Col>
                                            <Col xs="auto" lg={4}>
                                                <SeniorityFilter
                                                    data={seniorityFilter}
                                                    setData={setSeniorityFilter}
                                                />
                                            </Col>
                                            <Col xs="auto" lg={4}>
                                                <DepartmentFilter
                                                    data={departmentFilter}
                                                    setData={
                                                        setDepartmentFilter
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <div className="d-flex">
                                            <motion.button
                                                style={{
                                                    boxShadow:
                                                        '0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)'
                                                }}
                                                className="btn btn-sm btn-primary rounded-pill d-flex align-items-center justify-content-center me-2"
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                onClick={() => handleFilter()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFilter}
                                                    className="me-1"
                                                />
                                                Fitler
                                            </motion.button>
                                            <button
                                                className="btn btn-falcon-secondary rounded-pill btn-sm"
                                                onClick={() =>
                                                    handleFilterReset()
                                                }
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={
                                        filteredUsers
                                            ? filteredUsers.length
                                            : users.length
                                    }
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {!loaded && <div>Loading...</div>}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
        </>
    );
}

export default BuyersDashboard;
