import React from "react";
import Select from 'react-select';

function SeniorityDropdown({user, setUser, levels}) {
    let organizerOptions;
    if (levels){
        organizerOptions = levels.map((level) => {
            const data = {value : level.seniority_level_id, label: level.name}
            return data;
        });
        organizerOptions.sort((a, b) => (a.value > b.value) ? 1 : -1);
    }
    const handleChange = target => {
        setUser({
            ...user,
            ['seniority_level_id']: target.value,
            ['seniority_level']: target.label
        })
    }
    // console.log(organizerOptions, "these are the levels inside the Seniority Dropdown");
    return ( 
        <Select 
            closeMenuOnSelect={true}
            options={organizerOptions}
            placeholder={user.seniority_level}
            classNamePrefix="react-select"
            value={user.seniority_level}
            onChange={value => handleChange(value)}
        />
    );
}

export default SeniorityDropdown;