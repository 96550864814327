import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    FloatingLabel,
    Spinner
} from 'react-bootstrap';
import { createNewCompany, createNewSeller } from 'api/api';
import CompanySizeDropdown from '../CompanySizeDropdown';
import IndustriesDropdown from '../IndustriesDropdown';
import SellersDropdown from './SellersDropdown';

function AddSeller1stStep({ formData, setFormData, step, setStep }) {
    return (
        <Card.Body className="p-4 p-sm-5">
            <div style={{position: "absolute", top: "10px", right: "10px", fontSize: "small", color: "#ccc"}}>C:123</div>
            <Row className="align-items-center mb-2">
                <Col className="mb-2">
                    <h5 id="modalLabel">Add Seller</h5>
                </Col>
                <SellerForm
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    setStep={setStep}
                    hasLabel={true}
                />
            </Row>
        </Card.Body>
    );
}

function SellerForm({ formData, setFormData, step, setStep }) {
    //* State
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    //* Helper Functions

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (formData.company_id !== 0) {
                var res = await createNewSeller(
                    formData.email,
                    formData.first_name,
                    formData.last_name,
                    formData.company_id
                );
            } else {
                var createdCompany = await createNewCompany(
                    formData.company,
                    formData.website,
                    formData.company_size_id,
                    formData.industry_id
                );
                var createdSeller = await createNewSeller(
                    formData.email,
                    formData.first_name,
                    formData.last_name,
                    createdCompany.data
                );
            }
        } catch (err) {
            toast.error(
                `There was an error, please contact DEV. ERROR: ${err.message}`,
                {
                    render: `Error: ${err.message}`,
                    type: 'error',
                    isLoading: false
                }
            );
        }

        if (formData.company_id !== 0) {
            if (res.success) {
                toast.success(
                    `Successfully registered ${formData.first_name} into ${formData.company}`,
                    {
                        theme: 'colored',
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
                setLoading(false);
            } else {
                toast.error(
                    `There was an error, please contact DEV. ERROR: ${res.error}`,
                    {
                        theme: 'colored',
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
                setLoading(false);
            }
        } else {
            if (createdSeller.success) {
                toast.success(
                    `Successfully registered ${formData.first_name} into ${formData.company}`,
                    {
                        theme: 'colored',
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
                setLoading(false);
                return navigate(`/company/${createdCompany.data}`);
            } else {
                toast.error(
                    `There was an error, please contact DEV. ERROR: ${res.error}`,
                    {
                        theme: 'colored',
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
                setLoading(false);
            }
        }

        console.log(res);
    };

    const handleFieldChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={6}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="First Name"
                        className="mb-3"
                    >
                        <Form.Control
                            placeholder={'First Name'}
                            value={formData.first_name}
                            name="first_name"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} sm={6}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Last name"
                        className="mb-3"
                    >
                        <Form.Control
                            placeholder={'Last Name'}
                            value={formData.last_name}
                            name="last_name"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </FloatingLabel>
                </Form.Group>
            </Row>

            <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={6}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                    >
                        <Form.Control
                            placeholder={'Email'}
                            value={formData.email}
                            name="email"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} sm={6}>
                    <SellersDropdown data={formData} setData={setFormData} />
                </Form.Group>
            </Row>

            {formData.company_id === 0 && (
                <Row className="g-2 mb-3">
                    <Form.Group as={Col} sm={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Company Name"
                            className="mb-3"
                        >
                            <Form.Control
                                placeholder={'Company Name'}
                                value={formData.company}
                                name="company"
                                onChange={handleFieldChange}
                                type="text"
                            />
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group as={Col} sm={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Website"
                            className="mb-3"
                        >
                            <Form.Control
                                placeholder={'Website'}
                                value={formData.website}
                                name="website"
                                onChange={handleFieldChange}
                                type="text"
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
            )}

            {formData.company_id === 0 && (
                <Row className="g-2 mb-3">
                    <Form.Group as={Col} sm={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            className="mb-3"
                            style={{ height: 'calc(3.5rem + 2px)' }}
                        >
                            <CompanySizeDropdown
                                company={formData}
                                setCompany={setFormData}
                            />
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group as={Col} sm={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            className="mb-3"
                            style={{ height: 'calc(3.5rem + 2px)' }}
                        >
                            <IndustriesDropdown
                                company={formData}
                                setCompany={setFormData}
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
            )}

            <Form.Group className="mb-3 d-flex justify-content-around">
                <Button
                    className="w-25"
                    type="reset"
                    variant="secondary"
                    disabled={loading}
                >
                    Reset
                </Button>
                {loading && (
                    <Button
                        disabled
                        type="button"
                        className="w-25 d-flex justify-content-center align-items-center"
                    >
                        <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Button>
                )}
                {!loading && formData.company_id !== 0 && (
                    <Button
                        className="w-25"
                        type="submit"
                        disabled={
                            !formData.first_name ||
                            !formData.last_name ||
                            !formData.email ||
                            !formData.company_id
                        }
                        // onClick={() => nextStep()}
                    >
                        Add Seller
                    </Button>
                )}
                {!loading && formData.company_id === 0 && (
                    <Button
                        className="w-25"
                        type="submit"
                        disabled={
                            !formData.first_name ||
                            !formData.last_name ||
                            !formData.email ||
                            !formData.company ||
                            !formData.website ||
                            !formData.company_size_id ||
                            !formData.industry_id
                        }
                        // onClick={() => nextStep()}
                    >
                        Add Seller
                    </Button>
                )}
            </Form.Group>
        </Form>
    );
}

SellerForm.propTypes = {
    hasLabel: PropTypes.bool
};
export default AddSeller1stStep;
