import React, { useState } from 'react';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { createSellerDemos } from 'api/api';

const HomeTableHeader = ({ selectedRowIds, data }) => {
    const [action, setAction] = useState(0);
    const [show, setShow] = useState(false);

    const handleClick = async () => {
        var invited = 0;
        var notInvited = [];
        const selected = Object.keys(selectedRowIds);
        // console.log(selected, 'this is the selected');

        for (let index = 0; index < selected.length; index++) {
            const i = selected[index];
            console.log(data[i].company_id);
            const res = await createSellerDemos(data[i].company_id);
            console.log(
                res,
                `this is the response for company ${data[index].name}`
            );
            if (res.success) {
                invited += 1;
            } else {
                notInvited.push(i);
            }
        }

        if (invited.length > 0) {
            alert(`${invited} matching users were invited.`);
        } else if (invited.length === 0) {
            alert(`No matching ICPs were found for the selected companies`);
        }
    };
    return (
        <Row className="flex-between-center">
            <span style={{fontSize:"small", position: "absolute", top: "5px", right: "5px"}}>C:645</span>
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">
                    Demos For The Month Per Company
                </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <Form.Select
                            size="sm"
                            aria-label="Bulk actions"
                            onChange={(e) => {
                                console.log(e.target.value);
                                setAction(() => e.target.value);
                            }}
                        >
                            <option value="0">Bulk Actions</option>
                            <option value="invite_icps">Invite ICPs</option>
                        </Form.Select>
                        <Button
                            type="button"
                            variant="falcon-default"
                            size="sm"
                            className={`ms-2 ${action === 0 ? `disabled` : ``}`}
                            onClick={() => handleClick()}
                        >
                            Apply
                        </Button>
                    </div>
                ) : null}
            </Col>
        </Row>
    );
};

HomeTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default HomeTableHeader;
