import React, { useState, useEffect } from 'react';
import { getIndustries } from 'api/api';
import Select from 'react-select';

function IndustriesDropdown({ company, setCompany }) {
    const [industries, setIndustries] = useState(null);
    let organizerOptions;

    useEffect(() => {
        async function loadIndustries() {
            try {
                getIndustries().then((res) => setIndustries(res.data));
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        }

        loadIndustries();
    }, []);

    const handleChange = (target) => {
        setCompany({
            ...company,
            ['industry']: target.label,
            ['industry_id']: target.value
        });
    };

    if (industries) {
        organizerOptions = industries.map((object) => {
            const data = { value: object.industry_id, label: object.name };
            return data;
        });
        organizerOptions.sort((a, b) => (a.value > b.value ? 1 : -1));
    }

    return (
        <Select
            options={organizerOptions}
            onChange={(value) => handleChange(value)}
            placeholder={company.industry ? company.industry : 'Industry'}
            value={company.industry}
            classNamePrefix="react-select"
        />
    );
}

export default IndustriesDropdown;
