import React, { useState, useEffect } from 'react';
import { getDepartments } from 'api/api';
import Select from 'react-select';

function DepartmentFilter({ data, setData }) {
    const [departments, setDepartments] = useState(null);
    let organizerOptions;

    useEffect(() => {
        const loadDepartments = async () => {
            try {
                const res = await getDepartments();
                setDepartments(res.data);
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        };
        loadDepartments();
    }, []);

    const handleChange = (target) => {
        setData(target.label);
    };

    if (departments) {
        organizerOptions = departments.map((object) => {
            const data = { value: object.department_id, label: object.name };
            return data;
        });
        organizerOptions.sort((a, b) => (a.value > b.value ? 1 : -1));
        organizerOptions.unshift({ value: 0, label: 'Department' });
    }

    return (
        <Select
            closeMenuOnSelect={true}
            options={organizerOptions}
            placeholder={data ? data : 'Department'}
            classNamePrefix="react-select"
            value={data ? data : 'Department'}
            onChange={(value) => handleChange(value)}
        />
    );
}

export default DepartmentFilter;
