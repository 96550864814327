import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { getMonthlyCompanyDemosTotal } from 'api/api';
import { motion } from 'framer-motion/dist/framer-motion';
// import moment from 'moment';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { First, Last } from 'react-bootstrap/esm/PageItem';

function MonthlyCompanyDemosTotal({}) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    //todo: sort by completed for the current month
    //todo: show down or up tick on approved based on percentage

    useEffect(() => {
        const controller = new AbortController();

        const getData = async () => {
            try {
                const res = await getMonthlyCompanyDemosTotal(controller);
                // console.log(res, 'this is inside the monthly demos');

                res.sort((a, b) => {
                    console.log(
                        a.december[0],
                        ' :this is a',
                        b.december[0],
                        ' :this is b'
                    );
                    return (
                        b.december[0].total_approved -
                        a.december[0].total_approved
                    );
                });

                // console.warn(res, 'this is inside the monthly demos');
                setData(res);
            } catch (err) {
                console.log(JSON.stringify(err.message));
            } finally {
                setLoading(false);
            }
        };

        getData();

        return () => {
            controller.abort();
        };
    }, []);

    const companyMonthlyDemosTotals = [
        {
            accessor: 'name',
            Header: 'Company',
            Cell: (rowData) => {
                const { company_id, name, logo } = rowData.row.original;

                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-2"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {name + ' '}
                        <Link className="ms-1" to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </div>
                );
            }
        },
        {
            accessor: 'none',
            Header: 'July',
            Cell: (rowData) => {
                const index = rowData.row.index;
                const { july } = rowData.row.original;
                console.log(index, '********');
                console.log(july[0], 'this is the july object');

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Total Created</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-warning mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {july[0].demos_created}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Submitted</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-info mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {july[0].total_submitted}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Approved</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-primary mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {july[0].total_approved}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Completed</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-success mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {july[0].total_completed}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Declined</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-danger mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {july[0].total_declined}
                            </div>
                        </OverlayTrigger>
                    </div>
                );
            }
        },
        {
            accessor: 'none1',
            Header: 'August',
            Cell: (rowData) => {
                const { august } = rowData.row.original;

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Total Created</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-warning mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {august[0].demos_created}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Submitted</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-info mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {august[0].total_submitted}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Approved</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-primary mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {august[0].total_approved}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Completed</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-success mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {august[0].total_completed}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Declined</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-danger mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {august[0].total_declined}
                            </div>
                        </OverlayTrigger>
                    </div>
                );
            }
        },
        {
            accessor: 'none2',
            Header: 'September',
            Cell: (rowData) => {
                const { september } = rowData.row.original;

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Total Created</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-warning mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {september[0].demos_created}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Submitted</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-info mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {september[0].total_submitted}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Approved</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-primary mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {september[0].total_approved}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Completed</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-success mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {september[0].total_completed}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Declined</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-danger mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {september[0].total_declined}
                            </div>
                        </OverlayTrigger>
                    </div>
                );
            }
        },
        {
            accessor: 'none3',
            Header: 'October',
            Cell: (rowData) => {
                const { october } = rowData.row.original;

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Total Created</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-warning mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {october[0].demos_created}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Submitted</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-info mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {october[0].total_submitted}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Approved</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-primary mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {october[0].total_approved}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Completed</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-success mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {october[0].total_completed}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Declined</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-danger mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {october[0].total_declined}
                            </div>
                        </OverlayTrigger>
                    </div>
                );
            }
        },
        {
            accessor: 'none4',
            Header: 'November',
            Cell: (rowData) => {
                const { november } = rowData.row.original;

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Total Created</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-warning mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {november[0].demos_created}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Submitted</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-info mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {november[0].total_submitted}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Approved</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-primary mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {november[0].total_approved}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Completed</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-success mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {november[0].total_completed}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Declined</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-danger mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {november[0].total_declined}
                            </div>
                        </OverlayTrigger>
                    </div>
                );
            }
        },
        {
            accessor: 'none5',
            Header: 'December',
            Cell: (rowData) => {
                const { december } = rowData.row.original;

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Total Created</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-warning mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {december[0].demos_created}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Submitted</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-info mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {december[0].total_submitted}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Approved</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-primary mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {december[0].total_approved}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Completed</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge bg-success mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {december[0].total_completed}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip-' + 'top'}>
                                    <strong>Declined</strong>.
                                </Tooltip>
                            }
                        >
                            <div
                                className="d-flex align-items-center justify-content-center badge badge-soft-danger mx-1"
                                style={{
                                    borderRadius: '50%',
                                    height: '25px',
                                    width: '25px'
                                }}
                            >
                                {december[0].total_declined}
                            </div>
                        </OverlayTrigger>
                    </div>
                );
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <Container fluid style={{ padding: '0' }}>
                {/* Demos Per Month Per Company */}
                <Row>
                    <Col>
                        <FalconComponentCard>
                            <FalconComponentCard.Header
                                title="Demos Per Month"
                                titleTag="h2"
                                noPreview
                            />
                            <FalconComponentCard.Body
                                language="jsx"
                                noInline
                                noLight
                            >
                                {loading && !data && <h5>Loading...</h5>}
                                {!loading && data && (
                                    <AdvanceTableWrapper
                                        columns={companyMonthlyDemosTotals}
                                        data={data}
                                    >
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                bordered: true,
                                                striped: true,
                                                className:
                                                    'fs--1 mb-0 overflow-hidden'
                                            }}
                                        />
                                    </AdvanceTableWrapper>
                                )}
                            </FalconComponentCard.Body>
                        </FalconComponentCard>
                    </Col>
                </Row>
            </Container>
        </motion.div>
    );
}

export default MonthlyCompanyDemosTotal;
