import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import {
    getCharityCampaignDetails,
    getCharityCampaignPayouts,
    getCharityCampaignAdditionalEarnings
} from 'api/api';
import ProfileBanner from 'components/pages/user/ProfileBanner';
import { Card, Form } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

function CharityCampaignDetails() {
    const { charityCampaignId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [charityPayouts, setCharityPayouts] = useState(null);
    const [additionalEarnings, setAdditionalEarnings] = useState(null);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await getCharityCampaignDetails(charityCampaignId);
                console.log(
                    res,
                    'this is the response for the charity details'
                );
                setData(() => res.data);
                const res2 = await getCharityCampaignPayouts(charityCampaignId);
                setCharityPayouts(() => res2.data);
                const res3 = await getCharityCampaignAdditionalEarnings(
                    charityCampaignId
                );
                setAdditionalEarnings(() => res3.data);
            } catch (err) {
                console.warn(err.message, 'this is the error');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const additionalEarningsColumns = [
        {
            accessor: 'charity_campaign_id',
            Header: 'Charity Campaign'
        },
        {
            accessor: 'amount',
            Header: 'Amount'
        },
        {
            accessor: 'first_name',
            Header: 'Name',
            Cell: (rowData) => {
                const { first_name, last_name, registered_user_id } =
                    rowData.row.original;

                return (
                    <>
                        <span>{first_name + ' ' + last_name}</span>
                        <Link to={'/user/' + registered_user_id}>
                            ({registered_user_id}){' '}
                        </Link>
                    </>
                );
            }
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        onExit: {
            opacity: 0,
            transition: { duration: 1 }
        }
    };

    return (
        <motion.div
            key="settings"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>
            <ProfileBanner className="mb-3">
                <ProfileBanner.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && data && (
                        <>
                            <div className="d-grid gap-4">
                                <div className="d-grid">
                                    <h2 className="m-0">
                                        #{charityCampaignId} - {data.name}
                                    </h2>
                                    <span>
                                        <u>Created:</u>{' '}
                                        {moment(data.date_created)
                                            .local()
                                            .format('MMM Do YY, h:mm a')}
                                    </span>
                                </div>
                                <div className="d-grid">
                                    <Form.Label>
                                        <u>Description:</u>
                                    </Form.Label>
                                    <span>{data.description}</span>
                                </div>
                            </div>
                        </>
                    )}
                </ProfileBanner.Body>
            </ProfileBanner>

            <Card className="mb-3">
                <Card.Header>
                    <h4>Payouts</h4>
                </Card.Header>
                <Card.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && charityPayouts && (
                        <AdvanceTableWrapper
                            columns={additionalEarningsColumns}
                            data={charityPayouts}
                            sortable
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </AdvanceTableWrapper>
                    )}
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>
                    <h4>Additional Earnings</h4>
                </Card.Header>
                <Card.Body>
                    {loading && <h5>Loading...</h5>}
                    {!loading && additionalEarnings && (
                        <AdvanceTableWrapper
                            columns={additionalEarningsColumns}
                            data={additionalEarnings}
                            sortable
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </AdvanceTableWrapper>
                    )}
                </Card.Body>
            </Card>
        </motion.div>
    );
}

export default CharityCampaignDetails;
