import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function DemoStatusDropdown({ setSelected }) {
    const organizerOptions = [
        { value: 'Completed', label: 'Completed' },
        { value: 'Rescheduled', label: 'Rescheduled' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Declined', label: 'Declined' }
    ];

    const handleChange = (target) => {
        setSelected(target.value);
    };

    return (
        <Select
            closeMenuOnSelect={true}
            closeMenuOnScroll={true}
            options={organizerOptions}
            placeholder={'Select a Demo Status'}
            classNamePrefix="react-select"
            onChange={(value) => handleChange(value)}
        />
    );
}

export default DemoStatusDropdown;
