import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Modal, Button, Toast } from 'react-bootstrap';
import moment from 'moment';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { demoPaid, updateCompany, createDemo, getCompanyAvailableBuyers } from 'api/api';
import { motion } from 'framer-motion/dist/framer-motion';
import './Modal.css';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

function CompanySettingsModal({
    selected,
    show,
    setShow,
    company,
    setCompany,
    action,
    loading
}) {
    const [toast, setToast] = useState(false);
    const [failed, setFailed] = useState(false);
    const [availableBuyers, setAvailableBuyers] = useState([]);
    const [buyersLoaded, setBuyersLoaded] = useState(false);
    const { companyId } = useParams();
    const navigate = useNavigate();

    async function loadBuyers(){
        let res = await getCompanyAvailableBuyers(companyId);

        if (res.success){
            setAvailableBuyers(res.data);
            setBuyersLoaded(true);
        }
    }

    useEffect(() => {
        if (show === true && action === 'Available Buyers')
            loadBuyers();

    }, [show, action]);

    const handleClose = () => setShow(false);

    const handleDelete = async () => {
        // console.log('clicked');
        const d = { is_active: false };
        const response = await updateCompany(companyId, d);
        console.log(response);
        if (response.success) {
            setShow(false);
            setToast(true);
            setTimeout(() => {
                navigate(-1);
            }, 4000);
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleUpdate = async () => {
        const data = {
            name: company['name'],
            company_size_id: company['company_size_id'],
            description: company['description'],
            ideal_customer_profile: company['ideal_customer_profile'],
            industry_id: company['industry_id'],
            pain_point: company['pain_point'],
            unique_value_proposition: company['unique_value_proposition'],
            website: company['website'],
            tagline: company['tagline'],
            sales_rep_id: company['sales_rep_id'],
            annual_revenue_text: company['annual_revenue_text'],
            company_stage_id: company['company_stage_id'],
            url_slug: company['url_slug'],
            year_founded: company['year_founded']
        };

        let response = await updateCompany(companyId, data);

        if (response.success) {
            setShow(false);
            setToast(true);
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleChangePrimarySeller = async () => {
        const data = { default_seller_id: selected.value };
        let response = await updateCompany(companyId, data);

        if (response.success) {
            setShow(false);
            setToast(true);
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleInviteBuyer = async (index, demo) => {
        let d = {
            registered_user_id: demo.registered_user_id,
            company_id: companyId
        };
        const response = await createDemo(d);
        if (response.success) {
            setToast(true);
            setAvailableBuyers((items) => {
                return items.map((item, j) => {
                    if (j === index) {
                        item = { ...item, invited: true };
                        return item;
                    } else {
                        return item;
                    }
                });
            });
        } else if (!response.success) {
            setFailed(true);
        }
    };

    const columns = [
        {
            accessor: 'registered_user_id',
            Header: 'User Id',
            Cell: (rowData) => {
                const { registered_user_id } = rowData.row.original;
                return (
                    <Link to={'/user/' + registered_user_id}>
                        {registered_user_id}
                    </Link>
                );
            }
        },
        {
            accessor: 'demo_count',
            Header: 'Demos'
        },
        {
            accessor: 'date_created',
            Header: 'Joined On',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },
        {
            accessor: 'first_name',
            Header: 'Name',
            Cell: (rowData) => {
                const { first_name, last_name } = rowData.row.original;
                return <span>{first_name + ' ' + last_name}</span>;
            }
        },
        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'company_size',
            Header: 'Size'
        },
        {
            accessor: 'company_name',
            Header: 'Company Name (Id)',
            Cell: (rowData) => {
                const { company_name, company_id } = rowData.row.original;
                return (
                    <span>
                        {company_name + ' '}
                        <Link to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </span>
                );
            }
        },
        {
            accessor: 'seniority_level',
            Header: 'Seniority'
        },
        {
            accessor: 'job_title',
            Header: 'Job Title'
        },
        {
            accessor: 'department',
            Header: 'Department',
            Cell: (rowData) => {
                const { department, department_name } = rowData.row.original;
                if (department_name) {
                    return <span>{department_name}</span>;
                } else {
                    return <span>{department}</span>;
                }
            }
        },
        {
            accessor: 'timezone',
            Header: 'Timezone'
        },

        {
            accessor: 'is_buyer',
            Header: 'Roles',
            Cell: (rowData) => {
                const { is_buyer, is_seller, is_admin } = rowData.row.original;
                let elements = [];
                if (is_buyer)
                    elements.push(
                        <span className="badge badge-soft-success rounded-pill">
                            Buyer
                        </span>
                    );
                if (is_seller)
                    elements.push(
                        <span className="badge badge-soft-primary rounded-pill">
                            Seller
                        </span>
                    );
                if (is_admin)
                    elements.push(
                        <span className="badge badge-soft-warning rounded-pill">
                            Admin
                        </span>
                    );
                return <div>{elements}</div>;
            }
        },
        {
            accessor: 'none',
            Cell: (rowData) => {
                const index = rowData.row.index;
                const demo = rowData.row.original;
                const { invited } = rowData.row.original;

                if (invited) {
                    return (
                        <span className="badge badge-soft-success rounded-pill">
                            Invited
                        </span>
                    );
                }
                return (
                    <motion.button
                        className="btn btn-primary"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleInviteBuyer(index, demo)}
                    >
                        Invite
                    </motion.button>
                );
            }
        }
    ];

    return (
        <>
            {/* Delete Modal */}
            {action === 'Delete' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Delete Company</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this company?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => handleDelete()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The company was successfully deleted. Please
                                wait for the page to navigate back.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}
            {/* Update Modal */}
            {action === 'Update' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Update Company</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to update the companies
                            information?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleUpdate()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={3500}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The company was successfully updated.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {/* Change Primary Seller Modal */}
            {action === 'Primary Seller' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Change Primary Seller</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            (In testing) Are you sure you want to change the
                            primary seller to <u>{selected.label}</u>?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleChangePrimarySeller()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={3500}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                Primary seller was successfully updated.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {/* Available Buyers */}
            {action === 'Available Buyers' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-95vw"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Available Buyers
                            </Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            {availableBuyers.length === 0 && buyersLoaded === true && (
                                <>
                                    <i>No users found...</i>
                                </>
                            )}

                            {buyersLoaded === false && (
                                <i>Loading...</i>
                            )}
                            {availableBuyers.length > 0 && (
                                <AdvanceTableWrapper
                                    columns={columns}
                                    data={availableBuyers}
                                    sortable
                                    pagination
                                    perPage={15}
                                >
                                    <AdvanceTable
                                        table
                                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                                        rowClassName="align-middle white-space-nowrap"
                                        tableProps={{
                                            bordered: true,
                                            striped: true,
                                            className:
                                                'fs--1 mb-0 overflow-hidden'
                                        }}
                                    />
                                    <div className="mt-3">
                                        <AdvanceTableFooter
                                            rowCount={availableBuyers.length}
                                            table
                                            rowInfo
                                            navButtons
                                        />
                                    </div>
                                </AdvanceTableWrapper>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {/* <Button variant="primary" onClick={() => console.log(`clicked`)}>Yes</Button> */}
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={2500}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The user was successfully invited.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {/* Failure Toast */}
            <div className="position-fixed bottom-0 end-0 p-3">
                <Toast
                    onClose={() => setFailed(false)}
                    show={failed}
                    // delay={3500}
                    // autohide
                >
                    <Toast.Header className="bg-danger text-white">
                        <strong className="me-auto">Failed</strong>
                    </Toast.Header>
                    <Toast.Body>
                        There was an error. Contact DEV if this issue persists.
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
}

export default CompanySettingsModal;
