import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import AddSeller1stStep from './AddSeller1stStep';

function Index() {
    //* State
    const initialForm = {
        company: '',
        company_id: '',
        website: '',
        company_size_id: '',
        industry_id: '',
        email: '',
        first_name: '',
        last_name: '',
        company_id: ''
    };
    const [formData, setFormData] = useState({ ...initialForm });
    const [step, setStep] = useState(1);

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <>
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="onExit"
            >
                <AddSeller1stStep
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    setStep={setStep}
                />
            </motion.div>
        </>
    );
}

export default Index;
