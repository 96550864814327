const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || 'http://localhost:3003';
/**
 * Defines the default headers for these functions to work with `json-server`
 */
const headers = new Headers();
headers.append('Content-Type', 'application/json');

function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export async function textmagicTest() {
    const url = new URL(`${API_BASE_URL}/textmagic/test`);
    let access_token = getCookie('access_token');

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: 'Bearer ' + access_token,
            'Content-Type': 'application/json'
        }
    }).then((response) => response.json());

    return response;
}
