import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Toast } from 'react-bootstrap';
import moment from 'moment';
import { dotsWithdraw } from 'api/api';
import FalconCloseButton from 'components/common/FalconCloseButton';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

import './UserPayoutsModal.css';

function UserPayoutsModal({
    show,
    setShow,
    action,
    index,
    modalData,
    demoPayouts,
    additionalEarnings,
    setData
}) {
    console.log(demoPayouts, additionalEarnings);
    const [toast, setToast] = useState(false);
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    console.warn(modalData, '+++++++++++++');

    const handleClose = () => setShow(false);

    const handleDotsWithdraw = async () => {
        const {
            user_payout_request_id,
            reference_id,
            registered_user_id,
            amount,
            charity_amount
        } = modalData;

        try {
            let charity_amount_num = parseFloat(charity_amount);
            let charity_amount_final = 0;
            if (!isNaN(charity_amount_num)){
                let rounded = charity_amount_num.toFixed(2);
                charity_amount_final = parseFloat(rounded);
            }

            setLoading(true);
            const res = await dotsWithdraw({
                user_payout_request_id,
                reference_id,
                registered_user_id,
                amount,
                charity_amount: charity_amount_final
            });
            if (res.success) {
                setData((items) => {
                    return items.map((item, j) => {
                        if (j === index) {
                            item.status = 'Paid';
                            return item;
                        } else {
                            return item;
                        }
                    });
                });
                setShow(false);
                setToast(true);
            } else {
                console.warn(res, 'this is the response from the dots payout');
                setFailed(true);
            }
        } catch (err) {
            console.log(err.message);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            accessor: 'demo_id',
            Header: 'Demo Id',
            Cell: (rowData) => {
                const { demo_id } = rowData.row.original;
                return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            }
        },

        {
            accessor: 'demo_time',
            Header: 'Demo Time',
            Cell: (rowData) => {
                const { demo_time } = rowData.row.original;
                if (!demo_time) {
                    return <div>No date.</div>;
                }
                const date = moment(demo_time)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <div>{date}</div>;
            }
        },
        {
            accessor: 'seller_company_name',
            Header: 'Seller'
        }
    ];

    const additionalEarningsColumns = [
        {
            accessor: 'additional_earning_id',
            Header: 'Additional Earning Id'
            // Cell: (rowData) => {
            //     const { demo_id } = rowData.row.original;
            //     return <Link to={'/demo/' + demo_id}>{demo_id}</Link>;
            // }
        },

        {
            accessor: 'date_created',
            Header: 'Date Created',
            Cell: (rowData) => {
                const { date_created } = rowData.row.original;
                if (!date_created) {
                    return <div>No date.</div>;
                }
                const date = moment(date_created)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <div>{date}</div>;
            }
        },
        {
            accessor: 'name',
            Header: 'Name'
        },
        {
            accessor: 'amount',
            Header: 'Amount',
            Cell: (rowData) => {
                const { amount } = rowData.row.original;

                return <span>${amount}</span>;
            }
        }
    ];

    return (
        <>
            {/* List of Demos */}
            {action === 'Reference Id' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-95vw"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Demos Completed
                            </Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            {demoPayouts && demoPayouts.length > 0 && (
                                <AdvanceTableWrapper
                                    columns={columns}
                                    data={demoPayouts}
                                    sortable
                                    pagination
                                    perPage={15}
                                >
                                    <AdvanceTable
                                        table
                                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                                        rowClassName="align-middle white-space-nowrap"
                                        tableProps={{
                                            bordered: true,
                                            striped: true,
                                            className:
                                                'fs--1 mb-0 overflow-hidden'
                                        }}
                                    />
                                    <div className="my-3">
                                        <AdvanceTableFooter
                                            rowCount={demoPayouts.length}
                                            table
                                            rowInfo
                                            navButtons
                                        />
                                    </div>
                                </AdvanceTableWrapper>
                            )}
                            {additionalEarnings &&
                                additionalEarnings.length > 0 && (
                                    <AdvanceTableWrapper
                                        columns={additionalEarningsColumns}
                                        data={additionalEarnings}
                                        sortable
                                        pagination
                                        perPage={15}
                                    >
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                bordered: true,
                                                striped: true,
                                                className:
                                                    'fs--1 mb-0 overflow-hidden'
                                            }}
                                        />
                                        <div className="my-3">
                                            <AdvanceTableFooter
                                                rowCount={
                                                    additionalEarnings.length
                                                }
                                                table
                                                rowInfo
                                                navButtons
                                            />
                                        </div>
                                    </AdvanceTableWrapper>
                                )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {/* <Button variant="primary" onClick={() => console.log(`clicked`)}>Yes</Button> */}
                        </Modal.Footer>
                    </Modal>
                </>
            )}

            {/* Confirmation Modal For Dots Payout */}
            {action === 'Dots Withdraw' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-95vw"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Dots Payout
                            </Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to send a Dots payout link to{' '}
                            {modalData.first_name + ' ' + modalData.last_name}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {loading && (
                                <Button
                                    variant="secondary disabled"
                                    // onClick={() => handleDotsWithdraw()}
                                >
                                    Loading...
                                </Button>
                            )}
                            {!loading && (
                                <Button
                                    variant="primary"
                                    onClick={() => handleDotsWithdraw()}
                                >
                                    Yes
                                </Button>
                            )}
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={3500}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>Dots link created and sent.</Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            <div className="position-fixed bottom-0 end-0 p-3">
                <Toast
                    onClose={() => setFailed(false)}
                    show={failed}
                    delay={4000}
                    autohide
                >
                    <Toast.Header className="bg-danger text-white">
                        <strong className="me-auto">Failed</strong>
                    </Toast.Header>
                    <Toast.Body>
                        There was an error. Contact DEV if this issue persists.
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
}

export default UserPayoutsModal;
