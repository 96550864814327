import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Toast, Form, Row, Card, Col } from 'react-bootstrap';
import { createStripePayment } from 'api/api';
import FalconCloseButton from 'components/common/FalconCloseButton';

function CompanyChargesModal({
    show,
    setShow,
    data,
    demos,
    amount,
    setAmount,
    notes
}) {
    // console.log(data, 'this is the data object ');
    // console.log(demos, 'these are the demo ids');
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);
    const [failed, setFailed] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShow(false);

    const handleStripeClick = async () => {
        const { user_with_payment_id, company_id } = data;
        console.log(user_with_payment_id, 'this is the user_id');
        console.log(parseInt(amount), 'this is the charge_amount');
        console.log(company_id, 'this is the company_id');
        setLoading(true);

        try {
            const res = await createStripePayment(
                user_with_payment_id,
                amount,
                company_id,
                demos,
                notes
            );
            if (res.success) {
                setToast(true);
                alert(
                    'Payment created successfully check console for more details'
                );
                console.log(res);
                setShow(false);
                navigate(0);
            } else {
                alert('Payment creation failed check console for more details');
                setShow(false);
                console.warn(res);
                navigate(0);
            }
        } catch (err) {
            console.log(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {show && data && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title className="text-warning">
                                Charge {data.name}? (In testing)
                            </Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to charge {data.name} $
                            {amount} through Stripe™? <br />
                            <i>
                                After clicking 'Yes' wait for page to refresh.
                                Do not click 'yes' twice as you could double
                                charge the seller.
                            </i>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleStripeClick()}
                                disabled={loading ? true : false}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                Payment created successfully check console for
                                more details.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}
            <div className="position-fixed bottom-0 end-0 p-3">
                <Toast
                    onClose={() => setFailed(false)}
                    show={failed}
                    delay={4000}
                    autohide
                >
                    <Toast.Header className="bg-danger text-white">
                        <strong className="me-auto">Failed</strong>
                    </Toast.Header>
                    <Toast.Body>
                        Their was an error creating the Stripe payment. Contact
                        DEV if this issue persists.
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
}

export default CompanyChargesModal;
