import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Toast } from 'react-bootstrap';
import moment from 'moment';
import { updateUser } from 'api/api';
import FalconCloseButton from 'components/common/FalconCloseButton';

function UnverifiedBuyersModal({
    index,
    modalData,
    action,
    show,
    setShow,
    setData
}) {
    const [toast, setToast] = useState(false);
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);

    const handleSuspend = async () => {
        setLoading(false);
        try {
            const res = await updateUser(modalData.registered_user_id, {
                is_suspended: true
            });
            if (res.success) {
                handleClose();
                setToast(true);
                setData((items) => {
                    return items.filter((item, j) => j !== index);
                });
            } else {
                handleClose();
                setFailed(true);
            }
        } catch (err) {
            console.error(err.message);
        } finally {
            setLoading(false);
            setShow(false);
        }
    };

    //* is_suspended;
    return (
        <>
            {/* Confirmation Modal to Suspend Buyer */}
            {action === 'Suspend User' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        // dialogClassName="modal-95vw"
                        // aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Suspend Buyer
                            </Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to suspend{' '}
                            {modalData.first_name + ' ' + modalData.last_name}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {loading && (
                                <Button variant="secondary disabled">
                                    Loading...
                                </Button>
                            )}
                            {!loading && (
                                <Button
                                    variant="primary"
                                    onClick={() => handleSuspend()}
                                >
                                    Yes
                                </Button>
                            )}
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={3500}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>Buyer suspended.</Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            <div className="position-fixed bottom-0 end-0 p-3">
                <Toast
                    onClose={() => setFailed(false)}
                    show={failed}
                    delay={4000}
                    autohide
                >
                    <Toast.Header className="bg-danger text-white">
                        <strong className="me-auto">Failed</strong>
                    </Toast.Header>
                    <Toast.Body>
                        There was an error. Contact DEV if this issue persists.
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
}

export default UnverifiedBuyersModal;
