import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { getBuyerCompanies } from "api/api";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";

function BuyerCompanies() {
    const [loaded, setLoaded] = useState(false);
    const [companies, setCompanies] = useState(null);

    useEffect(() => {
        const loadCompanies = async () => {
            try{
                setLoaded(false);
                const res = await getBuyerCompanies();
                console.log(res, "these are the buyer companies&&&&&&&");
                setCompanies(() => res.data);
            } catch (err){
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        }
        loadCompanies();
    }, []);

    const columns =[
        {
            accessor: 'company_id',
            Header: 'Company Id',
            Cell: rowData => {
                const { company_id } = rowData.row.original;
                return (
                    <>
                        <Link to={'/company/' + company_id}>
                            {company_id + ""}
                        </Link>
                        <a href={`https://leadrpro.com/company/${company_id}`} target="_blank"> (Public URL)</a>
                    </>
                )
            }
        },
        {
            accessor: 'date_created',
            Header: 'Created On',
            Cell: rowData => {
                const {date_created} = rowData.row.original;
                const date = moment(date_created).local().format("MMM Do YY, h:mm a");
                return (
                    <span>{date}</span>
                )
            }
        },
        {
            accessor: 'name',
            Header: 'Company Name',
            Cell: rowData => {
                const { company_id, name, logo } = rowData.row.original;
                return (
                    <div
                        className='d-flex align-items-center'
                    >
                        <div
                            className='me-1'
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=''
                                className='dashboard__company-logo-column1'
                                style={{ height: '30px', width: '30px' }}
                            /> 
                        </div>
                        {name + " "}
                        <Link className='ms-1' to={'/company/' + company_id}>({company_id})</Link>
                    </div>
                )
            }
        },
        {
            accessor: 'company_size',
            Header: 'Company Size'
        },
        {
            accessor: 'industry',
            Header: 'Industry'
        },
        {
            accessor: 'website',
            Header: 'Website'
        },
        {
            accessor: 'source',
            Header: 'Source'
        }
    ]

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: .5,
                delay: .5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: "easeInOut" }
        }
    }

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard >
                <FalconComponentCard.Header title="Buyer Companies" titleTag="h3" noPreview/>
                <FalconComponentCard.Body 
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                {(companies && loaded) && 
                    <AdvanceTableWrapper 
                        columns={columns}
                        data={companies}
                        sortable
                        pagination
                        perPage={20}
                    >
                        <Row className='flex-end-center mb-3'>
                            <Col xs='auto' sm={6} lg={4} >
                                <AdvanceTableSearchBox table />
                            </Col>
                        </Row>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                                rowCount={companies.length}
                                table
                                rowInfo
                                navButtons
                            />
                        </div>
                    </AdvanceTableWrapper>
                }
                {!loaded && 
                    <div>Loading...</div>
                }
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
}

export default BuyerCompanies;