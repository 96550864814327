import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Toast, Row, Col, Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { deleteDemo, completeDemo, updateDemo } from 'api/api';

registerLocale('es', es);

function DemoProfileModal({ show, selected, setShow, demo, setDemo, action }) {
    const [loaded, setLoaded] = useState(false);
    const [toast, setToast] = useState(false);
    const [failed, setFailed] = useState(false);
    const [date, setDate] = useState(null);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleDelete = async () => {
        const response = await deleteDemo(demo.demo_id);
        console.log(response);
        if (response.success) {
            setShow(false);
            setToast(true);
            setTimeout(() => {
                navigate(-1);
            }, 3000);
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleUpdate = async () => {
        // ? update the demo status to pending then refresh the page whenever you get a successful response
        // ? if the response is not successful then throw an alert
        // const response = await completeDemo(selected.demo_id);
        // console.log(response);
        // if(response.success){
        //     setShow(false)
        //     setToast(true);
        //     setData(items => {
        //         return items.map((item, j) => {
        //             if(j === index){
        //                 item.demo_status = 'Completed';
        //                 return item;
        //             } else {
        //                 return item;
        //             }
        //         })
        //     })
        // } else if (!response.success){
        //     setShow(false);
        //     setFailed(true);
        // }
    };

    const handleCompleted = async () => {
        const response = await completeDemo(demo.demo_id);

        console.log(response);

        if (response.success) {
            setDemo({
                ...demo,
                ['demo_status']: 'Completed'
            });
            setShow(false);
            setToast(true);
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handlePending = async () => {
        const d = { demo_status_id: 1 };
        const response = await updateDemo(demo.demo_id, d);

        if (response.success) {
            setDemo({
                ...demo,
                ['demo_status']: 'Pending'
            });
            setShow(false);
            setToast(true);
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleSellerUpdate = async () => {
        console.log(selected);
        const d = { seller_id: selected.value };
        console.log(d);
        const response = await updateDemo(demo.demo_id, d);

        console.log(response);

        if (response.success) {
            setShow(false);
            setToast(true);
            setTimeout(() => {
                navigate(0);
            }, 4000);
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    const handleDateUpdate = async () => {
        let newDate = new Date(date).toISOString();
        let newDt = new Date(date);
        let data = {
            seller_status: 'Approved',
            buyer_status: 'Approved',
            reschedule_date_time: newDate
        };

        const response = await updateDemo(demo.demo_id, data);
        console.log('-----', response);

        if (response.success) {
            setShow(false);
            setToast(true);
            setDemo({
                ...demo,
                ['reschedule_date_time']: date
            });
        } else if (!response.success) {
            setShow(false);
            setFailed(true);
        }
    };

    return (
        <>
            {action === 'Delete' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Delete Demo</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this demo?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => handleDelete()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The demo was successfully deleted. Please wait
                                for the page to navigate back.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {action === 'Update' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Update Demo</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want update the demo?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleUpdate()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The demo was successfully updated.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {action === 'Completed' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Update Demo</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to set the demo to completed?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleCompleted()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast onClose={() => setToast(false)} show={toast}>
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The demo was successfully set to completed.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {action === 'Pending' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Update Demo</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to set the demo to pending?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handlePending()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast onClose={() => setToast(false)} show={toast}>
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The demo was successfully set to pending.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {action === 'Change Seller' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Change Seller</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to change the seller to{' '}
                            <u>{selected.label}</u>?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleSellerUpdate()}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={4000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                Seller was successfully changed, wait for the
                                page to refresh.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {action === 'UpdateTime' && (
                <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Update Demo Date & Time</Modal.Title>
                            <FalconCloseButton onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Label>
                                Date & time selected is in local time
                            </Form.Label>
                            <DatePicker
                                selected={date}
                                onChange={(date) => setDate(date)}
                                formatWeekDay={(day) => day.slice(0, 3)}
                                className="form-control"
                                placeholderText="Select Date & Time"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeSelect
                                fixedHeight
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => handleDateUpdate()}
                                className={date ? '' : 'disabled'}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="position-fixed bottom-0 end-0 p-3">
                        <Toast
                            onClose={() => setToast(false)}
                            show={toast}
                            delay={10000}
                            autohide
                        >
                            <Toast.Header className="bg-success text-white">
                                <strong className="me-auto">Success</strong>
                            </Toast.Header>
                            <Toast.Body>
                                The demo date & time was successfully updated.
                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )}

            {/* Toast For Failed Responses */}
            <div className="position-fixed bottom-0 end-0 p-3">
                <Toast
                    onClose={() => setFailed(false)}
                    show={failed}
                    // delay={4000}
                    // autohide
                >
                    <Toast.Header className="bg-danger text-white">
                        <strong className="me-auto">Failed</strong>
                    </Toast.Header>
                    <Toast.Body>
                        Their was an error updating the demo. Contact DEV if
                        this issue persists.
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
}

export default DemoProfileModal;
