import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Form, Card, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { motion } from 'framer-motion/dist/framer-motion';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getSellerFeedback, getCoupons, addCoupon, getSellersForDropdown, getCompanyPackagePurchases, getCompanyPackages, createPackagePurchase, getEventsList, addNewEvent, updateEvent, deleteEvent} from 'api/api';
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import StarRating from 'components/common/StarRating';
import SellersDropdown from '../users/addSeller/SellersDropdown';// from '../demos/completedDemos/SellerDropdown';
import DatePicker, { registerLocale } from 'react-datepicker';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';

function Events() {
    const [loading, setLoading] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [data, setData] = useState([]);
    const [sellers, setSellers] = useState(null);
    const [code, setCode] = useState('');
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('');
    const [date, setDate] = useState(null);
    const [isSingleUse, setIsSingleUse] = useState(false);
    const [packages, setPackages] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState]  =useState('');
    const [zipcode ,setZipcode] = useState('');
    const [website, setWebsite] = useState('');
    const [eventDate, setEventDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [eventTimezone, setEventTimezone] = useState('');
    const [venueInstructions, setVenueInstructions] = useState('');
    const [isFree, setIsFree] = useState(true);
    const [isPartner, setIsPartner] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [currentEventId, setCurrentEventId] = useState(null);
    const inputRefTest = useRef(null);
    const inputRefThumb = useRef(null);
    const ikUploadRefTest = useRef(null);
    const initialForm = {
        company: '',
        company_id: '',
        website: '',
        company_size_id: '',
        industry_id: '',
        email: '',
        first_name: '',
        last_name: '',
        company_id: ''
    };

    const loadData = async () => {
        setLoading(true);
        try {
            const res = await getEventsList();
            console.log(res);
            setData(res.data);
        } catch (err) {
            console.warn('This is the error: ', err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    async function handleSubmit(e){
        e.preventDefault();

        if (name.length > 0){
            let res = await addNewEvent(name, description, address1, address2, city, state, zipcode, website, eventDate, eventTimezone, startDate, endDate, isFree, imageUrl, venueInstructions, isPartner);

            if (res.success){
                setName('');
                setDescription('');
                setAddress1('');
                setAddress2('');
                setCity('');
                setState('');
                setZipcode('');
                setWebsite('');
                setImageUrl('');
                setVenueInstructions('');
                setEventTimezone('');
                setStartDate(null);
                setEndDate(null);
                setEventDate(null);
                setIsFree(false);
                setIsPartner(false);

                await loadData();
                alert("Success");
            }

            else{
                alert("Could not add");
            }
        }

        else{
            alert("Must enter a valid name");
        }
    }

    async function handleUpdate(e){
        e.preventDefault();

        if (name.length > 0){
            let res = await updateEvent(currentEventId, name, description, address1, address2, city, state, zipcode, website, eventDate, eventTimezone, startDate, endDate, isFree, imageUrl, venueInstructions);

            if (res.success){
                setName('');
                setDescription('');
                setAddress1('');
                setAddress2('');
                setCity('');
                setState('');
                setZipcode('');
                setWebsite('');
                setImageUrl('');
                setVenueInstructions('');
                setEventTimezone('');
                setStartDate(null);
                setEndDate(null);
                setEventDate(null);

                await loadData();
                alert("Success");
            }

            else{
                alert("Could not add");
            }
        }

        else{
            alert("Must enter a valid name");
        }
    }
      async function handleEditing(id, data){
        setIsEditing(true);
        setShowAdd(true);
        setCurrentEventId(id);
    
        setName(data.name);
        setDescription(data.description);
        setVenueInstructions(data.venue_instructions);
        setAddress1(data.address1);
        setAddress2(data.address2);
        setCity(data.city);
        setState(data.state);
        setZipcode(data.zipcode);
        setWebsite(data.website);
        setImageUrl(data.image_url);

        if (data.event_date)
        {
            let _date = new Date(data.event_date);
            setEventDate(_date);
        }

        if (data.start_date)
        {
            let _date = new Date(data.start_date);
            setStartDate(_date);
        }

        if (data.end_date)
        {
            let _date = new Date(data.end_date);
            setEndDate(_date);
        }

        setEventTimezone(data.timezone);
      }

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    const columns = [
        {
            accessor: 'event_id',
            Header: 'Edit',
            Cell: (rowData) => {
                const { event_id, name, description, venueInstructions } = rowData.row.original;
                return (
                    <>
                    <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => handleEditing(event_id, rowData.row.original)}>{event_id} {'(Edit)'}</span>
                    {' '}
                    <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => handleShowDelete(event_id)}>{'Delete'}</span>
                    </>
                );
            }
        },

        {
            accessor: 'date_created',
            Header: 'Event Info',
            Cell: (rowData) => {
                const { date_created, name, address1, address2, city, state, zipcode, website, image_url } = rowData.row.original;
                return <div>
                    <div><strong>{name}</strong></div>
                    <div>
                        {image_url && image_url.length > 0 && (
                        <div>
                            <img src={image_url} style={{height: '100px'}} />
                        </div>
                        )}
                        
                        <div><strong>Address1: </strong>{address1}</div>
                        <div><strong>Address2: </strong>{address2}</div>
                        <div><strong>City: </strong>{city}</div>
                        <div><strong>State: </strong>{state}</div>
                        <div><strong>Zipcode: </strong>{zipcode}</div>
                        <div><strong>Website: </strong>{website}</div>
                    </div>
                </div>;
            }
        },

        {
            accessor: 'event_date',
            Header: 'Event Date',
            Cell: (rowData) => {
                const { event_date } = rowData.row.original;
                if (!event_date) {
                    return <span>No date.</span>;
                }
                const date = moment(event_date)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },

        {
            accessor: 'start_date',
            Header: 'Start Date',
            Cell: (rowData) => {
                const { start_date } = rowData.row.original;
                if (!start_date) {
                    return <span>No date.</span>;
                }
                const date = moment(start_date)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },

        {
            accessor: 'end_date',
            Header: 'End Date',
            Cell: (rowData) => {
                const { end_date } = rowData.row.original;
                if (!end_date) {
                    return <span>No date.</span>;
                }
                const date = moment(end_date)
                    .local()
                    .format('MMM Do YY, h:mm a');
                return <span>{date}</span>;
            }
        },

        {
            accessor: 'image_url',
            Header: 'Image',
            Cell: (rowData) => {
                const { image_url } = rowData.row.original;
                if (!image_url) {
                    return <span>No image.</span>;
                }

                return <img src={image_url} style={{height: '100px'}} />
            }
        },

        {
            accessor: 'timezone',
            Header: 'Timezone'
        },

        {
            accessor: 'venue_instructions',
            Header: 'Venue Instructions'
        },

        {
            accessor: 'is_free',
            Header: 'Is Free',
            Cell: (rowData) => {
                const { is_free } = rowData.row.original;
                return <span>{is_free ? 'True': 'False'}</span>
            }
            
        },

        {
            accessor: 'is_partner',
            Header: 'Is Partner',
            Cell: (rowData) => {
                const { is_partner } = rowData.row.original;
                return <span>{is_partner ? 'True': 'False'}</span>
            }
        },
    ];

    const onUploadProgress = (progress) => {
        console.log('Progress', progress);
    };
    const onUploadStart = (evt) => {
        console.log('Start', evt);
    };

    const onError = (evt) => {
        console.log('Error', evt);
    };

    const onSuccess = async (e) => {
        setImageUrl(e.name);
        console.log('success', e.name);

        if (name.length > 0){

        }
    };

    async function handleShowDelete(id){
        setSelectedId(id);
        setShowDeleteConfirm(true);
    }

    async function handleDeleteConfirm(){
        setShowDeleteConfirm(false);
        handleDelete(selectedId);
    }

    async function handleDelete(id){
        let res = await deleteEvent(id);

        if (res.success){
            //alert("Removed");
            await loadData();
        }
    }

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            {/* Company Info Card */}
            <Modal
                show={showAdd}
                contentClassName="border"
              >
            <Card className="mb-3">
                <FalconCardHeader title={'Events'} />
                <Card.Body className="bg-light">
                        {/* Name & Website */}
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Name:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Event name"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Description:
                                </Form.Label>

                                <textarea className='form-control' placeholder='Description' name='description' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Venue Instructions:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Instructions"
                                    name="venue_instructions"
                                    value={venueInstructions}
                                    onChange={(e) => setVenueInstructions(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Address1:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Address1"
                                    name="address1"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Address2:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Address2"
                                    name="address2"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    City:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="City"
                                    name="city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    State:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="State"
                                    name="state"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Zipcode:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Zipcode"
                                    name="zipcode"
                                    value={zipcode}
                                    onChange={(e) => setZipcode(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Website:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Website"
                                    name="website"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Event Date:
                                </Form.Label>
                                {/* <Form.Control
                                    type="date"
                                    placeholder="Event Date"
                                    name="event_date"
                                    value={eventDate}
                                    onChange={(e) => setEventDate(e.target.value)}
                                /> */}
                                <DatePicker
                                selected={eventDate}
                                onChange={(date) => setEventDate(date)}
                                formatWeekDay={(day) => day.slice(0, 3)}
                                className="form-control"
                                placeholderText="Select Date & Time"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                fixedHeight
                            />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Start Date:
                                </Form.Label>
                                <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                formatWeekDay={(day) => day.slice(0, 3)}
                                className="form-control"
                                placeholderText="Select Date & Time"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeSelect
                                fixedHeight
                            />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    End Date:
                                </Form.Label>
                                <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                formatWeekDay={(day) => day.slice(0, 3)}
                                className="form-control"
                                placeholderText="Select Date & Time"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeSelect
                                fixedHeight
                            />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Timezone:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Timezone"
                                    name="timezone"
                                    value={eventTimezone}
                                    onChange={(e) => setEventTimezone(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Image: {imageUrl}
                                </Form.Label>
                                <div className="d-flex flex-column">
                                        <IKContext
                                            publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs="
                                            urlEndpoint="https://ik.imagekit.io/ivgrhmd4k"
                                            authenticationEndpoint={
                                                process.env
                                                    .REACT_APP_API_BASE_URL +
                                                '/imagekit/auth'
                                            }
                                        >
                                            <IKUpload
                                                fileName="logo.jpg"
                                                tags={[
                                                    'sample-tag1',
                                                    'sample-tag2'
                                                ]}
                                                customCoordinates={
                                                    '10,10,10,10'
                                                }
                                                isPrivateFile={false}
                                                useUniqueFileName={true}
                                                responseFields={['tags']}
                                                folder={'/Events'}
                                                onError={onError}
                                                onSuccess={onSuccess}
                                                onUploadProgress={
                                                    onUploadProgress
                                                }
                                                onUploadStart={onUploadStart}
                                                style={{ display: 'none' }} // hide the default input and use the custom upload button
                                                inputRef={inputRefTest}
                                                ref={ikUploadRefTest}
                                            />
                                            {inputRefTest && (
                                                <button
                                                    onClick={() =>
                                                        inputRefTest.current.click()
                                                    }
                                                    className="btn btn-success rounded-pill btn-sm mt-3"
                                                >
                                                    
                                                    Upload
                                                </button>
                                            )}
                                            {/* {ikUploadRefTest && (
                                                <button
                                                    onClick={() =>
                                                        ikUploadRefTest.current.abort()
                                                    }
                                                    className="btn btn-falcon-secondary rounded-pill btn-sm"
                                                >
                                                    Cancel
                                                </button>
                                            )} */}
                                        </IKContext>
                                    </div>
                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Is Free:
                                </Form.Label>
                                
                                <input type='checkbox' value={isFree} onChange={(e) => setIsFree(e.target.checked)} />

                            </Form.Group>

                            <Form.Group as={Col} lg={6}>
                                <Form.Label
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Is Partner:
                                </Form.Label>
                                
                                <input type='checkbox' value={isPartner} onChange={(e) => setIsPartner(e.target.checked)} />

                            </Form.Group>
                        </Row>
                      
                        {/* Submit Button */}
                        {isEditing === false && (
                        <div className="text-end">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={(e) => handleSubmit(e)}
                            >Create Event</motion.button>

                            <div>
                                <span className='btn' onClick={() => setShowAdd(false)}>Cancel</span>
                            </div>
                        </div>
                        )}

                        {isEditing && (
                            <div className="text-end">
                            <motion.button
                                type="submit"
                                className="btn btn-primary"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={(e) => handleUpdate(e)}
                            >Update</motion.button>{' '}<span onClick={() => {
                                setIsEditing(false);
                                setShowAdd(false)
                            }}>Cancel</span>
                        </div>
                        )}
                </Card.Body>
            </Card>
            </Modal>

            <FalconComponentCard>
                <FalconComponentCard.Header
                    title={`Events List (${data.length})`}
                    titleTag="h2"
                    noPreview
                />

                <FalconComponentCard.Body
                    language="jsx"
                    noInline
                    noLight
                    className="bg-light"
                >
                    <div>
                <span onClick={() => setShowAdd(true)} className='btn'>Add new event</span>
            </div>

                    {!loading && purchases && (
                        <AdvanceTableWrapper
                            columns={columns}
                            data={data}
                            sortable
                            pagination
                            perPage={100}
                        >
                            <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table />
                                </Col>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={data.length}
                                    table
                                    rowInfo
                                    navButtons
                                />
                            </div>
                        </AdvanceTableWrapper>
                    )}
                    {loading && <h5>Loading...</h5>}
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {showDeleteConfirm && (
                <Modal
                show={showDeleteConfirm}
                contentClassName="border"
              >
                  <Modal.Header
                    closeButton
                    className="bg-light px-card border-bottom-0"
                  >
                    <Modal.Title as="h5"> Delete Event</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-card">
                    <div><strong>Are you sure you want to delete this event?</strong></div>

                    <div>
                        <span onClick={() => setShowDeleteConfirm(false)}>Cancel</span>

                        <span><button className='btn' onClick={() => handleDeleteConfirm()}>Confirm</button></span>
                    </div>
                    </Modal.Body>
                </Modal>
            )}
        </motion.div>
    );
}

export default Events;
