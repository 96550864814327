import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Col,
    Container,
    OverlayTrigger,
    Row,
    Tooltip,
    Table
} from 'react-bootstrap';
import {
    getUserCountsPerMonth,
    getDemoCounts,
    getMonthlyCompanyDemos,
    getSourceDistribution,
    getChannelDistribution
} from 'api/api';
import { motion } from 'framer-motion/dist/framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import Flex from 'components/common/Flex';
import TableRow from 'components/dashboards/analytics/session-by-browser/TableRow';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import HomeTableHeader from './HomeTableHeader';
import SessionByBrowserChart from 'components/dashboards/analytics/session-by-browser/SessionByBrowserChart';

function round(num, places) {
    var multiplier = Math.pow(10, places);
    return Math.round(num * multiplier) / multiplier;
}

function HomeDasboard({}) {
    // const [data, setData] = useState(null);
    const [sourceDistribution, setSourceDistribution] = useState(null);
    const [channelDistribution, setChannelDistribution] = useState(null);
    const [userCounts, setUserCounts] = useState(null);
    const [demoCounts, setDemoCounts] = useState(null);
    const [companyMonthly, setCompanyMonthly] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const res1 = await getSourceDistribution();
                setSourceDistribution(() => res1.data);

                const res2 = await getChannelDistribution();
                setChannelDistribution(res2.data);

                const res3 = await getDemoCounts();
                setDemoCounts(() => res3.data);

                const res4 = await getMonthlyCompanyDemos();
                setCompanyMonthly(() => res4.data);

                // const res5 = await getUserCountsPerMonth();
                // setUserCounts(() => res5.data);
            } catch (err) {
                console.log(JSON.stringify(err.message));
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const sourceDistributionColumns = [
        {
            accessor: 'source',
            Header: 'Source',
            Cell: (rowData) => {
                const { source } = rowData.row.original;
                return (
                    <Link
                        className="ms-1"
                        to={'/source-distribution/details?source=' + source}
                    >
                        {source}
                    </Link>
                );
            }
        },
        {
            accessor: 'count',
            Header: 'Total # of Users'
        },
        {
            accessor: 'buyer_count',
            Header: 'Buyer Count'
        },
        {
            accessor: 'seller_count',
            Header: 'Seller Count'
        }
    ];

    const channelDistributionColumns = [
        {
            accessor: 'ad_id',
            Header: 'Ad'
        },
        {
            accessor: 'campaign',
            Header: 'Campaign'
        },
        {
            accessor: 'channel',
            Header: 'Channel'
        },
        {
            accessor: 'count',
            Header: '# of Users'
        }
    ];

    const userColumns = [
        {
            accessor: 'year',
            Header: 'Year'
        },
        {
            accessor: 'month',
            Header: 'Month',
            Cell: (rowData) => {
                const { month } = rowData.row.original;
                if (month) {
                    const date = months[month - 1];
                    return <span>{date}</span>;
                }
            }
        },
        {
            accessor: 'count',
            Header: 'Users'
        }
    ];

    const demoColumns = [
        {
            accessor: 'production_to_month',
            Header: 'Month',
            Cell: (rowData) => {
                const { production_to_month } = rowData.row.original;
                if (production_to_month) {
                    const month = moment(production_to_month)
                        .add(1, 'M')
                        .format('MMM YY');
                    return <span>{month}</span>;
                }
            }
        },
        {
            accessor: 'demos_created',
            Header: 'Created'
        },
        {
            accessor: 'demos_submitted',
            Header: 'Submitted'
        },
        {
            accessor: 'demos_approved',
            Header: 'Approved'
        },
        {
            accessor: 'demos_pending',
            Header: 'Pending'
        },
        {
            accessor: 'demos_completed',
            Header: 'Completed',
            Cell: (rowData) => {
                const { demos_completed, demos_created } = rowData.row.original;
                let percentage = (demos_completed / demos_created) * 100;
                percentage = round(percentage, 2);
                // console.log(percentage, 'this is the calculated percentage');
                return (
                    <div>
                        {demos_completed} (
                        <span className="text-info">{percentage}%</span>)
                    </div>
                );
            }
        },
        {
            accessor: 'demos_declined',
            Header: 'Declined',
            Cell: (rowData) => {
                const { demos_declined, demos_created } = rowData.row.original;
                let percentage = (demos_declined / demos_created) * 100;
                percentage = round(percentage, 2);
                // console.log(percentage, 'this is the calculated percentage');
                return (
                    <div>
                        {demos_declined} (
                        <span className="text-info">{percentage}%</span>)
                    </div>
                );
            }
        },
        {
            accessor: 'moneys_total',
            Header: 'Revenue',
            Cell: (rowData) => {
                const { moneys_total } = rowData.row.original;

                return (
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={'tooltip-' + 'top'}>
                                <strong>Demos Completed X 250/avg</strong>.
                            </Tooltip>
                        }
                    >
                        <span className="text-warning">${moneys_total}</span>
                    </OverlayTrigger>
                );
            }
        },
        {
            accessor: 'money_paid',
            Header: 'Demo Cost',
            Cell: (rowData) => {
                const { money_paid } = rowData.row.original;

                return (
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={'tooltip-' + 'top'}>
                                <strong>Demos Completed X 75/avg</strong>.
                            </Tooltip>
                        }
                    >
                        <span className="text-danger">${money_paid}</span>
                    </OverlayTrigger>
                );
            }
        },
        {
            accessor: 'income',
            Header: 'Profit',
            Cell: (rowData) => {
                const { income } = rowData.row.original;

                return (
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={'tooltip-' + 'top'}>
                                <strong>Revenue - Demo Cost</strong>.
                            </Tooltip>
                        }
                    >
                        <span className="text-success">${income}</span>
                    </OverlayTrigger>
                );
            }
        }
    ];

    const monthlyCompanyDemos = [
        {
            accessor: 'name',
            Header: 'Company',
            Cell: (rowData) => {
                const { company_id, name, logo, is_paused } =
                    rowData.row.original;

                if (is_paused) {
                    return (
                        <div
                            className="d-flex align-items-center"
                            style={{ opacity: '.4' }}
                        >
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip id={'tooltip-' + 'top'}>
                                        <strong>Paused</strong>.
                                    </Tooltip>
                                }
                            >
                                <div className="me-2">
                                    <FontAwesomeIcon
                                        icon={faPauseCircle}
                                        size="lg"
                                    />
                                </div>
                            </OverlayTrigger>
                            <div
                                className="me-2"
                                style={{
                                    borderRadius: '50%',
                                    overflow: 'hidden'
                                }}
                            >
                                <img
                                    src={
                                        'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                        'tr:di-@@companylogos@@default-buyer.png/' +
                                        logo
                                    }
                                    alt=""
                                    className="dashboard__company-logo-column1"
                                    style={{ height: '30px', width: '30px' }}
                                />
                            </div>
                            {name + ' '}
                            <Link
                                className="ms-1"
                                to={'/company/' + company_id}
                            >
                                ({company_id})
                            </Link>
                        </div>
                    );
                }

                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-2"
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={
                                    'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                    'tr:di-@@companylogos@@default-buyer.png/' +
                                    logo
                                }
                                alt=""
                                className="dashboard__company-logo-column1"
                                style={{ height: '30px', width: '30px' }}
                            />
                        </div>
                        {name + ' '}
                        <Link className="ms-1" to={'/company/' + company_id}>
                            ({company_id})
                        </Link>
                    </div>
                );

                // return (
                //     <span>
                //         {name + " "}
                //         <Link to={'/company/' + company_id}>({company_id})</Link>
                //     </span>
                // )
            }
        },
        {
            accessor: 'demos_created',
            Header: 'Created'
        },
        {
            accessor: 'total_submitted',
            Header: 'Submitted'
        },
        {
            accessor: 'total_approved',
            Header: 'Approved'
        },
        {
            accessor: 'total_completed',
            Header: 'Completed'
        },
        {
            accessor: 'total_declined',
            Header: 'Declined'
        },
        {
            accessor: 'remaining',
            Header: 'Remaining'
        },
        {
            accessor: 'remaining_current',
            Header: 'Remaining Today'
        }
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <Container fluid style={{ padding: '0' }}>
                {/* Source Distribution */}
                

                {/* Channel Distribution */}
                {/* <Row>
                    <Col>
                        <FalconComponentCard>
                            <FalconComponentCard.Header
                                title="Channel Distribution"
                                titleTag="h2"
                                noPreview
                            />
                            <FalconComponentCard.Body
                                language="jsx"
                                noInline
                                noLight
                            >
                                {loading && !channelDistribution && (
                                    <h5>Loading...</h5>
                                )}
                                
                                {!loading && channelDistribution && (
                                    <AdvanceTableWrapper
                                        columns={channelDistributionColumns}
                                        data={channelDistribution}
                                    >
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                bordered: true,
                                                striped: true,
                                                className:
                                                    'fs--1 mb-0 overflow-hidden'
                                            }}
                                        />
                                    </AdvanceTableWrapper>
                                )}
                            </FalconComponentCard.Body>
                        </FalconComponentCard>
                    </Col>
                </Row> */}

                {/* Demos Per Month */}
                <Row>
                    <Col>
                        <FalconComponentCard>
                            <FalconComponentCard.Header
                                title="Demos Per Month"
                                titleTag="h2"
                                noPreview
                            />
                            <FalconComponentCard.Body
                                language="jsx"
                                noInline
                                noLight
                            >
                                {loading && !demoCounts && <h5>Loading...</h5>}
                                {!loading && demoCounts && (
                                    <AdvanceTableWrapper
                                        columns={demoColumns}
                                        data={demoCounts}
                                    >
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                bordered: true,
                                                striped: true,
                                                className:
                                                    'fs--1 mb-0 overflow-hidden'
                                            }}
                                        />
                                    </AdvanceTableWrapper>
                                )}
                            </FalconComponentCard.Body>
                        </FalconComponentCard>
                    </Col>
                </Row>

                {/* Demos For The Month Per Company */}
                <Row>
                    <Col>
                        {loading && !companyMonthly && <h5>Loading...</h5>}
                        {!loading && companyMonthly && (
                            <AdvanceTableWrapper
                                columns={monthlyCompanyDemos}
                                data={companyMonthly}
                                selection
                                sortable
                            >
                                <Card className="mb-3">
                                    <Card.Header>
                                        <HomeTableHeader
                                            table
                                            data={companyMonthly}
                                        />
                                    </Card.Header>
                                    <AdvanceTable
                                        table
                                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                                        rowClassName="align-middle white-space-nowrap"
                                        tableProps={{
                                            bordered: true,
                                            striped: true,
                                            className:
                                                'fs--1 mb-0 overflow-hidden'
                                        }}
                                    />
                                </Card>
                            </AdvanceTableWrapper>
                        )}
                    </Col>
                </Row>

                {/* Users Per Month */}
                {/* <Row>
                    <Col>
                        <FalconComponentCard>
                            <FalconComponentCard.Header
                                title="New Users Per Month"
                                titleTag="h2"
                                noPreview
                            />
                            <FalconComponentCard.Body
                                language="jsx"
                                noInline
                                noLight
                            >
                                {loading && !userCounts && <h5>Loading...</h5>}
                                {!loading && userCounts && (
                                    <AdvanceTableWrapper
                                        columns={userColumns}
                                        data={userCounts}
                                    >
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                bordered: true,
                                                striped: true,
                                                className:
                                                    'fs--1 mb-0 overflow-hidden'
                                            }}
                                        />
                                    </AdvanceTableWrapper>
                                )}
                            </FalconComponentCard.Body>
                        </FalconComponentCard>
                    </Col>
                </Row> */}
            </Container>
        </motion.div>
    );
}

export default HomeDasboard;
