import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckSquare,
    faTimes,
    faAngleDoubleLeft
} from '@fortawesome/free-solid-svg-icons';
import { Row, ToastContainer, Toast, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion/dist/framer-motion';
import { getUser, addSellerRole, addBuyerRole, updateUser, removeUserRole } from 'api/api';
import { addProfileUrl } from 'api/api';
import Avatar from 'components/common/Avatar';
import moment from 'moment';
import ProfileSettings from './ProfileSettings';
import ProfileBanner from '../../pages/user/ProfileBanner';
import VerifiedBadge from 'components/common/VerifiedBadge';
import './Settings.css';

const Settings = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const loadUser = async () => {
            try {
                setLoaded(false);
                getUser(userId).then((res) => setUser(res.data));
            } catch (err) {
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        };

        loadUser();
    }, [userId]);

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: 'easeInOut' }
        }
    };

    const handleAddSellerRole = async () => {
        const res = await addSellerRole(userId);
        console.log(res);
        if (res.success) {
            navigate(0);
        } else {
            alert('Error adding seller role: ', res.message);
        }
    };

    const handlePauseBuyer = async (bool) => {
        let d = { is_paused: bool };
        try {
            const res = await updateUser(userId, d);
            if (res.success) {
                setUser({
                    ...user,
                    ['is_paused']: bool
                });
                alert(`Buyer Paused: ${bool}`);
            } else {
                alert('Error pausing/unpausing');
            }
        } catch (err) {
            console.warn(err.message);
        }
    };

    const handleAddBuyerRole = async () => {
        const res = await addBuyerRole(userId);
        console.log(res);
        if (res.success) {
            navigate(0);
        } else {
            alert('Error adding buyer role: ', res.message);
        }
    };

    async function handleWorkEmail(e){
        try {
            const res = await updateUser(userId, { work_email_verified: e.target.checked });
            if (res.success) {
                setUser({
                    ...user,
                    ['work_email_verified']: !e.target.checked
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
               // alert(`Buyer Paused: ${bool}`);
            } else {
              //  alert('Error pausing/unpausing');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleEmailVerified(e){
        try {
            const res = await updateUser(userId, { is_verified: e.target.checked });
            if (res.success) {
                setUser({
                    ...user,
                    ['is_verified']: !e.target.checked
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
            } else {
            }
        } catch (err) {
            //console.log(JSON.stringify(err.message));
        }
    }

    async function handleSuspended(e){
        try {
            const res = await updateUser(userId, { is_suspended: e.target.checked });
            if (res.success) {
                setUser({
                    ...user,
                    ['is_suspended']: !e.target.checked
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
               // alert(`Buyer Paused: ${bool}`);
            } else {
              //  alert('Error pausing/unpausing');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handlePaused(e){
        try {
            const res = await updateUser(userId, { is_paused: e.target.checked });
            if (res.success) {
                setUser({
                    ...user,
                    ['is_paused']: !e.target.checked
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
               // alert(`Buyer Paused: ${bool}`);
            } else {
              //  alert('Error pausing/unpausing');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleIsBuyer(e){
        try {
            if (e.target.checked){
                let res = await addBuyerRole(userId);

                if (res.success) {
                    setUser({
                        ...user,
                        ['is_buyer']: !e.target.checked
                    });
    
                    toast.success(`User profile successfully updated`, {
                        theme: 'colored'
                      });
                }
            }

            else{
                let res = await removeUserRole(userId, 1);

                if (res.success) {
                    setUser({
                        ...user,
                        ['is_buyer']: !e.target.checked
                    });
    
                    toast.success(`User profile successfully updated`, {
                        theme: 'colored'
                      });
                }
            }
            //const res = await updateUser(userId, { is_suspended: e.target.checked });

        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleIsSeller(e){
        try {
            if (e.target.checked){
                let res = await addSellerRole(userId);

                if (res.success) {
                    setUser({
                        ...user,
                        ['is_seller']: !e.target.checked
                    });
    
                    toast.success(`User profile successfully updated`, {
                        theme: 'colored'
                      });
                }
            }

            else{
                let res = await removeUserRole(userId, 2);

                if (res.success) {
                    setUser({
                        ...user,
                        ['is_seller']: !e.target.checked
                    });
    
                    toast.success(`User profile successfully updated`, {
                        theme: 'colored'
                      });
                }
            }
            //const res = await updateUser(userId, { is_suspended: e.target.checked });

        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleIsOnTimeout(e){
        try {
            const res = await updateUser(userId, { is_on_timeout: e.target.checked });
            if (res.success) {
                setUser({
                    ...user,
                    ['is_on_timeout']: !e.target.checked
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
               // alert(`Buyer Paused: ${bool}`);
            } else {
              //  alert('Error pausing/unpausing');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleIsActive(e){
        try {
            const res = await updateUser(userId, { is_active: e.target.checked });
            if (res.success) {
                setUser({
                    ...user,
                    ['is_active']: !e.target.checked
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
               // alert(`Buyer Paused: ${bool}`);
            } else {
              //  alert('Error pausing/unpausing');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleEmailsBlocked(e){
        try {
            const res = await updateUser(userId, { emails_blocked: e.target.checked });
            if (res.success) {
                setUser({
                    ...user,
                    ['emails_blocked']: !e.target.checked
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
               // alert(`Buyer Paused: ${bool}`);
            } else {
              //  alert('Error pausing/unpausing');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleInReview(e){
        try {
            const res = await updateUser(userId, { in_review: e.target.checked });
            if (res.success) {
                setUser({
                    ...user,
                    ['in_review']: !e.target.checked
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
               // alert(`Buyer Paused: ${bool}`);
            } else {
              //  alert('Error pausing/unpausing');
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleAppsPerDay(val){
        try {
            const res = await updateUser(userId, { applications_per_day: val });
            if (res.success) {
                setUser({
                    ...user,
                    ['applications_per_day']: val
                });

                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function handleProfileChange(val){
        setUser({
            ...user,
            ['profile_url']: val
        });
    }

    async function handleProfileSave(){
        try {
            const res = await addProfileUrl(userId, user.profile_url);
            if (res.success) {
                toast.success(`User profile successfully updated`, {
                    theme: 'colored'
                  });
            } else {
            }
        } catch (err) {
            console.log(JSON.stringify(err.message));
        }
    }

    async function resendVerification(){
        setVerificationSent(true);
    }

    return (
        <motion.div
            key="settings"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <motion.button
                onClick={() => navigate(-1)}
                className="btn btn-outline-primary rounded-pill my-3 shadow"
                whileTap={{ scale: 0.9 }}
                whileHover={{
                    boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
                    scale: 1.1
                }}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-1">Go Back</span>
            </motion.button>
            <ProfileBanner>
                <ProfileBanner.Body>
                    {loaded && user && (
                        <div className="d-flex align-items-center">
                            <div className="d-flex">
                                <div className="me-3">
                                    <Avatar
                                        src={
                                            'https://ik.imagekit.io/ivgrhmd4k/profileimages/' +
                                            'tr:di-@@companylogos@@default-buyer.png/' +
                                            user.profile_image
                                        }
                                        rounded="circle"
                                        size="4xl"
                                        className="company__avatar"
                                    />
                                </div>
                                <div className="d-grid">
                                    <h5 className="mb-0">
                                    {user.work_email_verified && (
                                                    <VerifiedBadge />
                                                )} #{userId} - {user.first_name} {user.last_name} from{' '}
                                        <Avatar
                                        src={
                                            'https://ik.imagekit.io/ivgrhmd4k/companylogos/' +
                                            'tr:di-@@companylogos@@default-buyer.png/' +
                                            user.company_logo
                                        }
                                        rounded="circle"
                                        size="2xl"
                                        className="company__avatar"
                                        style={{verticalAlign: 'middle', margin: '0px 5px'}}
                                    />
                                        {user.company_name}{' '}
                                        <Link
                                            to={`/company/${user.company_id}`}
                                        >
                                            ({user.company_id})
                                        </Link>
                                        {' '}
                                        {user.profile_url && (
                                        <span style={{fontSize: "small"}}>
                                            <a href={`https://www.leadrpro.com/profile/${user.profile_url}`} target='_blank'>Public Profile</a>
                                        </span>
                                        )}
                                    </h5>

                                    <div style={{margin: '20px'}}>
                                        <input type='text' className='form-control' placeholder='Profile URL Slug' value={user.profile_url} onChange={(e) => handleProfileChange(e.target.value)}  />
                                        <a href='javascript:void(0)' onClick={() => handleProfileSave()}>Save</a>
                                    </div>

                                    <div className="d-grid" style={{marginTop: "20px", marginLeft: "20px"}}>
                                        <div className="d-flex align-items-center">
                                            <span className="me-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    id='cbIsVerified'
                                                    label='Email Verified'
                                                    checked={user.is_verified}
                                                    onChange={(e) => handleEmailVerified(e)}
                                                />
                                            </span>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <div className="me-2" style={{display: 'flex'}}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id='cbInactive'
                                                    label='Work Email Verified'
                                                    checked={user.work_email_verified}
                                                    onChange={(e) => handleWorkEmail(e)}
                                                />

                                                {user.work_email_verified === false && verificationSent === false && (
                                                    <>
                                                        <a href="javascript:void(0)" style={{marginLeft: '10px'}} onClick={() => resendVerification()}>Resend email verification</a>
                                                    </>
                                                )}

                                                {user.work_email_verified === false && verificationSent === true && (
                                                    <>
                                                        <span style={{marginLeft: '10px', fontSize: '12px'}}>Email sent!</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <span className="me-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    id='cbSuspended'
                                                    label='Is Suspended'
                                                    checked={user.is_suspended}
                                                    onChange={(e) => handleSuspended(e)}
                                                />
                                            </span>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <span className="me-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    id='cbPaused'
                                                    label='Is Paused'
                                                    checked={user.is_paused}
                                                    onChange={(e) => handlePaused(e)}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{position: 'absolute', top: '10px', right: '10px', fontSize: 'small'}}>


                                        <div style={{textAlign: 'right'}}>
                                            <label>Apps per day</label>
                                            <div>
                                                <input type='number' className='form-control' style={{width: '100px', float: 'right'}} value={user.applications_per_day} onChange={(e) => handleAppsPerDay(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{marginLeft: "20px"}}>
                                        <span>
                                            <Form.Check
                                                type="checkbox"
                                                id='cbIsBuyer'
                                                label='Buyer'
                                                checked={user.is_buyer}
                                                onChange={(e) => handleIsBuyer(e)}
                                            />
                                        </span>

                                        <span>
                                            <Form.Check
                                                type="checkbox"
                                                id='cbIsSeller'
                                                label='Seller'
                                                checked={user.is_seller}
                                                onChange={(e) => handleIsSeller(e)}
                                            />
                                        </span>

                                        <span>
                                            <Form.Check
                                                type="checkbox"
                                                id='cbOnTimeout'
                                                label='🕰️ 1-month Timeout'
                                                title='Can not apply to new companies for 30 days'
                                                checked={user.is_on_timeout}
                                                onChange={(e) => handleIsOnTimeout(e)}
                                            />
                                        </span>

                                        <span>
                                            <Form.Check
                                                type="checkbox"
                                                id='cbIsActive'
                                                label='Is Active'
                                                title=''
                                                checked={user.is_active}
                                                onChange={(e) => handleIsActive(e)}
                                            />
                                        </span>

                                        <span>
                                            <Form.Check
                                                type="checkbox"
                                                id='cbEmailsBlocked'
                                                label='Emails Blocked'
                                                title=''
                                                checked={user.emails_blocked}
                                                onChange={(e) => handleEmailsBlocked(e)}
                                            />
                                        </span>

                                        <span>
                                            <Form.Check
                                                type="checkbox"
                                                id='cbInReview'
                                                label='In Review'
                                                title=''
                                                checked={user.in_review}
                                                onChange={(e) => handleInReview(e)}
                                            />
                                        </span>

                                        {user.is_admin && (
                                            <span className="badge badge-soft-warning rounded-pill ms-1">
                                                Admin
                                            </span>
                                        )}
                                    </div>

                                    {user && user.rating && (
                                    <div>
                                        <span className="me-2">
                                            Rating:{' '}
                                            {user.rating}
                                        </span>
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex" style={{marginTop: '-170px', flexDirection: 'column'}}>
                                <h3>User Info</h3>
                            <div>
                                <span className="me-2">
                                    Date Joined:{' '}
                                    {moment(user.date_created)
                                        .local()
                                        .format('MMM Do YY, h:mm a')}
                                </span>
                            </div>

                            <div>
                                {user.user_source && (
                                    <span>
                                Source: {user.user_source}
                                </span>
                                )}

                            </div>

                            <div>
                                <span className="me-2">
                                    Country:{' '}
                                    {user.country_name}
                                </span>
                            </div>

                            <div>
                                <span className="me-2">
                                    Demos Completed:{' '}
                                    {user.demos_completed}
                                </span>
                            </div>

                                    {user && user.strikes.length > 0 && (
                                        <div>
                                            <span className='me-2'>
                                                Strikes:{' '}
                                                {user.strikes.map(s => (
                                                    <span>
                                                        <span style={{cursor: 'pointer'}} onClick={() => navigate('/demo/' + s.demo_id)}>⚾</span>
                                                    </span>
                                                ))}
                                            </span>
                                        </div>
                                    )}

                                {/* {!user.is_seller && (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <motion.button
                                            onClick={() =>
                                                handleAddSellerRole()
                                            }
                                            className="btn btn-primary shadow"
                                            whileTap={{ scale: 0.9 }}
                                            whileHover={{
                                                boxShadow:
                                                    '10px 10px 0 rgba(0, 0, 0, 0.2)',
                                                scale: 1.1
                                            }}
                                        >
                                            Add Seller
                                        </motion.button>
                                    </div>
                                )} */}
                                {/* {!user.is_buyer && (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <motion.button
                                            onClick={() => handleAddBuyerRole()}
                                            className="btn btn-primary shadow"
                                            whileTap={{ scale: 0.9 }}
                                            whileHover={{
                                                boxShadow:
                                                    '10px 10px 0 rgba(0, 0, 0, 0.2)',
                                                scale: 1.1
                                            }}
                                        >
                                            Add Buyer
                                        </motion.button>
                                    </div>
                                )} */}
                                
                                {/* {!user.is_paused && user.is_buyer && (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <motion.button
                                            onClick={() =>
                                                handlePauseBuyer(true)
                                            }
                                            className="btn btn-primary shadow"
                                            whileTap={{ scale: 0.9 }}
                                            whileHover={{
                                                boxShadow:
                                                    '10px 10px 0 rgba(0, 0, 0, 0.2)',
                                                scale: 1.1
                                            }}
                                        >
                                            Pause Buyer
                                        </motion.button>
                                    </div>
                                )} */}
                            </div>
                        </div>
                    )}
                </ProfileBanner.Body>
            </ProfileBanner>
            <Row className="g-1">
                {loaded && user && (
                    <ProfileSettings user={user} setUser={setUser} />
                )}
                {!loaded && <div>Loading...</div>}
            </Row>
        </motion.div>
    );
};

export default Settings;
