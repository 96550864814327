import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getBuyersForDropdown } from 'api/api';

function BuyerDropdown({ data, setData }) {
    const [sellers, setSellers] = useState(null);
    let organizerOptions;

    useEffect(() => {
        const loadSellers = async () => {
            try {
                const res = await getBuyersForDropdown();
                setSellers(res);
            } catch (err) {
                console.log(JSON.stringify(err));
            }
        };

        loadSellers();
    }, []);

    if (sellers) {
        organizerOptions = sellers.map((level) => {
            const data = { value: level.company_id, label: level.name };
            return data;
        });
        organizerOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
        organizerOptions.unshift({ value: 0, label: 'Add New Company' });
    }
    const handleChange = (target) => {
        setData({
            ...data,
            ['company']: target.label,
            ['company_id']: target.value
        });
    };
    // console.log(organizerOptions, "these are the levels inside the Seniority Dropdown");
    return (
        <Select
            closeMenuOnSelect={true}
            options={organizerOptions}
            placeholder={'Select a Company'}
            classNamePrefix="react-select"
            onChange={(value) => handleChange(value)}
            className="form-control p-0"
        />
    );
}

export default BuyerDropdown;
