import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { motion } from "framer-motion/dist/framer-motion";
import { getUsers } from "api/api";
import moment from 'moment';
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";

function UsersDashboard () {
    const [loaded, setLoaded] = useState(false);
    const [users, setUsers] = useState(null);
    useEffect(()=> {
        const loadUsers = async () => {
            try {
                setLoaded(false);
                getUsers().then(res => setUsers(res.data.data));
            } catch(err){
                console.log(JSON.stringify(err));
            } finally {
                setLoaded(true);
            }
        }
        loadUsers();
    }, [])
       // console.log(users, "this is inside the users dashboard");
    const columns = [
        {
            accessor: 'registered_user_id',
            Header: 'User Id',
            Cell: rowData => {
                const { registered_user_id } = rowData.row.original
                return (
                    <Link to={'/user/' + registered_user_id}>
                        {registered_user_id}
                    </Link>
                )
            }
        },

        {
            accessor: 'date_created',
            Header: 'Joined On',
            Cell: rowData => {
                const {date_created} = rowData.row.original;
                const date = moment(date_created).local().format("MMM Do YY, h:mm a");
                return (
                    <span>{date}</span>
                )
            }
        },
        {
            accessor: 'first_name',
            Header: 'Name',
            Cell: rowData => {
                const { first_name, last_name } = rowData.row.original;
                return (
                    <span>{first_name + " " + last_name}</span>
                )
            }
        },
        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'company_size',
            Header: 'Size'
        },
        {
            accessor: 'company_name',
            Header: 'Company Name (Id)',
            Cell: rowData => {
                const { company_name, company_id } = rowData.row.original;
                return (
                    <span>
                        {company_name + " "}
                        <Link to={'/company/' + company_id}>({company_id})</Link>
                    </span>
                )
            }
        },
        {
            accessor: 'seniority_level',
            Header: 'Seniority'
        },
        {
            accessor: 'job_title',
            Header: 'Job Title'
        },
        {
            accessor: 'department',
            Header: 'Department',
            Cell: rowData => {
                const { department, department_name } = rowData.row.original;
                if(department_name){
                    return (
                        <span>{department_name}</span>
                    )
                } else {
                    return (
                        <span>{department}</span>
                    )
                }
            }
        },
        {
            accessor: 'timezone',
            Header: 'Timezone'
        },
        // {
        //     accessor: 'source',
        //     Header: 'Source'
        // },
        {
            accessor: 'is_buyer',
            Header: 'Roles',
            Cell: rowData => {
                const { is_buyer, is_seller, is_admin } = rowData.row.original;
                let elements = []
                if(is_buyer) elements.push(<span className="badge badge-soft-success rounded-pill">Buyer</span>);
                if(is_seller) elements.push(<span className="badge badge-soft-primary rounded-pill">Seller</span>);
                if(is_admin) elements.push(<span className="badge badge-soft-warning rounded-pill">Admin</span>);
                return (
                    <div>{elements}</div>
                )
            }
        },
    ];

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: .5,
                delay: .5
            }
        },
        onExit: {
            opacity: 0,
            transition: { ease: "easeInOut" }
        }
    }
    
    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="onExit"
        >
            <FalconComponentCard >
                <FalconComponentCard.Header title='Users' titleTag='h2' noPreview/>
                <FalconComponentCard.Body
                    language='jsx'
                    noInline
                    noLight
                    className="bg-light"
                > 
                
                {(loaded && users) &&
                    <AdvanceTableWrapper 
                        columns={columns}
                        data={users}
                        sortable
                        pagination
                        perPage={15}
                    >
                        <Row className='flex-end-center mb-3'>
                            <Col xs='auto' sm={6} lg={4} >
                                <AdvanceTableSearchBox table/>
                            </Col>
                        </Row>
                        < AdvanceTable 
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className='mt-3'>
                            <AdvanceTableFooter 
                                rowCount={users.length}
                                table
                                rowInfo
                                navButtons
                            />
                        </div>
                    </AdvanceTableWrapper>
                }
                {!loaded && 
                    <div>Loading...</div>
                }
                    </FalconComponentCard.Body>
            </FalconComponentCard>
        </motion.div>
    );
};

export default UsersDashboard;